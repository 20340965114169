import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { httpClient } from "app/datasources";
import { AxiosError, AxiosResponse } from "axios";
import { Response } from "../types";
import { Bom } from "./types";

export const DEACTIVE_BOM_URL = (id: ID) => `/api/admin/boms/${id}/deactive`;

export enum DeactiveBomError {}

export const deactiveBomErrorMessages = {};

export type DeactiveBomResponse = Response<Bom>;

export type DeactiveBomArgs = {
  id: ID;
};

export const deactiveBom = ({ id }: DeactiveBomArgs) => {
  return httpClient.put<DeactiveBomResponse>(DEACTIVE_BOM_URL(id));
};

export const useDeactiveBomMutation = (
  opts?: Omit<
    UseMutationOptions<
      AxiosResponse<DeactiveBomResponse>,
      AxiosError<any>,
      DeactiveBomArgs
    >,
    "mutationFn"
  >,
) =>
  useMutation<
    AxiosResponse<DeactiveBomResponse>,
    AxiosError<any>,
    DeactiveBomArgs
  >({
    mutationFn: deactiveBom,
    mutationKey: [DEACTIVE_BOM_URL],
    ...opts,
  });
