import { ColumnDef } from "@tanstack/react-table";
import { Button } from "app/components/molecules/Button";
import { useConfirm } from "app/components/molecules/ConfirmationProvider";
import { DebouncedInput } from "app/components/molecules/DebounceInput";
import { DataTable } from "app/components/organisms/DataTable";
import ListPage from "app/components/templates/ListPage";
import { DEFAULT_LIMIT_PAGE, DEFAULT_PAGE } from "app/helpers";
import { useAppTranslation } from "app/hooks";
import useAppSearch from "app/hooks/useAppSearch";
import {
  MasterDataAction,
  MasterDataActionDropdown,
  MasterDataFormData,
  MasterDataFormModal,
} from "app/modules/masterData/components";
import {
  useCreateMasterData,
  useDeleteMasterData,
  useRetrieveMasterDatas,
  useUpdateMasterData,
} from "app/modules/masterData/hooks";
import {
  mapCreateMasterData,
  mapUpdateMasterData,
} from "app/modules/masterData/mapper";
import { MasterData, MasterDataType } from "app/services/masterData";
import BreadCrumb from "Components/Common/BreadCrumb";
import "gridjs/dist/theme/mermaid.css";
import React, { FC, useEffect, useMemo, useRef, useState } from "react";
import { Col, Row } from "reactstrap";

interface ListMasterDataProps {}

const ListMasterData: FC<ListMasterDataProps> = () => {
  const { masterDataText, confirmText, t } = useAppTranslation();
  const { confirm } = useConfirm();
  const params = new URLSearchParams(location.search);
  const [isMasterDataModalOpen, toggleMasterDataModal] = useState(false);
  const rowSelected = useRef<MasterData | null>(null);

  const handleToggleMasterDataModal = (data?: MasterData) => {
    if (data) {
      rowSelected.current = data;
    }
    if (isMasterDataModalOpen) {
      rowSelected.current = null;
    }
    toggleMasterDataModal((prevState) => !prevState);
  };

  const {
    page,
    limit,
    searchString,
    customParams,
    setPage,
    setLimit,
    setSearchString,
    setCustomParams,
  } = useAppSearch<{ type: MasterDataType | null }>({
    initialPage: DEFAULT_PAGE,
    initialLimit: DEFAULT_LIMIT_PAGE,
    initialSearchString: "",
    initialCustomParams: {
      type: null,
    },
  });

  const initType = params.get("type") as MasterDataType | null;

  useEffect(() => {
    setCustomParams({ ...customParams, type: initType });
    setPage(DEFAULT_PAGE);
    setSearchString("");
  }, [initType]);

  const { masterDatas, isLoadingMasterDatas, isRefetching } =
    useRetrieveMasterDatas({
      limit,
      page,
      searchString: searchString || undefined,
      type: customParams?.type || undefined,
    });

  const { mutateAsync: deleteMasterData } = useDeleteMasterData();

  const handleActionClick = (action: MasterDataAction, data?: MasterData) => {
    switch (action) {
      case "edit":
        if (data) {
          handleToggleMasterDataModal(data);
        }
        break;
      case "delete":
        if (data?.id) {
          confirm({
            title: confirmText.deleteTitle(masterDataText.title),
            description: confirmText.deleteContent(masterDataText.title),
            onConfirm: () => deleteMasterData({ id: data.id }),
          });
        }
        break;
      default:
        break;
    }
  };

  const columns: ColumnDef<MasterData>[] = useMemo(
    () => [
      {
        header: masterDataText.field.name,
        accessorKey: "name",
      },
      {
        header: masterDataText.field.description,
        accessorKey: "description",
        cell: ({ row }) => row.original.description || "-",
      },
      {
        header: masterDataText.field.color,
        accessorKey: "colorCode",
        cell: ({ row }) =>
          row.original.colorCode ? (
            <>
              <div className="d-flex">
                <i
                  style={{
                    border:
                      row.original.colorCode.toLowerCase() === "#ffffff" ||
                      row.original.colorCode.toLowerCase() === "#fff"
                        ? "1px solid black"
                        : undefined,
                    borderRadius: "8px",
                    height: "20px",
                    width: "20px",
                    background: row.original.colorCode,
                    display: "block",
                  }}
                />
              </div>
            </>
          ) : (
            "-"
          ),
      },
      {
        header: "",
        accessorKey: "action",
        size: 40,
        cell: ({ row }) => (
          <MasterDataActionDropdown
            onAction={handleActionClick}
            data={row.original}
          />
        ),
      },
    ],
    [masterDataText],
  );

  const { mutateAsync: createMasterData, isPending: isCreating } =
    useCreateMasterData({
      onSuccess: () => {
        handleToggleMasterDataModal();
      },
    });

  const handleCreateMasterData = (data: MasterDataFormData) => {
    if (initType) {
      createMasterData({
        ...mapCreateMasterData({ ...data }),
        type: initType,
      });
    }
  };

  const { mutateAsync: updateMasterData, isPending: isUpdating } =
    useUpdateMasterData({
      id: rowSelected.current?.id,
      onSuccess: () => {
        handleToggleMasterDataModal();
      },
    });

  const handleUpdateMasterData = (data: MasterDataFormData) => {
    if (rowSelected.current) {
      if (initType) {
        updateMasterData({
          ...mapUpdateMasterData({
            id: rowSelected.current.id,
            ...data,
          }),
          type: initType,
        });
      }
    }
  };

  const handleSubmitMasterData = (data: MasterDataFormData) => {
    if (rowSelected.current) {
      handleUpdateMasterData(data);
    } else {
      handleCreateMasterData(data);
    }
  };

  const title = t(`menu.${initType || "masterData"}`);

  return (
    <React.Fragment>
      <ListPage>
        <ListPage.BreadCrumb>
          <BreadCrumb title={title} pageTitle="Kingston" />
        </ListPage.BreadCrumb>
        <ListPage.Filter>
          <Row>
            <Col>
              <Row>
                <Col xs={6}>
                  <div
                    className="search-box me-2 mb-2 d-inline-block col-12"
                    style={{ width: 200 }}
                  >
                    <DebouncedInput
                      name="list-master-data-search"
                      value={searchString || ""}
                      onChange={(value) => {
                        setSearchString(String(value));
                      }}
                    />
                    <i className="bx bx-search-alt search-icon"></i>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col className="text-end">
              <Button
                color="primary"
                size="sm"
                onClick={() => handleToggleMasterDataModal()}
              >
                {masterDataText.add}
              </Button>
            </Col>
          </Row>
        </ListPage.Filter>
        <ListPage.Main>
          <DataTable<MasterData>
            columns={columns}
            data={masterDatas?.result || []}
            loading={isLoadingMasterDatas || isRefetching}
            page={page}
            limit={limit}
            setPage={setPage}
            setLimit={setLimit}
            total={masterDatas?.total || 0}
          />
        </ListPage.Main>
      </ListPage>
      <MasterDataFormModal
        open={isMasterDataModalOpen}
        onToggle={handleToggleMasterDataModal}
        onSubmit={handleSubmitMasterData}
        submitting={isCreating || isUpdating}
        edit={!!rowSelected.current}
        data={rowSelected.current || undefined}
      />
    </React.Fragment>
  );
};

export default ListMasterData;
