import FormPage from "app/components/templates/FormPage";
import { useAppTranslation } from "app/hooks";
import { BomForm, BomFormData } from "app/modules/bom/components";
import { useCreateBom } from "app/modules/bom/hooks";
import { mapCreateBom, mapDefaultBom } from "app/modules/bom/mapper";
import { getProductLabel } from "app/modules/product/helpers";
import { useRetrieveProductById } from "app/modules/product/hooks";
import { Product } from "app/services/product";
import BreadCrumb from "Components/Common/BreadCrumb";
import { FC } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Card, CardBody } from "reactstrap";

export interface CreateBomByProductProps {}

const CreateBomByProduct: FC<CreateBomByProductProps> = () => {
  const { id } = useParams();
  const { bomText } = useAppTranslation();
  const navigate = useNavigate();
  const { mutateAsync, isPending } = useCreateBom({
    onSuccess: () => {
      navigate(-1);
    },
  });

  const handleSubmit = async (data: BomFormData) => {
    mutateAsync(mapCreateBom(data));
  };

  const { product, isLoadingProduct } = useRetrieveProductById(
    { id: id as string },
    {
      enabled: !!id,
    },
  );

  return (
    <FormPage loading={isLoadingProduct}>
      <FormPage.BreadCrumb>
        <BreadCrumb title={bomText.add} pageTitle={bomText.title} isBack />
      </FormPage.BreadCrumb>
      <FormPage.Main>
        <Card>
          <CardBody>
            <BomForm
              onSubmit={handleSubmit}
              defaultValues={{
                ...mapDefaultBom(),
                product: product.id
                  ? {
                      label: getProductLabel(product as Product),
                      value: product.id,
                    }
                  : null,
              }}
              submitting={isPending}
              isDisableProduct
            />
          </CardBody>
        </Card>
      </FormPage.Main>
    </FormPage>
  );
};

export default CreateBomByProduct;
