import { useAppTranslation } from "app/hooks";
import { OptionSelect } from "app/services/types";
import React from "react";
import Select, { Props } from "react-select";
import { useRetrieveProductionPlanById } from "../../hooks";

export type ProductionPlanItemOptionSelect = OptionSelect<ID> & {
  quantity: number | null;
  planQty: number | null;
};

export interface ProductionPlanItemSelectProps
  extends Omit<
    Props<ProductionPlanItemOptionSelect, false>,
    "value" | "onChange"
  > {
  id?: ID;
  value: ProductionPlanItemOptionSelect | null;
  onChange: (value: ProductionPlanItemOptionSelect | null) => void;
  isInvalid?: boolean;
}

export const ProductionPlanItemSelect: React.FC<
  ProductionPlanItemSelectProps
> = ({ value, id, onChange, isInvalid, ...props }) => {
  const { placeHolderText } = useAppTranslation();
  const { productionPlan, isLoadingProductionPlan } =
    useRetrieveProductionPlanById(
      {
        id: id as string,
      },
      { enabled: !!id },
    );

  const productionPlanItems = productionPlan?.planItems || [];

  return (
    <>
      <Select<ProductionPlanItemOptionSelect, false>
        {...props}
        className={`${props.className} ${isInvalid ? "is-invalid" : ""}`}
        noOptionsMessage={() => "Không có dữ liệu"}
        value={value}
        isMulti={false}
        isLoading={isLoadingProductionPlan}
        onChange={onChange}
        options={
          productionPlanItems.map((item) => ({
            value: item.id,
            label: `Mục đơn hàng #${item.orderItem?.order?.id} - ${item.orderItem?.product?.nameVn}`,
            quantity: item.quantity || null,
            planQty: item.productionOrderQty || null,
          })) || []
        }
        getOptionLabel={(option) => option.label}
        getOptionValue={(option) => option.value}
        isClearable
        placeholder={placeHolderText.select}
        styles={{
          control: (provided) => ({
            ...provided,
            boxShadow: "none",
            bproductionPlanColor: isInvalid
              ? "var(--vz-form-invalid-color)"
              : provided.bproductionPlanColor,
            ":hover": {
              bproductionPlanColor: isInvalid
                ? "var(--vz-form-invalid-color)"
                : provided.bproductionPlanColor,
            },
          }),
        }}
      />
    </>
  );
};
