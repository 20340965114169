import {
  createContext,
  FC,
  PropsWithChildren,
  Suspense,
  useContext,
  useEffect,
} from "react";
import { useNavigate } from "react-router-dom";

import { LoadingScreen } from "app/components/organisms/LoadingScreen";
import { employeeStorage } from "app/services/auth";
import { Employee } from "app/services/employee";

const PanelEmployeeContext = createContext<{ employee: Employee | null }>({
  employee: null,
});

const PanelEmployeeProtected: FC<PropsWithChildren> = ({ children }) => {
  const navigate = useNavigate();
  const employeeLS = employeeStorage.get();

  useEffect(() => {
    if (!employeeLS) {
      navigate("/panel/scan");
    }
  }, [employeeLS]);

  useEffect(() => {
    window.addEventListener("storage", (event) => {
      if (event.key === "ks-employee-info") {
        navigate("/panel/scan");
      }
    });
  }, []);

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    const resetTimeout = () => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        employeeStorage.remove();
        navigate("/");
      }, 10000);
    };

    const handleUserActivity = () => {
      resetTimeout();
    };

    window.addEventListener("mousemove", handleUserActivity);
    window.addEventListener("keydown", handleUserActivity);

    resetTimeout();

    return () => {
      clearTimeout(timeout);
      window.removeEventListener("mousemove", handleUserActivity);
      window.removeEventListener("keydown", handleUserActivity);
    };
  }, [navigate]);

  if (!employeeLS) {
    return <LoadingScreen />;
  }

  return (
    <Suspense fallback={<LoadingScreen />}>
      <PanelEmployeeContext.Provider value={{ employee: employeeLS }}>
        {children}
      </PanelEmployeeContext.Provider>
    </Suspense>
  );
};

const usePanelEmployee = () => useContext(PanelEmployeeContext);

export default PanelEmployeeProtected;

export { usePanelEmployee };
