import { UseMutateAsyncFunction } from "@tanstack/react-query";
import { FileDTO, FileKey, FilePreview, Source } from "app/models";
import {
  UploadFilesArgs,
  UploadFilesResponse,
  UploadSingleFilesArgs,
  UploadSingleFilesResponse,
} from "app/services/file";
import difference from "lodash/difference";

export interface UploadFilesProps {
  upload: UseMutateAsyncFunction<
    UploadFilesResponse,
    any,
    UploadFilesArgs,
    unknown
  >;
  data: File[];
  source: Source;
  fileKey: FileKey;
}

export const getUploadedFileIds = async ({
  upload,
  data,
  source,
  fileKey,
}: UploadFilesProps): Promise<UploadFilesResponse | undefined> => {
  const newImages = data.filter((item) => item instanceof File);

  if (newImages.length === 0) return undefined;

  return await upload({
    files: newImages,
    moduleType: source,
    fileType: fileKey,
  });
};

export interface UploadSingleFilesProps {
  upload: UseMutateAsyncFunction<
    UploadSingleFilesResponse,
    any,
    UploadSingleFilesArgs,
    unknown
  >;
  data: File;
  source: Source;
  fileKey: FileKey;
}

export const getDeleteFileIds = (
  currents?: FileDTO[],
  remains?: FilePreview[],
) => {
  const currentIds = currents?.map((image) => image.id) || [];
  const remainIds =
    remains?.reduce<ID[]>((ids, image) => {
      if (image.id) ids.push(image.id);
      return ids;
    }, []) || [];
  return difference(currentIds, remainIds);
};
