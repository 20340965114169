import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { httpClient } from "app/datasources";
import { AxiosError, AxiosResponse } from "axios";
import { Response } from "../types";
import { Material } from "./types";

export const UPDATE_MATERIAL_URL = (id: ID) => `/api/admin/materials/${id}`;

export enum UpdateMaterialError {}

export const updateMaterialErrorMessages = {};

export type UpdateMaterialResponse = Response<Material>;

export type UpdateMaterialArgs = {
  id: ID;
  code: string;
  name: string;
  purchasePrice: number | null;
  description: string | null;
  unitId: ID | null;
  vendorId: ID | null;
};

export const updateMaterial = ({ id, ...args }: UpdateMaterialArgs) => {
  return httpClient.put<UpdateMaterialResponse>(UPDATE_MATERIAL_URL(id), args);
};

export const useUpdateMaterialMutation = (
  opts?: Omit<
    UseMutationOptions<
      AxiosResponse<UpdateMaterialResponse>,
      AxiosError<any>,
      UpdateMaterialArgs
    >,
    "mutationFn"
  >,
) =>
  useMutation<
    AxiosResponse<UpdateMaterialResponse>,
    AxiosError<any>,
    UpdateMaterialArgs
  >({
    mutationFn: updateMaterial,
    mutationKey: [UPDATE_MATERIAL_URL],
    ...opts,
  });
