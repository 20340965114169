import { useDetailPageContext } from "app/components/templates/DetailPage/DetailPageContext";
import { useAppTranslation } from "app/hooks";
import { Bom } from "app/services/bom";
import { FC } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "reactstrap";

export interface DetailBomHeaderProps {}

const DetailBomHeader: FC<DetailBomHeaderProps> = () => {
  const { buttonText } = useAppTranslation();
  const { id } = useParams();
  const navigate = useNavigate();

  const { data: bom } = useDetailPageContext<Bom>();

  return (
    <div className="d-flex gap-4 flex-wrap">
      <div className="flex-grow-1 p-2">
        <h3 className="mb-1">{bom?.name}</h3>
      </div>
      <div className="text-end">
        <div className="d-flex gap-2 justify-content-end">
          <Button color="primary" onClick={() => navigate(`/bom/${id}/update`)}>
            {buttonText.edit}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default DetailBomHeader;
