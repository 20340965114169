import { useDetailPageContext } from "app/components/templates/DetailPage/DetailPageContext";
import { useAppTranslation } from "app/hooks";
import { Component } from "app/services/product";
import { FC } from "react";
import { Button } from "reactstrap";

export interface DetailComponentHeaderProps {
  onActionClick?: () => void;
}

const DetailComponentHeader: FC<DetailComponentHeaderProps> = ({
  onActionClick,
}) => {
  const { componentText, buttonText } = useAppTranslation();

  const { data: component } = useDetailPageContext<Component>();

  const avatar = component?.componentImage?.attachmentUrl;

  return (
    <div className="d-flex gap-4 flex-wrap">
      <div className="avatar-lg d-flex justify-content-center align-items-center">
        {avatar ? (
          <img
            src={avatar}
            alt={component.name}
            className="img-thumbnail rounded-4 w-100 h-100"
          />
        ) : (
          <i className="bx bx-package text-body"></i>
        )}
      </div>

      <div className="flex-grow-1 p-2">
        <h3 className="mb-1">{component?.code}</h3>
        <p className="text-muted">{component?.name}</p>
        <div className="hstack text-muted gap-1">
          <div className="me-2">
            {/* <i className="??? me-1 fs-16 align-bottom text-body"></i> */}
            {componentText.field.type}: {component?.type.name || "-"}
          </div>
        </div>
      </div>
      <div className="text-end">
        <div className="d-flex gap-2 justify-content-end">
          <Button color="primary" onClick={onActionClick}>
            {buttonText.edit}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default DetailComponentHeader;
