import { Chip } from "app/components/atoms/Chip";
import { ListInfoItem } from "app/components/molecules/ListInfoItem";
import { useDetailPageContext } from "app/components/templates/DetailPage/DetailPageContext";
import { formatDate } from "app/helpers/utils";
import { useAppTranslation } from "app/hooks";
import { mapGender } from "app/modules/employee/helpers";
import { Employee } from "app/services/employee";
import { FC } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";

export interface DetailEmployeeGeneralProps {}

const DetailEmployeeGeneral: FC<DetailEmployeeGeneralProps> = () => {
  const { data: employee } = useDetailPageContext<Employee>();
  const { employeeText } = useAppTranslation();
  const fullName = employee ? employee.fullName : "";

  return (
    <div className="p-3 pb-0">
      <Row>
        <Col xs={12}>
          <Card>
            <CardBody>
              <h5 className="card-title mb-3">
                {employeeText.detail.infomation}
              </h5>
              <Row className="gap-2">
                <Col xs={12}>
                  <ListInfoItem
                    label={employeeText.field.fullName}
                    value={fullName}
                  />
                </Col>
                <Col xs={12}>
                  <ListInfoItem
                    label={employeeText.field.employeeCode}
                    value={employee?.employeeCode}
                  />
                </Col>
                <Col xs={12}>
                  <ListInfoItem
                    label={employeeText.field.employeeIdCode}
                    value={
                      employee?.employeeIdCodes.find((item) => item.isActive)
                        ?.idCode || "-"
                    }
                  />
                </Col>
                <Col xs={12}>
                  <ListInfoItem
                    label={employeeText.field.gender}
                    value={employee?.gender ? mapGender(employee.gender) : "-"}
                  />
                </Col>
                <Col xs={12}>
                  <ListInfoItem
                    label={employeeText.field.phone}
                    value={employee?.phone}
                  />
                </Col>
                <Col xs={12}>
                  <ListInfoItem
                    label={employeeText.field.email}
                    value={employee?.email}
                  />
                </Col>
                <Col xs={12}>
                  <ListInfoItem
                    label={employeeText.field.department}
                    value={
                      employee?.department ? (
                        <Chip
                          size="sm"
                          color={employee?.department?.colorCode}
                          label={employee?.department?.name}
                        />
                      ) : (
                        "-"
                      )
                    }
                  />
                </Col>
                <Col xs={12}>
                  <ListInfoItem
                    label={employeeText.field.jobTitle}
                    value={
                      employee?.jobTitle ? (
                        <Chip
                          size="sm"
                          color={employee?.jobTitle?.colorCode}
                          label={employee?.jobTitle?.name}
                        />
                      ) : (
                        "-"
                      )
                    }
                  />
                </Col>
                <Col xs={12}>
                  <ListInfoItem
                    label={employeeText.field.dateOfBirth}
                    value={
                      employee?.birthdate ? formatDate(employee.birthdate) : "-"
                    }
                  />
                </Col>
                <Col xs={12}>
                  <ListInfoItem
                    label={employeeText.field.skill}
                    value={
                      <div className="d-flex gap-1 flex-wrap">
                        {employee?.employeeSkills?.map((skill) => (
                          <Chip
                            size="sm"
                            key={skill.skill.id}
                            color={skill.skill.colorCode}
                            label={skill.skill.name}
                          />
                        )) || "-"}
                      </div>
                    }
                  />
                </Col>
                <Col xs={12}>
                  <ListInfoItem
                    label={employeeText.field.taxCode}
                    value={employee?.taxCode}
                  />
                </Col>
                <Col xs={12}>
                  <ListInfoItem
                    label={employeeText.field.socialInsuranceCode}
                    value={employee?.socialInsuranceNumber}
                  />
                </Col>
                <Col xs={12}>
                  <ListInfoItem
                    label={employeeText.field.nationalId}
                    value={employee?.nationalId}
                  />
                </Col>
                <Col xs={12}>
                  <ListInfoItem
                    label={employeeText.field.dateOfIssue}
                    value={
                      employee?.dateOfIssue
                        ? formatDate(employee.dateOfIssue)
                        : "-"
                    }
                  />
                </Col>
                <Col xs={12}>
                  <ListInfoItem
                    label={employeeText.field.placeOfIssue}
                    value={employee?.placeOfIssue}
                  />
                </Col>
                <Col xs={12}>
                  <ListInfoItem
                    label={employeeText.field.permanentAddress}
                    value={employee?.permanentAddress}
                  />
                </Col>
                <Col xs={12}>
                  <ListInfoItem
                    label={employeeText.field.address}
                    value={employee?.currentAddress}
                  />
                </Col>
                <Col xs={12}>
                  <ListInfoItem
                    label={employeeText.field.dateOfJoining}
                    value={
                      employee?.dateOfJoining
                        ? formatDate(employee.dateOfJoining)
                        : "-"
                    }
                  />
                </Col>
                <Col xs={12}>
                  <ListInfoItem
                    label={employeeText.field.dateOfLeaving}
                    value={
                      employee?.dateOfLeaving
                        ? formatDate(employee.dateOfLeaving)
                        : "-"
                    }
                  />
                </Col>
                <Col xs={12}>
                  <ListInfoItem
                    label={employeeText.field.note}
                    value={employee?.notes}
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default DetailEmployeeGeneral;
