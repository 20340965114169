import {
  RetrieveTicketLogByIdArgs,
  useRetrieveTicketLogByIdQuery,
} from "app/services/ticketLog";

export const useRetrieveTicketLogById = (
  args: RetrieveTicketLogByIdArgs,
  opts?: Parameters<typeof useRetrieveTicketLogByIdQuery>[1],
) => {
  const result = useRetrieveTicketLogByIdQuery(args, opts);

  return {
    ticketLog: result.data?.data.data,
    isLoadingTicketLog: result.isLoading,
    ...result,
  };
};
