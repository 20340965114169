import { Button } from "app/components/molecules/Button";
import useToastify from "app/hooks/useToastify";
import { useLogoutPanel } from "app/modules/auth/hooks";
import { employeeStorage } from "app/services/auth";
import { retrievePanelEmployeeById } from "app/services/employee";
import { FC, FormEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

export interface NFCScanProps {}

const StyledI = styled.i`
  font-size: 120px;
`;

const NFCScanInput: FC = () => {
  const [id, setId] = useState("");
  const navigate = useNavigate();
  const toastify = useToastify();
  const { mutateAsync: logout } = useLogoutPanel();

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const { data: _data } = await retrievePanelEmployeeById({ id });
      const data = _data.data;
      employeeStorage.set(data);
      navigate("/panel/tickets");
    } catch (error) {
      toastify.error("ID không hợp lệ");
      console.error(error);
    }
  };

  return (
    <>
      <div className="d-flex flex-column justify-content-center align-items-center min-vh-100">
        <Button
          onClick={() => logout()}
          color="primary"
          className="position-absolute top-0 end-0 m-2"
        >
          Đăng xuất
        </Button>
        <StyledI className="ri-qr-scan-2-line" />
        <h1>Nhập ID nhân viên</h1>
        <form onSubmit={handleSubmit}>
          <div className="d-flex gap-2">
            <input
              autoFocus
              type="text"
              value={id}
              onChange={(e) => setId(e.target.value)}
            />
            <Button color="primary" type="submit">
              Xác nhận
            </Button>
          </div>
        </form>
      </div>
    </>
  );
};

const NFCScanDevide: FC = () => {
  const navigate = useNavigate();
  useEffect(() => {
    setTimeout(() => {
      navigate("/panel/tickets");
    }, 5000);
  }, []);
  return (
    <div className="d-flex flex-column justify-content-center align-items-center min-vh-100">
      <StyledI className="ri-qr-scan-2-line" />
      <h1>Scan để tiếp tục</h1>
    </div>
  );
};

const NFCScan: FC<NFCScanProps> = () => {
  const navigate = useNavigate();
  const employeeLS = employeeStorage.get();
  useEffect(() => {
    if (employeeLS) {
      navigate("/panel/tickets");
    }
  }, [employeeLS]);
  const component =
    import.meta.env.VITE_ENV === "production" ? (
      <NFCScanDevide />
    ) : (
      <NFCScanInput />
    );

  return component;
};

export default NFCScan;
