import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { httpClient } from "app/datasources";
import { AxiosError, AxiosResponse } from "axios";
import { Response } from "../types";
import { Ticket } from "./types";

export const UPDATE_TICKET_URL = (id: ID) => `/api/admin/tickets/${id}`;

export enum UpdateTicketError {}

export const updateTicketErrorMessages = {};

export type UpdateTicketResponse = Response<Ticket>;

export type UpdateTicketArgs = {
  id: ID;
  productionOrderItemId: ID;
  workStepId: ID;
  workshopId: ID;
  quantity: number;
  note: string;
};

export const updateTicket = ({ id, ...args }: UpdateTicketArgs) => {
  return httpClient.put<UpdateTicketResponse>(UPDATE_TICKET_URL(id), args);
};

export const useUpdateTicketMutation = (
  opts?: Omit<
    UseMutationOptions<
      AxiosResponse<UpdateTicketResponse>,
      AxiosError<any>,
      UpdateTicketArgs
    >,
    "mutationFn"
  >,
) =>
  useMutation<
    AxiosResponse<UpdateTicketResponse>,
    AxiosError<any>,
    UpdateTicketArgs
  >({
    mutationFn: updateTicket,
    mutationKey: [UPDATE_TICKET_URL],
    ...opts,
  });
