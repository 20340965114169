import { useAppTranslation } from "app/hooks";
import { OptionSelect } from "app/services/types";
import React from "react";
import Select, { Props } from "react-select";
import { useRetrieveComponentsProductionOrderId } from "../../hooks";
import { OptionValue } from "app/models";

export type ComponentProductionOrderOptionSelect = OptionSelect<ID>;

export interface ComponentProductionOrderSelectProps
  extends Omit<
    Props<ComponentProductionOrderOptionSelect>,
    "value" | "onChange"
  > {
  id?: ID;
  value: OptionValue;
  onChange: (value: OptionValue) => void;
  isInvalid?: boolean;
}

export const ComponentProductionOrderSelect: React.FC<
  ComponentProductionOrderSelectProps
> = ({ value, id, onChange, isInvalid, ...props }) => {
  const { placeHolderText, noDataTableText } = useAppTranslation();
  const { componentProductionOrders, isLoadingComponentProductionOrders } =
    useRetrieveComponentsProductionOrderId(
      {
        id: id as string,
      },
      { enabled: !!id },
    );

  const componentsItems = componentProductionOrders || [];

  return (
    <>
      <Select
        {...props}
        className={`${props.className} ${isInvalid ? "is-invalid" : ""}`}
        noOptionsMessage={() => noDataTableText}
        value={value}
        isLoading={isLoadingComponentProductionOrders}
        onChange={onChange}
        options={
          componentsItems.map((item) => ({
            value: item.id,
            label: item.nameVn,
          })) || []
        }
        getOptionLabel={(option) => option.label}
        getOptionValue={(option) => option.value}
        isClearable
        placeholder={placeHolderText.select}
        styles={{
          control: (provided) => ({
            ...provided,
            boxShadow: "none",
            borderColor: isInvalid
              ? "var(--vz-form-invalid-color)"
              : provided.borderColor,
            ":hover": {
              borderColor: isInvalid
                ? "var(--vz-form-invalid-color)"
                : provided.borderColor,
            },
          }),
        }}
      />
    </>
  );
};
