import { ColumnDef } from "@tanstack/react-table";
import { Chip } from "app/components/atoms/Chip";
import { AvatarRow } from "app/components/molecules/AvatarRow";
import { Button } from "app/components/molecules/Button";
import { useConfirm } from "app/components/molecules/ConfirmationProvider";
import { DataTable } from "app/components/organisms/DataTable";
import ListPage from "app/components/templates/ListPage";
import { DEFAULT_LIMIT_PAGE, DEFAULT_PAGE } from "app/helpers";
import { formatDate } from "app/helpers/utils";
import useAppSearch from "app/hooks/useAppSearch";
import { useAppTranslation } from "app/hooks/useAppTranslation";
import {
  EmployeeAction,
  EmployeeActionDropdown,
  ListEmployeeAction,
  ListEmployeeActionDropdown,
} from "app/modules/employee/components";
import {
  DeactiveEmployeeIdCodeFormData,
  DeactiveEmployeeIdCodeFormModal,
} from "app/modules/employee/components/DeactiveEmployeeIdCodeFormModal";
import { mapGender } from "app/modules/employee/helpers";
import {
  useCreateEmployeeIdCode,
  useDeactiveEmployeeIdCode,
  useDeleteEmployee,
  useImportAvatarEmployee,
  useImportEmployee,
  useRetrieveEmployees,
} from "app/modules/employee/hooks";
import { UserFormData, UserFormModal } from "app/modules/user/components";
import { useCreateUser } from "app/modules/user/hooks";
import { mapCreateUser } from "app/modules/user/mapper";
import { Employee, EmployeeGender } from "app/services/employee";
import { OptionSelect } from "app/services/types";
import BreadCrumb from "Components/Common/BreadCrumb";
import "gridjs/dist/theme/mermaid.css";
import React, { FC, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "reactstrap";
import { ListEmployeeFilter } from "./ListEmployeeFilter";
import { ListEmployeeFilterChips } from "./ListEmployeeFilterChips";
import {
  UploadEmployeeAvatarFormData,
  UploadEmployeeAvatarFormModal,
} from "app/modules/employee/components/UploadEmployeeAvatarModal";
import { useDeleteFile, useUploadFiles } from "app/services/file";
import { getUploadedFileIds } from "app/modules/file/helpers";
import { Source } from "app/models";
import {
  UploadEmployeeExcelFormData,
  UploadEmployeeExcelFormModal,
} from "app/modules/employee/components/UploadEmployeeExcelModal";

interface ListEmployeeProps {}

const ListEmployee: FC<ListEmployeeProps> = () => {
  const { confirm } = useConfirm();
  const [isUserModalOpen, toggleUserModal] = useState(false);
  const [isDeactiveModalOpen, toggleDeactiveModal] = useState(false);
  const rowSelected = useRef<Employee | null>(null);
  const navigate = useNavigate();
  const { employeeText, confirmText } = useAppTranslation();

  const handleToggleUserModal = (data?: Employee) => {
    if (data) {
      rowSelected.current = data;
    }
    if (isUserModalOpen) {
      rowSelected.current = null;
    }
    toggleUserModal((prevState) => !prevState);
  };

  const handleToggleDeactiveModal = (data?: Employee) => {
    if (data) {
      rowSelected.current = data;
    }
    if (isDeactiveModalOpen) {
      rowSelected.current = null;
    }
    toggleDeactiveModal((prevState) => !prevState);
  };

  const {
    page,
    limit,
    searchString,
    customParams,
    setPage,
    setLimit,
    setSearchString,
    setCustomParams,
  } = useAppSearch<{
    departments: OptionSelect[];
    jobTitles: OptionSelect[];
    skills: OptionSelect[];
    gender: OptionSelect<EmployeeGender> | null;
    dateOfJoining: Date | null;
    dateOfLeaving: Date | null;
  }>({
    initialPage: DEFAULT_PAGE,
    initialLimit: DEFAULT_LIMIT_PAGE,
    initialSearchString: "",
    initialCustomParams: {
      departments: [],
      jobTitles: [],
      skills: [],
      gender: null,
      dateOfJoining: null,
      dateOfLeaving: null,
    },
  });

  const handleClearFilter = () => {
    setCustomParams({
      departments: [],
      jobTitles: [],
      skills: [],
      gender: null,
      dateOfJoining: null,
      dateOfLeaving: null,
    });
  };

  const { employees, isLoadingEmployees } = useRetrieveEmployees({
    limit,
    page,
    searchString: searchString || undefined,
    departmentIds: customParams.departments?.map((item) => item.value),
    jobTitleIds: customParams.jobTitles?.map((item) => item.value),
    skillIds: customParams.skills?.map((item) => item.value),
    gender: customParams.gender?.value || null,
    dateOfJoining: customParams.dateOfJoining
      ? customParams.dateOfJoining?.toISOString()
      : null,
    dateOfLeaving: customParams.dateOfLeaving
      ? customParams.dateOfLeaving?.toISOString()
      : null,
  });

  const { mutateAsync: deleteEmployee } = useDeleteEmployee();

  const { mutateAsync: createEmployeeIdCode } = useCreateEmployeeIdCode();

  const { mutateAsync: deactiveEmployeeIdCode, isPending: isDeactivating } =
    useDeactiveEmployeeIdCode({
      onSuccess: () => {
        handleToggleDeactiveModal();
      },
    });

  const handleActionClick = (action: EmployeeAction, data?: Employee) => {
    switch (action) {
      case "create-user":
        handleToggleUserModal(data);
        break;
      case "edit":
        if (data) {
          navigate(`/employee/${data.id}/update`);
        }
        break;
      case "detail":
        if (data) {
          navigate(`/employee/${data.id}`);
        }
        break;
      case "delete":
        if (data?.id) {
          confirm({
            title: confirmText.deleteTitle(employeeText.title),
            description: confirmText.deleteContent(employeeText.title),
            onConfirm: () => deleteEmployee({ id: data.id }),
          });
        }
        break;
      case "create-employee-id-code":
        if (data?.id) {
          confirm({
            title: employeeText.createEmployeeIdCode,
            description: employeeText.createEmployeeIdCodeContent,
            onConfirm: () => createEmployeeIdCode({ employeeId: data.id }),
          });
        }
        break;
      case "deactive-employee-id-code":
        if (data?.id) {
          handleToggleDeactiveModal(data);
        }
        break;
      default:
        break;
    }
  };

  const columns: ColumnDef<Employee>[] = useMemo(
    () => [
      {
        header: employeeText.field.fullName,
        accessorKey: "fullName",
        size: 250,
        cell: ({ row }) => (
          <AvatarRow
            name={row.original.fullName}
            avatarUrl={row.original.avatar?.attachmentUrl}
          />
        ),
      },
      {
        header: employeeText.field.employeeCode,
        accessorKey: "employeeCode",
        cell: ({ row }) => row.original.employeeCode || "-",
      },
      {
        header: employeeText.field.employeeIdCode,
        accessorKey: "employeeIdCode",
        cell: ({ row }) =>
          row.original.employeeIdCodes.find((item) => item.isActive)?.idCode ||
          "-",
      },
      {
        header: employeeText.field.username,
        accessorKey: "username",
        cell: ({ row }) => row.original?.user?.username || "-",
      },
      {
        header: employeeText.field.phone,
        accessorKey: "phone",
        cell: ({ row }) => row.original.phone || "-",
      },
      {
        header: employeeText.field.jobTitle,
        accessorKey: "jobTitle",
        cell: ({ row }) =>
          row.original.jobTitle?.name ? (
            <Chip
              size="sm"
              color={row.original.jobTitle?.colorCode}
              label={row.original.jobTitle?.name}
            />
          ) : (
            "-"
          ),
      },
      {
        header: employeeText.field.department,
        accessorKey: "department",
        cell: ({ row }) =>
          row.original.department?.name ? (
            <Chip
              size="sm"
              color={row.original.department?.colorCode}
              label={row.original.department?.name}
            />
          ) : (
            "-"
          ),
      },
      {
        header: employeeText.field.email,
        accessorKey: "email",
        cell: ({ row }) => row.original.email || "-",
      },
      {
        header: employeeText.field.gender,
        accessorKey: "gender",
        cell: ({ row }) =>
          row.original.gender ? mapGender(row.original.gender) : "-",
      },
      {
        header: employeeText.field.dateOfBirth,
        accessorKey: "birthdate",
        cell: ({ row }) =>
          row.original.birthdate
            ? formatDate(new Date(row.original.birthdate))
            : "-",
      },
      {
        header: employeeText.field.skill,
        accessorKey: "skill",
        cell: ({ row }) => (
          <div className="d-flex gap-1 flex-wrap">
            {row.original.employeeSkills?.map((skill) => (
              <Chip
                size="sm"
                key={skill.id}
                color={skill.skill.colorCode}
                label={skill.skill.name}
              />
            )) || "-"}
          </div>
        ),
      },
      {
        header: employeeText.field.taxCode,
        accessorKey: "taxCode",
        cell: ({ row }) => row.original.taxCode || "-",
      },
      {
        header: employeeText.field.socialInsuranceCode,
        accessorKey: "socialInsuranceCode",
        cell: ({ row }) => row.original.socialInsuranceNumber || "-",
      },
      {
        header: employeeText.field.nationalId,
        accessorKey: "nationalId",
        cell: ({ row }) => row.original.nationalId || "-",
      },
      {
        header: employeeText.field.dateOfIssue,
        accessorKey: "dateOfIssue",
        cell: ({ row }) =>
          row.original.dateOfIssue
            ? formatDate(new Date(row.original.dateOfIssue))
            : "-",
      },
      {
        header: employeeText.field.placeOfIssue,
        accessorKey: "placeOfIssue",
        cell: ({ row }) => row.original.placeOfIssue || "-",
      },
      {
        header: employeeText.field.permanentAddress,
        accessorKey: "permanentAddress",
        size: 300,
        cell: ({ row }) => row.original.permanentAddress || "-",
      },
      {
        header: employeeText.field.address,
        accessorKey: "address",
        size: 300,
        cell: ({ row }) => row.original.currentAddress || "-",
      },
      {
        header: employeeText.field.dateOfJoining,
        accessorKey: "dateOfJoining",
        cell: ({ row }) =>
          row.original.dateOfJoining
            ? formatDate(new Date(row.original.dateOfJoining))
            : "-",
      },
      {
        header: employeeText.field.dateOfLeaving,
        accessorKey: "dateOfLeaving",
        cell: ({ row }) =>
          row.original.dateOfLeaving
            ? formatDate(new Date(row.original.dateOfLeaving))
            : "-",
      },
      {
        header: employeeText.field.note,
        accessorKey: "note",
        size: 300,
        cell: ({ row }) => row.original.notes || "-",
      },
      {
        header: "",
        accessorKey: "action",
        size: 40,
        cell: ({ row }) => (
          <EmployeeActionDropdown
            onAction={handleActionClick}
            data={row.original}
          />
        ),
      },
    ],
    [employeeText],
  );

  const { mutateAsync: createUser, isPending: isCreating } = useCreateUser({
    onSuccess: () => {
      handleToggleUserModal();
    },
  });

  const handleCreateUser = (data: UserFormData) => {
    if (rowSelected.current) {
      createUser(
        mapCreateUser({ employeeId: rowSelected.current.id, ...data }),
      );
    }
  };

  const handleSubmitUser = (data: UserFormData) => {
    handleCreateUser(data);
  };

  const handleDeactive = ({ notes }: DeactiveEmployeeIdCodeFormData) => {
    if (rowSelected.current?.id) {
      const activeEmployeeIdCode = rowSelected.current.employeeIdCodes.find(
        (item) => item.isActive,
      )?.id;
      if (!activeEmployeeIdCode) {
        return;
      }
      deactiveEmployeeIdCode({
        id: activeEmployeeIdCode,
        notes,
      });
    }
  };

  const [isImportModalOpen, toggleImportModal] = useState(false);
  const [isImportAvatarModalOpen, toggleImportAvatarModal] = useState(false);

  const { mutateAsync: upload, isPending: isUploading } = useUploadFiles();

  const { mutateAsync: deleteFile } = useDeleteFile();

  const { mutateAsync: importAvatarEmployee, isPending: isAvatarImporting } =
    useImportAvatarEmployee({
      onSuccess: () => {
        toggleImportAvatarModal(false);
      },
    });

  const { mutateAsync: importEmployee, isPending: isImporting } =
    useImportEmployee({
      onSuccess: () => {
        toggleImportModal(false);
      },
    });

  const handleUploadAvatar = async (data: UploadEmployeeAvatarFormData) => {
    const uploadResult = await getUploadedFileIds({
      data: data.file,
      fileKey: "document",
      source: Source.Employee,
      upload,
    });
    await importAvatarEmployee(
      { filePath: uploadResult?.fileResponse[0].filePath || "" },
      {
        onError() {
          uploadResult?.fileIds.map((id) => {
            deleteFile({ id });
          });
        },
      },
    );
  };

  const handleUpload = async (data: UploadEmployeeExcelFormData) => {
    const uploadResult = await getUploadedFileIds({
      data: data.file,
      fileKey: "document",
      source: Source.Employee,
      upload,
    });
    await importEmployee(
      { filePath: uploadResult?.fileResponse[0].filePath || "" },
      {
        onError() {
          uploadResult?.fileIds.map((id) => {
            deleteFile({ id });
          });
        },
      },
    );
  };

  const handleListActionClick = (action: ListEmployeeAction) => {
    switch (action) {
      case "upload-avatars":
        toggleImportAvatarModal(true);
        break;
      case "import":
        toggleImportModal(true);
        break;
      default:
        break;
    }
  };

  return (
    <React.Fragment>
      <ListPage>
        <ListPage.BreadCrumb>
          <BreadCrumb title={employeeText.title} pageTitle="Kingston" />
        </ListPage.BreadCrumb>
        <ListPage.Filter>
          <Row>
            <Col xs={6}>
              <ListEmployeeFilter
                searchString={searchString}
                handleSearchChange={setSearchString}
                filterParams={{
                  departments: customParams.departments,
                  jobTitles: customParams.jobTitles,
                  skills: customParams.skills,
                  gender: customParams.gender,
                  dateOfJoining: customParams.dateOfJoining,
                  dateOfLeaving: customParams.dateOfLeaving,
                }}
                setCustomParams={setCustomParams}
              />
            </Col>
            <Col xs={6} className="text-end">
              <div className="d-flex justify-content-end gap-2">
                <ListEmployeeActionDropdown onAction={handleListActionClick} />
                <Button
                  color="primary"
                  size="sm"
                  onClick={() => navigate("/employee/new")}
                >
                  {employeeText.add}
                </Button>
              </div>
            </Col>
            <Col xs={12}>
              <ListEmployeeFilterChips
                filterParams={{
                  departments: customParams.departments,
                  jobTitles: customParams.jobTitles,
                  skills: customParams.skills,
                  gender: customParams.gender,
                  dateOfJoining: customParams.dateOfJoining,
                  dateOfLeaving: customParams.dateOfLeaving,
                }}
                setCustomParams={setCustomParams}
                handleClearFilter={handleClearFilter}
              />
            </Col>
          </Row>
        </ListPage.Filter>
        <ListPage.Main>
          <DataTable<Employee>
            columns={columns}
            data={employees?.result || []}
            loading={isLoadingEmployees}
            page={page}
            limit={limit}
            setPage={setPage}
            setLimit={setLimit}
            total={employees?.total || 0}
            onRowClick={(row) => handleActionClick("detail", row)}
            pinLeftColumns={["fullName"]}
          />
        </ListPage.Main>
      </ListPage>

      <UserFormModal
        open={isUserModalOpen}
        onToggle={handleToggleUserModal}
        onSubmit={handleSubmitUser}
        submitting={isCreating}
      />

      <DeactiveEmployeeIdCodeFormModal
        open={isDeactiveModalOpen}
        onToggle={handleToggleDeactiveModal}
        onSubmit={handleDeactive}
        submitting={isDeactivating}
      />
      {isImportAvatarModalOpen && (
        <UploadEmployeeAvatarFormModal
          open={isImportAvatarModalOpen}
          onToggle={() => toggleImportAvatarModal((prev) => !prev)}
          onSubmit={handleUploadAvatar}
          submitting={isAvatarImporting || isUploading}
        />
      )}
      {isImportModalOpen && (
        <UploadEmployeeExcelFormModal
          open={isImportModalOpen}
          onToggle={() => toggleImportModal((prev) => !prev)}
          onSubmit={handleUpload}
          submitting={isImporting || isUploading}
          onActionClick={handleListActionClick}
        />
      )}
    </React.Fragment>
  );
};

export default ListEmployee;
