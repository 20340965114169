import { Button } from "app/components/molecules/Button";
import { useAppTranslation } from "app/hooks";
import { useRetrievePanelTicketById } from "app/modules/ticket/hooks";
import classnames from "classnames";
import { FC, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Card, CardBody, Nav, NavItem, NavLink } from "reactstrap";
import { DetailTicketPanelGeneral } from "./DetailTicketPanelGeneral";
import { DetailTicketPanelProduct } from "./DetailTicketPanelProduct";
import { DetailTicketPanelLogs } from "./DetailTicketPanelLogs";

export interface DetailTicketPanelProps {}

export enum DetailTicketPanelTabEnum {
  General = "general",
  Product = "product",
  Logs = "logs",
}

const DetailTicketPanel: FC<DetailTicketPanelProps> = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { ticketText, productText } = useAppTranslation();
  const { ticket } = useRetrievePanelTicketById({ id: id as string });

  const [activeTab, setActiveTab] = useState<DetailTicketPanelTabEnum>(
    DetailTicketPanelTabEnum.General,
  );

  const logs = ticket?.ticketLogs || [];

  return (
    <div className="p-3">
      <Card>
        <CardBody>
          <div className="d-flex justify-content-between align-items-center mb-3">
            <div className="d-flex align-items-center gap-2 mb-3">
              <Button
                color=""
                className="btn-icon"
                style={{ height: 18, width: 18 }}
                onClick={() => navigate(-1)}
              >
                <i className="ri-arrow-left-s-line fs-24" />
              </Button>
              <h5 className="mb-sm-0">{ticketText.detail.title}</h5>
            </div>
          </div>
          <div className="d-flex px-3">
            <Nav
              pills
              className="animation-nav profile-nav gap-2 gap-lg-3 flex-grow-1"
              role="tablist"
            >
              <NavItem>
                <NavLink
                  className={classnames("fs-14 nav-link cursor-pointer", {
                    active: activeTab === DetailTicketPanelTabEnum.General,
                  })}
                  onClick={() => setActiveTab(DetailTicketPanelTabEnum.General)}
                >
                  <i className="ri-airplay-fill d-inline-block d-md-none"></i>{" "}
                  <span className="d-none d-md-inline-block">
                    {ticketText.detail.tab.general}
                  </span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames("fs-14 nav-link cursor-pointer", {
                    active: activeTab === DetailTicketPanelTabEnum.Product,
                  })}
                  onClick={() => setActiveTab(DetailTicketPanelTabEnum.Product)}
                >
                  <i className="ri-airplay-fill d-inline-block d-md-none"></i>{" "}
                  <span className="d-none d-md-inline-block">
                    {productText.title}
                  </span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames("fs-14 nav-link cursor-pointer", {
                    active: activeTab === DetailTicketPanelTabEnum.Logs,
                  })}
                  onClick={() => setActiveTab(DetailTicketPanelTabEnum.Logs)}
                >
                  <i className="ri-airplay-fill d-inline-block d-md-none"></i>{" "}
                  <span className="d-none d-md-inline-block">
                    {ticketText.detail.tab.log}
                  </span>
                </NavLink>
              </NavItem>
            </Nav>
          </div>
          <div className="mt-4">
            {activeTab === DetailTicketPanelTabEnum.General && (
              <DetailTicketPanelGeneral ticket={ticket} />
            )}
            {activeTab === DetailTicketPanelTabEnum.Product && (
              <DetailTicketPanelProduct ticket={ticket} />
            )}
            {activeTab === DetailTicketPanelTabEnum.Logs && (
              <DetailTicketPanelLogs logs={logs} />
            )}
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default DetailTicketPanel;
