import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { httpClient } from "app/datasources";
import { AxiosError, AxiosResponse } from "axios";

export const IMPORT_AVATAR_EMPLOYEE_URL = "/api/admin/import-products";

export enum ImportAvatarEmployeeError {}

export const importAvatarEmployeeErrorMessages = {};

export interface ImportAvatarEmployeeArgs {
  filePath: string;
}

export type ImportAvatarEmployeeResponse = any;

export const importAvatarEmployee = (args: ImportAvatarEmployeeArgs) => {
  return httpClient.post<ImportAvatarEmployeeResponse>(
    IMPORT_AVATAR_EMPLOYEE_URL,
    args,
  );
};

export const useImportAvatarEmployeeMutation = (
  opts?: Omit<
    UseMutationOptions<
      AxiosResponse<ImportAvatarEmployeeResponse>,
      AxiosError<any>,
      ImportAvatarEmployeeArgs
    >,
    "mutationFn"
  >,
) =>
  useMutation<
    AxiosResponse<ImportAvatarEmployeeResponse>,
    AxiosError<any>,
    ImportAvatarEmployeeArgs
  >({
    mutationFn: importAvatarEmployee,
    mutationKey: [IMPORT_AVATAR_EMPLOYEE_URL],
    ...opts,
  });
