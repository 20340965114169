import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { httpClient } from "app/datasources";
import { AxiosError, AxiosResponse } from "axios";
import { Response } from "../types";

export const DELETE_COMPONENT_URL = (id: ID) =>
  `/api/admin/products/${id}/remove-child`;

export enum DeleteComponentError {}

export const deleteComponentErrorMessages = {};

export type DeleteComponentResponse = Response<unknown>;

export type DeleteComponentArgs = {
  id: ID;
  componentId: ID;
};

export const deleteComponent = ({ id, ...args }: DeleteComponentArgs) => {
  return httpClient.put<DeleteComponentResponse>(
    DELETE_COMPONENT_URL(id),
    args,
  );
};

export const useDeleteComponentMutation = (
  opts?: UseMutationOptions<
    AxiosResponse<DeleteComponentResponse>,
    AxiosError<Response<null>>,
    DeleteComponentArgs
  >,
) => {
  return useMutation({
    mutationKey: [DELETE_COMPONENT_URL],
    mutationFn: (deleteComponentArgs: DeleteComponentArgs) =>
      deleteComponent(deleteComponentArgs),
    ...opts,
  });
};
