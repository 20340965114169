import { useQueryClient } from "@tanstack/react-query";
import { errorMessageMapper } from "app/helpers";
import useToastify from "app/hooks/useToastify";
import {
  rejectBomErrorMessages,
  RETRIEVE_BOMS_URL,
  useRejectBomMutation,
  RETRIEVE_BOM_BY_ID_URL,
} from "app/services/bom";

interface useRejectBomProps {
  onSuccess?: () => void;
  onError?: () => void;
  id?: ID; // to invalidate the cache
}

export const useRejectBom = ({
  onError: handleError,
  onSuccess: handleSuccess,
  id,
}: useRejectBomProps = {}) => {
  const toast = useToastify();
  const queryClient = useQueryClient();
  return useRejectBomMutation({
    onSuccess: () => {
      toast.success();
      queryClient.invalidateQueries({ queryKey: [RETRIEVE_BOMS_URL] });
      if (id) {
        queryClient.invalidateQueries({
          queryKey: [RETRIEVE_BOM_BY_ID_URL(id)],
        });
      }
      handleSuccess?.();
    },
    onError: (error) => {
      toast.error(
        errorMessageMapper(
          rejectBomErrorMessages,
          error.response?.data.message,
        ),
      );
      handleError?.();
    },
  });
};
