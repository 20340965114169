import { useQueryClient } from "@tanstack/react-query";
import { errorMessageMapper } from "app/helpers";
import useToastify from "app/hooks/useToastify";
import {
  createProductErrorMessages,
  CreateProductResponse,
  RETRIEVE_PRODUCTS_URL,
  useCreateProductMutation,
} from "app/services/product";
import { AxiosResponse } from "axios";

interface UseCreateProductProps {
  onSuccess?: (data?: AxiosResponse<CreateProductResponse, any>) => void;
  onError?: () => void;
}

export const useCreateProduct = ({
  onError: handleError,
  onSuccess: handleSuccess,
}: UseCreateProductProps = {}) => {
  const toast = useToastify();
  const queryClient = useQueryClient();
  return useCreateProductMutation({
    onSuccess: (data) => {
      toast.success();
      queryClient.invalidateQueries({ queryKey: [RETRIEVE_PRODUCTS_URL] });
      handleSuccess?.(data);
    },
    onError: (error) => {
      toast.error(
        errorMessageMapper(
          createProductErrorMessages,
          error.response?.data.message,
        ),
      );
      handleError?.();
    },
  });
};
