import { useAppTranslation } from "app/hooks";
import { TicketLog } from "app/services/ticketLog";
import { FC, MouseEvent, useState } from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";

export type TicketLogAction = "edit" | "delete";

export interface TicketLogActionDropdownProps {
  data?: TicketLog;
  onAction: (action: TicketLogAction, data?: TicketLog) => void;
  isTicketCompleted?: boolean;
}

export const TicketLogActionDropdown: FC<TicketLogActionDropdownProps> = ({
  data,
  onAction,
  isTicketCompleted = false,
}) => {
  const { buttonText } = useAppTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const handleToggle = () => setIsOpen(!isOpen);

  const handleAction = (action: TicketLogAction) => () => {
    onAction(action, data);
    setIsOpen(false);
  };

  return (
    <Dropdown
      isOpen={isOpen}
      toggle={(e: MouseEvent) => {
        e.stopPropagation();
        handleToggle();
      }}
      direction="start"
    >
      <DropdownToggle
        tag="button"
        className="btn py-0 px-2 m-0"
        onClick={(e) => {
          const trClosest = e.currentTarget.closest("tr");
          trClosest?.classList.toggle("z-3", !isOpen);
        }}
        onBlur={(e) => {
          const trClosest = e.currentTarget.closest("tr");
          setTimeout(() => trClosest?.classList.remove("z-3"), 100);
        }}
      >
        <i className="ri-more-fill"></i>
      </DropdownToggle>
      <DropdownMenu className="dropdown-menu-end">
        <DropdownItem
          disabled={isTicketCompleted}
          onClick={handleAction("edit")}
        >
          <i className="ri-pencil-line text-muted fs-16 align-middle me-1"></i>
          <span className="align-middle">{buttonText.edit}</span>
        </DropdownItem>
        <DropdownItem
          disabled={isTicketCompleted}
          onClick={handleAction("delete")}
        >
          <i className="ri-delete-bin-line text-muted fs-16 align-middle me-1"></i>
          <span className="align-middle">{buttonText.delete}</span>
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};
