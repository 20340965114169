import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { httpClient } from "app/datasources";
import { Response } from "app/services/types";
import { AxiosError, AxiosResponse } from "axios";
import { Product } from "./types";

export const CREATE_COMPONENT_URL = (id: ID) =>
  `/api/admin/products/${id}/add-child`;

export enum CreateComponentError {
  ComponentCodeAlreadyExists = "Component Code Already Exists",
}

export const createComponentErrorMessages = {
  [CreateComponentError.ComponentCodeAlreadyExists]:
    "Product Code Already Exists",
};

export interface CreateComponentArgs {
  productId: string;
  parentId: string | null;
  // Product Information
  name: string;
  typeId: ID | null;
  workpieceTypeId: ID | null;
  frameTypeId: ID | null;
  thicknessCm: number | null;
  lengthOfBoxCm: number | null;
  widthOfBoxCm: number | null;
  lengthOfDetailCm: number | null;
  imageId: ID | null;
  workSteps: {
    stepId: ID;
    estimateTime: number | null;
    quantity: number | null;
    order: number;
  }[];
  // Other Infomation
  quantity: number;
  description: string | null;
  fileIds?: ID[];
}

export type CreateComponentResponse = Response<Product>;

export const createComponent = (args: CreateComponentArgs) => {
  return httpClient.post<CreateComponentResponse>(
    CREATE_COMPONENT_URL(args.productId),
    args,
  );
};

export const useCreateComponentMutation = (
  opts?: Omit<
    UseMutationOptions<
      AxiosResponse<CreateComponentResponse>,
      AxiosError<any>,
      CreateComponentArgs
    >,
    "mutationFn"
  >,
) =>
  useMutation<
    AxiosResponse<CreateComponentResponse>,
    AxiosError<any>,
    CreateComponentArgs
  >({
    mutationFn: createComponent,
    mutationKey: [CREATE_COMPONENT_URL],
    ...opts,
  });
