import FormPage from "app/components/templates/FormPage";
import { useAppTranslation } from "app/hooks";
import { BomForm, BomFormData } from "app/modules/bom/components";
import { useCreateBom } from "app/modules/bom/hooks";
import { mapCreateBom, mapDefaultBom } from "app/modules/bom/mapper";
import BreadCrumb from "Components/Common/BreadCrumb";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { Card, CardBody } from "reactstrap";

export interface CreateBomProps {}

const CreateBom: FC<CreateBomProps> = () => {
  const { bomText } = useAppTranslation();
  const navigate = useNavigate();
  const { mutateAsync, isPending } = useCreateBom({
    onSuccess: () => {
      navigate(-1);
    },
  });

  const handleSubmit = async (data: BomFormData) => {
    mutateAsync(mapCreateBom(data));
  };

  return (
    <FormPage>
      <FormPage.BreadCrumb>
        <BreadCrumb title={bomText.add} pageTitle={bomText.title} isBack />
      </FormPage.BreadCrumb>
      <FormPage.Main>
        <Card>
          <CardBody>
            <BomForm
              onSubmit={handleSubmit}
              defaultValues={mapDefaultBom()}
              submitting={isPending}
            />
          </CardBody>
        </Card>
      </FormPage.Main>
    </FormPage>
  );
};

export default CreateBom;
