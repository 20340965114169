import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "app/components/molecules/Button";
import { FormHelper } from "app/components/molecules/FormHelper";
import { FormInput } from "app/components/molecules/FormInput";
import { FormLabel } from "app/components/molecules/FormLabel";
import { yupSchema } from "app/helpers/schema";
import { useAppTranslation, useGetEnableSubmitButton } from "app/hooks";
import { MasterDataInfiniteSelect } from "app/modules/masterData/components";
import { VendorSelect } from "app/modules/vendor/components";
import { MasterData, MasterDataType } from "app/services/masterData";
import { OptionSelect } from "app/services/types";
import { FC, FormEvent } from "react";
import { Controller, useForm } from "react-hook-form";
import { Col, Form, FormGroup, Row } from "reactstrap";
import * as Yup from "yup";

export interface MaterialFormProps {
  defaultValues?: MaterialFormData;
  onSubmit: (data: MaterialFormData) => void;
  submitting?: boolean;
  onCancel?: () => void;
}

export interface MaterialFormData {
  name: string;
  code: string;
  purchasePrice: number | null;
  description: string;
  unit: OptionSelect | null;
  vendor: OptionSelect | null;
}

export const MaterialForm: FC<MaterialFormProps> = ({
  onSubmit,
  defaultValues,
  submitting,
  onCancel,
}) => {
  const { materialText, buttonText } = useAppTranslation();
  const {
    control,
    setValue,
    handleSubmit,
    formState: { isDirty, isValid },
  } = useForm<MaterialFormData>({
    mode: "onChange",
    resolver: yupResolver(
      Yup.object({
        name: yupSchema.stringRequired(""),
        code: yupSchema.stringRequired(""),
        purchasePrice: yupSchema.numberMoreThanNotRequired(
          0,
          "component.error.purchasePriceMoreThanZero",
        ),
        description: yupSchema.stringNotRequired(),
        unit: yupSchema.objectNotRequired(),
        vendor: yupSchema.objectNotRequired(),
      }),
    ),
    defaultValues,
  });

  const isEnableSubmit = useGetEnableSubmitButton({
    isDirty,
    isValid,
    submitting,
  });

  const submit = async (e: FormEvent) => {
    e.preventDefault();
    handleSubmit(onSubmit)();
  };

  const handleSetValue =
    (name: keyof MaterialFormData) => (data: MasterData) => {
      setValue(
        name,
        {
          value: data.id,
          label: data.name,
        },
        { shouldDirty: true },
      );
    };

  return (
    <Form onSubmit={submit} id="material-form">
      <Row>
        <Col xs={12} md={6}>
          <FormGroup>
            <FormLabel required htmlFor="code" className="form-label">
              {materialText.field.code}
            </FormLabel>
            <Controller
              name="code"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <FormInput
                    {...field}
                    type="text"
                    className="form-control"
                    id="code"
                    invalid={!!fieldState.error}
                  />
                  <FormHelper message={fieldState.error?.message} />
                </>
              )}
            />
          </FormGroup>
        </Col>
        <Col xs={12} md={6}>
          <FormGroup>
            <FormLabel required htmlFor="name" className="form-label">
              {materialText.field.name}
            </FormLabel>
            <Controller
              name="name"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <FormInput
                    {...field}
                    type="text"
                    className="form-control"
                    id="name"
                    invalid={!!fieldState.error}
                  />
                  <FormHelper message={fieldState.error?.message} />
                </>
              )}
            />
          </FormGroup>
        </Col>
        <Col xs={12}>
          <FormGroup>
            <FormLabel htmlFor="purchasePrice" className="form-label">
              {materialText.field.purchasePrice}
            </FormLabel>
            <Controller
              name="purchasePrice"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <FormInput
                    {...field}
                    value={field.value ?? ""}
                    onChange={(value) => {
                      field.onChange(
                        value.target.value === ""
                          ? null
                          : Number(value.target.value),
                      );
                    }}
                    type="number"
                    className="form-control"
                    id="purchasePrice"
                    invalid={!!fieldState.invalid}
                  />
                  <FormHelper message={fieldState.error?.message} />
                </>
              )}
            />
          </FormGroup>
        </Col>
        <Col xs={12} md={6}>
          <FormGroup>
            <FormLabel htmlFor="unit" className="form-label">
              {materialText.field.unit}
            </FormLabel>
            <Controller
              name="unit"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <MasterDataInfiniteSelect
                    isCreatable
                    key="type"
                    name="type"
                    inputId="type"
                    value={field.value}
                    onChange={field.onChange}
                    isInvalid={fieldState.invalid}
                    retrieveKeys={{ type: MasterDataType.ProductUnit }}
                    onCreateSuccess={handleSetValue("unit")}
                  />
                  <FormHelper message={fieldState.error?.message} />
                </>
              )}
            />
          </FormGroup>
        </Col>
        <Col xs={12} md={6}>
          <FormGroup>
            <FormLabel htmlFor="vendor" className="form-label">
              {materialText.field.vendor}
            </FormLabel>
            <Controller
              name="vendor"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <VendorSelect
                    name="vendor"
                    value={field.value}
                    inputId="vendor"
                    onChange={field.onChange}
                    isInvalid={fieldState.invalid}
                  />
                  <FormHelper message={fieldState.error?.message} />
                </>
              )}
            />
          </FormGroup>
        </Col>
        <Col xs={12}>
          <FormGroup>
            <FormLabel htmlFor="description" className="form-label">
              {materialText.field.description}
            </FormLabel>
            <Controller
              name="description"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <FormInput
                    {...field}
                    type="text"
                    className="form-control"
                    id="description"
                    invalid={!!fieldState.error}
                  />
                  <FormHelper message={fieldState.error?.message} />
                </>
              )}
            />
          </FormGroup>
        </Col>
        <Col xs={12}>
          <div className="hstack gap-2 justify-content-end">
            {onCancel ? (
              <Button color="light" onClick={onCancel}>
                {buttonText.cancel}
              </Button>
            ) : null}
            <Button
              disabled={!isEnableSubmit}
              type="submit"
              color="primary"
              loading={submitting}
            >
              {buttonText.save}
            </Button>
          </div>
        </Col>
      </Row>
    </Form>
  );
};
