import { useAppTranslation } from "app/hooks";
import { FC } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "reactstrap";

export interface DetailProductionOrderHeaderProps {}

const DetailProductionOrderHeader: FC<
  DetailProductionOrderHeaderProps
> = () => {
  const { productionOrderText, buttonText } = useAppTranslation();
  const { id } = useParams();
  const navigate = useNavigate();

  const avatar = false;

  return (
    <div className="d-flex gap-4 flex-wrap">
      <div className="avatar-lg d-flex justify-content-center align-items-center">
        {avatar ? (
          <img
            src={avatar}
            alt="user-img"
            className="img-thumbnail rounded-4 w-100 h-100"
          />
        ) : (
          <i className="ri-file-list-3-line text-body"></i>
        )}
      </div>

      <div className="flex-grow-1 p-2">
        <h3 className="mb-1">
          {productionOrderText.title} #{id}
        </h3>
      </div>
      <div className="text-end">
        <div className="d-flex gap-2 justify-content-end">
          <Button
            color="primary"
            onClick={() => navigate(`/production-order/${id}/update`)}
          >
            {buttonText.edit}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default DetailProductionOrderHeader;
