import { RetrieveBomsArgs, useRetrieveBomsQuery } from "app/services/bom";

export const useRetrieveBoms = (args: RetrieveBomsArgs) => {
  const result = useRetrieveBomsQuery(args);
  return {
    boms: result.data?.data.data,
    isLoadingBoms: result.isLoading,
    ...result,
  };
};
