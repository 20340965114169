import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { httpClient } from "app/datasources";
import { AxiosError, AxiosResponse } from "axios";
import { Response } from "../types";
import { Bom } from "./types";

export const APPROVE_BOM_URL = (id: ID) => `/api/admin/boms/${id}/approve`;

export enum ApproveBomError {}

export const approveBomErrorMessages = {};

export type ApproveBomResponse = Response<Bom>;

export type ApproveBomArgs = {
  id: ID;
};

export const approveBom = ({ id }: ApproveBomArgs) => {
  return httpClient.put<ApproveBomResponse>(APPROVE_BOM_URL(id));
};

export const useApproveBomMutation = (
  opts?: Omit<
    UseMutationOptions<
      AxiosResponse<ApproveBomResponse>,
      AxiosError<any>,
      ApproveBomArgs
    >,
    "mutationFn"
  >,
) =>
  useMutation<
    AxiosResponse<ApproveBomResponse>,
    AxiosError<any>,
    ApproveBomArgs
  >({
    mutationFn: approveBom,
    mutationKey: [APPROVE_BOM_URL],
    ...opts,
  });
