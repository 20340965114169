import { ColumnDef } from "@tanstack/react-table";
import { Button } from "app/components/molecules/Button";
import { useConfirm } from "app/components/molecules/ConfirmationProvider";
import { DebouncedInput } from "app/components/molecules/DebounceInput";
import { DataTable } from "app/components/organisms/DataTable";
import ListPage from "app/components/templates/ListPage";
import { formatCurrency } from "app/helpers/utils";
import { useAppTranslation } from "app/hooks";
import useAppSearch from "app/hooks/useAppSearch";
import {
  MaterialAction,
  MaterialActionDropdown,
} from "app/modules/material/components";
import {
  useDeleteMaterial,
  useRetrieveMaterials,
} from "app/modules/material/hooks";
import { Material } from "app/services/material";
import BreadCrumb from "Components/Common/BreadCrumb";
import "gridjs/dist/theme/mermaid.css";
import React, { FC, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "reactstrap";

interface ListMaterialProps {}

const ListMaterial: FC<ListMaterialProps> = () => {
  const { confirm } = useConfirm();
  const navigate = useNavigate();
  const { materialText, confirmText } = useAppTranslation();

  const { page, limit, searchString, setPage, setLimit, setSearchString } =
    useAppSearch({
      initialPage: 1,
      initialLimit: 20,
      initialSearchString: "",
    });

  const { materials, isLoadingMaterials } = useRetrieveMaterials({
    limit,
    page,
    searchString: searchString || undefined,
  });

  const { mutateAsync: deleteMaterial } = useDeleteMaterial();

  const handleActionClick = (action: MaterialAction, data?: Material) => {
    switch (action) {
      case "edit":
        if (data) {
          navigate(`/material/${data.id}/update`);
        }
        break;
      case "detail":
        if (data) {
          navigate(`/material/${data.id}`);
        }
        break;
      case "delete":
        if (data?.id) {
          confirm({
            title: confirmText.deleteTitle(materialText.title),
            description: confirmText.deleteContent(materialText.title),
            onConfirm: () => deleteMaterial({ id: data.id }),
          });
        }
        break;
      default:
        break;
    }
  };

  const columns: ColumnDef<Material>[] = useMemo(
    () => [
      {
        header: materialText.field.name,
        accessorKey: "name",
      },
      {
        header: materialText.field.code,
        accessorKey: "code",
      },
      {
        header: materialText.field.purchasePrice,
        accessorKey: "purchasePrice",
        cell: ({ row }) =>
          row.original.purchasePrice
            ? formatCurrency(row.original.purchasePrice)
            : "-",
      },
      {
        header: materialText.field.unit,
        accessorKey: "unit",
        cell: ({ row }) => (row.original.unit ? row.original.unit.name : "-"),
      },
      {
        header: materialText.field.vendor,
        accessorKey: "vendor",
        cell: ({ row }) =>
          row.original.vendor ? row.original.vendor.vendorName : "-",
      },
      {
        header: materialText.field.description,
        accessorKey: "description",
        cell: ({ row }) => row.original.description || "-",
      },
      {
        header: "",
        accessorKey: "action",
        size: 40,
        cell: ({ row }) => (
          <MaterialActionDropdown
            onAction={handleActionClick}
            data={row.original}
          />
        ),
      },
    ],
    [materialText],
  );

  return (
    <React.Fragment>
      <ListPage>
        <ListPage.BreadCrumb>
          <BreadCrumb title={materialText.title} pageTitle="Kingston" />
        </ListPage.BreadCrumb>
        <ListPage.Filter>
          <Row>
            <Col xs={6}>
              <div className="search-box me-2 mb-2 d-inline-block col-12">
                <DebouncedInput
                  name="list-material-search"
                  value={searchString || ""}
                  onChange={(value) => {
                    setSearchString(String(value));
                  }}
                />
                <i className="bx bx-search-alt search-icon"></i>
              </div>
            </Col>
            <Col xs={6} className="text-end">
              <Button
                color="primary"
                size="sm"
                onClick={() => navigate("/material/new")}
              >
                {materialText.add}
              </Button>
            </Col>
          </Row>
        </ListPage.Filter>
        <ListPage.Main>
          <DataTable<Material>
            columns={columns}
            data={materials?.result || []}
            loading={isLoadingMaterials}
            page={page}
            limit={limit}
            setPage={setPage}
            setLimit={setLimit}
            total={materials?.total || 0}
            onRowClick={(row) => handleActionClick("detail", row)}
          />
        </ListPage.Main>
      </ListPage>
    </React.Fragment>
  );
};

export default ListMaterial;
