import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { httpClient } from "app/datasources";
import { Response } from "app/services/types";
import { AxiosError, AxiosResponse } from "axios";
import { Product } from "./types";

export const ADD_PRODUCT_MATERIAL_URL = (id: ID) =>
  `/api/admin/products/${id}/add-material`;

export enum AddProductMaterialError {
  ProductCodeAlreadyExists = "Product Code Already Exists",
}

export const addProductMaterialErrorMessages = {
  [AddProductMaterialError.ProductCodeAlreadyExists]:
    "Product Code Already Exists",
};

export interface AddProductMaterialArgs {
  productId: ID;
  componentId: ID | null;
  materialId: ID;
  quantity: number;
  note: string | null;
}

export type AddProductMaterialResponse = Response<Product>;

export const addProductMaterial = ({ ...args }: AddProductMaterialArgs) => {
  return httpClient.post<AddProductMaterialResponse>(
    ADD_PRODUCT_MATERIAL_URL(args.productId),
    args,
  );
};

export const useAddProductMaterialMutation = (
  opts?: Omit<
    UseMutationOptions<
      AxiosResponse<AddProductMaterialResponse>,
      AxiosError<any>,
      AddProductMaterialArgs
    >,
    "mutationFn"
  >,
) =>
  useMutation<
    AxiosResponse<AddProductMaterialResponse>,
    AxiosError<any>,
    AddProductMaterialArgs
  >({
    mutationFn: addProductMaterial,
    mutationKey: [ADD_PRODUCT_MATERIAL_URL],
    ...opts,
  });
