import { useAppTranslation } from "app/hooks";
import {
  ProductionPlan,
  ProductionPlanStatus,
} from "app/services/productionPlan";
import { FC, MouseEvent, useState } from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";

export type ProductionPlanAction =
  | "edit"
  | "delete"
  | "detail"
  | "approve"
  | "reject";

export interface ProductionPlanActionDropdownProps {
  data?: ProductionPlan;
  onAction: (action: ProductionPlanAction, data?: ProductionPlan) => void;
}

export const ProductionPlanActionDropdown: FC<
  ProductionPlanActionDropdownProps
> = ({ data, onAction }) => {
  const { buttonText } = useAppTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const handleToggle = () => setIsOpen(!isOpen);

  const handleAction = (action: ProductionPlanAction) => () => {
    onAction(action, data);
    setIsOpen(false);
  };

  const isWaitForApprove = data?.status === ProductionPlanStatus.WaitForApprove;

  return (
    <Dropdown
      isOpen={isOpen}
      toggle={(e: MouseEvent) => {
        e.stopPropagation();
        handleToggle();
      }}
      direction="start"
    >
      <DropdownToggle
        tag="button"
        className="btn py-0 px-2 m-0"
        onClick={(e) => {
          const trClosest = e.currentTarget.closest("tr");
          trClosest?.classList.toggle("z-3", !isOpen);
        }}
        onBlur={(e) => {
          const trClosest = e.currentTarget.closest("tr");
          setTimeout(() => trClosest?.classList.remove("z-3"), 100);
        }}
      >
        <i className="ri-more-fill"></i>
      </DropdownToggle>
      <DropdownMenu className="dropdown-menu-end">
        <DropdownItem className="p-0" onClick={handleAction("detail")}>
          <div className="dropdown-item">
            <i className="ri-arrow-right-s-line text-muted fs-16 align-middle me-1"></i>
            <span className="align-middle">{buttonText.detail}</span>
          </div>
        </DropdownItem>
        {isWaitForApprove && (
          <DropdownItem className="p-0" onClick={handleAction("approve")}>
            <div className="dropdown-item">
              <i className="ri-checkbox-circle-line text-muted fs-16 align-middle me-1"></i>
              <span className="align-middle">{buttonText.approve}</span>
            </div>
          </DropdownItem>
        )}
        <DropdownItem className="p-0" onClick={handleAction("edit")}>
          <div className="dropdown-item">
            <i className="ri-pencil-line text-muted fs-16 align-middle me-1"></i>
            <span className="align-middle">{buttonText.edit}</span>
          </div>
        </DropdownItem>
        <DropdownItem className="p-0" onClick={handleAction("delete")}>
          <div className="dropdown-item">
            <i className="ri-delete-bin-line text-muted fs-16 align-middle me-1"></i>
            <span className="align-middle">{buttonText.delete}</span>
          </div>
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};
