import {
  RetrieveProductStructureByIdArgs,
  useRetrieveProductStructureByIdQuery,
} from "app/services/product";

export const useRetrieveProductStructureById = (
  args: RetrieveProductStructureByIdArgs,
  opts?: Parameters<typeof useRetrieveProductStructureByIdQuery>[1],
) => {
  const result = useRetrieveProductStructureByIdQuery(args, opts as any);
  return {
    productStructure: result.data?.data.data,
    isLoadingProductStructure: result.isLoading,
    ...result,
  };
};
