import { ListInfoItem } from "app/components/molecules/ListInfoItem";
import { useDetailPageContext } from "app/components/templates/DetailPage/DetailPageContext";
import { formatCurrency } from "app/helpers/utils";
import { useAppTranslation } from "app/hooks";
import { Material } from "app/services/material";
import { FC } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";

export interface DetailMaterialGeneralProps {}

const DetailMaterialGeneral: FC<DetailMaterialGeneralProps> = () => {
  const { materialText } = useAppTranslation();
  const { data: material } = useDetailPageContext<Material>();
  return (
    <div className="p-3 pb-0">
      <Row>
        <Col xs={12}>
          <Card>
            <CardBody>
              <h5 className="card-title mb-3">
                {materialText.detail.infomation}
              </h5>
              <Row className="gap-2">
                <Col xs={12}>
                  <ListInfoItem
                    label={materialText.field.name}
                    value={material?.name}
                  />
                  <ListInfoItem
                    label={materialText.field.code}
                    value={material?.code}
                  />
                  <ListInfoItem
                    label={materialText.field.purchasePrice}
                    value={
                      material?.purchasePrice
                        ? formatCurrency(material.purchasePrice)
                        : null
                    }
                  />
                  <ListInfoItem
                    label={materialText.field.unit}
                    value={material?.unit ? material.unit.name : "-"}
                  />
                  <ListInfoItem
                    label={materialText.field.vendor}
                    value={material?.vendor ? material.vendor.vendorName : "-"}
                  />
                  <ListInfoItem
                    label={materialText.field.description}
                    value={material?.description}
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default DetailMaterialGeneral;
