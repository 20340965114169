import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { httpClient } from "app/datasources";
import { AxiosError, AxiosResponse } from "axios";
import { Response } from "../types";
import { EmployeeIdCode } from "./types";

export const DEACTIVE_EMPLOYEE_ID_CODE_URL = (id: ID) =>
  `/api/admin/employee-id-codes/${id}`;

export enum DeactiveEmployeeIdCodeError {}

export const deactiveEmployeeIdCodeErrorMessages = {};

export type DeactiveEmployeeIdCodeResponse = Response<EmployeeIdCode>;

export type DeactiveEmployeeIdCodeArgs = {
  id: ID;
  notes: string;
};

export const deactiveEmployeeIdCode = ({
  id,
  ...args
}: DeactiveEmployeeIdCodeArgs) => {
  return httpClient.put<DeactiveEmployeeIdCodeResponse>(
    DEACTIVE_EMPLOYEE_ID_CODE_URL(id),
    args,
  );
};

export const useDeactiveEmployeeIdCodeMutation = (
  opts?: Omit<
    UseMutationOptions<
      AxiosResponse<DeactiveEmployeeIdCodeResponse>,
      AxiosError<any>,
      DeactiveEmployeeIdCodeArgs
    >,
    "mutationFn"
  >,
) =>
  useMutation<
    AxiosResponse<DeactiveEmployeeIdCodeResponse>,
    AxiosError<any>,
    DeactiveEmployeeIdCodeArgs
  >({
    mutationFn: deactiveEmployeeIdCode,
    mutationKey: [DEACTIVE_EMPLOYEE_ID_CODE_URL],
    ...opts,
  });
