import {
  RetrieveComponentByIdArgs,
  useRetrieveComponentByIdQuery,
} from "app/services/product";

export const useRetrieveComponentById = (
  args: RetrieveComponentByIdArgs,
  opts?: Parameters<typeof useRetrieveComponentByIdQuery>[1],
) => {
  const result = useRetrieveComponentByIdQuery(args, opts as any);
  const images =
    result.data?.data.data.attachments?.filter(
      (attachment) => attachment.fileType === "image",
    ) || [];
  const documents =
    result.data?.data.data.attachments?.filter(
      (attachment) => attachment.fileType === "document",
    ) || [];
  return {
    component: { ...result.data?.data.data, images, documents },
    isLoadingComponent: result.isLoading,
    ...result,
  };
};
