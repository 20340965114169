import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { httpClientPanel } from "app/datasources";
import { APIError, APISuccess, Response } from "../types";

export const LOGOUT_PANEL_URL = "/api/panel/auth/logout";

export type LogoutPanelResponse = Response<unknown>;

export enum LogoutPanelError {
  InvalidLogoutPanelInfo = "User Not Found",
}

export const logoutPanelErrorMessages = {
  [LogoutPanelError.InvalidLogoutPanelInfo]:
    "Thông tin đăng xuất không chính xác",
};

export const logoutPanel = () => {
  return httpClientPanel.post<LogoutPanelResponse>(LOGOUT_PANEL_URL);
};

export const useLogoutPanelMutation = (
  opts?: UseMutationOptions<
    APISuccess<LogoutPanelResponse>,
    APIError<LogoutPanelError>
  >,
) =>
  useMutation({
    mutationFn: logoutPanel,
    mutationKey: [LOGOUT_PANEL_URL],
    ...opts,
  });
