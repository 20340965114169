import { useAppTranslation } from "app/hooks";
import { useRetrieveOrderById } from "app/modules/order/hooks";
import { FC } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "reactstrap";

export interface DetailOrderHeaderProps {}

const DetailOrderHeader: FC<DetailOrderHeaderProps> = () => {
  const { buttonText, orderText } = useAppTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const { order } = useRetrieveOrderById(
    { id: id as string },
    { enabled: !!id },
  );

  return (
    <div className="d-flex gap-4 flex-wrap">
      <div className="flex-grow-1 p-2">
        <h3 className="mb-1">
          {orderText.title} {order?.orderCode}
        </h3>
        {/* <Badge pill color="success">
          Đã giao hàng
        </Badge> */}
      </div>
      <div className="text-end">
        <div className="d-flex gap-2 justify-content-end">
          <Button
            color="primary"
            onClick={() => navigate(`/order/${id}/update`)}
          >
            {buttonText.edit}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default DetailOrderHeader;
