import { PagingData } from "./../types";
import {
  keepPreviousData,
  useQuery,
  UseQueryOptions,
} from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import { Response } from "../types";
import { httpClient } from "app/datasources";
import { AccessTypeUser, User } from "./types";

export const RETRIEVE_USERS_URL = "/api/admin/users";

export interface RetrieveUsersArgs {
  page: number;
  limit: number;
  searchString?: string;
  accessType?: AccessTypeUser;
}

export type RetrieveUsersResponse = Response<PagingData<User[]>>;

export const retrieveUsers = (args: RetrieveUsersArgs) => {
  return httpClient.get<RetrieveUsersResponse>(RETRIEVE_USERS_URL, {
    params: args,
  });
};

export const useRetrieveUsersQuery = (
  retrieveUsersArgs: RetrieveUsersArgs,
  opts?: Omit<
    UseQueryOptions<AxiosResponse<RetrieveUsersResponse>, AxiosError<any>>,
    "queryKey" | "queryFn"
  >,
) =>
  useQuery<AxiosResponse<RetrieveUsersResponse>, AxiosError<any>>({
    queryKey: [RETRIEVE_USERS_URL, retrieveUsersArgs],
    queryFn: () => retrieveUsers(retrieveUsersArgs),
    placeholderData: keepPreviousData,
    ...opts,
  });
