import { useQueryClient } from "@tanstack/react-query";
import { errorMessageMapper } from "app/helpers";
import useToastify from "app/hooks/useToastify";
import {
  importAvatarEmployeeErrorMessages,
  ImportAvatarEmployeeResponse,
  RETRIEVE_EMPLOYEES_URL,
  useImportAvatarEmployeeMutation,
} from "app/services/employee";
import { AxiosResponse } from "axios";

interface UseImportAvatarEmployeeProps {
  onSuccess?: (data?: AxiosResponse<ImportAvatarEmployeeResponse, any>) => void;
  onError?: () => void;
}

export const useImportAvatarEmployee = ({
  onError: handleError,
  onSuccess: handleSuccess,
}: UseImportAvatarEmployeeProps = {}) => {
  const toast = useToastify();
  const queryClient = useQueryClient();
  return useImportAvatarEmployeeMutation({
    onSuccess: (data) => {
      toast.success();
      queryClient.invalidateQueries({ queryKey: [RETRIEVE_EMPLOYEES_URL] });
      handleSuccess?.(data);
    },
    onError: (error) => {
      toast.error(
        errorMessageMapper(
          importAvatarEmployeeErrorMessages,
          error.response?.data.message,
        ),
      );
      handleError?.();
    },
  });
};
