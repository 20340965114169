import { ColumnDef } from "@tanstack/react-table";
import { Button } from "app/components/molecules/Button";
import { useConfirm } from "app/components/molecules/ConfirmationProvider";
import { DebouncedInput } from "app/components/molecules/DebounceInput";
import { DataTable } from "app/components/organisms/DataTable";
import { useAppTranslation } from "app/hooks";
import useAppSearch from "app/hooks/useAppSearch";
import { BomAction, BomActionDropdown } from "app/modules/bom/components";
import { mapBomStatus } from "app/modules/bom/helpers";
import {
  useActiveBom,
  useApproveBom,
  useDeactiveBom,
  useDeleteBom,
  useRejectBom,
  useRetrieveBoms,
} from "app/modules/bom/hooks";
import { Bom } from "app/services/bom";
import { FC, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Col, Row } from "reactstrap";

export interface DetailProductBomProps {}

const DetailProductBom: FC<DetailProductBomProps> = () => {
  const { id } = useParams();
  const { bomText, confirmText } = useAppTranslation();
  const navigate = useNavigate();
  const { confirm } = useConfirm();

  const { page, limit, searchString, setPage, setLimit, setSearchString } =
    useAppSearch({
      initialPage: 1,
      initialLimit: 20,
      initialSearchString: "",
    });

  const { boms, isLoadingBoms } = useRetrieveBoms({
    productId: id,
    limit,
    page,
    searchString: searchString || undefined,
  });

  const { mutateAsync: deleteBom } = useDeleteBom();

  const { mutateAsync: activeBom } = useActiveBom();

  const { mutateAsync: deactiveBom } = useDeactiveBom();

  const { mutateAsync: approveBom } = useApproveBom();

  const { mutateAsync: rejectBom } = useRejectBom();

  const handleActionClick = (action: BomAction, data?: Bom) => {
    switch (action) {
      case "active":
        if (data) activeBom({ id: data.id });
        break;
      case "deactive":
        if (data) deactiveBom({ id: data.id });
        break;
      case "edit":
        if (data) {
          navigate(`/bom/${data.id}/update`);
        }
        break;
      case "detail":
        if (data) {
          navigate(`/bom/${data.id}`);
        }
        break;
      case "delete":
        if (data?.id) {
          confirm({
            title: confirmText.deleteTitle(bomText.title),
            description: confirmText.deleteContent(bomText.title),
            onConfirm: () => deleteBom({ id: data.id }),
          });
        }
        break;
      case "approve":
        if (data?.id) {
          confirm({
            icon: "bx bx-check-circle",
            severity: "success",
            title: confirmText.approveTitle(bomText.title),
            description: confirmText.approveContent(bomText.title),
            onConfirm: () => approveBom({ id: data.id }),
          });
        }
        break;
      case "reject":
        if (data?.id) {
          confirm({
            title: confirmText.rejectTitle(bomText.title),
            description: confirmText.rejectContent(bomText.title),
            onConfirm: () => rejectBom({ id: data.id }),
          });
        }
        break;
      default:
        break;
    }
  };

  const columns: ColumnDef<Bom>[] = useMemo(
    () => [
      {
        header: bomText.field.name,
        accessorKey: "name",
        cell: ({ row }) => row.original.name || "-",
      },
      {
        header: bomText.field.version,
        accessorKey: "version",
        cell: ({ row }) => row.original.version || "-",
      },
      {
        header: bomText.field.status,
        accessorKey: "status",
        cell: ({ row }) =>
          row.original.status ? mapBomStatus(row.original.status) : null,
      },
      {
        header: bomText.field.description,
        accessorKey: "description",
        cell: ({ row }) => row.original.description || "-",
      },
      {
        header: "",
        accessorKey: "action",
        size: 40,
        cell: ({ row }) => (
          <BomActionDropdown onAction={handleActionClick} data={row.original} />
        ),
      },
    ],
    [bomText],
  );

  return (
    <div className="p-3 py-0 h-100 d-flex flex-column">
      <Row>
        <Col xs={6} className="search-box my-2 d-inline-block">
          <DebouncedInput
            name="detail-product-bom-search"
            value={searchString || ""}
            onChange={(value) => {
              setSearchString(String(value));
            }}
          />
          <i className="bx bx-search-alt search-icon"></i>
        </Col>
        <Col xs={6} className="text-end my-2">
          <Button
            color="primary"
            size="sm"
            onClick={() => {
              navigate(`/bom/new/${id}`);
            }}
          >
            {bomText.add}
          </Button>
        </Col>
      </Row>
      <DataTable<Bom>
        columns={columns}
        data={boms?.result || []}
        loading={isLoadingBoms}
        total={boms?.total || 0}
        page={page}
        limit={limit}
        setPage={setPage}
        setLimit={setLimit}
        onRowClick={(row) => handleActionClick("detail", row)}
      />
    </div>
  );
};

export default DetailProductBom;
