import { useAppTranslation } from "app/hooks";
import { ProductMaterial } from "app/services/product";
import { FC } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { useRetrieveProductMaterialById } from "../../hooks";
import { mapDefaultProductMaterial } from "../../mapper";
import {
  ProductMaterialForm,
  ProductMaterialFormData,
} from "./ProductMaterialForm";

export interface ProductMaterialFormModalProps {
  data?: ProductMaterial;
  open: boolean;
  onToggle: () => void;
  onSubmit: (data: ProductMaterialFormData) => void;
  submitting?: boolean;
  edit?: boolean;
}

export const ProductMaterialFormModal: FC<ProductMaterialFormModalProps> = ({
  data,
  onToggle,
  open = false,
  onSubmit,
  submitting,
  edit = false,
}) => {
  const { productText } = useAppTranslation();
  const title = edit
    ? productText.editMaterialTitle
    : productText.addMaterialTitle;
  const { productMaterial, isLoadingProductMaterial } =
    useRetrieveProductMaterialById(
      {
        id: data?.id as string,
      },
      { enabled: !!edit && !!data?.id },
    );

  return (
    <Modal isOpen={open} toggle={onToggle}>
      <ModalHeader className="modal-title" toggle={onToggle}>
        {title}
      </ModalHeader>
      <ModalBody>
        {!isLoadingProductMaterial ? (
          <ProductMaterialForm
            onSubmit={onSubmit}
            onToggle={onToggle}
            defaultValues={mapDefaultProductMaterial(productMaterial || {})}
            submitting={submitting}
            edit={edit}
          />
        ) : null}
      </ModalBody>
    </Modal>
  );
};
