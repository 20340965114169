import { useAppTranslation } from "app/hooks";
import { FC, MouseEvent, useState } from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";

export type ListProductAction = "import" | "download-template";

export interface ListProductActionDropdownProps {
  onAction: (action: ListProductAction) => void;
}

export const ListProductActionDropdown: FC<ListProductActionDropdownProps> = ({
  onAction,
}) => {
  const { buttonText } = useAppTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const handleToggle = () => setIsOpen(!isOpen);

  const handleAction = (action: ListProductAction) => () => {
    onAction(action);
    setIsOpen(false);
  };

  return (
    <Dropdown
      isOpen={isOpen}
      toggle={(e: MouseEvent) => {
        e.stopPropagation();
        handleToggle();
      }}
      direction="end"
    >
      <DropdownToggle
        tag="button"
        className="btn btn-sm btn-icon btn-outline-primary"
        onClick={(e) => {
          const trClosest = e.currentTarget.closest("tr");
          trClosest?.classList.toggle("z-3", !isOpen);
        }}
        onBlur={(e) => {
          const trClosest = e.currentTarget.closest("tr");
          setTimeout(() => trClosest?.classList.remove("z-3"), 100);
        }}
      >
        <i className="ri-more-fill"></i>
      </DropdownToggle>
      <DropdownMenu className="dropdown-menu-end">
        <DropdownItem className="p-0" onClick={handleAction("import")}>
          <div className="dropdown-item">
            <i className="ri-upload-2-line text-muted fs-16 align-middle me-1"></i>
            <span className="align-middle">{buttonText.import}</span>
          </div>
        </DropdownItem>
        <DropdownItem
          className="p-0"
          onClick={handleAction("download-template")}
        >
          <div className="dropdown-item">
            <i className="ri-file-excel-line text-muted fs-16 align-middle me-1"></i>
            <span className="align-middle">{buttonText.downloadTemplate}</span>
          </div>
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};
