import FormPage from "app/components/templates/FormPage";
import { useAppTranslation } from "app/hooks";
import {
  MaterialForm,
  MaterialFormData,
} from "app/modules/material/components";
import { useCreateMaterial } from "app/modules/material/hooks";
import {
  mapCreateMaterial,
  mapDefaultMaterial,
} from "app/modules/material/mapper";
import BreadCrumb from "Components/Common/BreadCrumb";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { Card, CardBody } from "reactstrap";

export interface CreateMaterialProps {}

const CreateMaterial: FC<CreateMaterialProps> = () => {
  const { materialText } = useAppTranslation();
  const navigate = useNavigate();
  const { mutateAsync, isPending } = useCreateMaterial({
    onSuccess: () => {
      navigate("/materials");
    },
  });

  const handleSubmit = async (data: MaterialFormData) => {
    mutateAsync(mapCreateMaterial(data));
  };

  return (
    <FormPage>
      <FormPage.BreadCrumb>
        <BreadCrumb
          title={materialText.add}
          pageTitle={materialText.title}
          isBack
        />
      </FormPage.BreadCrumb>
      <FormPage.Main>
        <Card>
          <CardBody>
            <MaterialForm
              onSubmit={handleSubmit}
              defaultValues={mapDefaultMaterial()}
              submitting={isPending}
            />
          </CardBody>
        </Card>
      </FormPage.Main>
    </FormPage>
  );
};

export default CreateMaterial;
