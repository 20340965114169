import { useQueryClient } from "@tanstack/react-query";
import { AttachmentBar } from "app/components/organisms/AttachmentBar";
import DetailPage from "app/components/templates/DetailPage";
import { useAppTranslation, useDebounceValue } from "app/hooks";
import useToastify from "app/hooks/useToastify";
import { FileDTO, Source } from "app/models";
import { getDeleteFileIds, getUploadedFileIds } from "app/modules/file/helpers";
import {
  useRetrieveFilesInfinite,
  useUploadModuleFiles,
} from "app/modules/file/hooks";
import { ComponentFormModal } from "app/modules/product/components";
import { ComponentFormData } from "app/modules/product/components/ComponentForm";
import {
  useRetrieveComponentById,
  useUpdateComponent,
} from "app/modules/product/hooks";
import { mapUpdateComponent } from "app/modules/product/mapper";
import { useDeleteFile, useUploadFiles } from "app/services/file";
import { Component, RETRIEVE_COMPONENT_BY_ID_URL } from "app/services/product";
import BreadCrumb from "Components/Common/BreadCrumb";
import { useMemo, useState } from "react";
import { Outlet, useParams } from "react-router-dom";
import DetailComponentHeader from "./DetailComponentHeader";
import { DetailComponentTabs } from "./DetailComponentTabs";

const DetailComponent = () => {
  const { id } = useParams();
  const { componentText } = useAppTranslation();
  const toastify = useToastify();
  const queryClient = useQueryClient();

  const { component, isLoadingComponent } = useRetrieveComponentById(
    { id: id as string },
    { enabled: !!id },
  );

  const [searchString, setSearchString] = useState("");

  const handleSetSearchString = (value: string) => {
    setSearchString(value);
  };

  const debouncedSearchString = useDebounceValue(searchString);

  const { mutateAsync: deleteFile } = useDeleteFile();

  const handleDelete = async (id: string) => {
    deleteFile(
      { id },
      {
        onSuccess: () => {
          toastify.success();
          queryClient.invalidateQueries({
            queryKey: [RETRIEVE_COMPONENT_BY_ID_URL(id)],
          });
        },
        onError: () => {
          toastify.error();
        },
      },
    );
  };

  const { mutateAsync } = useUploadModuleFiles();

  const { mutateAsync: upload, isPending: isUploading } = useUploadFiles();

  const {
    files: _images,
    hasNextPage: hasNextPageImages,
    fetchNextPage: fetchNextPageImages,
    isFetchingNextPage: isFetchingNextPageImages,
    isLoading: isImagesLoading,
  } = useRetrieveFilesInfinite({
    moduleId: id as ID,
    moduleType: Source.Component,
    fileType: "image",
    searchString: debouncedSearchString,
  });

  const imagesData = useMemo(() => {
    let result: FileDTO[] = [];
    _images?.pages.forEach((page) => {
      result = result.concat(page.data.data.result);
    });
    return result;
  }, [_images]);

  const handleUploadImages = async (data: File[]) => {
    const imageIds =
      (
        await getUploadedFileIds({
          data: data,
          fileKey: "image",
          source: Source.Component,
          upload,
        })
      )?.fileIds || [];
    return await mutateAsync(
      {
        fileIds: imageIds,
        moduleId: id as string,
        moduleType: Source.Component,
        relationType: "component_manual",
      },
      {
        onError: () => {
          imageIds?.map((imageId) => {
            deleteFile({ id: imageId });
          });
        },
      },
    );
  };

  const {
    files: _documents,
    hasNextPage: hasNextPageDocuments,
    fetchNextPage: fetchNextPageDocuments,
    isFetchingNextPage: isFetchingNextPageDocuments,
    isLoading: isDocumentsLoading,
  } = useRetrieveFilesInfinite({
    moduleId: id as ID,
    moduleType: Source.Component,
    fileType: "document",
    searchString: debouncedSearchString,
  });

  const documentsData = useMemo(() => {
    let result: FileDTO[] = [];
    _documents?.pages.forEach((page) => {
      result = result.concat(page.data.data.result);
    });
    return result;
  }, [_documents]);

  const handleUploadDocuments = async (data: File[]) => {
    const documentIds =
      (
        await getUploadedFileIds({
          data: data,
          fileKey: "document",
          source: Source.Component,
          upload,
        })
      )?.fileIds || [];
    return await mutateAsync(
      {
        fileIds: documentIds,
        moduleId: id as string,
        moduleType: Source.Component,
        relationType: "component_manual",
      },
      {
        onError: () => {
          documentIds?.map((documentId) => {
            deleteFile({ id: documentId });
          });
        },
      },
    );
  };

  const [isEditModalOpen, toggleEditModalOpen] = useState(false);

  const handleToggleEditModal = () => {
    toggleEditModalOpen((prev) => !prev);
  };

  const { mutateAsync: updateComponent, isPending: isComponentUpdating } =
    useUpdateComponent({
      id,
      onSuccess: () => {
        handleToggleEditModal();
      },
    });

  const handleUpdate = async (data: ComponentFormData) => {
    const currentAvatarId = data.avatars[0]?.id;
    const avatarIds =
      (
        await getUploadedFileIds({
          data: data.avatars,
          fileKey: "image",
          source: Source.Component,
          upload,
        })
      )?.fileIds || [];
    const avatarId = avatarIds[0];
    const imageIds =
      (
        await getUploadedFileIds({
          data: data.images,
          fileKey: "image",
          source: Source.Component,
          upload,
        })
      )?.fileIds || [];
    const documentIds =
      (
        await getUploadedFileIds({
          data: data.documents,
          fileKey: "document",
          source: Source.Component,
          upload,
        })
      )?.fileIds || [];
    const deleteImageIds = getDeleteFileIds(component.images, data.images);
    const deleteDocumentIds = getDeleteFileIds(
      component.documents,
      data.documents,
    );

    updateComponent(
      mapUpdateComponent({
        ...data,
        id: id as ID,
        imageId: currentAvatarId || avatarId,
        files: [...imageIds, ...documentIds],
        deleteFileIds: [...deleteImageIds, ...deleteDocumentIds],
      }),
    );
  };

  return (
    <>
      <DetailPage<Component>
        data={component as Component}
        loading={isLoadingComponent}
      >
        <DetailPage.BreadCrumb>
          <BreadCrumb
            title={componentText.detail.title}
            pageTitle={componentText.title}
            isBack
          />
        </DetailPage.BreadCrumb>
        <DetailPage.Header>
          <DetailComponentHeader onActionClick={handleToggleEditModal} />
        </DetailPage.Header>
        <DetailPage.Main
          tabs={<DetailComponentTabs />}
          attachmentBar={
            <AttachmentBar
              imageProps={{
                data: imagesData || [],
                onUpload: handleUploadImages,
                uploading: isUploading,
                loading: isImagesLoading,
                onDelete: handleDelete,
                fetchingProps: {
                  fetchNextPage: fetchNextPageImages,
                  hasNextPage: hasNextPageImages,
                  isFetchingNextPage: isFetchingNextPageImages,
                },
              }}
              documentProps={{
                data: documentsData || [],
                onUpload: handleUploadDocuments,
                uploading: isUploading,
                loading: isDocumentsLoading,
                onDelete: handleDelete,
                fetchingProps: {
                  fetchNextPage: fetchNextPageDocuments,
                  hasNextPage: hasNextPageDocuments,
                  isFetchingNextPage: isFetchingNextPageDocuments,
                },
              }}
              onSearch={handleSetSearchString}
            />
          }
        >
          <Outlet />
        </DetailPage.Main>
      </DetailPage>
      <ComponentFormModal
        open={isEditModalOpen}
        onCancel={handleToggleEditModal}
        onSubmit={handleUpdate}
        submitting={isComponentUpdating || isUploading}
        edit={true}
        data={component as Component}
      />
    </>
  );
};

export default DetailComponent;
