import { errorMessageMapper } from "app/helpers";
import useToastify from "app/hooks/useToastify";
import {
  exportProductTemplateErrorMessages,
  ExportProductTemplateResponse,
  useExportProductTemplateMutation,
} from "app/services/product";
import { AxiosResponse } from "axios";

interface UseExportProductTemplateProps {
  onSuccess?: (
    data?: AxiosResponse<ExportProductTemplateResponse, any>,
  ) => void;
  onError?: () => void;
}

export const useExportProductTemplate = ({
  onError: handleError,
  onSuccess: handleSuccess,
}: UseExportProductTemplateProps = {}) => {
  const toast = useToastify();
  return useExportProductTemplateMutation({
    onSuccess: (data) => {
      toast.success();
      handleSuccess?.(data);
    },
    onError: (error) => {
      toast.error(
        errorMessageMapper(
          exportProductTemplateErrorMessages,
          error.response?.data.message,
        ),
      );
      handleError?.();
    },
  });
};
