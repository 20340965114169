import DetailPage from "app/components/templates/DetailPage";
import { useAppTranslation } from "app/hooks";
import {
  StartTicketFormData,
  StartTicketFormModal,
  TicketAction,
  TicketLogFormData,
  TicketLogFormModal,
  TicketProductionOrderItemFormData,
  TicketProductionOrderItemFormModal,
} from "app/modules/ticket/components";
import {
  useRetrieveTicketById,
  useStartTicket,
  useUpdateTicket,
} from "app/modules/ticket/hooks";
import { mapUpdateTicket } from "app/modules/ticket/mapper";
import { useCreateTicketLog } from "app/modules/ticketLog/hooks";
import { mapCreateTicketLog } from "app/modules/ticketLog/mapper";
import { Ticket } from "app/services/ticket";
import BreadCrumb from "Components/Common/BreadCrumb";
import { useState } from "react";
import { Outlet, useParams } from "react-router-dom";
import DetailTicketHeader from "./DetailTicketHeader";
import { DetailTicketTabs } from "./DetailTicketTabs";

const DetailTicket = () => {
  const { id } = useParams();
  const { ticketText } = useAppTranslation();
  const [isModalOpen, toggleModal] = useState(false);
  const [isCheckInModalOpen, toggleCheckInModal] = useState(false);
  const [isStartModalOpen, toggleStartModal] = useState(false);

  const handleToggleCheckInModal = () => {
    toggleCheckInModal((prevState) => !prevState);
  };

  const handleToggleStartModal = () => {
    toggleStartModal((prevState) => !prevState);
  };

  const { ticket, isLoadingTicket } = useRetrieveTicketById(
    { id: id as string },
    { enabled: !!id },
  );

  const handleToggleModal = () => {
    toggleModal((prevState) => !prevState);
  };

  const { mutateAsync: updateTicket, isPending: isCreating } = useUpdateTicket({
    id: ticket?.id as ID,
    onSuccess: () => {
      handleToggleModal();
    },
  });

  const handleUpdateTicket = (data: TicketProductionOrderItemFormData) => {
    updateTicket(mapUpdateTicket({ id: ticket?.id as ID, ...data }));
  };

  const handleActionClick = (action: TicketAction) => {
    switch (action) {
      case "edit":
        handleToggleModal();
        break;
      case "start":
        handleToggleStartModal();
        break;
      case "checkIn":
        handleToggleCheckInModal();
        break;
      default:
        break;
    }
  };

  const { mutateAsync: createLog, isPending: isLogCreating } =
    useCreateTicketLog({ id, onSuccess: handleToggleCheckInModal });

  const handleCreateLog = (data: TicketLogFormData) => {
    createLog(mapCreateTicketLog({ ...data, ticketId: id as ID }));
  };

  const { mutateAsync: start, isPending: isStarting } = useStartTicket({
    id,
    onSuccess: handleToggleStartModal,
  });

  const handleStart = ({ employee }: StartTicketFormData) => {
    start({
      employeeId: employee?.value as ID,
      id: id as ID,
    });
  };

  return (
    <>
      <DetailPage<Ticket> data={ticket as Ticket} loading={isLoadingTicket}>
        <DetailPage.BreadCrumb>
          <BreadCrumb
            title={ticketText.detail.title}
            pageTitle={ticketText.title}
            isBack
          />
        </DetailPage.BreadCrumb>
        <DetailPage.Header>
          <DetailTicketHeader onActionClick={handleActionClick} />
        </DetailPage.Header>
        <DetailPage.Main tabs={<DetailTicketTabs />}>
          <Outlet />
        </DetailPage.Main>
      </DetailPage>
      <TicketProductionOrderItemFormModal
        open={isModalOpen}
        onToggle={handleToggleModal}
        onSubmit={handleUpdateTicket}
        submitting={isCreating}
        data={ticket}
        edit
      />
      <TicketLogFormModal
        open={isCheckInModalOpen}
        onToggle={handleToggleCheckInModal}
        onSubmit={handleCreateLog}
        submitting={isLogCreating}
        defaultValues={{
          remainingQuantity: ticket?.remainingQuantity,
          defaultRemaningQuantity: ticket?.remainingQuantity,
          totalQuantity: ticket?.quantity,
          employee: ticket?.assignee
            ? {
                label: ticket.assignee.fullName,
                value: ticket.assignee.id,
              }
            : null,
          quantity: null,
          note: "",
        }}
      />
      <StartTicketFormModal
        open={isStartModalOpen}
        onToggle={handleToggleStartModal}
        onSubmit={handleStart}
        submitting={isStarting}
      />
    </>
  );
};

export default DetailTicket;
