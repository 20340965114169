import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { httpClient } from "app/datasources";
import { AxiosError, AxiosResponse } from "axios";
import { Bom } from "./types";
import { Response } from "../types";

export const RETRIEVE_BOM_BY_ID_URL = (id: ID) => `/api/admin/boms/${id}`;

export type RetrieveBomByIdResponse = Response<Bom>;

export type RetrieveBomByIdArgs = {
  id: ID;
};

export const retrieveBomById = ({ id }: RetrieveBomByIdArgs) => {
  return httpClient.get<RetrieveBomByIdResponse>(RETRIEVE_BOM_BY_ID_URL(id));
};

export const useRetrieveBomByIdQuery = (
  retrieveBomsArgs: RetrieveBomByIdArgs,
  opts?: Omit<
    UseQueryOptions<AxiosResponse<RetrieveBomByIdResponse>, AxiosError<any>>,
    "queryKey" | "queryFn"
  >,
) =>
  useQuery<AxiosResponse<RetrieveBomByIdResponse>, AxiosError<any>>({
    queryKey: [RETRIEVE_BOM_BY_ID_URL(retrieveBomsArgs.id)],
    queryFn: () => retrieveBomById(retrieveBomsArgs),
    ...opts,
  });
