import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "app/components/molecules/Button";
import { FormHelper } from "app/components/molecules/FormHelper";
import { FormInput } from "app/components/molecules/FormInput";
import { FormLabel } from "app/components/molecules/FormLabel";
import { yupSchema } from "app/helpers/schema";
import { useAppTranslation, useGetEnableSubmitButton } from "app/hooks";
import { FC, FormEvent } from "react";
import { Controller, useForm } from "react-hook-form";
import { Col, Form, FormGroup, Row } from "reactstrap";
import * as Yup from "yup";

export interface WorkshopFormProps {
  defaultValues?: WorkshopFormData;
  onSubmit: (data: WorkshopFormData) => void;
  submitting?: boolean;
}

export interface WorkshopFormData {
  name: string;
  description: string;
  address: string;
}

export const WorkshopForm: FC<WorkshopFormProps> = ({
  onSubmit,
  defaultValues,
  submitting,
}) => {
  const { workshopText, buttonText, generalInfomationText } =
    useAppTranslation();
  const {
    control,
    handleSubmit,
    formState: { isDirty, isValid },
  } = useForm<WorkshopFormData>({
    mode: "onChange",
    resolver: yupResolver(
      Yup.object({
        name: yupSchema.stringRequired("workshop.error.nameRequired"),
        description: yupSchema.stringNotRequired(),
        address: yupSchema.stringNotRequired(),
      }),
    ),
    defaultValues,
  });

  const isEnableSubmit = useGetEnableSubmitButton({
    isDirty,
    isValid,
    submitting,
  });

  const submit = async (e: FormEvent) => {
    e.preventDefault();
    handleSubmit(onSubmit)();
  };

  return (
    <Form onSubmit={submit} id="workshop-form">
      <h5 className="py-2 fw-semibold">{generalInfomationText}</h5>
      <Row>
        <Col xs={12}>
          <FormGroup>
            <FormLabel required htmlFor="name" className="form-label">
              {workshopText.field.name}
            </FormLabel>
            <Controller
              name="name"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <FormInput
                    {...field}
                    type="text"
                    className="form-control"
                    id="name"
                    invalid={!!fieldState.error}
                  />
                  <FormHelper message={fieldState.error?.message} />
                </>
              )}
            />
          </FormGroup>
        </Col>
        <Col xs={12}>
          <FormGroup>
            <FormLabel htmlFor="description" className="form-label">
              {workshopText.field.description}
            </FormLabel>
            <Controller
              name="description"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <FormInput
                    {...field}
                    type="text"
                    className="form-control"
                    id="description"
                    invalid={!!fieldState.error}
                  />
                  <FormHelper message={fieldState.error?.message} />
                </>
              )}
            />
          </FormGroup>
        </Col>
        <Col xs={12}>
          <FormGroup>
            <FormLabel htmlFor="address" className="form-label">
              {workshopText.field.address}
            </FormLabel>
            <Controller
              name="address"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <FormInput
                    {...field}
                    type="text"
                    className="form-control"
                    id="address"
                    invalid={!!fieldState.error}
                  />
                  <FormHelper message={fieldState.error?.message} />
                </>
              )}
            />
          </FormGroup>
        </Col>
        <Col xs={12}>
          <div className="hstack gap-2 justify-content-end">
            <Button
              disabled={!isEnableSubmit}
              type="submit"
              color="primary"
              loading={submitting}
            >
              {buttonText.save}
            </Button>
          </div>
        </Col>
      </Row>
    </Form>
  );
};
