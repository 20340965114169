import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "app/components/molecules/Button";
import { FormHelper } from "app/components/molecules/FormHelper";
import { FormInput } from "app/components/molecules/FormInput";
import { FormLabel } from "app/components/molecules/FormLabel";
import { yupSchema } from "app/helpers/schema";
import { useAppTranslation, useGetEnableSubmitButton } from "app/hooks";
import { ProductSelect } from "app/modules/product/components";
import { Component } from "app/services/product";
import { OptionSelect } from "app/services/types";
import { FC, FormEvent } from "react";
import {
  Controller,
  FormProvider,
  useForm,
  useFormState,
} from "react-hook-form";
import { Col, Form, FormGroup, Row } from "reactstrap";
import * as Yup from "yup";

export interface BomFormProps {
  defaultValues?: BomFormData;
  onSubmit: (data: BomFormData) => void;
  submitting?: boolean;
  onCancel?: () => void;
  isDisableProduct?: boolean;
}

export type BomStructureFormData = {
  component?: Component | null;
  steps: {
    step: OptionSelect | null;
    estimateTime: number | null;
  }[];
  materials: {
    material: OptionSelect | null;
    quantity: number | null;
    note: string;
  }[];
  children?: BomStructureFormData[];
};

export interface BomFormData {
  name: string;
  description: string;
  version: string;
  product: OptionSelect | null;
  bom?: BomStructureFormData[];
}

// const mapChildren = (
//   children: ComponentWithChildren[] | undefined,
// ): BomStructureFormData[] => {
//   return (
//     children?.map((child) => ({
//       component: child,
//       steps: [{ step: null, estimateTime: null }],
//       materials: [],
//       children: mapChildren(child.children),
//     })) || []
//   );
// };

const bomStructureSchema: Yup.Schema<BomStructureFormData> = Yup.lazy(() =>
  Yup.object({
    steps: Yup.array().of(
      Yup.object({
        step: yupSchema.objectRequired("bom.error.stepRequired"),
        estimateTime: yupSchema.numberMoreThanNotRequired(
          0,
          "bom.error.estimateTimeRequired",
        ),
      }),
    ),
    materials: Yup.array().of(
      Yup.object({
        material: yupSchema.objectRequired("bom.error.materialRequired"),
        quantity: yupSchema.numberMoreThanNotRequired(
          0,
          "bom.error.estimateTimeRequired",
        ),
        note: yupSchema.stringNotRequired(),
      }),
    ),
    children: Yup.lazy(() => Yup.array().of(bomStructureSchema)),
  }),
) as any;

export const BomForm: FC<BomFormProps> = ({
  onSubmit,
  defaultValues,
  submitting,
  onCancel,
  isDisableProduct = false,
}) => {
  const { bomText, buttonText } = useAppTranslation();
  const formControl = useForm<BomFormData>({
    mode: "onChange",
    resolver: yupResolver(
      Yup.object({
        name: yupSchema.stringRequired("bom.error.nameRequired"),
        version: yupSchema.stringRequired("bom.error.versionRequired"),
        description: yupSchema.stringNotRequired(),
        product: yupSchema.objectRequired("bom.error.productRequired"),
        // bom: Yup.array().of(bomStructureSchema).defined(),
      }),
    ),
    defaultValues,
    shouldUnregister: false,
  });

  const { handleSubmit, control } = formControl;

  const { isDirty, isValid } = useFormState({ control });

  const submit = async (e: FormEvent) => {
    e.preventDefault();
    handleSubmit(onSubmit)();
  };

  // const { productStructure, isLoadingProductStructure } =
  //   useRetrieveProductStructureById({
  //     id: "1",
  //   });

  // useEffect(() => {
  //   const newValue =
  //     productStructure?.map((component) => ({
  //       component,
  //       steps: [{ step: null, estimateTime: null }],
  //       materials: [],
  //       children: mapChildren(component.children),
  //     })) || [];
  //   setValue("bom", newValue);
  // }, [productStructure]);

  const isEnableSubmit = useGetEnableSubmitButton({
    isDirty,
    isValid,
    submitting,
  });

  return (
    <FormProvider {...formControl}>
      <Form onSubmit={submit} id="product-form">
        <h5 className="py-2 fw-semibold">{bomText.detail.generalInfomation}</h5>
        <Row>
          <Col xs={12}>
            <FormGroup>
              <FormLabel required htmlFor="name" className="form-label">
                {bomText.field.name}
              </FormLabel>
              <Controller
                name="name"
                control={control}
                render={({ field, fieldState }) => (
                  <>
                    <FormInput
                      {...field}
                      type="text"
                      className="form-control"
                      id="name"
                      invalid={!!fieldState.invalid}
                    />
                    <FormHelper message={fieldState.error?.message} />
                  </>
                )}
              />
            </FormGroup>
          </Col>
          <Col xs={12}>
            <FormGroup>
              <FormLabel required htmlFor="version" className="form-label">
                {bomText.field.version}
              </FormLabel>
              <Controller
                name="version"
                control={control}
                render={({ field, fieldState }) => (
                  <>
                    <FormInput
                      {...field}
                      type="text"
                      className="form-control"
                      id="version"
                      invalid={!!fieldState.invalid}
                    />
                    <FormHelper message={fieldState.error?.message} />
                  </>
                )}
              />
            </FormGroup>
          </Col>
          <Col xs={12}>
            <FormGroup>
              <FormLabel required htmlFor="product" className="form-label">
                {bomText.field.product}
              </FormLabel>
              <Controller
                name="product"
                control={control}
                render={({ field, fieldState }) => (
                  <>
                    <ProductSelect
                      isDisabled={isDisableProduct}
                      name={field.name}
                      value={field.value}
                      inputId="product"
                      onChange={field.onChange}
                      isInvalid={fieldState.invalid}
                    />
                    <FormHelper message={fieldState.error?.message} />
                  </>
                )}
              />
            </FormGroup>
          </Col>
          <Col xs={12}>
            <FormGroup>
              <FormLabel htmlFor="description" className="form-label">
                {bomText.field.description}
              </FormLabel>
              <Controller
                name="description"
                control={control}
                render={({ field, fieldState }) => (
                  <>
                    <FormInput
                      {...field}
                      type="text"
                      className="form-control"
                      id="description"
                      invalid={!!fieldState.error}
                    />
                    <FormHelper message={fieldState.error?.message} />
                  </>
                )}
              />
            </FormGroup>
          </Col>
          {/* <Col xs={12}>
            <BomNest loading={isLoadingProductStructure} path="bom" />
          </Col> */}
          <Col xs={12}>
            <div className="hstack gap-2 justify-content-end">
              {onCancel ? (
                <Button color="light" onClick={onCancel}>
                  {buttonText.cancel}
                </Button>
              ) : null}
              <Button
                disabled={!isEnableSubmit}
                type="submit"
                color="primary"
                loading={submitting}
              >
                {buttonText.save}
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </FormProvider>
  );
};
