import { ColumnDef } from "@tanstack/react-table";
import { Button } from "app/components/molecules/Button";
import { useConfirm } from "app/components/molecules/ConfirmationProvider";
import { DataTable } from "app/components/organisms/DataTable";
import { useDetailPageContext } from "app/components/templates/DetailPage/DetailPageContext";
import { useAppTranslation } from "app/hooks";
import {
  DeactiveEmployeeIdCodeFormData,
  DeactiveEmployeeIdCodeFormModal,
  EmployeeIdCodeAction,
  EmployeeIdCodeActionDropdown,
} from "app/modules/employee/components";
import {
  useCreateEmployeeIdCode,
  useDeactiveEmployeeIdCode,
} from "app/modules/employee/hooks";
import { Employee, EmployeeIdCode } from "app/services/employee";
import { FC, useMemo, useRef, useState } from "react";
import { Col, Row } from "reactstrap";

export interface DetailWorkshopPanelProps {}

const DetailWorkshopPanel: FC<DetailWorkshopPanelProps> = () => {
  const { employeeText } = useAppTranslation();
  const { confirm } = useConfirm();
  const [isDeactiveModalOpen, toggleDeactiveModal] = useState(false);
  const rowSelected = useRef<EmployeeIdCode | null>(null);

  const handleToggleDeactiveModal = (data?: EmployeeIdCode) => {
    if (data) {
      rowSelected.current = data;
    }
    if (isDeactiveModalOpen) {
      rowSelected.current = null;
    }
    toggleDeactiveModal((prevState) => !prevState);
  };

  const { data, loading } = useDetailPageContext<Employee>();

  const employeeIdCodes = data?.employeeIdCodes || [];

  const isActived = employeeIdCodes.find((item) => item.isActive);

  const { mutateAsync: createEmployeeIdCode } = useCreateEmployeeIdCode({
    id: data?.id,
  });

  const { mutateAsync: deactiveEmployeeIdCode, isPending: isDeactivating } =
    useDeactiveEmployeeIdCode({
      id: data?.id,
      onSuccess: () => {
        handleToggleDeactiveModal();
      },
    });

  const handleActionClick = (
    action: EmployeeIdCodeAction,
    employeeIdCodeData?: EmployeeIdCode,
  ) => {
    switch (action) {
      case "create-employee-id-code":
        if (data?.id)
          confirm({
            title: employeeText.createEmployeeIdCode,
            description: employeeText.createEmployeeIdCodeContent,
            onConfirm: () => createEmployeeIdCode({ employeeId: data?.id }),
          });
        break;
      case "deactive-employee-id-code":
        if (employeeIdCodeData?.id) {
          handleToggleDeactiveModal(employeeIdCodeData);
        }
        break;
      default:
        break;
    }
  };

  const columns: ColumnDef<EmployeeIdCode>[] = useMemo(
    () => [
      {
        header: employeeText.field.employeeIdCode,
        accessorKey: "employeeIdCode",
        cell: ({ row }) => row.original.idCode || "-",
      },
      {
        header: employeeText.field.isActive,
        accessorKey: "isActive",
        cell: ({ row }) => (row.original?.isActive ? "Có" : "Không"),
      },
      {
        header: employeeText.field.note,
        accessorKey: "notes",
        cell: ({ row }) => row.original.notes || "-",
      },
      {
        header: "",
        accessorKey: "action",
        size: 40,
        cell: ({ row }) => (
          <EmployeeIdCodeActionDropdown
            onAction={handleActionClick}
            data={row.original}
          />
        ),
      },
    ],
    [employeeText],
  );

  const handleDeactive = ({ notes }: DeactiveEmployeeIdCodeFormData) => {
    if (rowSelected.current?.id) {
      deactiveEmployeeIdCode({
        id: rowSelected.current.id,
        notes,
      });
    }
  };

  return (
    <>
      <div className="p-3 py-0 h-100 d-flex flex-column">
        <Row>
          {!isActived ? (
            <Col xs={12} className="text-end my-2">
              <Button
                color="primary"
                size="sm"
                onClick={() => handleActionClick("create-employee-id-code")}
              >
                {employeeText.createEmployeeIdCode}
              </Button>
            </Col>
          ) : null}
        </Row>
        <DataTable<EmployeeIdCode>
          columns={columns}
          data={employeeIdCodes || []}
          loading={loading}
          total={employeeIdCodes?.length || 0}
          isHiddenPagination
        />
      </div>
      <DeactiveEmployeeIdCodeFormModal
        open={isDeactiveModalOpen}
        onToggle={handleToggleDeactiveModal}
        onSubmit={handleDeactive}
        submitting={isDeactivating}
      />
    </>
  );
};

export default DetailWorkshopPanel;
