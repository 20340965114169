import { RetrieveBomByIdArgs, useRetrieveBomByIdQuery } from "app/services/bom";

export const useRetrieveBomById = (
  args: RetrieveBomByIdArgs,
  opts?: Parameters<typeof useRetrieveBomByIdQuery>[1],
) => {
  const result = useRetrieveBomByIdQuery(args, opts as any);
  return {
    bom: result.data?.data.data,
    isLoadingBom: result.isLoading,
    ...result,
  };
};
