import { ColumnDef } from "@tanstack/react-table";
import { useConfirm } from "app/components/molecules/ConfirmationProvider";
import { DataTable } from "app/components/organisms/DataTable";
import { DEFAULT_LIMIT_PAGE, DEFAULT_PAGE } from "app/helpers";
import { formatDate } from "app/helpers/utils";
import { useAppTranslation } from "app/hooks";
import useAppSearch from "app/hooks/useAppSearch";
import {
  StartTicketFormData,
  StartTicketFormModal,
  TicketAction,
  TicketActionDropdown,
  TicketLogFormData,
  TicketLogFormModal,
} from "app/modules/ticket/components";
import {
  TicketProductionOrderItemFormData,
  TicketProductionOrderItemFormModal,
} from "app/modules/ticket/components/TicketProductionOrderItemFormModal";
import { mapTicketStatus } from "app/modules/ticket/helpers";
import {
  useDeleteTicket,
  useRetrieveTicketById,
  useRetrieveTickets,
  useStartTicket,
  useUpdateTicket,
} from "app/modules/ticket/hooks";
import { mapUpdateTicket } from "app/modules/ticket/mapper";
import { useCreateTicketLog } from "app/modules/ticketLog/hooks";
import { mapCreateTicketLog } from "app/modules/ticketLog/mapper";
import { Ticket, TicketStatus } from "app/services/ticket";
import { OptionSelect } from "app/services/types";
import { FC, useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Col, Row } from "reactstrap";
import { DetailProductionOrderTicketFilterChips } from "./DetailProductionOrderTicketFilterChips";
import { DetailProductionOrderTicketFilter } from "./DetailProductionOrderTicketFilter";

export interface DetailProductionOrderItemsProps {}

const DetailProductionOrderItems: FC<DetailProductionOrderItemsProps> = () => {
  const { ticketText, confirmText } = useAppTranslation();
  const [isModalOpen, toggleModal] = useState(false);
  const rowSelected = useRef<Ticket | null>(null);
  const navigate = useNavigate();
  const { confirm } = useConfirm();
  const { id } = useParams();

  const handleToggleModal = (data?: Ticket) => {
    if (data) {
      rowSelected.current = data;
    }
    if (isModalOpen) {
      rowSelected.current = null;
    }
    toggleModal((prevState) => !prevState);
  };

  const [isCheckInModalOpen, toggleCheckInModal] = useState(false);
  const [isStartModalOpen, toggleStartModal] = useState(false);

  const handleToggleCheckInModal = (data?: Ticket) => {
    if (data) {
      rowSelected.current = data;
    }
    if (isCheckInModalOpen) {
      rowSelected.current = null;
    }
    toggleCheckInModal((prevState) => !prevState);
  };

  const handleToggleStartModal = (data?: Ticket) => {
    if (data) {
      rowSelected.current = data;
    }
    if (isStartModalOpen) {
      rowSelected.current = null;
    }
    toggleStartModal((prevState) => !prevState);
  };

  const {
    page,
    limit,
    searchString,
    setPage,
    setLimit,
    setSearchString,
    customParams,
    setCustomParams,
  } = useAppSearch<{
    status: OptionSelect<TicketStatus>[];
    workSteps: OptionSelect[];
    assignees: OptionSelect[];
    workshops: OptionSelect[];
    startDate: Date | null;
    endDate: Date | null;
  }>({
    initialPage: DEFAULT_PAGE,
    initialLimit: DEFAULT_LIMIT_PAGE,
    initialSearchString: "",
    initialCustomParams: {
      status: [],
      workSteps: [],
      assignees: [],
      workshops: [],
      startDate: null,
      endDate: null,
    },
  });

  const { tickets, isLoadingTickets } = useRetrieveTickets({
    limit,
    page,
    searchString: searchString || undefined,
    status: customParams.status.map((item) => item.value),
    workStepIds: customParams.workSteps.map((item) => item.value),
    assigneeIds: customParams.assignees.map((item) => item.value),
    workshopIds: customParams.workshops.map((item) => item.value),
    startDate: customParams.startDate?.toISOString(),
    endDate: customParams.endDate?.toISOString(),
    productionOrderIds: id ? [id] : undefined,
  });

  const handleClearFilter = () => {
    setCustomParams({
      status: [],
      workSteps: [],
      assignees: [],
      workshops: [],
      startDate: null,
      endDate: null,
    });
  };

  const { mutateAsync: deleteTicket } = useDeleteTicket();

  const handleActionClick = (action: TicketAction, data?: Ticket) => {
    if (data) {
      rowSelected.current = data;
    }
    switch (action) {
      case "edit":
        if (data) {
          handleToggleModal(data);
        }
        break;
      case "start":
        handleToggleStartModal();
        break;
      case "checkIn":
        handleToggleCheckInModal();
        break;
      case "detail":
        if (data) {
          navigate(`/ticket/${data.id}`);
        }
        break;
      case "delete":
        if (data?.id) {
          confirm({
            title: confirmText.deleteTitle(ticketText.title),
            description: confirmText.deleteContent(ticketText.title),
            onConfirm: () => deleteTicket({ id: data.id }),
          });
        }
        break;
      default:
        break;
    }
  };

  const { mutateAsync: createLog, isPending: isLogCreating } =
    useCreateTicketLog({ onSuccess: handleToggleCheckInModal });

  const handleCreateLog = (data: TicketLogFormData) => {
    if (rowSelected.current)
      createLog(
        mapCreateTicketLog({ ...data, ticketId: rowSelected.current.id }),
      );
  };

  const { mutateAsync: start, isPending: isStarting } = useStartTicket({
    onSuccess: handleToggleStartModal,
  });

  const handleStart = ({ employee }: StartTicketFormData) => {
    if (rowSelected.current)
      start({
        employeeId: employee?.value as ID,
        id: rowSelected.current.id,
      });
  };

  const columns: ColumnDef<Ticket>[] = useMemo(
    () => [
      {
        header: ticketText.field.component,
        accessorKey: "product",
        size: 250,
        cell: ({ row }) => row.original?.workStep?.product?.nameVn || "-",
      },
      {
        header: ticketText.field.workStep,
        accessorKey: "workStep",
        cell: ({ row }) => row.original?.workStep?.step.name || "-",
      },
      {
        header: ticketText.field.workshop,
        accessorKey: "workshop",
        cell: ({ row }) => row.original?.workshop.name || "-",
      },
      {
        header: ticketText.field.assignee,
        accessorKey: "assignee",
        cell: ({ row }) => row.original?.assignee?.fullName || "-",
      },
      {
        header: ticketText.field.status,
        accessorKey: "status",
        cell: ({ row }) => mapTicketStatus(row.original?.status) || "-",
      },
      {
        header: ticketText.field.quantity,
        accessorKey: "quantity",
        cell: ({ row }) => row.original?.quantity || "-",
      },
      {
        header: ticketText.field.startDate,
        accessorKey: "startDate",
        cell: ({ row }) =>
          row.original.startedAt
            ? formatDate(new Date(row.original.startedAt))
            : "-",
      },
      {
        header: ticketText.field.endDate,
        accessorKey: "endDate",
        cell: ({ row }) =>
          row.original.completedAt
            ? formatDate(new Date(row.original.completedAt))
            : "-",
      },
      {
        header: "",
        accessorKey: "action",
        size: 40,
        cell: ({ row }) => (
          <TicketActionDropdown
            onAction={handleActionClick}
            data={row.original}
          />
        ),
      },
    ],
    [ticketText],
  );

  const { ticket } = useRetrieveTicketById(
    { id: rowSelected.current?.id as string },
    { enabled: !!rowSelected.current?.id && !!isCheckInModalOpen },
  );

  const { mutateAsync: updateTicket, isPending: isCreating } = useUpdateTicket({
    onSuccess: () => {
      handleToggleModal();
    },
  });

  const handleUpdateTicket = (data: TicketProductionOrderItemFormData) => {
    updateTicket(
      mapUpdateTicket({ id: rowSelected.current?.id as ID, ...data }),
    );
  };

  return (
    <>
      <div className="p-3 py-0 h-100 d-flex flex-column">
        <Row className="mt-2">
          <Col xs={6}>
            <DetailProductionOrderTicketFilter
              searchString={searchString}
              handleSearchChange={setSearchString}
              filterParams={{
                status: customParams.status,
                workSteps: customParams.workSteps,
                assignees: customParams.assignees,
                workshops: customParams.workshops,
                startDate: customParams.startDate,
                endDate: customParams.endDate,
              }}
              setCustomParams={setCustomParams}
            />
          </Col>
          <Col xs={12}>
            <DetailProductionOrderTicketFilterChips
              filterParams={{
                status: customParams.status,
                workSteps: customParams.workSteps,
                assignees: customParams.assignees,
                workshops: customParams.workshops,
                startDate: customParams.startDate,
                endDate: customParams.endDate,
              }}
              setCustomParams={setCustomParams}
              handleClearFilter={handleClearFilter}
            />
          </Col>
        </Row>
        <DataTable<Ticket>
          columns={columns}
          data={tickets?.result || []}
          loading={isLoadingTickets}
          page={page}
          limit={limit}
          setPage={setPage}
          setLimit={setLimit}
          total={tickets?.total || 0}
          onRowClick={(row) => handleActionClick("detail", row)}
        />
      </div>
      <TicketProductionOrderItemFormModal
        open={isModalOpen}
        onToggle={handleToggleModal}
        onSubmit={handleUpdateTicket}
        submitting={isCreating}
        data={rowSelected.current || null}
        edit
      />
      <TicketLogFormModal
        open={isCheckInModalOpen && !!ticket}
        onToggle={handleToggleCheckInModal}
        onSubmit={handleCreateLog}
        submitting={isLogCreating}
        defaultValues={{
          remainingQuantity: ticket?.remainingQuantity,
          defaultRemaningQuantity: ticket?.remainingQuantity,
          totalQuantity: ticket?.quantity,
          employee: ticket?.assignee
            ? {
                label: ticket.assignee.fullName,
                value: ticket.assignee.id,
              }
            : null,
          quantity: null,
          note: "",
        }}
      />
      <StartTicketFormModal
        open={isStartModalOpen}
        onToggle={handleToggleStartModal}
        onSubmit={handleStart}
        submitting={isStarting}
      />
    </>
  );
};

export default DetailProductionOrderItems;
