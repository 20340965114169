import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { httpClient } from "app/datasources";
import { AxiosError, AxiosResponse } from "axios";
import { Component } from "./types";
import { Response } from "../types";

export const RETRIEVE_COMPONENT_BY_ID_URL = (id: ID) =>
  `/api/admin/components/${id}`;

export type RetrieveComponentByIdResponse = Response<Component>;

export type RetrieveComponentByIdArgs = {
  id: ID;
};

export const retrieveComponentById = ({ id }: RetrieveComponentByIdArgs) => {
  return httpClient.get<RetrieveComponentByIdResponse>(
    RETRIEVE_COMPONENT_BY_ID_URL(id),
  );
};

export const useRetrieveComponentByIdQuery = (
  retrieveComponentsArgs: RetrieveComponentByIdArgs,
  opts?: Omit<
    UseQueryOptions<
      AxiosResponse<RetrieveComponentByIdResponse>,
      AxiosError<any>
    >,
    "queryKey" | "queryFn"
  >,
) =>
  useQuery<AxiosResponse<RetrieveComponentByIdResponse>, AxiosError<any>>({
    queryKey: [RETRIEVE_COMPONENT_BY_ID_URL(retrieveComponentsArgs.id)],
    queryFn: () => retrieveComponentById(retrieveComponentsArgs),
    ...opts,
  });
