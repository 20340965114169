import { useDetailPageContext } from "app/components/templates/DetailPage/DetailPageContext";
import { useAppTranslation } from "app/hooks";
import { Material } from "app/services/material";
import { FC } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "reactstrap";

export interface DetailMaterialHeaderProps {}

const DetailMaterialHeader: FC<DetailMaterialHeaderProps> = () => {
  const { buttonText } = useAppTranslation();
  const { id } = useParams();
  const navigate = useNavigate();

  const { data: material } = useDetailPageContext<Material>();

  const avatar = false;

  return (
    <div className="d-flex gap-4 flex-wrap">
      <div className="avatar-lg d-flex justify-content-center align-items-center">
        {avatar ? (
          <img
            src={avatar}
            alt="user-img"
            className="img-thumbnail rounded-4 w-100 h-100"
          />
        ) : (
          <i className="bx bx-package text-body"></i>
        )}
      </div>

      <div className="flex-grow-1 p-2">
        <h3 className="mb-1">{material?.name}</h3>
        <p className="text-muted">{material?.code}</p>
      </div>
      <div className="text-end">
        <div className="d-flex gap-2 justify-content-end">
          <Button
            color="primary"
            onClick={() => navigate(`/material/${id}/update`)}
          >
            {buttonText.edit}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default DetailMaterialHeader;
