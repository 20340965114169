import { useQueryClient } from "@tanstack/react-query";
import { useAppTranslation } from "app/hooks";
import { OptionValue } from "app/models";
import {
  Material,
  RETRIEVE_MATERIALS_URL,
  RetrieveMaterialsArgs,
  useRetrieveMaterialsInfiniteQuery,
} from "app/services/material";
import { OptionSelect } from "app/services/types";
import React, { useMemo, useState } from "react";
import Select, { Props } from "react-select";

type RetrieveKeys = keyof RetrieveMaterialsArgs;

export interface MaterialSelectProps
  extends Omit<Props<OptionSelect<ID>>, "value" | "onChange"> {
  value: OptionValue;
  onChange: (value: OptionValue) => void;
  isInvalid?: boolean;
  retrieveKeys?: {
    [Property in RetrieveKeys]?: any;
  };
}

export const MaterialSelect: React.FC<MaterialSelectProps> = ({
  value,
  onChange,
  retrieveKeys,
  isInvalid,
  onMenuClose,
  onMenuOpen,
  ...props
}) => {
  const { placeHolderText, noDataTableText } = useAppTranslation();
  const [open, setOpen] = useState(false);
  const queryClient = useQueryClient();

  const { data, fetchNextPage, isLoading } = useRetrieveMaterialsInfiniteQuery(
    {
      page: 1,
      limit: 10,
      ...retrieveKeys,
    },
    { enabled: !!open },
  );

  const options = useMemo(() => {
    let result: Material[] = [];
    data?.pages.forEach((page: any) => {
      result = result.concat(page.data.data.result);
    });
    return result;
  }, [data, retrieveKeys]);

  return (
    <>
      <Select
        {...props}
        className={`${props.className} ${isInvalid ? "is-invalid" : ""}`}
        noOptionsMessage={() => noDataTableText}
        menuIsOpen={open}
        onMenuOpen={() => {
          setOpen(true);
          onMenuOpen?.();
        }}
        onMenuClose={() => {
          queryClient.removeQueries({
            queryKey: [RETRIEVE_MATERIALS_URL, "INFINITE_QUERY"],
          });
          setOpen(false);
          onMenuClose?.();
        }}
        value={value}
        isLoading={isLoading}
        onChange={onChange}
        options={
          options.map((item) => ({
            value: item.id,
            label: item.name,
          })) || []
        }
        getOptionLabel={(option) => option.label}
        getOptionValue={(option) => option.value}
        isClearable
        placeholder={placeHolderText.select}
        onMenuScrollToBottom={fetchNextPage}
        styles={{
          control: (provided) => ({
            ...provided,
            boxShadow: "none",
            borderColor: isInvalid
              ? "var(--vz-form-invalid-color)"
              : provided.borderColor,
            ":hover": {
              borderColor: isInvalid
                ? "var(--vz-form-invalid-color)"
                : provided.borderColor,
            },
          }),
        }}
      />
    </>
  );
};
