import { ColumnDef } from "@tanstack/react-table";
import { DebouncedInput } from "app/components/molecules/DebounceInput";
import { DataTable } from "app/components/organisms/DataTable";
import { intl } from "app/helpers";
import { useAppTranslation } from "app/hooks";
import useAppSearch from "app/hooks/useAppSearch";
import { useRetrieveProductOrderById } from "app/modules/product/hooks";
import { OrderItem } from "app/services/order";
import { FC, useMemo } from "react";
import { useParams } from "react-router-dom";
import { Col, Row } from "reactstrap";

export interface DetailProductOrderItemsProps {}

const DetailProductOrderItems: FC<DetailProductOrderItemsProps> = () => {
  const { id } = useParams();
  const { orderText } = useAppTranslation();

  const { page, limit, searchString, setPage, setLimit, setSearchString } =
    useAppSearch({
      initialPage: 1,
      initialLimit: 20,
      initialSearchString: "",
    });

  const { productOrders, isLoadingProductOrder } = useRetrieveProductOrderById(
    { id: id as string, limit, page, searchString: searchString || undefined },
    { enabled: !!id },
  );

  const columns: ColumnDef<OrderItem>[] = useMemo(
    () => [
      {
        header: orderText.field.orderCode,
        accessorKey: "orderCode",
        cell: ({ row }) => row.original.order?.orderCode || "-",
      },
      {
        header: orderText.field.customer,
        accessorKey: "product",
        cell: ({ row }) => row.original.order?.customer.customerName || "-",
      },
      {
        header: orderText.field.quantity,
        accessorKey: "quantity",
        cell: ({ row }) => row.original.quantity || "-",
      },
      {
        header: orderText.field.expectedDeliveryDate,
        accessorKey: "deliveryDate",
        cell: ({ row }) =>
          row.original.deliveryDate
            ? intl.formatDate(row.original.deliveryDate)
            : "-",
      },
    ],
    [orderText],
  );

  return (
    <div className="p-3 py-0 h-100 d-flex flex-column">
      <Row>
        <Col xs={6} className="search-box me-2 my-2 d-inline-block">
          <DebouncedInput
            name="list-product-search"
            value={searchString || ""}
            onChange={(value) => {
              setSearchString(String(value));
            }}
          />
          <i className="bx bx-search-alt search-icon"></i>
        </Col>
      </Row>
      <DataTable<OrderItem>
        columns={columns}
        data={productOrders?.result || []}
        loading={isLoadingProductOrder}
        total={productOrders?.total || 0}
        page={page}
        limit={limit}
        setPage={setPage}
        setLimit={setLimit}
      />
    </div>
  );
};

export default DetailProductOrderItems;
