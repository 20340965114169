import { ProductType } from "app/services/product";
import { OptionSelect } from "app/services/types";

export const productTypeMappers: Record<ProductType, string> = {
  [ProductType.Product]: "Sản Phẩm Chính",
  [ProductType.Cluster]: "Cụm",
  [ProductType.Detail]: "Chi Tiết",
};

export enum ProductTypeEnum {
  Product = "9",
  Cluster = "12",
  Detail = "13",
}

export const productTypeOptions: OptionSelect<ID>[] = [
  {
    label: productTypeMappers[ProductType.Product],
    value: "9",
  },
  {
    label: productTypeMappers[ProductType.Cluster],
    value: "12",
  },
  {
    label: productTypeMappers[ProductType.Detail],
    value: "13",
  },
];
