import { useQueryClient } from "@tanstack/react-query";
import { errorMessageMapper } from "app/helpers";
import useToastify from "app/hooks/useToastify";
import {
  RETRIEVE_PANEL_TICKET_BY_ID_URL,
  startPanelTicketErrorMessages,
  useStartPanelTicketMutation,
} from "app/services/ticket";

interface StartPanelTicketProps {
  id?: ID;
  onSuccess?: () => void;
  onError?: () => void;
}

export const useStartPanelTicket = ({
  id,
  onError: handleError,
  onSuccess: handleSuccess,
}: StartPanelTicketProps = {}) => {
  const toast = useToastify();
  const queryClient = useQueryClient();
  return useStartPanelTicketMutation({
    onSuccess: () => {
      toast.success();
      handleSuccess?.();
      if (id) {
        queryClient.invalidateQueries({
          queryKey: [RETRIEVE_PANEL_TICKET_BY_ID_URL(id)],
        });
      }
    },
    onError: (error) => {
      toast.error(
        errorMessageMapper(
          startPanelTicketErrorMessages,
          error.response?.data.message,
        ),
      );
      handleError?.();
    },
  });
};
