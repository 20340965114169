import { ColumnDef } from "@tanstack/react-table";
import { DataTable } from "app/components/organisms/DataTable";
import { useDetailPageContext } from "app/components/templates/DetailPage/DetailPageContext";
import { useAppTranslation } from "app/hooks";
import {
  ProductionOrderItemAction,
  ProductionOrderItemActionDropdown,
} from "app/modules/productionOrder/components/ProductionOrderItemActionDropdown";
import { productionOrderItemStatusMappers } from "app/modules/productionOrder/constants";
import {
  TicketProductionOrderItemFormData,
  TicketProductionOrderItemFormModal,
} from "app/modules/ticket/components/TicketProductionOrderItemFormModal";
import { useCreateTicket } from "app/modules/ticket/hooks";
import { mapCreateTicket } from "app/modules/ticket/mapper";
import {
  ProductionOrder,
  ProductionOrderItem,
} from "app/services/productionOrder";
import { FC, useMemo, useRef, useState } from "react";

export interface DetailProductionOrderItemsProps {}

const DetailProductionOrderItems: FC<DetailProductionOrderItemsProps> = () => {
  const { productionOrderText, productText } = useAppTranslation();
  const [isModalOpen, toggleModal] = useState(false);
  const rowSelected = useRef<ProductionOrderItem | null>(null);

  const handleToggleModal = (data?: ProductionOrderItem) => {
    if (data) {
      rowSelected.current = data;
    }
    if (isModalOpen) {
      rowSelected.current = null;
    }
    toggleModal((prevState) => !prevState);
  };

  const { data: productionOrder, loading: isLoadingProductionOrder } =
    useDetailPageContext<ProductionOrder>();

  const orderItems = productionOrder?.productionOrderItems;

  const handleAction = (
    action: ProductionOrderItemAction,
    data?: ProductionOrderItem,
  ) => {
    switch (action) {
      case "create-ticket":
        if (data) {
          handleToggleModal(data);
        }
        break;
      default:
        break;
    }
  };

  const columns: ColumnDef<ProductionOrderItem>[] = useMemo(
    () => [
      {
        header: productText.title,
        accessorKey: "orderProductName",
        size: 200,
        cell: ({ row }) =>
          row.original.planItem.orderItem.product.nameVn || "-",
      },
      {
        header: productText.field.code,
        accessorKey: "orderProductCode",
        cell: ({ row }) => row.original.planItem.orderItem.product.code || "-",
      },
      {
        header: productionOrderText.field.status,
        accessorKey: "productionOrderItemStatus",
        cell: ({ row }) =>
          productionOrderItemStatusMappers[row.original.status],
      },
      {
        header: `${productionOrderText.field.quantity}/${productionOrderText.field.totalQuantity}`,
        accessorKey: "quantity",
        cell: ({ row }) =>
          `${row.original.quantity}/${row.original.planItem.quantity}` || "-",
      },
      {
        header: "",
        accessorKey: "action",
        size: 40,
        cell: ({ row }) => (
          <ProductionOrderItemActionDropdown
            data={row.original}
            onAction={handleAction}
          />
        ),
      },
    ],
    [productionOrderText],
  );

  const { mutateAsync: createRole, isPending: isCreating } = useCreateTicket({
    onSuccess: () => {
      handleToggleModal();
    },
  });

  const handleCreateTicket = (data: TicketProductionOrderItemFormData) => {
    createRole(mapCreateTicket(data));
  };

  return (
    <>
      <div className="p-3 py-0 h-100 d-flex flex-column">
        <DataTable<ProductionOrderItem>
          columns={columns}
          data={orderItems || []}
          loading={isLoadingProductionOrder}
          total={orderItems?.length || 0}
          isHiddenPagination
        />
      </div>
      <TicketProductionOrderItemFormModal
        open={isModalOpen}
        onToggle={handleToggleModal}
        onSubmit={handleCreateTicket}
        submitting={isCreating}
        defaultValues={{
          note: "",
          productionOrderItem: rowSelected.current?.productionOrderId
            ? {
                label: rowSelected.current?.planItem.orderItem.product.nameVn,
                value: rowSelected.current?.id,
                product: rowSelected.current.planItem.orderItem.product.id,
              }
            : null,
          workStep: null,
          quantity: null,
          workshop: null,
          component: null,
        }}
      />
    </>
  );
};

export default DetailProductionOrderItems;
