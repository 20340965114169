import classNames from "classnames";
import { FC, ReactNode } from "react";
import styled from "styled-components";

export interface ChipProps {
  color?:
    | "primary"
    | "secondary"
    | "success"
    | "danger"
    | "warning"
    | "info"
    | string;
  size?: "sm" | "md" | "lg";
  label: ReactNode;
}

const getContrastColor = (hexColor: string): string => {
  // Ensure the hex color is valid
  const hex = hexColor.replace("#", "");
  if (hex.length !== 6) {
    throw new Error("Invalid hex color");
  }

  // Convert hex to RGB
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  // Calculate luminance
  const luminance =
    0.2126 * (r / 255) + 0.7152 * (g / 255) + 0.0722 * (b / 255);

  // Return white for dark colors and black for light colors
  return luminance > 0.5 ? "#000000" : "#FFFFFF";
};

const StyledChip = styled.div<{ color?: string }>`
  background-color: ${(props) => props.color};
  color: ${(props) =>
    props.color ? getContrastColor(props.color) : "undefined"};
  border-color: ${(props) =>
    props.color?.toUpperCase() === "#FFFFFF" ? "#000000" : props.color};
`;

export const Chip: FC<ChipProps> = ({
  size = "md",
  label,
  color = "primary",
}) => {
  const isPalletteColor = [
    "primary",
    "secondary",
    "success",
    "danger",
    "warning",
    "info",
  ].includes(color);

  return (
    <StyledChip
      color={!isPalletteColor ? color : undefined}
      className={classNames(
        "chip",
        `chip-${size}`,
        isPalletteColor ? `chip-${color}` : "",
      )}
    >
      {label}
    </StyledChip>
  );
};
