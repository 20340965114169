import { useAppTranslation } from "app/hooks";
import { ComponentWithChildren } from "app/services/product";
import { FC } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { mapDefaultProductMaterial } from "../../mapper";
import {
  ProductMaterialForm,
  ProductMaterialFormData,
} from "../ProductMaterialFormModal/ProductMaterialForm";

export interface CreateProductMaterialFormModalProps {
  data?: ComponentWithChildren;
  open: boolean;
  onToggle: () => void;
  onSubmit: (data: ProductMaterialFormData) => void;
  submitting?: boolean;
  edit?: boolean;
  isAddParent?: boolean;
}

export const CreateProductMaterialFormModal: FC<
  CreateProductMaterialFormModalProps
> = ({
  data,
  onToggle,
  open = false,
  onSubmit,
  submitting,
  isAddParent,
  edit,
}) => {
  const { productText } = useAppTranslation();
  const title = productText.addMaterialTitle;

  return (
    <Modal isOpen={open} toggle={onToggle}>
      <ModalHeader className="modal-title" toggle={onToggle}>
        {title}
      </ModalHeader>
      <ModalBody>
        <ProductMaterialForm
          onSubmit={onSubmit}
          onToggle={onToggle}
          defaultValues={mapDefaultProductMaterial({})}
          submitting={submitting}
          parent={data || null}
          isAddParent={isAddParent}
          edit={edit}
        />
      </ModalBody>
    </Modal>
  );
};
