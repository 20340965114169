import { ColumnDef } from "@tanstack/react-table";
import { NestedDataTable } from "app/components/organisms/NestedDataTable";
import { useAppTranslation } from "app/hooks";
import { sortProductStepOrder } from "app/modules/product/helpers";
import { ProductStep } from "app/services/product";
import { useMemo } from "react";
import { Card, CardBody } from "reactstrap";

export const StepRow = ({ data }: { data: ProductStep[] }) => {
  const { productText } = useAppTranslation();

  const columns: ColumnDef<ProductStep>[] = useMemo(
    () => [
      {
        header: productText.field.order,
        accessorKey: "order",
        size: 60,
        cell: ({ row }) => row.original.order || "-",
      },
      {
        header: productText.field.workStepName,
        accessorKey: "name",
        size: 200,
        cell: ({ row }) => row.original.step?.name,
      },
      {
        header: productText.field.estimateTime,
        accessorKey: "estimateTime",
        size: 200,
        cell: ({ row }) => row.original.estimateTime || "-",
      },
      {
        header: productText.field.quantity,
        accessorKey: "quantity",
        size: 200,
        cell: ({ row }) => row.original.quantity || "-",
      },
    ],
    [productText],
  );

  return (
    <Card>
      <CardBody className="card-gray">
        <div className="d-flex flex-column gap-2">
          <h5 className="fw-semibold">{productText.field.workStepName}</h5>
          <NestedDataTable<ProductStep>
            className="table-flat overflow-visible"
            columns={columns}
            data={sortProductStepOrder(data || [])}
            loading={false}
            total={data.length || 0}
            isHiddenPagination
          />
        </div>
      </CardBody>
    </Card>
  );
};
