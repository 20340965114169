import { Button } from "app/components/molecules/Button";
import { useDetailPageContext } from "app/components/templates/DetailPage/DetailPageContext";
import { useAppTranslation } from "app/hooks";
import { ProductionPlanAction } from "app/modules/productionPlan/components";
import {
  ProductionPlan,
  ProductionPlanStatus,
} from "app/services/productionPlan";
import { FC } from "react";
import { useNavigate, useParams } from "react-router-dom";

export interface DetailProductionPlanHeaderProps {
  onAction: (action: ProductionPlanAction) => void;
  isProcessing?: boolean;
}

const DetailProductionPlanHeader: FC<DetailProductionPlanHeaderProps> = ({
  onAction,
  isProcessing,
}) => {
  const { buttonText } = useAppTranslation();
  const { id } = useParams();
  const navigate = useNavigate();

  const { data: productionPlan } = useDetailPageContext<ProductionPlan>();

  const avatar = false;
  const isWaitForApprove =
    productionPlan?.status === ProductionPlanStatus.WaitForApprove;

  const handleAction = (action: ProductionPlanAction) => () => {
    onAction(action);
  };

  return (
    <div className="d-flex p-2 gap-2">
      <div className="avatar-lg d-flex justify-content-center align-items-center">
        {avatar ? (
          <img
            src={avatar}
            alt="user-img"
            className="img-thumbnail rounded-4 w-100 h-100"
          />
        ) : (
          <i className="ri-list-settings-line text-body"></i>
        )}
      </div>

      <div className="flex-grow-1 p-2">
        <h3 className="mb-1">{productionPlan?.name}</h3>
      </div>
      <div className="text-end">
        <div className="d-flex gap-2 justify-content-end">
          <Button
            className="d-inline"
            color="primary"
            onClick={() => navigate(`/production-plan/${id}/update`)}
          >
            {buttonText.edit}
          </Button>
          {isWaitForApprove && (
            <Button
              color="success"
              loading={isProcessing}
              onClick={handleAction("approve")}
            >
              {buttonText.approve}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default DetailProductionPlanHeader;
