import { Product } from "../product";
import { IBaseModel } from "../types";
export interface Bom extends IBaseModel {
  name: string;
  product: Product;
  version: string;
  status: BomStatus;
  description: string | null;
}

export enum BomStatus {
  WaitForApprove = "WAIT_FOR_APPROVAL",
  Active = "ACTIVE",
  InActive = "INACTIVE",
}
