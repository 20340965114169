import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "app/components/molecules/Button";
import { FormHelper } from "app/components/molecules/FormHelper";
import { FileUpload } from "app/components/organisms/FileUpload";
import { yupSchema } from "app/helpers/schema";
import { useAppTranslation, useGetEnableSubmitButton } from "app/hooks";
import { FC } from "react";
import { Controller, useForm } from "react-hook-form";
import { Alert, Col, Modal, ModalBody, ModalHeader } from "reactstrap";
import * as Yup from "yup";

export interface UploadEmployeeAvatarFormModalProps {
  open: boolean;
  onToggle: () => void;
  onSubmit: (data: UploadEmployeeAvatarFormData) => void;
  submitting?: boolean;
}

export interface UploadEmployeeAvatarFormData {
  file: File[];
}

export const UploadEmployeeAvatarFormModal: FC<
  UploadEmployeeAvatarFormModalProps
> = ({ onToggle, open = false, onSubmit, submitting }) => {
  const { buttonText, employeeText } = useAppTranslation();

  const {
    control,
    handleSubmit,
    reset,
    formState: { isDirty, isValid },
  } = useForm<UploadEmployeeAvatarFormData>({
    mode: "onChange",
    resolver: yupResolver(Yup.object({ file: yupSchema.fileRequired(1, "") })),
  });

  const submit = (data: UploadEmployeeAvatarFormData) => {
    onSubmit(data);
  };

  const handleClose = () => {
    onToggle();
    reset();
  };

  const FORM_ID = "upload-file-form";

  const isEnableSubmit = useGetEnableSubmitButton({
    isDirty,
    isValid,
    submitting,
  });

  return (
    <Modal isOpen={open} toggle={onToggle}>
      <ModalHeader className="modal-title" toggle={onToggle}>
        {employeeText.uploadAvatarTitle}
      </ModalHeader>
      <ModalBody>
        <div className="my-2">{employeeText.uploadAvatarContent}</div>
        <Alert color="info" fade={false}>
          {employeeText.uploadAvatarNote}
        </Alert>
        <form id={FORM_ID} onSubmit={handleSubmit(submit)}>
          <Controller
            name="file"
            control={control}
            render={({ field, fieldState }) => (
              <>
                <FileUpload
                  multiple
                  value={field.value || []}
                  onChange={field.onChange}
                />
                <FormHelper message={fieldState.error?.message} />
              </>
            )}
          />
          <Col xs={12}>
            <div className="hstack gap-2 justify-content-end mt-2">
              <Button color="light" onClick={handleClose}>
                {buttonText.cancel}
              </Button>
              <Button
                disabled={!isEnableSubmit}
                type="submit"
                color="primary"
                loading={submitting}
              >
                {buttonText.save}
              </Button>
            </div>
          </Col>
        </form>
      </ModalBody>
    </Modal>
  );
};
