import { ColumnDef } from "@tanstack/react-table";
import { useConfirm } from "app/components/molecules/ConfirmationProvider";
import { DataTable } from "app/components/organisms/DataTable";
import { formatDate } from "app/helpers/utils";
import { useAppTranslation } from "app/hooks";
import useAppSearch from "app/hooks/useAppSearch";
import {
  ProductionOrderAction,
  ProductionOrderActionDropdown,
} from "app/modules/productionOrder/components";
import { productionOrderStatusMappers } from "app/modules/productionOrder/constants";
import {
  useDeleteProductionOrder,
  useRetrieveProductionOrders,
} from "app/modules/productionOrder/hooks";
import {
  ProductionOrder,
  ProductionOrderStatus,
} from "app/services/productionOrder";
import { OptionSelect } from "app/services/types";
import "gridjs/dist/theme/mermaid.css";
import { FC, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Col, Row } from "reactstrap";
import { DetailProductionPlanOrderFilter } from "./DetailProductionPlanOrderFilter";
import { DetailProductionPlanOrderFilterChips } from "./DetailProductionPlanOrderFilterChips";

interface DetailProductionPlanOrderProps {}

const DetailProductionPlanOrder: FC<DetailProductionPlanOrderProps> = () => {
  const { confirm } = useConfirm();
  const navigate = useNavigate();
  const { id } = useParams();
  const { productionOrderText, confirmText } = useAppTranslation();

  const {
    page,
    limit,
    searchString,
    setPage,
    setLimit,
    setSearchString,
    customParams,
    setCustomParams,
  } = useAppSearch<{
    status: OptionSelect<ProductionOrderStatus>[];
    startDate: Date | null;
    endDate: Date | null;
  }>({
    initialPage: 1,
    initialLimit: 20,
    initialSearchString: "",
    initialCustomParams: {
      status: [],
      startDate: null,
      endDate: null,
    },
  });

  const handleClearFilter = () => {
    setCustomParams({
      status: [],
      startDate: null,
      endDate: null,
    });
  };

  const { productionOrders, isLoadingProductionOrders } =
    useRetrieveProductionOrders({
      limit,
      page,
      searchString: searchString || undefined,
      status: customParams.status.map((item) => item.value),
      startDate: customParams.startDate?.toISOString(),
      endDate: customParams.endDate?.toISOString(),
      productionPlanIds: id ? [id as ID] : undefined,
    });

  const { mutateAsync: deleteProductionOrder } = useDeleteProductionOrder();

  const handleActionClick = (
    action: ProductionOrderAction,
    data?: ProductionOrder,
  ) => {
    switch (action) {
      case "edit":
        if (data) {
          navigate(`/production-order/${data.id}/update`);
        }
        break;
      case "detail":
        if (data) {
          navigate(`/production-order/${data.id}`);
        }
        break;
      case "delete":
        if (data?.id) {
          confirm({
            title: confirmText.deleteTitle(productionOrderText.title),
            description: confirmText.deleteContent(productionOrderText.title),
            onConfirm: () => deleteProductionOrder({ id: data.id }),
          });
        }
        break;
      default:
        break;
    }
  };

  const columns: ColumnDef<ProductionOrder>[] = useMemo(
    () => [
      {
        header: productionOrderText.field.name,
        accessorKey: "name",
      },
      {
        header: productionOrderText.field.status,
        accessorKey: "status",
        cell: ({ row }) => productionOrderStatusMappers[row.original.status],
      },
      {
        header: productionOrderText.field.startDate,
        accessorKey: "startDate",
        cell: ({ row }) =>
          row.original.startDate
            ? formatDate(new Date(row.original.startDate))
            : "-",
      },
      {
        header: productionOrderText.field.endDate,
        accessorKey: "endDate",
        cell: ({ row }) =>
          row.original.endDate
            ? formatDate(new Date(row.original.endDate))
            : "-",
      },
      {
        header: "",
        accessorKey: "action",
        size: 40,
        cell: ({ row }) => (
          <ProductionOrderActionDropdown
            onAction={handleActionClick}
            data={row.original}
          />
        ),
      },
    ],
    [productionOrderText],
  );

  return (
    <>
      <div className="p-3 py-0 h-100 d-flex flex-column">
        <Row className="mt-2">
          <Col xs={6}>
            <DetailProductionPlanOrderFilter
              searchString={searchString}
              handleSearchChange={setSearchString}
              filterParams={{
                status: customParams.status,
                startDate: customParams.startDate,
                endDate: customParams.endDate,
              }}
              setCustomParams={setCustomParams}
            />
          </Col>
          <Col xs={12}>
            <DetailProductionPlanOrderFilterChips
              filterParams={{
                status: customParams.status,
                startDate: customParams.startDate,
                endDate: customParams.endDate,
              }}
              setCustomParams={setCustomParams}
              handleClearFilter={handleClearFilter}
            />
          </Col>
        </Row>
        <DataTable<ProductionOrder>
          columns={columns}
          data={productionOrders?.result || []}
          loading={isLoadingProductionOrders}
          page={page}
          limit={limit}
          setPage={setPage}
          setLimit={setLimit}
          total={productionOrders?.total || 0}
          onRowClick={(row) => handleActionClick("detail", row)}
        />
      </div>
    </>
  );
};

export default DetailProductionPlanOrder;
