import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { httpClient } from "app/datasources";
import { Response } from "app/services/types";
import { AxiosError, AxiosResponse } from "axios";
import { Material } from "./types";

export const CREATE_MATERIAL_URL = "/api/admin/materials";

export enum CreateMaterialError {}

export const createMaterialErrorMessages = {};

export interface CreateMaterialArgs {
  code: string;
  name: string;
  purchasePrice: number | null;
  description: string | null;
  unitId: ID | null;
  vendorId: ID | null;
}

export type CreateMaterialResponse = Response<Material>;

export const createMaterial = (args: CreateMaterialArgs) => {
  return httpClient.post<CreateMaterialResponse>(CREATE_MATERIAL_URL, args);
};

export const useCreateMaterialMutation = (
  opts?: Omit<
    UseMutationOptions<
      AxiosResponse<CreateMaterialResponse>,
      AxiosError<any>,
      CreateMaterialArgs
    >,
    "mutationFn"
  >,
) =>
  useMutation<
    AxiosResponse<CreateMaterialResponse>,
    AxiosError<any>,
    CreateMaterialArgs
  >({
    mutationFn: createMaterial,
    mutationKey: [CREATE_MATERIAL_URL],
    ...opts,
  });
