import { useAppTranslation, useRouteMatch } from "app/hooks";
import classnames from "classnames";
import { FC } from "react";
import { Link, useParams } from "react-router-dom";
import { Nav, NavItem } from "reactstrap";

export interface DetailEmployeeTabsProps {}

export enum DetailEmployeeTabEnum {
  General = "/employee/:id/general",
  IdCode = "/employee/:id/id-code",
}

export const DetailEmployeeTabs: FC<DetailEmployeeTabsProps> = () => {
  const { id } = useParams();
  const routeMatch = useRouteMatch([
    "/employee/:id/general",
    "/employee/:id/id-code",
  ]);
  const { employeeText } = useAppTranslation();

  const currentTab = routeMatch?.pattern?.path ?? DetailEmployeeTabEnum.General;

  return (
    <div className="d-flex px-3">
      <Nav
        pills
        className="animation-nav profile-nav gap-2 gap-lg-3 flex-grow-1"
        role="tablist"
      >
        <NavItem>
          <Link
            to={`/employee/${id}/general`}
            className={classnames(
              {
                active: currentTab === DetailEmployeeTabEnum.General,
              },
              "fs-14 nav-link",
            )}
            replace
          >
            <i className="ri-airplay-fill d-inline-block d-md-none"></i>{" "}
            <span className="d-none d-md-inline-block">
              {employeeText.detail.tab.general}
            </span>
          </Link>
        </NavItem>
        <NavItem>
          <Link
            to={`/employee/${id}/id-code`}
            className={classnames(
              {
                active: currentTab === DetailEmployeeTabEnum.IdCode,
              },
              "fs-14 nav-link",
            )}
            replace
          >
            <i className="ri-airplay-fill d-inline-block d-md-none"></i>{" "}
            <span className="d-none d-md-inline-block">
              {employeeText.detail.tab.idCode}
            </span>
          </Link>
        </NavItem>
      </Nav>
    </div>
  );
};
