import { ColumnDef } from "@tanstack/react-table";
import { DataTable } from "app/components/organisms/DataTable";
import { formatDate } from "app/helpers/utils";
import { useAppTranslation } from "app/hooks";
import { TicketLog } from "app/services/ticketLog";
import { FC, useMemo } from "react";

export interface DetailTicketPanelLogsProps {
  logs: TicketLog[];
}

export const DetailTicketPanelLogs: FC<DetailTicketPanelLogsProps> = ({
  logs,
}) => {
  const { ticketText } = useAppTranslation();
  const columns: ColumnDef<TicketLog>[] = useMemo(
    () => [
      {
        header: ticketText.field.checkInAt,
        accessorKey: "time",
        cell: ({ row }) =>
          row.original.createdAt ? formatDate(row.original.checkinAt) : "-",
      },
      {
        header: ticketText.field.quantity,
        accessorKey: "quantity",
        cell: ({ row }) => row.original.quantity || "-",
      },
    ],
    [ticketText],
  );
  return (
    <>
      <DataTable<TicketLog>
        columns={columns}
        data={logs}
        loading={false}
        total={0}
        isHiddenPagination
      />
    </>
  );
};
