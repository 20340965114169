import { DateInput } from "app/components/molecules/DateInput";
import { DebouncedInput } from "app/components/molecules/DebounceInput";
import { FilterOffCanvas } from "app/components/molecules/FilterOffCanvas";
import { FormLabel } from "app/components/molecules/FormLabel";
import { ReactSelectCustom } from "app/components/molecules/ReactSelectCustom";
import { formatDate } from "app/helpers/utils";
import { useAppTranslation } from "app/hooks";
import { useRetrieveEmployees } from "app/modules/employee/hooks";
import { MasterDataInfiniteSelect } from "app/modules/masterData/components";
import { EmployeeGender } from "app/services/employee";
import { MasterDataType } from "app/services/masterData";
import { OptionSelect } from "app/services/types";
import { FC, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { FormGroup } from "reactstrap";

export interface EmployeeFilterParams {
  jobTitles: OptionSelect[];
  departments: OptionSelect[];
  skills: OptionSelect[];
  gender: OptionSelect<EmployeeGender> | null;
  dateOfJoining: Date | null;
  dateOfLeaving: Date | null;
  rangeDate?: Date | null;
}

export interface ListEmployeeFilterProps {
  searchString: string;
  filterParams: EmployeeFilterParams;
  handleSearchChange: (value: string) => void;
  setCustomParams: (params: EmployeeFilterParams) => void;
}

export const ListEmployeeFilter: FC<ListEmployeeFilterProps> = ({
  handleSearchChange,
  filterParams,
  searchString,
  setCustomParams,
}) => {
  const { employeeText } = useAppTranslation();
  const { control, reset, watch, handleSubmit } = useForm<EmployeeFilterParams>(
    {
      defaultValues: filterParams,
    },
  );

  const watchDepartments = watch("departments");
  const watchJobTitles = watch("jobTitles");
  const watchSkills = watch("skills");
  const watchGender = watch("gender");
  const watchDateOfJoining = watch("dateOfJoining");
  const watchDateOfLeaving = watch("dateOfLeaving");

  const { employees } = useRetrieveEmployees({
    limit: 20,
    page: 1,
    searchString: searchString || undefined,
    departmentIds: watchDepartments?.map((item) => item.value),
    jobTitleIds: watchJobTitles?.map((item) => item.value),
    skillIds: watchSkills?.map((item) => item.value),
    gender: watchGender?.value || null,
    dateOfJoining: watchDateOfJoining
      ? watchDateOfJoining?.toISOString()
      : null,
    dateOfLeaving: watchDateOfLeaving
      ? watchDateOfLeaving?.toISOString()
      : null,
  });

  const total = employees?.total || 0;

  const handleFilter = () => {
    setCustomParams({
      departments: watchDepartments,
      jobTitles: watchJobTitles,
      skills: watchSkills,
      gender: watchGender,
      dateOfJoining: watchDateOfJoining,
      dateOfLeaving: watchDateOfLeaving,
    });
    handleToggle();
  };

  const handleClearFilter = () => {
    reset({
      departments: [],
      jobTitles: [],
      skills: [],
      gender: null,
      dateOfJoining: null,
      dateOfLeaving: null,
    });
  };

  useEffect(() => {
    reset(filterParams);
  }, [filterParams]);

  const formID = "employee-filter-form";

  const [show, setShow] = useState(false);
  const handleToggle = () => setShow(!show);
  return (
    <div className="d-flex align-items-center mb-2">
      <div className="search-box me-2 d-inline-block">
        <DebouncedInput
          name="list-employee-search"
          value={searchString || ""}
          onChange={(value) => {
            handleSearchChange(String(value));
          }}
        />
        <i className="bx bx-search-alt search-icon"></i>
      </div>
      <form noValidate id={formID} onSubmit={handleSubmit(handleFilter)}>
        <FilterOffCanvas
          formId={formID}
          handleClearFilter={handleClearFilter}
          onToggle={handleToggle}
          show={show}
          total={total}
        >
          <div className="d-flex flex-column flex-grow-1">
            <FormGroup>
              <FormLabel className="mb-2">
                {employeeText.field.department}
              </FormLabel>
              <Controller
                name="departments"
                control={control}
                render={({ field, fieldState }) => (
                  <MasterDataInfiniteSelect
                    isMulti
                    name={field.name}
                    value={field.value}
                    retrieveKeys={{ type: MasterDataType.Department }}
                    inputId="departments"
                    onChange={(value) => {
                      field.onChange(value);
                    }}
                    isInvalid={fieldState.invalid}
                  />
                )}
              />
            </FormGroup>
            <FormGroup>
              <FormLabel className="mb-2">
                {employeeText.field.jobTitle}
              </FormLabel>
              <Controller
                name="jobTitles"
                control={control}
                render={({ field, fieldState }) => (
                  <MasterDataInfiniteSelect
                    isMulti
                    name={field.name}
                    value={field.value}
                    retrieveKeys={{ type: MasterDataType.JobTitle }}
                    inputId="jobTitles"
                    onChange={(value) => {
                      field.onChange(value);
                    }}
                    isInvalid={fieldState.invalid}
                  />
                )}
              />
            </FormGroup>
            <FormGroup>
              <FormLabel className="mb-2">{employeeText.field.skill}</FormLabel>
              <Controller
                name="skills"
                control={control}
                render={({ field, fieldState }) => (
                  <MasterDataInfiniteSelect
                    isMulti
                    name={field.name}
                    value={field.value}
                    retrieveKeys={{ type: MasterDataType.Skill }}
                    inputId="skills"
                    onChange={(value) => {
                      field.onChange(value);
                    }}
                    isInvalid={fieldState.invalid}
                  />
                )}
              />
            </FormGroup>
            <FormGroup>
              <FormLabel htmlFor="gender" className="form-label">
                {employeeText.field.gender}
              </FormLabel>
              <Controller
                name="gender"
                control={control}
                render={({ field, fieldState }) => (
                  <>
                    <ReactSelectCustom
                      value={field.value}
                      name={field.name}
                      inputId="gender"
                      onChange={field.onChange}
                      options={[
                        { label: "Nam", value: EmployeeGender.Male },
                        { label: "Nữ", value: EmployeeGender.Female },
                      ]}
                      placeholder="Chọn"
                      isInvalid={fieldState.invalid}
                    />
                  </>
                )}
              />
            </FormGroup>
            <FormGroup>
              <FormLabel className="mb-2">
                {employeeText.field.dateOfJoining}
              </FormLabel>
              <Controller
                name="dateOfJoining"
                control={control}
                render={({ field, fieldState }) => (
                  <DateInput
                    name={field.name}
                    id="dateOfJoining"
                    value={field.value || ""}
                    onChange={([date]) => {
                      field.onChange(date);
                    }}
                    options={{
                      maxDate: watchDateOfLeaving
                        ? formatDate(watchDateOfLeaving)
                        : undefined,
                    }}
                    isInvalid={fieldState.invalid}
                  />
                )}
              />
            </FormGroup>
            <FormGroup>
              <FormLabel className="mb-2">
                {employeeText.field.dateOfLeaving}
              </FormLabel>
              <Controller
                name="dateOfLeaving"
                control={control}
                render={({ field, fieldState }) => (
                  <DateInput
                    name={field.name}
                    id="dateOfLeaving"
                    value={field.value || ""}
                    onChange={([date]) => {
                      field.onChange(date);
                    }}
                    options={{
                      minDate: watchDateOfJoining
                        ? formatDate(watchDateOfJoining)
                        : undefined,
                    }}
                    isInvalid={fieldState.invalid}
                  />
                )}
              />
            </FormGroup>
          </div>
        </FilterOffCanvas>
      </form>
    </div>
  );
};
