import { useQueryClient } from "@tanstack/react-query";
import { errorMessageMapper } from "app/helpers";
import useToastify from "app/hooks/useToastify";
import {
  createComponentErrorMessages,
  CreateComponentResponse,
  RETRIEVE_COMPONENT_BY_ID_URL,
  useCreateComponentMutation,
} from "app/services/product";
import { AxiosResponse } from "axios";

interface UseCreateComponentProps {
  onSuccess?: (data?: AxiosResponse<CreateComponentResponse, any>) => void;
  onError?: () => void;
  id?: ID;
}

export const useCreateComponent = ({
  onError: handleError,
  onSuccess: handleSuccess,
  id,
}: UseCreateComponentProps = {}) => {
  const toast = useToastify();
  const queryClient = useQueryClient();
  return useCreateComponentMutation({
    onSuccess: (data) => {
      toast.success();
      if (id)
        queryClient.invalidateQueries({
          queryKey: [RETRIEVE_COMPONENT_BY_ID_URL(id)],
        });
      handleSuccess?.(data);
    },
    onError: (error) => {
      toast.error(
        errorMessageMapper(
          createComponentErrorMessages,
          error.response?.data.message,
        ),
      );
      handleError?.();
    },
  });
};
