import {
  keepPreviousData,
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  useQuery,
  UseQueryOptions,
} from "@tanstack/react-query";
import { httpClient } from "app/datasources";
import { AxiosError, AxiosResponse } from "axios";
import { Response } from "../types";
import { PagingData } from "./../types";
import { ProductionOrder, ProductionOrderStatus } from "./types";
import { DEFAULT_LIMIT_PAGE, DEFAULT_PAGE } from "app/helpers";

export const RETRIEVE_PRODUCTION_ORDERS_URL = "/api/admin/production-orders";

export interface RetrieveProductionOrdersArgs {
  page: number;
  limit: number;
  searchString?: string;
  startDate?: DateTimeString;
  endDate?: DateTimeString;
  status?: ProductionOrderStatus[];
  productionPlanIds?: ID[];
}

export type RetrieveProductionOrdersResponse = Response<
  PagingData<ProductionOrder[]>
>;

export const retrieveProductionOrders = (
  args: RetrieveProductionOrdersArgs,
) => {
  return httpClient.get<RetrieveProductionOrdersResponse>(
    RETRIEVE_PRODUCTION_ORDERS_URL,
    { params: args },
  );
};

export const useRetrieveProductionOrdersQuery = (
  retrieveProductionOrdersArgs: RetrieveProductionOrdersArgs,
  opts?: Omit<
    UseQueryOptions<
      AxiosResponse<RetrieveProductionOrdersResponse>,
      AxiosError<any>
    >,
    "queryKey" | "queryFn"
  >,
) =>
  useQuery<AxiosResponse<RetrieveProductionOrdersResponse>, AxiosError<any>>({
    queryKey: [RETRIEVE_PRODUCTION_ORDERS_URL, retrieveProductionOrdersArgs],
    queryFn: () => retrieveProductionOrders(retrieveProductionOrdersArgs),
    placeholderData: keepPreviousData,
    ...opts,
  });

export const useRetrieveProductionOrdersInfiniteQuery = (
  retrieveProductionOrdersArgs: RetrieveProductionOrdersArgs,
  opts?: Omit<
    UseInfiniteQueryOptions<
      AxiosResponse<RetrieveProductionOrdersResponse>,
      AxiosError<any>
    >,
    "initialPageParam" | "queryFn" | "queryKey" | "getNextPageParam"
  >,
): any => {
  return useInfiniteQuery<
    AxiosResponse<RetrieveProductionOrdersResponse>,
    AxiosError<any>,
    AxiosResponse<RetrieveProductionOrdersResponse, any>
  >({
    initialPageParam: {
      page: DEFAULT_PAGE,
      limit: DEFAULT_LIMIT_PAGE,
    },
    queryKey: [RETRIEVE_PRODUCTION_ORDERS_URL, "INFINITE_QUERY"],
    queryFn: ({ pageParam }) => {
      const { page, limit } = pageParam as RetrieveProductionOrdersArgs;
      return retrieveProductionOrders({
        ...retrieveProductionOrdersArgs,
        page,
        limit,
      });
    },
    getNextPageParam: (lastPage) => {
      const total = lastPage.data.data.total;
      const { limit, page } = lastPage.config
        .params as RetrieveProductionOrdersArgs;
      let nextPageParam: RetrieveProductionOrdersArgs | undefined;

      if (page) {
        const hasNextPage = page * (limit || DEFAULT_LIMIT_PAGE) < total;

        if (hasNextPage) {
          nextPageParam = {
            ...retrieveProductionOrdersArgs,
            page: page + 1,
            limit,
          };
        }
      }
      return nextPageParam;
    },
    ...opts,
  });
};
