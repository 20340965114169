import { Chip } from "app/components/atoms/Chip";
import { ListInfoItem } from "app/components/molecules/ListInfoItem";
import { useDetailPageContext } from "app/components/templates/DetailPage/DetailPageContext";
import { useAppTranslation } from "app/hooks";
import { Component } from "app/services/product";
import { FC } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Row } from "reactstrap";

export interface DetailDetailGeneralProps {}

const DetailDetailGeneral: FC<DetailDetailGeneralProps> = () => {
  const { componentText } = useAppTranslation();
  const { data: component } = useDetailPageContext<Component>();
  return (
    <div className="p-3 pb-0">
      <Row>
        <Col xs={12}>
          <Card>
            <CardBody>
              <h5 className="card-title mb-3">
                {componentText.detail.infomation}
              </h5>
              <Row className="gap-2">
                <Col xs={12}>
                  <ListInfoItem
                    label={componentText.field.code}
                    value={component?.code}
                  />
                </Col>
                <Col xs={12}>
                  <ListInfoItem
                    label={componentText.field.name}
                    value={component?.name}
                  />
                </Col>
                <Col xs={12}>
                  <ListInfoItem
                    label={componentText.field.type}
                    value={
                      component?.type ? (
                        <Chip
                          size="sm"
                          color={component?.type?.colorCode}
                          label={component?.type?.name}
                        />
                      ) : (
                        "-"
                      )
                    }
                  />
                </Col>
                <Col xs={12}>
                  <ListInfoItem
                    label={componentText.field.frameType}
                    value={
                      component?.frameType ? (
                        <Chip
                          size="sm"
                          color={component?.frameType?.colorCode}
                          label={component?.frameType?.name}
                        />
                      ) : (
                        "-"
                      )
                    }
                  />
                </Col>
                <Col xs={12}>
                  <ListInfoItem
                    label={componentText.field.workpieceType}
                    value={
                      component?.workpieceType ? (
                        <Chip
                          size="sm"
                          color={component?.workpieceType?.colorCode}
                          label={component?.workpieceType?.name}
                        />
                      ) : (
                        "-"
                      )
                    }
                  />
                </Col>
                <Col xs={12}>
                  <ListInfoItem
                    label={componentText.field.thicknessCm}
                    value={component?.thicknessCm}
                  />
                </Col>
                <Col xs={12}>
                  <ListInfoItem
                    label={componentText.field.lengthOfBoxCm}
                    value={component?.lengthOfBoxCm}
                  />
                </Col>
                <Col xs={12}>
                  <ListInfoItem
                    label={componentText.field.widthOfBoxCm}
                    value={component?.widthOfBoxCm}
                  />
                </Col>
                <Col xs={12}>
                  <ListInfoItem
                    label={componentText.field.lengthOfDetailCm}
                    value={component?.lengthOfDetailCm}
                  />
                </Col>
                <Col xs={12}>
                  <ListInfoItem
                    label={`${componentText.field.workStep} - ${componentText.field.estimateTime}`}
                    value={
                      <div className="d-flex gap-1 flex-gap">
                        {component?.workSteps?.map((step) => (
                          <Chip
                            key={step.id}
                            size="sm"
                            color={step.step.colorCode}
                            label={
                              <Link
                                to={`/step/${step.step.id}`}
                                className="text-decoration-none text-white"
                                target="_blank"
                              >
                                {step.estimateTime
                                  ? `${step.step.name} - ${step.estimateTime}`
                                  : step.step.name}
                              </Link>
                            }
                          />
                        ))}
                      </div>
                    }
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default DetailDetailGeneral;
