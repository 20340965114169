import {
  keepPreviousData,
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  useQuery,
  UseQueryOptions,
} from "@tanstack/react-query";
import { httpClient } from "app/datasources";
import { DEFAULT_LIMIT_PAGE, DEFAULT_PAGE } from "app/helpers";
import { AxiosError, AxiosResponse } from "axios";
import { Response } from "../types";
import { PagingData } from "./../types";
import { Component } from "./types";

export const RETRIEVE_COMPONENTS_URL = "/api/admin/components";

export interface RetrieveComponentsArgs {
  page?: number;
  limit?: number;
  searchString?: string;
  typeId?: ID;
}

export type RetrieveComponentsResponse = Response<PagingData<Component[]>>;

export const retrieveComponents = (args: RetrieveComponentsArgs) => {
  return httpClient.get<RetrieveComponentsResponse>(RETRIEVE_COMPONENTS_URL, {
    params: args,
  });
};

export const useRetrieveComponentsQuery = (
  retrieveComponentsArgs: RetrieveComponentsArgs,
  opts?: Omit<
    UseQueryOptions<AxiosResponse<RetrieveComponentsResponse>, AxiosError<any>>,
    "queryKey" | "queryFn"
  >,
) =>
  useQuery<AxiosResponse<RetrieveComponentsResponse>, AxiosError<any>>({
    queryKey: [RETRIEVE_COMPONENTS_URL, retrieveComponentsArgs],
    queryFn: () => retrieveComponents(retrieveComponentsArgs),
    placeholderData: keepPreviousData,
    ...opts,
  });

export const useRetrieveComponentsInfiniteQuery = (
  retrieveComponentsArgs: RetrieveComponentsArgs,
  opts?: Omit<
    UseInfiniteQueryOptions<
      AxiosResponse<RetrieveComponentsResponse>,
      AxiosError<any>
    >,
    "initialPageParam" | "queryFn" | "queryKey" | "getNextPageParam"
  >,
): any => {
  return useInfiniteQuery<
    AxiosResponse<RetrieveComponentsResponse>,
    AxiosError<any>,
    AxiosResponse<RetrieveComponentsResponse, any>
  >({
    initialPageParam: {
      page: DEFAULT_PAGE,
      limit: DEFAULT_LIMIT_PAGE,
    },
    queryKey: [RETRIEVE_COMPONENTS_URL, "INFINITE_QUERY"],
    queryFn: ({ pageParam }) => {
      const { page, limit } = pageParam as RetrieveComponentsArgs;
      return retrieveComponents({
        ...retrieveComponentsArgs,
        page,
        limit,
      });
    },
    getNextPageParam: (lastPage) => {
      const total = lastPage.data.data.total;
      const { limit, page } = lastPage.config.params as RetrieveComponentsArgs;
      let nextPageParam: RetrieveComponentsArgs | undefined;

      if (page) {
        const hasNextPage = page * (limit || DEFAULT_LIMIT_PAGE) < total;

        if (hasNextPage) {
          nextPageParam = {
            ...retrieveComponentsArgs,
            page: page + 1,
            limit,
          };
        }
      }
      return nextPageParam;
    },
    ...opts,
  });
};
