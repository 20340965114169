import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "app/components/molecules/Button";
import { FormHelper } from "app/components/molecules/FormHelper";
import { FormInput } from "app/components/molecules/FormInput";
import { FormLabel } from "app/components/molecules/FormLabel";
import { ReactSelectCustom } from "app/components/molecules/ReactSelectCustom";
import { yupSchema } from "app/helpers/schema";
import { useAppTranslation } from "app/hooks";
import { userNameSchema } from "app/modules/auth/helpers";
import { useRetrieveRoles } from "app/modules/role/hooks";
import { OptionSelect } from "app/services/types";
import { FC, FormEvent, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Col, Form, FormGroup, Row } from "reactstrap";
import * as Yup from "yup";

export interface UserFormProps {
  defaultValues?: UserFormData;
  onSubmit: (data: UserFormData) => void;
  submitting?: boolean;
  onToggle: () => void;
}

export interface UserFormData {
  username: string;
  password: string;
  role: OptionSelect<ID> | null;
}

export const UserForm: FC<UserFormProps> = ({
  onSubmit,
  defaultValues,
  submitting,
  onToggle,
}) => {
  const { accountText, buttonText } = useAppTranslation();
  const [passwordShow, setPasswordShow] = useState(false);
  const {
    control,
    handleSubmit,
    formState: { isDirty, isValid },
  } = useForm<UserFormData>({
    mode: "onChange",
    resolver: yupResolver(
      Yup.object({
        username: userNameSchema,
        password: yupSchema.stringMoreThanOrEqualRequired(
          8,
          "user.error.passwordMinLength",
          "user.error.passwordRequired",
        ),
        role: yupSchema.objectRequired("user.error.roleRequired"),
      }),
    ),
    defaultValues,
  });

  const isEnableSubmit = isDirty && isValid;

  const submit = async (e: FormEvent) => {
    e.preventDefault();
    handleSubmit(onSubmit)();
  };

  const { roles } = useRetrieveRoles({ page: 1, limit: 100 });
  const roleOptions = roles?.result?.map((role) => ({
    label: role.name,
    value: role.id,
  }));

  return (
    <Form onSubmit={submit} id="user-form">
      <Row>
        <Col xs={12}>
          <FormGroup>
            <FormLabel required htmlFor="username" className="form-label">
              {accountText.field.username}
            </FormLabel>
            <Controller
              name="username"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <FormInput
                    {...field}
                    type="text"
                    className="form-control"
                    id="username"
                    invalid={!!fieldState.error}
                  />
                  <FormHelper message={fieldState.error?.message} />
                </>
              )}
            />
          </FormGroup>
          <FormGroup>
            <FormLabel
              required
              className="form-label"
              htmlFor="user-password-input"
            >
              {accountText.field.password}
            </FormLabel>
            <div className="position-relative auth-pass-inputgroup mb-3">
              <Controller
                name="password"
                control={control}
                render={({ field, fieldState }) => (
                  <>
                    <FormInput
                      {...field}
                      type={passwordShow ? "text" : "password"}
                      className="form-control pe-5 password-input"
                      id="user-password-input"
                      autoComplete="new-password"
                      invalid={!!fieldState.error}
                    />
                    <FormHelper message={fieldState.error?.message} />
                  </>
                )}
              />
              <button
                className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
                type="button"
                id="password-addon"
                onClick={() => setPasswordShow(!passwordShow)}
              >
                <i className="ri-eye-fill align-middle"></i>
              </button>
            </div>
          </FormGroup>
          <FormGroup>
            <FormLabel required htmlFor="role" className="form-label">
              {accountText.field.role}
            </FormLabel>
            <Controller
              name="role"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <ReactSelectCustom
                    value={field.value}
                    onChange={field.onChange}
                    options={roleOptions}
                    placeholder="Chọn"
                    isInvalid={fieldState.invalid}
                  />
                  <FormHelper message={fieldState.error?.message} />
                </>
              )}
            />
          </FormGroup>
        </Col>
        <Col xs={12}>
          <div className="hstack gap-2 justify-content-end">
            <Button color="light" onClick={onToggle}>
              {buttonText.cancel}
            </Button>
            <Button
              disabled={!isEnableSubmit}
              type="submit"
              color="primary"
              loading={submitting}
            >
              {buttonText.save}
            </Button>
          </div>
        </Col>
      </Row>
    </Form>
  );
};
