import {
  Column,
  ColumnDef,
  ExpandedState,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  Row as RowType,
  useReactTable,
} from "@tanstack/react-table";
import { Button } from "app/components/molecules/Button";
import { DEFAULT_LIMIT_PAGE, DEFAULT_PAGE } from "app/helpers";
import { useAppTranslation } from "app/hooks";
import { clsx } from "clsx";
import React, { CSSProperties, Fragment } from "react";
import { Row, Spinner, Table } from "reactstrap";
import useResizeObserver from "use-resize-observer";

export interface NestedDataTableProps<T> {
  total: number;
  loading?: boolean;
  columns: ColumnDef<T>[];
  data: T[];
  page?: number;
  limit?: number;
  setPage?: (page: number) => void;
  setLimit?: (limit: number) => void;
  isHiddenPagination?: boolean;
  onRowClick?: (data: T) => void;
  isTreeTable?: boolean;
  getSubRows?: (row: T) => T[];
  pinLeftColumns?: string[];
  pinRightColumns?: string[];
  getRowCanExpand?: (row: RowType<T>) => boolean;
  renderSubComponent?: (props: { row: RowType<T> }) => React.ReactNode;
  defaultExpand?: ExpandedState;
  className?: string;
}

export const NestedDataTable = <T,>({
  loading = false,
  total = 0,
  data = [],
  columns,
  setPage,
  setLimit,
  page = DEFAULT_PAGE,
  limit = DEFAULT_LIMIT_PAGE,
  isHiddenPagination = false,
  isTreeTable = false,
  pinLeftColumns,
  pinRightColumns = ["action"],
  onRowClick,
  getSubRows,
  getRowCanExpand,
  renderSubComponent,
  defaultExpand = {},
  className,
}: NestedDataTableProps<T>) => {
  const { limitPageText, noDataTableText } = useAppTranslation();
  const tableContainerResizeObserver = useResizeObserver<HTMLDivElement>();
  const getCommonPinningStyles = (column: Column<T>): CSSProperties => {
    const isPinned = column.getIsPinned();

    return {
      paddingLeft: "0.6em",
      background: "white",
      left: isPinned === "left" ? `${column.getStart("left")}px` : undefined,
      right: isPinned === "right" ? `${column.getAfter("right")}px` : undefined,
      opacity: isPinned ? 0.95 : 1,
      position: isPinned ? "sticky" : "relative",
      width: column.getSize(),
      zIndex: isPinned ? 1 : 0,
      verticalAlign: "middle",
    };
  };

  const cols: ColumnDef<T>[] = isTreeTable
    ? columns.map((column, index) => {
        if (index === 0) {
          return {
            ...column,
            header: (props) => (
              <div className="d-flex align-items-center">
                <i
                  className="cursor-pointer me-1"
                  onClick={table.getToggleAllRowsExpandedHandler()}
                >
                  {table.getIsAllRowsExpanded() ? (
                    <i className="ri-arrow-down-s-line fs-16" />
                  ) : (
                    <i className=" ri-arrow-right-s-line fs-16" />
                  )}
                </i>
                {column.header ? (
                  <>
                    {typeof column.header === "function"
                      ? column.header(props)
                      : column.header}
                  </>
                ) : (
                  "-"
                )}
              </div>
            ),
            cell: ({ row, getValue, ...props }) => (
              <div
                className="d-flex align-items-center"
                style={{
                  paddingLeft: !row.getCanExpand()
                    ? `calc(${row.depth * 1}rem + 1.25rem)`
                    : `${row.depth * 1}rem`,
                }}
              >
                {row.getCanExpand() ? (
                  <i
                    className="cursor-pointer me-1"
                    onClick={(e) => {
                      e.stopPropagation();
                      row.getToggleExpandedHandler()();
                    }}
                  >
                    {row.getIsExpanded() ? (
                      <i className="ri-arrow-down-s-line fs-16" />
                    ) : (
                      <i className=" ri-arrow-right-s-line fs-16" />
                    )}
                  </i>
                ) : null}
                {column.cell ? (
                  <>
                    {typeof column.cell === "function"
                      ? column.cell({ row, getValue, ...props })
                      : column.cell}
                  </>
                ) : (
                  getValue<boolean>()
                )}
              </div>
            ),
          } as ColumnDef<T>;
        }
        return column;
      }) || []
    : columns;

  const [expanded, setExpanded] = React.useState<ExpandedState>(defaultExpand);

  const table = useReactTable({
    data: data,
    columns: cols,
    manualPagination: true,
    rowCount: total,
    getCoreRowModel: getCoreRowModel(),
    onPaginationChange: (updater) => {
      const newState =
        typeof updater === "function"
          ? updater(table.getState().pagination)
          : updater;
      setPage?.(newState.pageIndex + 1);
      setLimit?.(newState.pageSize);
      return newState;
    },
    onExpandedChange: setExpanded,
    getSubRows,
    getExpandedRowModel: getExpandedRowModel(),
    getRowCanExpand,
    state: {
      columnPinning: {
        right: pinRightColumns,
        left: pinLeftColumns,
      },
      pagination: {
        pageIndex: page - 1,
        pageSize: limit,
      },
      expanded,
    },
  });

  const totalPage = Math.ceil(total / limit);
  const pageIndex = table.getState().pagination.pageIndex;

  const renderPageItems = () => {
    if (totalPage <= 4) {
      return Array.from({ length: totalPage }, (_, i) => (
        <li className="page-item" key={i}>
          <Button
            className={pageIndex === i ? "page-link active" : "page-link"}
            onClick={() => table.setPageIndex(i)}
          >
            {i + 1}
          </Button>
        </li>
      ));
    }

    const items = [];
    items.push(
      <li className="page-item" key={0}>
        <Button
          className={pageIndex === 0 ? "page-link active" : "page-link"}
          onClick={() => table.setPageIndex(0)}
        >
          1
        </Button>
      </li>,
    );

    if (page > 3) {
      items.push(
        <li className="page-item" key="start-ellipsis-1">
          <Button className="page-link" disabled>
            ...
          </Button>
        </li>,
      );
    }

    const startPage = Math.max(2, page - 1);
    const endPage = Math.min(totalPage - 1, page + 1);

    for (let i = startPage; i <= endPage; i++) {
      items.push(
        <li className="page-item" key={i - 1}>
          <Button
            className={pageIndex === i - 1 ? "page-link active" : "page-link"}
            onClick={() => table.setPageIndex(i - 1)}
          >
            {i}
          </Button>
        </li>,
      );
    }

    if (page < totalPage - 2) {
      items.push(
        <li className="page-item" key="start-ellipsis-2">
          <Button className="page-link" disabled>
            ...
          </Button>
        </li>,
      );
    }

    items.push(
      <li className="page-item" key={totalPage}>
        <Button
          className={
            pageIndex === totalPage - 1 ? "page-link active" : "page-link"
          }
          onClick={() => table.setPageIndex(totalPage - 1)}
        >
          {totalPage}
        </Button>
      </li>,
    );

    return items;
  };

  return (
    <>
      <div
        className={clsx("table-container", className)}
        ref={tableContainerResizeObserver.ref}
      >
        <Table style={{ maxHeight: 300 }}>
          <thead>
            {table.getHeaderGroups().map((headerGroup: any) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header: any) => (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    style={{
                      minWidth: `${header.getSize()}px`,
                      ...getCommonPinningStyles(header.column),
                    }}
                  >
                    {header.isPlaceholder ? null : (
                      <React.Fragment>
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                      </React.Fragment>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.length && !loading
              ? table.getRowModel().rows.map((row: any) => {
                  return (
                    <Fragment key={row.id}>
                      <tr
                        key={row.id}
                        className={onRowClick ? "cursor-pointer" : ""}
                        onClick={() => {
                          onRowClick?.(row.original);
                        }}
                      >
                        {row.getVisibleCells().map((cell: any) => {
                          return (
                            <td
                              key={cell.id}
                              style={{ ...getCommonPinningStyles(cell.column) }}
                            >
                              {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext(),
                              )}
                            </td>
                          );
                        })}
                      </tr>
                      {row.getIsExpanded() && renderSubComponent?.({ row }) ? (
                        <tr>
                          <td colSpan={row.getVisibleCells().length}>
                            <div
                              style={{
                                paddingLeft: `calc(${row.depth * 2}rem + 1rem)`,
                                width: tableContainerResizeObserver.width,
                              }}
                            >
                              {renderSubComponent?.({ row })}
                            </div>
                          </td>
                        </tr>
                      ) : null}
                    </Fragment>
                  );
                })
              : null}
          </tbody>
        </Table>
        {loading ? (
          <div className="no-data-cell">
            <Spinner color="primary" />
          </div>
        ) : null}
        {table.getRowModel().rows.length === 0 && !loading ? (
          <div className="no-data-cell">{noDataTableText}</div>
        ) : null}
      </div>
      {isHiddenPagination ? null : (
        <Row className="align-items-center pb-4 pt-3 g-3 text-center text-sm-start">
          <div className="col-sm">
            <div className=" d-flex align-items-center text-muted gap-1">
              {limitPageText.show}
              <select
                className="form-select form-select-sm ms-2 w-auto"
                value={limit}
                onChange={(e) => {
                  setLimit?.(parseInt(e.target.value));
                }}
              >
                {[10, 20, 50, 100].map((item) => (
                  <option key={item} value={item}>
                    {item}
                  </option>
                ))}
              </select>
              {limitPageText.in} <span className="fw-semibold">{total}</span>{" "}
              {limitPageText.result}
            </div>
          </div>
          <div className="col-sm-auto">
            <ul className="pagination pagination-separated pagination-md justify-content-center justify-content-sm-start mb-0">
              <li
                className={
                  !table.getCanPreviousPage()
                    ? "page-item disabled"
                    : "page-item"
                }
              >
                <Button className="page-link" onClick={table.previousPage}>
                  <i className="ri-arrow-left-s-line" />
                </Button>
              </li>
              {renderPageItems()}
              <li
                className={
                  !table.getCanNextPage() ? "page-item disabled" : "page-item"
                }
              >
                <Button
                  className="page-link"
                  onClick={() => {
                    table.nextPage();
                  }}
                >
                  <i className="ri-arrow-right-s-line" />
                </Button>
              </li>
            </ul>
          </div>
        </Row>
      )}
    </>
  );
};
