import { useAppTranslation } from "app/hooks";
import { useRetrieveEmployeeById } from "app/modules/employee/hooks";
import { FC } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "reactstrap";

export interface DetailEmployeeHeaderProps {}

export const DetailEmployeeHeader: FC<DetailEmployeeHeaderProps> = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { buttonText } = useAppTranslation();
  const { employee } = useRetrieveEmployeeById(
    { id: id as string },
    { enabled: !!id },
  );

  const fullName = employee ? employee.fullName : "";

  const avatar = employee?.avatar;

  return (
    <div className="d-flex gap-4 flex-wrap">
      <div className="avatar-lg d-flex justify-content-center align-items-center">
        {avatar ? (
          <img
            src={avatar?.attachmentUrl}
            alt={fullName}
            className="img-thumbnail rounded-4 w-100 h-100"
          />
        ) : (
          <i className="ri-account-circle-fill text-body"></i>
        )}
      </div>

      <div className="flex-grow-1 p-2">
        <h3 className="mb-1">{fullName}</h3>
        <p className="text-muted">{employee?.jobTitle.name}</p>
        <div className="hstack text-muted gap-1">
          <div className="me-2">
            <i className="ri-mail-line me-1 fs-16 align-bottom text-body"></i>
            {employee?.email || "-"}
          </div>
          <div>
            <i className="ri-phone-line me-1 fs-16 align-bottom text-body"></i>
            {employee?.phone}
          </div>
        </div>
      </div>
      <div className="text-end">
        <div className="d-flex gap-2 justify-content-end">
          <Button
            color="primary"
            onClick={() => navigate(`/employee/${id}/update`)}
          >
            {buttonText.edit}
          </Button>
        </div>
      </div>
    </div>
  );
};
