import { DateInput } from "app/components/molecules/DateInput";
import { DebouncedInput } from "app/components/molecules/DebounceInput";
import { FilterOffCanvas } from "app/components/molecules/FilterOffCanvas";
import { FormLabel } from "app/components/molecules/FormLabel";
import { ReactSelectCustom } from "app/components/molecules/ReactSelectCustom";
import { formatDate } from "app/helpers/utils";
import { useAppTranslation } from "app/hooks";
import { productionOrderStatusOptions } from "app/modules/productionOrder/constants";
import { useRetrieveProductionOrders } from "app/modules/productionOrder/hooks";
import { ProductionOrderStatus } from "app/services/productionOrder";
import { OptionSelect } from "app/services/types";
import { FC, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { FormGroup } from "reactstrap";

export interface DetailProductionPlanOrderFilterParams {
  status: OptionSelect<ProductionOrderStatus>[];
  startDate: Date | null;
  endDate: Date | null;
  rangeDate?: Date | null;
}

export interface DetailProductionPlanOrderFilterProps {
  searchString: string;
  filterParams: DetailProductionPlanOrderFilterParams;
  handleSearchChange: (value: string) => void;
  setCustomParams: (params: DetailProductionPlanOrderFilterParams) => void;
}

export const DetailProductionPlanOrderFilter: FC<
  DetailProductionPlanOrderFilterProps
> = ({ handleSearchChange, filterParams, searchString, setCustomParams }) => {
  const { productionOrderText } = useAppTranslation();
  const { id } = useParams();
  const { control, reset, watch, handleSubmit } =
    useForm<DetailProductionPlanOrderFilterParams>({
      defaultValues: filterParams,
    });

  const watchStatus = watch("status");
  const watchStartDate = watch("startDate");
  const watchEndDate = watch("endDate");

  const { productionOrders } = useRetrieveProductionOrders({
    limit: 20,
    page: 1,
    searchString: searchString || undefined,
    status: watchStatus?.map((item) => item.value),
    startDate: watchStartDate?.toISOString(),
    endDate: watchEndDate?.toISOString(),
    productionPlanIds: id ? [id] : undefined,
  });

  const total = productionOrders?.total || 0;

  const handleFilter = () => {
    setCustomParams({
      status: watchStatus,
      startDate: watchStartDate,
      endDate: watchEndDate,
    });
    handleToggle();
  };

  const handleClearFilter = () => {
    reset({
      status: [],
      startDate: null,
      endDate: null,
    });
  };

  useEffect(() => {
    reset(filterParams);
  }, [filterParams]);

  const formID = "production-order-filter-form";

  const [show, setShow] = useState(false);
  const handleToggle = () => setShow(!show);
  return (
    <div className="d-flex align-items-center mb-2">
      <div className="search-box me-2 d-inline-block">
        <DebouncedInput
          name="list-production-order-search"
          value={searchString || ""}
          onChange={(value) => {
            handleSearchChange(String(value));
          }}
        />
        <i className="bx bx-search-alt search-icon"></i>
      </div>
      <form noValidate id={formID} onSubmit={handleSubmit(handleFilter)}>
        <FilterOffCanvas
          formId={formID}
          handleClearFilter={handleClearFilter}
          onToggle={handleToggle}
          show={show}
          total={total}
        >
          <div className="d-flex flex-column flex-grow-1">
            <FormGroup>
              <FormLabel className="mb-2">
                {productionOrderText.field.status}
              </FormLabel>
              <Controller
                name="status"
                control={control}
                render={({ field }) => (
                  <ReactSelectCustom
                    isMulti
                    value={field.value}
                    onChange={field.onChange}
                    options={productionOrderStatusOptions}
                    placeholder="Chọn"
                  />
                )}
              />
            </FormGroup>
            <FormGroup>
              <FormLabel className="mb-2">
                {productionOrderText.field.startDate}
              </FormLabel>
              <Controller
                name="startDate"
                control={control}
                render={({ field, fieldState }) => (
                  <DateInput
                    name={field.name}
                    id="startDate"
                    value={field.value || ""}
                    onChange={([date]) => {
                      field.onChange(date);
                    }}
                    options={{
                      maxDate: watchEndDate
                        ? formatDate(watchEndDate)
                        : undefined,
                    }}
                    isInvalid={fieldState.invalid}
                  />
                )}
              />
            </FormGroup>
            <FormGroup>
              <FormLabel className="mb-2">
                {productionOrderText.field.endDate}
              </FormLabel>
              <Controller
                name="endDate"
                control={control}
                render={({ field, fieldState }) => (
                  <DateInput
                    name={field.name}
                    id="endDate"
                    value={field.value || ""}
                    onChange={([date]) => {
                      field.onChange(date);
                    }}
                    options={{
                      minDate: watchStartDate
                        ? formatDate(watchStartDate)
                        : undefined,
                    }}
                    isInvalid={fieldState.invalid}
                  />
                )}
              />
            </FormGroup>
          </div>
        </FilterOffCanvas>
      </form>
    </div>
  );
};
