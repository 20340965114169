import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { FormHelper } from "app/components/molecules/FormHelper";
import { FormInput } from "app/components/molecules/FormInput";
import { FormLabel } from "app/components/molecules/FormLabel";
import { useAppTranslation } from "app/hooks";
import { StepSelect } from "app/modules/step/components/StepSelect";
import { FC } from "react";
import { Controller } from "react-hook-form";
import { Card, CardBody, Col, FormGroup, Row } from "reactstrap";
import { ProductStepItem } from "./ProductForm";

export const StepCard: FC<{
  field: ProductStepItem;
  workStepsField: ProductStepItem[];
  removeStep: (index: number) => void;
  control: any;
  index: number;
}> = ({ field, control, index, removeStep, workStepsField }) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: field.id as ID });
  const { productText } = useAppTranslation();

  const selectedProductSteps =
    workStepsField.length > 0
      ? workStepsField.map((item) => item.step?.value)
      : [];

  return (
    <div
      ref={setNodeRef}
      style={{ transform: CSS.Transform.toString(transform), transition }}
    >
      <div className="d-flex gap-2">
        <div
          {...attributes}
          {...listeners}
          style={{
            cursor: "grab",
            background: "#ddd",
            alignContent: "center",
            padding: "0.25rem",
            marginBottom: "1.5rem",
            borderRadius: "0.25rem",
          }}
        >
          ☰
        </div>
        <Card key={field.id} className="flex-grow-1">
          <CardBody>
            <div className="text-end">
              <i
                className="ri-delete-bin-line fs-18 cursor-pointer lh-1"
                onClick={() => removeStep(index)}
              />
            </div>
            <Row>
              <Col xs={12} md={4}>
                <FormGroup>
                  <Row>
                    <Col>
                      <FormLabel required htmlFor={`workSteps[${index}].step`}>
                        {productText.field.workStepName} {index + 1}
                      </FormLabel>
                    </Col>
                  </Row>
                  <Controller
                    name={`workSteps.${index}.step`}
                    control={control}
                    render={({ field, fieldState }) => (
                      <>
                        <StepSelect
                          isOptionDisabled={(option) =>
                            selectedProductSteps.includes(option.value)
                          }
                          key={`workSteps.${index}.step`}
                          name={`workSteps.${index}.step`}
                          inputId={`workSteps[${index}].step`}
                          value={field.value}
                          onChange={field.onChange}
                          isInvalid={fieldState.invalid}
                        />
                        <FormHelper message={fieldState.error?.message} />
                      </>
                    )}
                  />
                </FormGroup>
              </Col>
              <Col xs={12} md={4}>
                <FormGroup>
                  <FormLabel
                    htmlFor={`workSteps.${index}.estimateTime`}
                    className="form-label"
                  >
                    {productText.field.estimateTime}
                  </FormLabel>
                  <Controller
                    name={`workSteps.${index}.estimateTime` as const}
                    control={control}
                    render={({ field, fieldState }) => (
                      <>
                        <FormInput
                          {...field}
                          value={field.value ?? ""}
                          onChange={(value) => {
                            field.onChange(
                              value.target.value === ""
                                ? null
                                : Number(value.target.value),
                            );
                          }}
                          type="number"
                          className="form-control"
                          id={`workSteps.${index}.estimateTime`}
                          invalid={!!fieldState.invalid}
                        />
                        <FormHelper message={fieldState.error?.message} />
                      </>
                    )}
                  />
                </FormGroup>
              </Col>
              <Col xs={12} md={4}>
                <FormGroup>
                  <FormLabel
                    htmlFor={`workSteps.${index}.quantity`}
                    className="form-label"
                  >
                    {productText.field.quantity}
                  </FormLabel>
                  <Controller
                    name={`workSteps.${index}.quantity` as const}
                    control={control}
                    render={({ field, fieldState }) => (
                      <>
                        <FormInput
                          {...field}
                          value={field.value ?? ""}
                          onChange={(value) => {
                            field.onChange(
                              value.target.value === ""
                                ? null
                                : Number(value.target.value),
                            );
                          }}
                          type="number"
                          className="form-control"
                          id={`workSteps.${index}.quantity`}
                          invalid={!!fieldState.invalid}
                        />
                        <FormHelper message={fieldState.error?.message} />
                      </>
                    )}
                  />
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    </div>
  );
};
