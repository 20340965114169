import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { httpClient } from "app/datasources";
import { AxiosError, AxiosResponse } from "axios";
import { Response } from "../types";
import { Material } from "./types";

export const DELETE_MATERIAL_URL = (id: ID) => `/api/admin/materials/${id}`;

export enum DeleteMaterialError {}

export const deletedMaterialErrorMessages = {};

export type DeleteMaterialResponse = Response<Material>;

export type DeleteMaterialArgs = {
  id: ID;
};

export const deleteMaterial = ({ id }: DeleteMaterialArgs) => {
  return httpClient.delete<DeleteMaterialResponse>(DELETE_MATERIAL_URL(id));
};

export const useDeleteMaterialMutation = (
  opts?: UseMutationOptions<
    AxiosResponse<DeleteMaterialResponse>,
    AxiosError<Response<null>>,
    DeleteMaterialArgs
  >,
) => {
  return useMutation({
    mutationKey: [DELETE_MATERIAL_URL],
    mutationFn: (deletedMaterialArgs: DeleteMaterialArgs) =>
      deleteMaterial(deletedMaterialArgs),
    ...opts,
  });
};
