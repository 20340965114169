import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { httpClient } from "app/datasources";
import { Response } from "app/services/types";
import { AxiosError, AxiosResponse } from "axios";
import { Ticket } from "./types";

export const CREATE_TICKET_URL = "/api/admin/tickets";

export enum CreateTicketError {}

export const createTicketErrorMessages = {};

export interface CreateTicketArgs {
  productionOrderItemId: ID;
  workStepId: ID;
  workshopId: ID;
  quantity: number;
  note: string;
}

export type CreateTicketResponse = Response<Ticket>;

export const createTicket = (args: CreateTicketArgs) => {
  return httpClient.post<CreateTicketResponse>(CREATE_TICKET_URL, args);
};

export const useCreateTicketMutation = (
  opts?: Omit<
    UseMutationOptions<
      AxiosResponse<CreateTicketResponse>,
      AxiosError<any>,
      CreateTicketArgs
    >,
    "mutationFn"
  >,
) =>
  useMutation<
    AxiosResponse<CreateTicketResponse>,
    AxiosError<any>,
    CreateTicketArgs
  >({
    mutationFn: createTicket,
    mutationKey: [CREATE_TICKET_URL],
    ...opts,
  });
