import { useAppTranslation } from "app/hooks";
import { Ticket } from "app/services/ticket";
import { FC } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { useRetrieveTicketById } from "../../hooks";
import { mapDefaultTicket } from "../../mapper";
import {
  TicketProductionOrderItemForm,
  TicketProductionOrderItemFormData,
} from "./TicketProductionOrderItemForm";

export interface TicketProductionOrderItemFormModalProps {
  open: boolean;
  onToggle: () => void;
  onSubmit: (data: TicketProductionOrderItemFormData) => void;
  submitting?: boolean;
  edit?: boolean;
  defaultValues?: TicketProductionOrderItemFormData;
  data?: Ticket | null;
}

export const TicketProductionOrderItemFormModal: FC<
  TicketProductionOrderItemFormModalProps
> = ({
  defaultValues,
  onToggle,
  open = false,
  onSubmit,
  submitting,
  edit = false,
  data,
}) => {
  const { ticketText } = useAppTranslation();
  const title = edit ? ticketText.edit : ticketText.add;

  const { ticket, isLoadingTicket } = useRetrieveTicketById(
    { id: data?.id as string },
    { enabled: !!data && !!open },
  );

  return (
    <Modal isOpen={open} toggle={onToggle}>
      <ModalHeader className="modal-title" toggle={onToggle}>
        {title}
      </ModalHeader>
      <ModalBody>
        {!isLoadingTicket ? (
          <TicketProductionOrderItemForm
            onSubmit={onSubmit}
            onToggle={onToggle}
            defaultValues={ticket ? mapDefaultTicket(ticket) : defaultValues}
            submitting={submitting}
          />
        ) : null}
      </ModalBody>
    </Modal>
  );
};
