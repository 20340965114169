import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "app/components/molecules/Button";
import { FormHelper } from "app/components/molecules/FormHelper";
import { FormInput } from "app/components/molecules/FormInput";
import { FormLabel } from "app/components/molecules/FormLabel";
import { yupSchema } from "app/helpers/schema";
import { useAppTranslation, useGetEnableSubmitButton } from "app/hooks";
import { MasterDataInfiniteSelect } from "app/modules/masterData/components";
import { MasterData, MasterDataType } from "app/services/masterData";
import { OptionSelect } from "app/services/types";
import { FC, FormEvent } from "react";
import { Controller, useForm } from "react-hook-form";
import { Col, Form, FormGroup, Row } from "reactstrap";
import * as Yup from "yup";

export interface StepFormProps {
  defaultValues?: StepFormData;
  onSubmit: (data: StepFormData) => void;
  submitting?: boolean;
}

export interface StepFormData {
  name: string;
  description: string;
  skills: OptionSelect[];
}

export const StepForm: FC<StepFormProps> = ({
  onSubmit,
  defaultValues,
  submitting,
}) => {
  const { stepText, buttonText, generalInfomationText } = useAppTranslation();
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { isDirty, isValid },
  } = useForm<StepFormData>({
    mode: "onChange",
    resolver: yupResolver(
      Yup.object({
        name: yupSchema.stringRequired("step.error.nameRequired"),
        description: yupSchema.stringNotRequired(),
        skills: yupSchema.arrayNotRequired(),
      }),
    ),
    defaultValues,
  });

  const isEnableSubmit = useGetEnableSubmitButton({
    isDirty,
    isValid,
    submitting,
  });

  const submit = async (e: FormEvent) => {
    e.preventDefault();
    handleSubmit(onSubmit)();
  };

  const handleSetValues = (name: keyof StepFormData) => (data: MasterData) => {
    const currentValue = getValues(name) as OptionSelect<ID>[];
    setValue(
      name,
      [
        ...currentValue,
        {
          value: data.id,
          label: data.name,
        },
      ],
      { shouldDirty: true },
    );
  };

  return (
    <Form onSubmit={submit} id="step-form">
      <h5 className="py-2 fw-semibold">{generalInfomationText}</h5>
      <Row>
        <Col xs={12}>
          <FormGroup>
            <FormLabel htmlFor="name" className="form-label">
              {stepText.field.name}
            </FormLabel>
            <Controller
              name="name"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <FormInput
                    {...field}
                    type="text"
                    className="form-control"
                    id="name"
                    invalid={!!fieldState.error}
                  />
                  <FormHelper message={fieldState.error?.message} />
                </>
              )}
            />
          </FormGroup>
        </Col>
        <Col xs={12}>
          <FormGroup>
            <FormLabel htmlFor="description" className="form-label">
              {stepText.field.description}
            </FormLabel>
            <Controller
              name="description"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <FormInput
                    {...field}
                    type="text"
                    className="form-control"
                    id="description"
                    invalid={!!fieldState.error}
                  />
                  <FormHelper message={fieldState.error?.message} />
                </>
              )}
            />
          </FormGroup>
        </Col>
        <Col xs={12}>
          <FormGroup>
            <FormLabel htmlFor="skills" className="form-label">
              {stepText.field.skill}
            </FormLabel>
            <Controller
              name="skills"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <MasterDataInfiniteSelect
                    isMulti
                    isCreatable
                    key="skills"
                    name="skills"
                    inputId="skills"
                    value={field.value}
                    onChange={field.onChange}
                    isInvalid={fieldState.invalid}
                    retrieveKeys={{ type: MasterDataType.Skill }}
                    onCreateSuccess={handleSetValues("skills")}
                  />
                  <FormHelper message={fieldState.error?.message} />
                </>
              )}
            />
          </FormGroup>
        </Col>
        <Col xs={12}>
          <div className="hstack gap-2 justify-content-end">
            <Button
              disabled={!isEnableSubmit}
              type="submit"
              color="primary"
              loading={submitting}
            >
              {buttonText.save}
            </Button>
          </div>
        </Col>
      </Row>
    </Form>
  );
};
