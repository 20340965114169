import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { httpClientPanel } from "app/datasources";
import { AxiosError, AxiosResponse } from "axios";
import { Response } from "../types";
import { PagingData } from "./../types";
import { Ticket } from "./types";

export const RETRIEVE_PANEL_TICKETS_BY_EMPLOYEE_ID_URL = (id: ID) =>
  `/api/panel/tickets/employees/${id}`;

export type RetrievePanelTicketsByEmployeeIdResponse = Response<
  PagingData<Ticket[]> & { hasTicketInprogress: boolean }
>;

export type RetrievePanelTicketsByEmployeeIdArgs = {
  id: ID;
};

export const retrievePanelTicketsByEmployeeId = ({
  id,
}: RetrievePanelTicketsByEmployeeIdArgs) => {
  return httpClientPanel.get<RetrievePanelTicketsByEmployeeIdResponse>(
    RETRIEVE_PANEL_TICKETS_BY_EMPLOYEE_ID_URL(id),
  );
};

export const useRetrievePanelTicketsByEmployeeIdQuery = (
  retrievePanelTicketssArgs: RetrievePanelTicketsByEmployeeIdArgs,
  opts?: Omit<
    UseQueryOptions<
      AxiosResponse<RetrievePanelTicketsByEmployeeIdResponse>,
      AxiosError<any>
    >,
    "queryKey" | "queryFn"
  >,
) =>
  useQuery<
    AxiosResponse<RetrievePanelTicketsByEmployeeIdResponse>,
    AxiosError<any>
  >({
    queryKey: [
      RETRIEVE_PANEL_TICKETS_BY_EMPLOYEE_ID_URL(retrievePanelTicketssArgs.id),
    ],
    queryFn: () => retrievePanelTicketsByEmployeeId(retrievePanelTicketssArgs),
    ...opts,
  });
