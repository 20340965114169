import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { httpClient } from "app/datasources";
import { AxiosError, AxiosResponse } from "axios";
import { Response } from "../types";
import { ProductMaterial } from "./types";

export const RETRIEVE_PRODUCT_MATERIAL_BY_ID_URL = (id: ID) =>
  `/api/admin/product-materials/${id}`;

export type RetrieveProductMaterialByIdResponse = Response<ProductMaterial>;

export type RetrieveProductMaterialByIdArgs = {
  id: ID;
};

export const retrieveProductMaterialById = ({
  id,
}: RetrieveProductMaterialByIdArgs) => {
  return httpClient.get<RetrieveProductMaterialByIdResponse>(
    RETRIEVE_PRODUCT_MATERIAL_BY_ID_URL(id),
  );
};

export const useRetrieveProductMaterialByIdQuery = (
  retrieveProductMaterialsArgs: RetrieveProductMaterialByIdArgs,
  opts?: Omit<
    UseQueryOptions<
      AxiosResponse<RetrieveProductMaterialByIdResponse>,
      AxiosError<any>
    >,
    "queryKey" | "queryFn"
  >,
) =>
  useQuery<AxiosResponse<RetrieveProductMaterialByIdResponse>, AxiosError<any>>(
    {
      queryKey: [
        RETRIEVE_PRODUCT_MATERIAL_BY_ID_URL(retrieveProductMaterialsArgs.id),
      ],
      queryFn: () => retrieveProductMaterialById(retrieveProductMaterialsArgs),
      ...opts,
    },
  );
