import { useQueryClient } from "@tanstack/react-query";
import { errorMessageMapper } from "app/helpers";
import useToastify from "app/hooks/useToastify";
import {
  deleteComponentErrorMessages,
  RETRIEVE_COMPONENT_BY_ID_URL,
  useDeleteComponentMutation,
} from "app/services/product";

interface UseDeleteComponentProps {
  onSuccess?: () => void;
  onError?: () => void;
  id?: ID;
}

export const useDeleteComponent = ({
  onError: handleError,
  onSuccess: handleSuccess,
  id,
}: UseDeleteComponentProps = {}) => {
  const toast = useToastify();
  const queryClient = useQueryClient();
  return useDeleteComponentMutation({
    onSuccess: () => {
      toast.success();
      if (id)
        queryClient.invalidateQueries({
          queryKey: [RETRIEVE_COMPONENT_BY_ID_URL(id)],
        });
      handleSuccess?.();
    },
    onError: (error) => {
      toast.error(
        errorMessageMapper(
          deleteComponentErrorMessages,
          error.response?.data.message,
        ),
      );
      handleError?.();
    },
  });
};
