import { Button } from "app/components/molecules/Button";
import { getDateFilterValue } from "app/helpers/getDateFilterValue";
import { OptionSelect } from "app/services/types";
import { isArray } from "lodash";
import { FC, useMemo } from "react";
import {
  DetailProductionPlanOrderFilterProps,
  DetailProductionPlanOrderFilterParams,
} from "./DetailProductionPlanOrderFilter";

export interface DetailProductionPlanOrderFilterChipsProps
  extends Pick<
    DetailProductionPlanOrderFilterProps,
    "filterParams" | "setCustomParams"
  > {
  handleClearFilter: () => void;
}

const getFilters = (searchParams: DetailProductionPlanOrderFilterParams) => {
  const filters: (OptionSelect & {
    field: keyof DetailProductionPlanOrderFilterParams;
  })[] = [];
  if (searchParams.status && searchParams.status.length > 0) {
    searchParams.status.forEach((item) => {
      filters.push({ ...item, field: "status" });
    });
  }
  if (searchParams.startDate || searchParams.endDate)
    filters.push(
      getDateFilterValue({
        startDate: searchParams.startDate || undefined,
        endDate: searchParams.endDate || undefined,
      }),
    );
  return filters;
};

export const DetailProductionPlanOrderFilterChips: FC<
  DetailProductionPlanOrderFilterChipsProps
> = ({ filterParams, setCustomParams, handleClearFilter }) => {
  const handleDeleteFilter =
    (
      item: OptionSelect & {
        field: keyof DetailProductionPlanOrderFilterParams;
      },
    ) =>
    () => {
      const newSearchParams: any = { ...filterParams };
      if (item.field in newSearchParams) {
        const value = newSearchParams[item.field];
        if (isArray(value)) {
          const newValue: OptionSelect<ID>[] = value.filter(
            (itm) => itm.value !== item.value,
          );
          newSearchParams[item.field] = newValue;
        }
      }
      if (item.field === "rangeDate") {
        newSearchParams.startDate = undefined;
        newSearchParams.endDate = undefined;
        newSearchParams.rangeDate = undefined;
      }
      setCustomParams(newSearchParams);
    };

  const chipFilters = useMemo(() => getFilters(filterParams), [filterParams]);

  return (
    chipFilters.length > 0 && (
      <div className="d-flex flex-wrap gap-2">
        {chipFilters.map((item, index) => (
          <div
            className="border border-primary d-flex align-items-center ps-2 pe-1 mr-2 gap-2 mb-2 rounded-pill"
            style={{ height: 28 }}
            key={index}
          >
            <span className="mr-1">{item.label}</span>
            <i
              onClick={handleDeleteFilter(item)}
              className="ri-close-line text-muted fs-16 align-middle me-1 cursor-pointer"
            ></i>
          </div>
        ))}
        <div>
          <Button
            color="primary"
            size="sm"
            onClick={handleClearFilter}
            className="rounded-pill"
          >
            Xóa bộ lọc
          </Button>
        </div>
      </div>
    )
  );
};
