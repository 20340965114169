import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { httpClient } from "app/datasources";
import { AxiosError, AxiosResponse } from "axios";
import { Response } from "../types";
import { Bom } from "./types";

export const REJECT_BOM_URL = (id: ID) => `/api/admin/boms/${id}/reject`;

export enum RejectBomError {}

export const rejectBomErrorMessages = {};

export type RejectBomResponse = Response<Bom>;

export type RejectBomArgs = {
  id: ID;
};

export const rejectBom = ({ id }: RejectBomArgs) => {
  return httpClient.put<RejectBomResponse>(REJECT_BOM_URL(id));
};

export const useRejectBomMutation = (
  opts?: Omit<
    UseMutationOptions<
      AxiosResponse<RejectBomResponse>,
      AxiosError<any>,
      RejectBomArgs
    >,
    "mutationFn"
  >,
) =>
  useMutation<AxiosResponse<RejectBomResponse>, AxiosError<any>, RejectBomArgs>(
    {
      mutationFn: rejectBom,
      mutationKey: [REJECT_BOM_URL],
      ...opts,
    },
  );
