import { useAppTranslation } from "app/hooks";
import { Ticket, TicketStatus } from "app/services/ticket";
import { FC, MouseEvent, useState } from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";

export type TicketAction = "edit" | "delete" | "detail" | "start" | "checkIn";

export interface TicketActionDropdownProps {
  data?: Ticket;
  onAction: (action: TicketAction, data?: Ticket) => void;
}

export const TicketActionDropdown: FC<TicketActionDropdownProps> = ({
  data,
  onAction,
}) => {
  const { buttonText } = useAppTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const handleToggle = () => setIsOpen(!isOpen);

  const handleAction = (action: TicketAction) => () => {
    onAction(action, data);
    setIsOpen(false);
  };

  const isInprogress = data?.status === TicketStatus.InProgress;
  const isCompleted = data?.status === TicketStatus.Completed;
  const isTodo = data?.status === TicketStatus.Todo;

  return (
    <Dropdown
      isOpen={isOpen}
      toggle={(e: MouseEvent) => {
        e.stopPropagation();
        handleToggle();
      }}
      direction="start"
    >
      <DropdownToggle
        tag="button"
        className="btn py-0 px-2 m-0"
        onClick={(e) => {
          const trClosest = e.currentTarget.closest("tr");
          trClosest?.classList.toggle("z-3", !isOpen);
        }}
        onBlur={(e) => {
          const trClosest = e.currentTarget.closest("tr");
          setTimeout(() => trClosest?.classList.remove("z-3"), 100);
        }}
      >
        <i className="ri-more-fill"></i>
      </DropdownToggle>
      <DropdownMenu className="dropdown-menu-end">
        <DropdownItem className="p-0" onClick={handleAction("detail")}>
          <div className="dropdown-item">
            <i className=" ri-arrow-right-s-line text-muted fs-16 align-middle me-1"></i>
            <span className="align-middle">{buttonText.detail}</span>
          </div>
        </DropdownItem>
        {!isCompleted ? (
          <>
            {isTodo ? (
              <DropdownItem className="p-0" onClick={handleAction("start")}>
                <div className="dropdown-item">
                  <i className="ri-play-line text-muted fs-16 align-middle me-1"></i>
                  <span className="align-middle">{buttonText.start}</span>
                </div>
              </DropdownItem>
            ) : null}
            {isInprogress ? (
              <DropdownItem className="p-0" onClick={handleAction("checkIn")}>
                <div className="dropdown-item">
                  <i className="ri-checkbox-line text-muted fs-16 align-middle me-1"></i>
                  <span className="align-middle">{buttonText.checkIn}</span>
                </div>
              </DropdownItem>
            ) : null}
          </>
        ) : null}
        <DropdownItem className="p-0" onClick={handleAction("edit")}>
          <div className="dropdown-item">
            <i className="ri-pencil-line text-muted fs-16 align-middle me-1"></i>
            <span className="align-middle">{buttonText.edit}</span>
          </div>
        </DropdownItem>
        <DropdownItem className="p-0" onClick={handleAction("delete")}>
          <div className="dropdown-item">
            <i className="ri-delete-bin-line text-muted fs-16 align-middle me-1"></i>
            <span className="align-middle">{buttonText.delete}</span>
          </div>
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};
