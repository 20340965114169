import {
  RetrieveComponentsArgs,
  useRetrieveComponentsQuery,
} from "app/services/product";

export const useRetrieveComponents = (args: RetrieveComponentsArgs) => {
  const result = useRetrieveComponentsQuery(args);
  return {
    components: result.data?.data.data,
    isLoadingComponents: result.isLoading,
    ...result,
  };
};
