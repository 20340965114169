import { AvatarRow } from "app/components/molecules/AvatarRow";
import { Button } from "app/components/molecules/Button";
import { useConfirm } from "app/components/molecules/ConfirmationProvider";
import { useAppTranslation } from "app/hooks";
import {
  CheckInPanelTicketFormData,
  CheckInPanelTicketFormModal,
} from "app/modules/ticket/components";
import {
  useCheckInPanelTicket,
  useStartPanelTicket,
} from "app/modules/ticket/hooks";
import { mapCheckInPanelTicket } from "app/modules/ticket/mapper";
import { employeeStorage } from "app/services/auth";
import { Ticket, TicketStatus } from "app/services/ticket";
import { FC, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { usePanelEmployee } from "Routes/PanelEmployeeProtected";

export interface DetailTicketPanelGeneralProps {
  ticket?: Ticket;
}

export const DetailTicketPanelGeneral: FC<DetailTicketPanelGeneralProps> = ({
  ticket,
}) => {
  const { confirm } = useConfirm();
  const { ticketText, productText, buttonText } = useAppTranslation();
  const { id } = useParams();
  const { employee } = usePanelEmployee();
  const navigate = useNavigate();
  const { mutateAsync: start } = useStartPanelTicket({
    id: id as string,
    onSuccess: () => {
      employeeStorage.remove();
      navigate("/");
    },
  });
  const { mutateAsync: checkIn, isPending: isCheckingIn } =
    useCheckInPanelTicket({
      onSuccess: () => {
        navigate(-1);
      },
    });
  const [isCheckInModalOpen, toggleCheckInModal] = useState(false);

  const handleToggleCheckInModal = () => {
    toggleCheckInModal((prevState) => !prevState);
  };
  const handleStart = () => {
    confirm({
      onConfirm: () => start({ id: id as ID, employeeId: employee?.id as ID }),
      icon: null,
      severity: "success",
      title: ticketText.startTitle,
      description: ticketText.startDescription,
    });
  };

  const handleCheckIn = ({ note, quantity }: CheckInPanelTicketFormData) => {
    checkIn(
      mapCheckInPanelTicket({
        employeeId: employee?.id as ID,
        ticketId: id as ID,
        quantity,
        note,
      }),
    );
  };

  const isTodo = ticket?.status === TicketStatus.Todo;
  const isInProgress = ticket?.status === TicketStatus.InProgress;

  return (
    <>
      <div className="d-flex flex-column align-items-center justify-content-center">
        <AvatarRow
          avatarUrl={ticket?.workStep.product.productImage?.attachmentUrl}
          name={ticket?.workStep.product.nameVn}
          size="lg"
          isClickable
          isHidenName
        />
        <div className="mt-3">
          <h3 className="mb-1">{ticket?.workStep.product.nameVn}</h3>
          <h3>
            {productText.field.workStep}: {ticket?.workStep.step.name}
          </h3>
          <h3>
            {ticketText.field.quantity}: {ticket?.quantity}
          </h3>
        </div>
        {isTodo && (
          <Button
            color="primary"
            size="lg"
            className="mt-3"
            onClick={handleStart}
          >
            {buttonText.start}
          </Button>
        )}
        {isInProgress && (
          <Button color="primary" onClick={handleToggleCheckInModal}>
            {buttonText.checkIn}
          </Button>
        )}
      </div>
      <CheckInPanelTicketFormModal
        open={isCheckInModalOpen}
        onToggle={handleToggleCheckInModal}
        onSubmit={handleCheckIn}
        submitting={isCheckingIn}
        defaultValues={{
          quantity: null,
          note: "",
          totalQuantity: ticket?.quantity,
          remainingQuantity: ticket?.remainingQuantity,
        }}
      />
    </>
  );
};
