import { Loading } from "app/components/molecules/Loading";
import { useAppTranslation } from "app/hooks";
import { Component, ComponentWithChildren } from "app/services/product";
import { FC } from "react";
import { DefaultValues } from "react-hook-form";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { useRetrieveComponentById } from "../../hooks";
import { mapDefaultComponent } from "../../mapper";
import {
  ComponentForm,
  ComponentFormData,
} from "../ComponentForm/ComponentForm";

export interface ComponentFormModalProps {
  data?: ComponentWithChildren | Component;
  open: boolean;
  onCancel: () => void;
  onSubmit: (data: ComponentFormData) => void;
  submitting?: boolean;
  defaultValues?: DefaultValues<ComponentFormData>;
  edit?: boolean;
  isAddParent?: boolean;
}

export const ComponentFormModal: FC<ComponentFormModalProps> = ({
  onSubmit,
  onCancel,
  open,
  submitting,
  data,
  edit,
  isAddParent = false,
}) => {
  const { productText } = useAppTranslation();
  const { component, isLoadingComponent } = useRetrieveComponentById(
    {
      id: data?.id as string,
    },
    { enabled: !!data?.id },
  );
  const title = edit
    ? productText.editComponentTitle
    : productText.addComponentTitle;
  return (
    <Modal isOpen={open} toggle={onCancel}>
      <ModalHeader className="modal-title" toggle={onCancel}>
        {title}
      </ModalHeader>
      <ModalBody>
        {!isLoadingComponent ? (
          <ComponentForm
            onSubmit={onSubmit}
            onCancel={onCancel}
            submitting={submitting}
            defaultValues={
              edit ? mapDefaultComponent(component) : mapDefaultComponent()
            }
            parent={data || null}
            isAddParent={isAddParent}
            edit={edit}
          />
        ) : (
          <Loading />
        )}
      </ModalBody>
    </Modal>
  );
};
