import { closestCenter, DndContext } from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "app/components/molecules/Button";
import { FormHelper } from "app/components/molecules/FormHelper";
import { FormInput } from "app/components/molecules/FormInput";
import { FormLabel } from "app/components/molecules/FormLabel";
import { AvatarUpload } from "app/components/organisms/AvatarUpload/AvatarUpload";
import { FileUpload } from "app/components/organisms/FileUpload";
import { yupSchema } from "app/helpers/schema";
import { useAppTranslation, useGetEnableSubmitButton } from "app/hooks";
import { FilePreview } from "app/models";
import { MasterDataInfiniteSelect } from "app/modules/masterData/components/MasterDataInfinitySelect/MasterDataInfinitySelect";
import { MasterData, MasterDataType } from "app/services/masterData";
import { Component, ComponentWithChildren } from "app/services/product";
import { OptionSelect } from "app/services/types";
import { FC, FormEvent } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { Alert, Card, CardBody, Col, Form, FormGroup, Row } from "reactstrap";
import * as Yup from "yup";
import { ComponentSelect } from "../ComponentSelect";
import { StepCard } from "./StepCard";

export interface ComponentFormProps {
  defaultValues?: ComponentFormData;
  onSubmit: (data: ComponentFormData) => void;
  submitting?: boolean;
  onCancel?: () => void;
  edit?: boolean;
  isAddParent?: boolean;
  parent: ComponentWithChildren | Component | null;
}

export interface DetailStepItem {
  id?: ID | null;
  step: OptionSelect<ID> | null;
  quantity: number | null;
  estimateTime: number | null;
  order: number | null;
}

export interface ComponentFormData {
  parent: OptionSelect | null;
  name: string;
  type: OptionSelect<ID> | null;
  frameType: OptionSelect<ID> | null;
  workpieceType: OptionSelect<ID> | null;
  thicknessCm: number | null;
  avatars: FilePreview[];
  workSteps: DetailStepItem[];
  lengthOfBoxCm: number | null;
  widthOfBoxCm: number | null;
  lengthOfDetailCm: number | null;
  quantity: number | null;
  description: string;
  images: FilePreview[];
  documents: FilePreview[];
}

export const ComponentForm: FC<ComponentFormProps> = ({
  onSubmit,
  defaultValues,
  submitting,
  onCancel,
  edit,
  isAddParent,
  parent,
}) => {
  const { componentText, buttonText } = useAppTranslation();
  const {
    control,
    watch,
    setValue,
    handleSubmit,
    formState: { isDirty, isValid },
  } = useForm<ComponentFormData>({
    mode: "onChange",
    resolver: yupResolver(
      Yup.object({
        parent: yupSchema.objectNotRequired(),
        name: yupSchema.stringRequired("component.error.nameRequired"),
        type: yupSchema.objectRequired("component.error.typeRequired"),
        workpieceType: yupSchema.objectNotRequired(),
        frameType: yupSchema.objectNotRequired(),
        thicknessCm: yupSchema.numberMoreThanNotRequired(
          0,
          "component.error.thicknessCmMoreThanZero",
        ),
        lengthOfBoxCm: yupSchema.numberMoreThanNotRequired(
          0,
          "component.error.lengthOfBoxCmMoreThanZero",
        ),
        widthOfBoxCm: yupSchema.numberMoreThanNotRequired(
          0,
          "component.error.widthOfBoxCmMoreThanZero",
        ),
        lengthOfDetailCm: yupSchema.numberMoreThanNotRequired(
          0,
          "component.error.lengthOfDetailCmMoreThanZero",
        ),
        avatars: yupSchema.fileNotRequired(),
        workSteps: Yup.array()
          .of(
            Yup.object({
              step: yupSchema.objectRequired(
                "component.error.workStepRequired",
              ),
              estimateTime: yupSchema.numberMoreThanOrEqualNotRequired(
                0,
                "component.error.estimateTimeMoreThanOrEqualZero",
              ),
              quantity: yupSchema.numberMoreThanNotRequired(
                0,
                "component.error.quantityMoreThanZero",
              ),
              order: yupSchema.numberMoreThanRequired(0, "", ""),
            }),
          )
          .defined(),
        quantity: yupSchema.numberMoreThanRequired(
          0,
          "component.error.quantityMoreThanZero",
          "component.error.quantityRequired",
        ),
        description: yupSchema.stringNotRequired(),
        images: yupSchema.fileNotRequired(),
        documents: yupSchema.fileNotRequired(),
      }),
    ),
    defaultValues,
  });

  const isEnableSubmit = useGetEnableSubmitButton({
    isDirty,
    isValid,
    submitting,
  });

  const submit = async (e: FormEvent) => {
    e.preventDefault();
    handleSubmit(onSubmit)();
  };

  const handleSetValue =
    (name: keyof ComponentFormData) => (data: MasterData) => {
      setValue(
        name,
        {
          value: data.id,
          label: data.name,
        },
        { shouldDirty: true },
      );
    };

  const {
    fields: _workStepsField,
    remove: removeStep,
    append: appendStep,
  } = useFieldArray({
    control,
    name: "workSteps",
  });

  const watchProductSteps = watch("workSteps");
  const workStepsField = _workStepsField.map((field, index) => {
    return {
      ...field,
      ...watchProductSteps[index],
    };
  });

  const handleDragEnd = (event: any) => {
    const { active, over } = event;
    if (active.id !== over.id) {
      const oldIndex = workStepsField.findIndex(
        (item) => item.id === active.id,
      );
      const newIndex = workStepsField.findIndex((item) => item.id === over.id);
      const newOrder = arrayMove(workStepsField, oldIndex, newIndex).map(
        (item, index) => ({
          ...item,
          order: index + 1,
        }),
      );
      setValue("workSteps", newOrder, { shouldDirty: true });
    }
  };

  return (
    <Form onSubmit={submit} id="product-form">
      {!edit ? (
        isAddParent ? (
          <Col xs={12}>
            <Alert color="info" fade={false}>
              {componentText.createNote}
            </Alert>
            <FormGroup>
              <FormLabel htmlFor="parent" className="form-label">
                {componentText.parentTitle}
              </FormLabel>
              <Controller
                name="parent"
                control={control}
                render={({ field, fieldState }) => (
                  <>
                    <ComponentSelect
                      name="parent"
                      value={field.value}
                      inputId="customer"
                      onChange={field.onChange}
                      isInvalid={fieldState.invalid}
                    />
                    <FormHelper message={fieldState.error?.message} />
                  </>
                )}
              />
            </FormGroup>
          </Col>
        ) : (
          <h6>
            {componentText.createFor} {parent?.name} - {parent?.code}
          </h6>
        )
      ) : null}
      <h5 className="py-2 fw-semibold">
        {componentText.detail.generalInfomation}
      </h5>
      <Row>
        <div className="d-flex gap-4">
          <FormGroup>
            <FormLabel htmlFor="avatars" className="form-label">
              {componentText.field.avatar}
            </FormLabel>
            <Controller
              name="avatars"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <AvatarUpload
                    value={field.value || []}
                    onChange={field.onChange}
                  />
                  <FormHelper message={fieldState.error?.message} />
                </>
              )}
            />
          </FormGroup>
          <Row className="flex-grow-1">
            <Col xs={12}>
              <FormGroup>
                <FormLabel required htmlFor="name" className="form-label">
                  {componentText.field.name}
                </FormLabel>
                <Controller
                  name="name"
                  control={control}
                  render={({ field, fieldState }) => (
                    <>
                      <FormInput
                        {...field}
                        type="text"
                        className="form-control"
                        id="name"
                        invalid={!!fieldState.invalid}
                      />
                      <FormHelper message={fieldState.error?.message} />
                    </>
                  )}
                />
              </FormGroup>
            </Col>
            <Col xs={12}>
              <FormGroup>
                <FormLabel required htmlFor="type" className="form-label">
                  {componentText.field.type}
                </FormLabel>
                <Controller
                  name="type"
                  control={control}
                  render={({ field, fieldState }) => (
                    <>
                      <MasterDataInfiniteSelect
                        key="type"
                        name="type"
                        inputId="type"
                        value={field.value}
                        onChange={field.onChange}
                        isInvalid={fieldState.invalid}
                        retrieveKeys={{ type: MasterDataType.ProductType }}
                      />
                      <FormHelper message={fieldState.error?.message} />
                    </>
                  )}
                />
              </FormGroup>
            </Col>
          </Row>
        </div>
        <Col xs={12}>
          <FormGroup>
            <FormLabel required htmlFor={`quantity`} className="form-label">
              {componentText.field.quantity}
            </FormLabel>
            <Controller
              name={`quantity` as const}
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <FormInput
                    {...field}
                    value={field.value ?? ""}
                    onChange={(value) => {
                      field.onChange(
                        value.target.value === ""
                          ? null
                          : Number(value.target.value),
                      );
                    }}
                    type="number"
                    className="form-control"
                    id={`quantity`}
                    invalid={!!fieldState.invalid}
                  />
                  <FormHelper message={fieldState.error?.message} />
                </>
              )}
            />
          </FormGroup>
        </Col>
        <Col xs={12} md={6}>
          <FormGroup>
            <FormLabel htmlFor="frameType" className="form-label">
              {componentText.field.frameType}
            </FormLabel>
            <Controller
              name="frameType"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <MasterDataInfiniteSelect
                    isCreatable
                    key="frameType"
                    name="frameType"
                    inputId="frameType"
                    value={field.value}
                    onChange={field.onChange}
                    isInvalid={fieldState.invalid}
                    retrieveKeys={{ type: MasterDataType.FrameType }}
                    onCreateSuccess={handleSetValue("frameType")}
                  />
                  <FormHelper message={fieldState.error?.message} />
                </>
              )}
            />
          </FormGroup>
        </Col>
        <Col xs={12} md={6}>
          <FormGroup>
            <FormLabel htmlFor="workpieceType" className="form-label">
              {componentText.field.workpieceType}
            </FormLabel>
            <Controller
              name="workpieceType"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <MasterDataInfiniteSelect
                    isCreatable
                    key="workpieceType"
                    name="workpieceType"
                    inputId="workpieceType"
                    value={field.value}
                    onChange={field.onChange}
                    isInvalid={fieldState.invalid}
                    retrieveKeys={{ type: MasterDataType.WorkPieceType }}
                    onCreateSuccess={handleSetValue("workpieceType")}
                  />
                  <FormHelper message={fieldState.error?.message} />
                </>
              )}
            />
          </FormGroup>
        </Col>
        <Col xs={12} md={6}>
          <FormGroup>
            <FormLabel htmlFor="lengthOfBoxCm" className="form-label">
              {componentText.field.lengthOfBoxCm}
            </FormLabel>
            <Controller
              name="lengthOfBoxCm"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <FormInput
                    {...field}
                    value={field.value ?? ""}
                    onChange={(value) => {
                      field.onChange(
                        value.target.value === ""
                          ? null
                          : Number(value.target.value),
                      );
                    }}
                    type="number"
                    className="form-control"
                    id="lengthOfBoxCm"
                    invalid={!!fieldState.invalid}
                  />
                  <FormHelper message={fieldState.error?.message} />
                </>
              )}
            />
          </FormGroup>
        </Col>
        <Col xs={12} md={6}>
          <FormGroup>
            <FormLabel htmlFor="widthOfBoxCm" className="form-label">
              {componentText.field.widthOfBoxCm}
            </FormLabel>
            <Controller
              name="widthOfBoxCm"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <FormInput
                    {...field}
                    value={field.value ?? ""}
                    onChange={(value) => {
                      field.onChange(
                        value.target.value === ""
                          ? null
                          : Number(value.target.value),
                      );
                    }}
                    type="number"
                    className="form-control"
                    id="widthOfBoxCm"
                    invalid={!!fieldState.invalid}
                  />
                  <FormHelper message={fieldState.error?.message} />
                </>
              )}
            />
          </FormGroup>
        </Col>
        <Col xs={12} md={6}>
          <FormGroup>
            <FormLabel htmlFor="lengthOfDetailCm" className="form-label">
              {componentText.field.lengthOfDetailCm}
            </FormLabel>
            <Controller
              name="lengthOfDetailCm"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <FormInput
                    {...field}
                    value={field.value ?? ""}
                    onChange={(value) => {
                      field.onChange(
                        value.target.value === ""
                          ? null
                          : Number(value.target.value),
                      );
                    }}
                    type="number"
                    className="form-control"
                    id="lengthOfDetailCm"
                    invalid={!!fieldState.invalid}
                  />
                  <FormHelper message={fieldState.error?.message} />
                </>
              )}
            />
          </FormGroup>
        </Col>
        <Col xs={12} md={6}>
          <FormGroup>
            <FormLabel htmlFor="thicknessCm" className="form-label">
              {componentText.field.thicknessCm}
            </FormLabel>
            <Controller
              name="thicknessCm"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <FormInput
                    {...field}
                    value={field.value ?? ""}
                    onChange={(value) => {
                      field.onChange(
                        value.target.value === ""
                          ? null
                          : Number(value.target.value),
                      );
                    }}
                    type="number"
                    className="form-control"
                    id="thicknessCm"
                    invalid={!!fieldState.invalid}
                  />
                  <FormHelper message={fieldState.error?.message} />
                </>
              )}
            />
          </FormGroup>
        </Col>
        <Col xs={12}>
          <FormGroup>
            <FormLabel htmlFor="description" className="form-label">
              {componentText.field.description}
            </FormLabel>
            <Controller
              name="description"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <FormInput
                    {...field}
                    type="text"
                    className="form-control"
                    id="description"
                    invalid={!!fieldState.error}
                  />
                  <FormHelper message={fieldState.error?.message} />
                </>
              )}
            />
          </FormGroup>
        </Col>
        <h5 className="py-2 fw-semibold">{componentText.field.workStep}</h5>
        <Col lg={12}>
          <Card className="card-gray">
            <CardBody>
              <DndContext
                collisionDetection={closestCenter}
                onDragEnd={handleDragEnd}
                modifiers={[restrictToVerticalAxis]}
              >
                <SortableContext
                  items={workStepsField.map((item) => item.id as ID)}
                  strategy={verticalListSortingStrategy}
                >
                  {workStepsField.map((field, index) => (
                    <StepCard
                      key={field.id}
                      index={index}
                      field={field}
                      control={control}
                      removeStep={removeStep}
                      workStepsField={workStepsField}
                    />
                  ))}
                </SortableContext>
              </DndContext>
              <Button
                color="primary"
                size="sm"
                className="mb-4"
                onClick={() =>
                  appendStep({
                    step: null,
                    estimateTime: null,
                    quantity: null,
                    order: workStepsField.length + 1,
                  })
                }
              >
                {buttonText.add}
              </Button>
            </CardBody>
          </Card>
        </Col>
        <Col xs={12} md={6}>
          <FormGroup>
            <FormLabel htmlFor="images" className="form-label">
              {componentText.field.image}
            </FormLabel>
            <Controller
              name="images"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <FileUpload
                    value={field.value || []}
                    onChange={field.onChange}
                  />
                  <FormHelper message={fieldState.error?.message} />
                </>
              )}
            />
          </FormGroup>
        </Col>
        <Col xs={12} md={6}>
          <FormGroup>
            <FormLabel htmlFor="documents" className="form-label">
              {componentText.field.document}
            </FormLabel>
            <Controller
              name="documents"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <FileUpload
                    value={field.value || []}
                    type="document"
                    onChange={field.onChange}
                  />
                  <FormHelper message={fieldState.error?.message} />
                </>
              )}
            />
          </FormGroup>
        </Col>
        <Col xs={12}>
          <div className="hstack gap-2 justify-content-end">
            {onCancel ? (
              <Button color="light" onClick={onCancel}>
                {buttonText.cancel}
              </Button>
            ) : null}
            <Button
              disabled={!isEnableSubmit}
              type="submit"
              color="primary"
              loading={submitting}
            >
              {buttonText.save}
            </Button>
          </div>
        </Col>
      </Row>
    </Form>
  );
};
