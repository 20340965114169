import { useQueryClient } from "@tanstack/react-query";
import { errorMessageMapper } from "app/helpers";
import useToastify from "app/hooks/useToastify";
import {
  addProductMaterialErrorMessages,
  AddProductMaterialResponse,
  RETRIEVE_PRODUCT_STRUCTURE_BY_ID_URL,
  useAddProductMaterialMutation,
} from "app/services/product";
import { AxiosResponse } from "axios";

interface UseAddProductMaterialProps {
  onSuccess?: (data?: AxiosResponse<AddProductMaterialResponse, any>) => void;
  onError?: () => void;
  id?: ID;
}

export const useAddProductMaterial = ({
  onError: handleError,
  onSuccess: handleSuccess,
  id,
}: UseAddProductMaterialProps = {}) => {
  const toast = useToastify();
  const queryClient = useQueryClient();
  return useAddProductMaterialMutation({
    onSuccess: (data) => {
      toast.success();
      if (id)
        queryClient.invalidateQueries({
          queryKey: [RETRIEVE_PRODUCT_STRUCTURE_BY_ID_URL(id)],
        });
      handleSuccess?.(data);
    },
    onError: (error) => {
      toast.error(
        errorMessageMapper(
          addProductMaterialErrorMessages,
          error.response?.data.message,
        ),
      );
      handleError?.();
    },
  });
};
