import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { httpClient } from "app/datasources";
import { AxiosError, AxiosResponse } from "axios";

export const EXPORT_PRODUCT_TEMPLATE_URL = "/api/admin/product-template";

export enum ExportProductTemplateError {}

export const exportProductTemplateErrorMessages = {};

export type ExportProductTemplateResponse = any;

export const exportProductTemplate = () => {
  return httpClient.get<ExportProductTemplateResponse>(
    EXPORT_PRODUCT_TEMPLATE_URL,
    {
      responseType: "blob",
    },
  );
};

export const useExportProductTemplateMutation = (
  opts?: Omit<
    UseMutationOptions<
      AxiosResponse<ExportProductTemplateResponse>,
      AxiosError<any>
    >,
    "queryKey" | "queryFn"
  >,
) =>
  useMutation<AxiosResponse<ExportProductTemplateResponse>, AxiosError<any>>({
    mutationFn: exportProductTemplate,
    mutationKey: [EXPORT_PRODUCT_TEMPLATE_URL],
    ...opts,
  });
