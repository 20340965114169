import { FileDTO, FilePreview, WithID } from "app/models";
import {
  AddProductMaterialArgs,
  Component,
  CreateComponentArgs,
  CreateProductArgs,
  Product,
  ProductMaterial,
  UpdateComponentArgs,
  UpdateProductArgs,
  UpdateProductMaterialArgs,
} from "app/services/product";
import { CommonProperties } from "app/services/types";
import { ProductFormData } from "./components";
import { ComponentFormData } from "./components/ComponentForm";
import { ProductMaterialFormData } from "./components/ProductMaterialFormModal/ProductMaterialForm";
import { sortProductStepOrder } from "./helpers";

export const mapper = ({
  code,
  countryOfOrigin,
  description,
  group,
  heightCm,
  lengthCm,
  nameEn,
  nameVn,
  seatSizeCm,
  unit,
  widthCm,
  color,
  imageId,
  workSteps,
}: ProductFormData & { imageId?: ID }): CommonProperties<
  CreateProductArgs,
  UpdateProductArgs
> => {
  return {
    code,
    nameEn: nameEn || null,
    nameVn,
    countryOfOrigin,
    description: description || null,
    groupId: group?.value || null,
    unitId: unit?.value as ID,
    heightCm: heightCm || null,
    lengthCm: lengthCm || null,
    seatSizeCm: seatSizeCm || null,
    widthCm: widthCm || null,
    color: color || null,
    imageId: imageId || null,
    workSteps: workSteps.map((step) => ({
      stepId: step.step?.value as ID,
      id: !Number.isNaN(Number(step.id)) ? step.id : undefined,
      estimateTime: step.estimateTime || null,
      quantity: step.quantity || null,
      order: step.order as number,
    })),
  };
};

export const mapCreateProduct = ({
  files,
  ...data
}: ProductFormData & { imageId?: ID; files?: ID[] }): CreateProductArgs => {
  return { fileIds: files, ...mapper(data) };
};

export const mapUpdateProduct = ({
  id,
  deleteFileIds,
  files,
  ...data
}: WithID<ProductFormData> & {
  imageId?: ID;
  files?: ID[];
  deleteFileIds?: ID[];
}): UpdateProductArgs => {
  return { id, deleteFileIds, addFileIds: files, ...mapper(data) };
};

export const mapDefaultProduct = ({
  code,
  countryOfOrigin,
  description,
  productGroup,
  heightCm,
  lengthCm,
  nameEn,
  nameVn,
  seatSizeCm,
  productUnit,
  widthCm,
  color,
  productImage,
  workSteps,
  images,
  documents,
}: Partial<Product> & {
  images?: FileDTO[];
  documents?: FileDTO[];
} = {}): ProductFormData => {
  return {
    code: code || "",
    nameEn: nameEn || "",
    nameVn: nameVn || "",
    description: description || "",
    countryOfOrigin: countryOfOrigin || "",
    group: productGroup
      ? { label: productGroup.name, value: productGroup.id }
      : null,
    unit: productUnit
      ? { label: productUnit.name, value: productUnit.id }
      : null,
    heightCm: heightCm || null,
    lengthCm: lengthCm || null,
    seatSizeCm: seatSizeCm || "",
    widthCm: widthCm || null,
    color: color || "",
    avatars: productImage?.id
      ? ([
          {
            id: productImage?.id || "",
            preview: productImage?.attachmentUrl,
            name: productImage?.fileName,
          },
        ] as FilePreview[])
      : [],
    workSteps:
      sortProductStepOrder(
        workSteps?.filter((i) => i.componentId === null) || [],
      )?.map((step) => ({
        id: step.id,
        step: {
          label: step.step.name || "",
          value: step.step.id,
        },
        estimateTime: step.estimateTime || null,
        quantity: step.quantity || null,
        order: step.order,
      })) || [],
    images:
      images?.map(
        (image) =>
          ({
            id: image?.id || "",
            preview: image?.attachmentUrl,
            name: image?.fileName,
          }) as FilePreview,
      ) || [],
    documents:
      documents?.map(
        (document) =>
          ({
            id: document?.id || "",
            preview: document?.attachmentUrl,
            name: document?.fileName,
          }) as FilePreview,
      ) || [],
  };
};

export const mapperComponent = ({
  name,
  type,
  imageId,
  workSteps,
  frameType,
  thicknessCm,
  workpieceType,
  lengthOfBoxCm,
  lengthOfDetailCm,
  widthOfBoxCm,
  quantity,
  description,
}: ComponentFormData & { imageId?: ID }): CommonProperties<
  CreateComponentArgs,
  UpdateComponentArgs
> => {
  return {
    parentId: null,
    quantity: Number(quantity),
    name,
    typeId: type?.value || null,
    imageId: imageId || null,
    workSteps: workSteps.map((step) => ({
      stepId: step.step?.value as ID,
      id: !Number.isNaN(Number(step.id)) ? step.id : undefined,
      estimateTime: step.estimateTime || null,
      quantity: step.quantity || null,
      order: step.order as number,
    })),
    frameTypeId: frameType?.value || null,
    thicknessCm: thicknessCm || null,
    workpieceTypeId: workpieceType?.value || null,
    lengthOfBoxCm: lengthOfBoxCm || null,
    lengthOfDetailCm: lengthOfDetailCm || null,
    widthOfBoxCm: widthOfBoxCm || null,
    description: description || null,
  };
};

export const mapCreateComponent = ({
  productId,
  files,
  ...data
}: ComponentFormData & {
  imageId?: ID;
  productId: ID;
  files?: ID[];
}): CreateComponentArgs => {
  return {
    ...mapperComponent(data),
    fileIds: files,
    productId,
    parentId: data.parent?.value || null,
  };
};

export const mapUpdateComponent = ({
  id,
  deleteFileIds = [],
  files = [],
  ...data
}: WithID<ComponentFormData> & {
  imageId?: ID;
  files?: ID[];
  deleteFileIds?: ID[];
}): UpdateComponentArgs => {
  return {
    ...mapperComponent(data),
    id,
    deleteFileIds,
    addFileIds: files,
  };
};

export const mapDefaultComponent = ({
  description,
  name,
  componentImage,
  workSteps,
  frameType,
  thicknessCm,
  workpieceType,
  lengthOfBoxCm,
  lengthOfDetailCm,
  widthOfBoxCm,
  quantity,
  type,
  images,
  documents,
}: Partial<Component> & {
  images?: FileDTO[];
  documents?: FileDTO[];
} = {}): ComponentFormData => {
  return {
    parent: null,
    quantity: quantity || null,
    name: name || "",
    description: description || "",
    type: type ? { label: type.name, value: type.id } : null,
    avatars: componentImage?.id
      ? ([
          {
            id: componentImage?.id || "",
            preview: componentImage?.attachmentUrl,
            name: componentImage?.fileName,
          },
        ] as FilePreview[])
      : [],
    workSteps:
      sortProductStepOrder(workSteps || []).map((step) => ({
        id: step.id,
        step: {
          label: step.step.name || "",
          value: step.step.id,
        },
        estimateTime: step.estimateTime || null,
        quantity: step.quantity || null,
        order: step.order,
      })) || [],
    frameType: frameType
      ? { label: frameType.name, value: frameType.id }
      : null,
    thicknessCm: thicknessCm || null,
    workpieceType: workpieceType
      ? { label: workpieceType.name, value: workpieceType.id }
      : null,
    lengthOfBoxCm: lengthOfBoxCm || null,
    lengthOfDetailCm: lengthOfDetailCm || null,
    widthOfBoxCm: widthOfBoxCm || null,
    images:
      images?.map(
        (image) =>
          ({
            id: image?.id || "",
            preview: image?.attachmentUrl,
            name: image?.fileName,
          }) as FilePreview,
      ) || [],
    documents:
      documents?.map(
        (document) =>
          ({
            id: document?.id || "",
            preview: document?.attachmentUrl,
            name: document?.fileName,
          }) as FilePreview,
      ) || [],
  };
};

export const mapperProductMaterial = ({
  material,
  note,
  quantity,
}: ProductMaterialFormData): CommonProperties<
  AddProductMaterialArgs,
  UpdateProductMaterialArgs
> => {
  return {
    materialId: material?.value as ID,
    note,
    quantity: Number(quantity),
  };
};

export const mapCreateProductMaterial = ({
  productId,
  ...data
}: ProductMaterialFormData & { productId: ID }): AddProductMaterialArgs => {
  return {
    ...mapperProductMaterial(data),
    productId,
    componentId: data.parent?.value || null,
  };
};

export const mapUpdateProductMaterial = ({
  id,
  ...data
}: WithID<ProductMaterialFormData> & { id: ID }): UpdateProductMaterialArgs => {
  return {
    ...mapperProductMaterial(data),
    id,
  };
};

export const mapDefaultProductMaterial = ({
  quantity,
  material,
  note,
}: Partial<ProductMaterial>): ProductMaterialFormData => {
  return {
    parent: null,
    material: material
      ? { label: material.name || "", value: material.id }
      : null,
    quantity: quantity || null,
    note: note || "",
  };
};
