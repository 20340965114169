import { ColumnDef } from "@tanstack/react-table";
import { Chip } from "app/components/atoms/Chip";
import { AvatarRow } from "app/components/molecules/AvatarRow";
import { Button } from "app/components/molecules/Button";
import { useConfirm } from "app/components/molecules/ConfirmationProvider";
import { DataTable } from "app/components/organisms/DataTable";
import ListPage from "app/components/templates/ListPage";
import { useAppTranslation } from "app/hooks";
import useAppSearch from "app/hooks/useAppSearch";
import { Source } from "app/models";
import { getUploadedFileIds } from "app/modules/file/helpers";
import {
  ListProductAction,
  ListProductActionDropdown,
  ProductAction,
  ProductActionDropdown,
} from "app/modules/product/components";
import {
  UploadProductExcelFormData,
  UploadProductExcelFormModal,
} from "app/modules/product/components/UploadProductExcelModal/UploadProductExcelModal";
import {
  useDeleteProduct,
  useExportProductTemplate,
  useImportProduct,
  useRetrieveProducts,
} from "app/modules/product/hooks";
import { useActiveProduct } from "app/modules/product/hooks/useActiveProduct";
import { useDeactiveProduct } from "app/modules/product/hooks/useDeactiveProduct";
import { useDeleteFile, useUploadFiles } from "app/services/file";
import { Product } from "app/services/product";
import BreadCrumb from "Components/Common/BreadCrumb";
import "gridjs/dist/theme/mermaid.css";
import { FC, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "reactstrap";
import { ListProductFilter } from "./ListProductFilter";

interface ListProductProps {}

const ListProduct: FC<ListProductProps> = () => {
  const { confirm } = useConfirm();
  const navigate = useNavigate();
  const rowSelected = useRef<Product | null>(null);
  const { productText, confirmText } = useAppTranslation();

  const { page, limit, searchString, setPage, setLimit, setSearchString } =
    useAppSearch({
      initialPage: 1,
      initialLimit: 20,
      initialSearchString: "",
    });

  const { products, isLoadingProducts } = useRetrieveProducts({
    limit,
    page,
    searchString: searchString || undefined,
  });

  const { mutateAsync: deleteProduct } = useDeleteProduct();

  const { mutateAsync: activeProduct } = useActiveProduct({
    id: rowSelected.current?.id,
  });

  const { mutateAsync: deactiveProduct } = useDeactiveProduct({
    id: rowSelected.current?.id,
  });

  const handleActionClick = (action: ProductAction, data?: Product) => {
    if (data) {
      rowSelected.current = data;
    }
    switch (action) {
      case "active":
        if (data) activeProduct({ id: data.id });
        break;
      case "deactive":
        if (data) deactiveProduct({ id: data.id });
        break;
      case "edit":
        if (data) {
          navigate(`/product/${data.id}/update`);
        }
        break;
      case "detail":
        if (data) {
          navigate(`/product/${data.id}`);
        }
        break;
      case "delete":
        if (data?.id) {
          confirm({
            title: confirmText.deleteTitle(productText.title),
            description: confirmText.deleteContent(productText.title),
            onConfirm: () => deleteProduct({ id: data.id }),
          });
        }
        break;
      default:
        break;
    }
  };

  const columns: ColumnDef<Product>[] = useMemo(
    () => [
      {
        header: productText.field.nameVn,
        accessorKey: "nameVn",
        size: 300,
        cell: ({ row }) => (
          <AvatarRow
            name={row.original.nameVn}
            avatarUrl={row.original.productImage?.attachmentUrl}
          />
        ),
      },
      {
        header: productText.field.code,
        accessorKey: "code",
        size: 200,
      },
      {
        header: productText.field.group,
        accessorKey: "productGroup",
        cell: ({ row }) =>
          row.original.productGroup?.name ? (
            <Chip
              size="sm"
              color={row.original.productGroup?.colorCode}
              label={row.original.productGroup?.name}
            />
          ) : (
            "-"
          ),
      },
      {
        header: productText.field.unit,
        accessorKey: "productUnit",
        cell: ({ row }) =>
          row.original.productUnit?.name ? (
            <Chip
              size="sm"
              color={row.original.productUnit?.colorCode}
              label={row.original.productUnit?.name}
            />
          ) : (
            "-"
          ),
      },
      {
        header: productText.field.nameEn,
        accessorKey: "nameEn",
        size: 200,
        cell: ({ row }) => row.original.nameEn || "-",
      },
      {
        header: productText.field.countryOfOrigin,
        accessorKey: "countryOfOrigin",
        cell: ({ row }) => row.original.countryOfOrigin || "-",
      },
      {
        header: productText.field.status,
        accessorKey: "status",
        cell: ({ row }) =>
          row.original.isActive ? "Đang hoạt động" : "Không hoạt động",
      },
      {
        header: productText.field.description,
        accessorKey: "description",
        cell: ({ row }) => row.original.description || "-",
      },
      {
        header: productText.field.lengthCm,
        accessorKey: "lengthCm",
        cell: ({ row }) => row.original.lengthCm || "-",
      },
      {
        header: productText.field.widthCm,
        accessorKey: "widthCm",
        cell: ({ row }) => row.original.widthCm || "-",
      },
      {
        header: productText.field.heightCm,
        accessorKey: "heightCm",
        cell: ({ row }) => row.original.heightCm || "-",
      },
      {
        header: productText.field.seatSizeCm,
        accessorKey: "seatSizeCm",
        cell: ({ row }) => row.original.seatSizeCm || "-",
      },
      {
        header: productText.field.color,
        accessorKey: "color",
        cell: ({ row }) => row.original.color || "-",
      },
      {
        header: "",
        accessorKey: "action",
        size: 40,
        cell: ({ row }) => (
          <ProductActionDropdown
            onAction={handleActionClick}
            data={row.original}
          />
        ),
      },
    ],
    [productText],
  );

  const [isImportModalOpen, toggleImportModal] = useState(false);

  const { mutateAsync: exportProductTemplate } = useExportProductTemplate();

  const { mutateAsync: upload, isPending: isUploading } = useUploadFiles();

  const { mutateAsync: deleteFile } = useDeleteFile();

  const { mutateAsync: importProduct, isPending: isImporting } =
    useImportProduct({
      onSuccess: () => {
        toggleImportModal(false);
      },
    });

  const handleUpload = async (data: UploadProductExcelFormData) => {
    const uploadResult = await getUploadedFileIds({
      data: data.file,
      fileKey: "document",
      source: Source.Product,
      upload,
    });
    await importProduct(
      { filePath: uploadResult?.fileResponse[0].filePath || "" },
      {
        onError() {
          uploadResult?.fileIds.map((id) => {
            deleteFile({ id });
          });
        },
      },
    );
  };

  const handleListActionClick = (action: ListProductAction) => {
    switch (action) {
      case "download-template":
        exportProductTemplate();
        break;
      case "import":
        toggleImportModal(true);
        break;

      default:
        break;
    }
  };

  return (
    <>
      <ListPage>
        <ListPage.BreadCrumb>
          <BreadCrumb title={productText.title} pageTitle="Kingston" />
        </ListPage.BreadCrumb>
        <ListPage.Filter>
          <Row>
            <Col xs={6}>
              <ListProductFilter
                searchString={searchString}
                handleSearchChange={setSearchString}
              />
            </Col>
            <Col xs={6} className="text-end">
              <div className="d-flex justify-content-end gap-2">
                <ListProductActionDropdown onAction={handleListActionClick} />
                <div>
                  <Button
                    color="primary"
                    size="sm"
                    onClick={() => navigate("/product/new")}
                  >
                    {productText.add}
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </ListPage.Filter>
        <ListPage.Main>
          <DataTable<Product>
            columns={columns}
            data={products?.result || []}
            loading={isLoadingProducts}
            page={page}
            limit={limit}
            setPage={setPage}
            setLimit={setLimit}
            total={products?.total || 0}
            onRowClick={(row) => handleActionClick("detail", row)}
            pinLeftColumns={["nameVn", "code"]}
          />
        </ListPage.Main>
      </ListPage>
      {isImportModalOpen && (
        <UploadProductExcelFormModal
          open={isImportModalOpen}
          onToggle={() => toggleImportModal((prev) => !prev)}
          onSubmit={handleUpload}
          onActionClick={handleListActionClick}
          submitting={isImporting || isUploading}
        />
      )}
    </>
  );
};

export default ListProduct;
