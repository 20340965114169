import { WithID } from "app/models";
import {
  CheckInPanelTicketArgs,
  CreateTicketArgs,
  Ticket,
  UpdateTicketArgs,
} from "app/services/ticket";
import { CommonProperties } from "app/services/types";
import { CheckInPanelTicketFormData } from "./components";
import { TicketProductionOrderItemFormData } from "./components/TicketProductionOrderItemFormModal";

export const mapper = ({
  note,
  workStep,
  productionOrderItem,
  quantity,
  workshop,
}: TicketProductionOrderItemFormData): CommonProperties<
  CreateTicketArgs,
  UpdateTicketArgs
> => {
  return {
    note: note || "",
    productionOrderItemId: productionOrderItem?.value as ID,
    quantity: quantity as number,
    workStepId: workStep?.value as ID,
    workshopId: workshop?.value as ID,
  };
};

export const mapCreateTicket = (
  data: TicketProductionOrderItemFormData,
): CreateTicketArgs => {
  return mapper(data);
};

export const mapUpdateTicket = ({
  id,
  ...data
}: WithID<TicketProductionOrderItemFormData>): UpdateTicketArgs => {
  return { id, ...mapper(data) };
};

export const mapDefaultTicket = ({
  note,
  productionOrderItem,
  workStep,
  product,
  quantity,
  workshop,
}: Partial<Ticket> = {}): TicketProductionOrderItemFormData => {
  return {
    note: note || "",
    productionOrderItem:
      productionOrderItem && product
        ? {
            label: product?.nameVn,
            value: productionOrderItem.id,
            product: product?.id || null,
          }
        : null,
    workStep: workStep
      ? {
          label: workStep?.step?.name || "",
          value: workStep?.id,
        }
      : null,
    quantity: quantity || null,
    workshop: workshop
      ? {
          label: workshop.name,
          value: workshop.id,
        }
      : null,
    component: product
      ? {
          value: workStep?.product.id as ID,
          label: workStep?.product.nameVn || "-",
        }
      : null,
  };
};

export const mapCheckInPanelTicket = ({
  note,
  quantity,
  employeeId,
  ticketId,
}: CheckInPanelTicketFormData & {
  employeeId: ID;
  ticketId: ID;
}): CheckInPanelTicketArgs => {
  return {
    ticketId,
    employeeId,
    quantity: quantity as number,
    note: note || null,
  };
};
