import DetailPage from "app/components/templates/DetailPage";
import { useAppTranslation } from "app/hooks";
import { useRetrieveMaterialById } from "app/modules/material/hooks";
import { Material } from "app/services/material";
import BreadCrumb from "Components/Common/BreadCrumb";
import { Outlet, useParams } from "react-router-dom";
import DetailMaterialHeader from "./DetailMaterialHeader";
import { DetailMaterialTabs } from "./DetailMaterialTabs";

const DetailMaterial = () => {
  const { id } = useParams();
  const { materialText } = useAppTranslation();

  const { material, isLoadingMaterial } = useRetrieveMaterialById(
    { id: id as string },
    { enabled: !!id },
  );

  return (
    <DetailPage<Material>
      data={material as Material}
      loading={isLoadingMaterial}
    >
      <DetailPage.BreadCrumb>
        <BreadCrumb
          title={materialText.detail.title}
          pageTitle={materialText.title}
          isBack
        />
      </DetailPage.BreadCrumb>
      <DetailPage.Header>
        <DetailMaterialHeader />
      </DetailPage.Header>
      <DetailPage.Main tabs={<DetailMaterialTabs />}>
        <Outlet />
      </DetailPage.Main>
    </DetailPage>
  );
};

export default DetailMaterial;
