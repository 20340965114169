import { Button } from "app/components/molecules/Button";
import { getDateFilterValue } from "app/helpers/getDateFilterValue";
import { OptionSelect } from "app/services/types";
import { isArray } from "lodash";
import { FC, useMemo } from "react";
import {
  ListEmployeeFilterProps,
  EmployeeFilterParams,
} from "./ListEmployeeFilter";

export interface ListEmployeeFilterChipsProps
  extends Pick<ListEmployeeFilterProps, "filterParams" | "setCustomParams"> {
  handleClearFilter: () => void;
}

const getFilters = (searchParams: EmployeeFilterParams) => {
  const filters: (OptionSelect & {
    field: keyof EmployeeFilterParams;
  })[] = [];
  if (searchParams.departments && searchParams.departments.length > 0) {
    searchParams.departments.forEach((item) => {
      filters.push({ ...item, field: "departments" });
    });
  }
  if (searchParams.jobTitles && searchParams.jobTitles.length > 0) {
    searchParams.jobTitles.forEach((item) => {
      filters.push({ ...item, field: "jobTitles" });
    });
  }
  if (searchParams.skills && searchParams.skills.length > 0) {
    searchParams.skills.forEach((item) => {
      filters.push({ ...item, field: "skills" });
    });
  }
  if (searchParams.gender) {
    filters.push({ ...searchParams.gender, field: "gender" });
  }
  if (searchParams.dateOfJoining || searchParams.dateOfLeaving)
    filters.push(
      getDateFilterValue({
        startDate: searchParams.dateOfJoining || undefined,
        endDate: searchParams.dateOfLeaving || undefined,
      }),
    );
  return filters;
};

export const ListEmployeeFilterChips: FC<ListEmployeeFilterChipsProps> = ({
  filterParams,
  setCustomParams,
  handleClearFilter,
}) => {
  const handleDeleteFilter =
    (item: OptionSelect & { field: keyof EmployeeFilterParams }) => () => {
      const newSearchParams: any = { ...filterParams };
      if (item.field in newSearchParams) {
        const value = newSearchParams[item.field];
        if (isArray(value)) {
          const newValue: OptionSelect<ID>[] = value.filter(
            (itm) => itm.value !== item.value,
          );
          newSearchParams[item.field] = newValue;
        }
      }
      if (item.field === "rangeDate") {
        newSearchParams.dateOfJoining = undefined;
        newSearchParams.dateOfLeaving = undefined;
        newSearchParams.rangeDate = undefined;
      }
      setCustomParams(newSearchParams);
    };

  const chipFilters = useMemo(() => getFilters(filterParams), [filterParams]);

  return (
    chipFilters.length > 0 && (
      <div className="d-flex flex-wrap gap-2">
        {chipFilters.map((item, index) => (
          <div
            className="border border-primary d-flex align-items-center ps-2 pe-1 mr-2 gap-2 mb-2 rounded-pill"
            style={{ height: 28 }}
            key={index}
          >
            <span className="mr-1">{item.label}</span>
            <i
              onClick={handleDeleteFilter(item)}
              className="ri-close-line text-muted fs-16 align-middle me-1 cursor-pointer"
            ></i>
          </div>
        ))}
        <div>
          <Button
            color="primary"
            size="sm"
            onClick={handleClearFilter}
            className="rounded-pill"
          >
            Xóa bộ lọc
          </Button>
        </div>
      </div>
    )
  );
};
