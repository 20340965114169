import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { httpClient } from "app/datasources";
import { Response } from "app/services/types";
import { AxiosError, AxiosResponse } from "axios";
import { EmployeeIdCode } from "./types";

export const CREATE_EMPLOYEE_ID_CODE_URL = "/api/admin/employee-id-codes";

export enum CreateEmployeeIdCodeError {}

export const createEmployeeIdCodeErrorMessages = {};

export interface CreateEmployeeIdCodeArgs {
  employeeId: ID;
}

export type CreateEmployeeIdCodeResponse = Response<EmployeeIdCode>;

export const createEmployeeIdCode = (args: CreateEmployeeIdCodeArgs) => {
  return httpClient.post<CreateEmployeeIdCodeResponse>(
    CREATE_EMPLOYEE_ID_CODE_URL,
    args,
  );
};

export const useCreateEmployeeIdCodeMutation = (
  opts?: Omit<
    UseMutationOptions<
      AxiosResponse<CreateEmployeeIdCodeResponse>,
      AxiosError<any>,
      CreateEmployeeIdCodeArgs
    >,
    "mutationFn"
  >,
) =>
  useMutation<
    AxiosResponse<CreateEmployeeIdCodeResponse>,
    AxiosError<any>,
    CreateEmployeeIdCodeArgs
  >({
    mutationFn: createEmployeeIdCode,
    mutationKey: [CREATE_EMPLOYEE_ID_CODE_URL],
    ...opts,
  });
