import { useAppTranslation } from "app/hooks";
import { User } from "app/services/user";
import { FC, MouseEvent, useState } from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";

export type UserAction = "delete" | "active" | "deactive";

export interface UserActionDropdownProps {
  data?: User;
  onAction: (action: UserAction, data?: User) => void;
}

export const UserActionDropdown: FC<UserActionDropdownProps> = ({
  data,
  onAction,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleToggle = () => setIsOpen(!isOpen);
  const { buttonText } = useAppTranslation();

  const isActive = data?.status === 1;

  const handleAction = (action: UserAction) => () => {
    onAction(action, data);
    setIsOpen(false);
  };

  return (
    <Dropdown
      isOpen={isOpen}
      toggle={(e: MouseEvent) => {
        e.stopPropagation();
        handleToggle();
      }}
      direction="start"
    >
      <DropdownToggle
        tag="button"
        className="btn py-0 px-2 m-0"
        onClick={(e) => {
          const trClosest = e.currentTarget.closest("tr");
          trClosest?.classList.toggle("z-3", !isOpen);
        }}
        onBlur={(e) => {
          const trClosest = e.currentTarget.closest("tr");
          setTimeout(() => trClosest?.classList.remove("z-3"), 100);
        }}
      >
        <i className="ri-more-fill"></i>
      </DropdownToggle>
      <DropdownMenu className="dropdown-menu-end">
        <DropdownItem className="p-0" onClick={handleAction("delete")}>
          <div className="dropdown-item">
            <i className="ri-delete-bin-line text-muted fs-16 align-middle me-1"></i>
            <span className="align-middle">{buttonText.delete}</span>
          </div>
        </DropdownItem>

        {!isActive ? (
          <DropdownItem className="p-0" onClick={handleAction("active")}>
            <div className="dropdown-item">
              <i className="ri-checkbox-circle-line text-muted fs-16 align-middle me-1"></i>
              <span className="align-middle">{buttonText.active}</span>
            </div>
          </DropdownItem>
        ) : null}
        {isActive ? (
          <DropdownItem className="p-0" onClick={handleAction("deactive")}>
            <div className="dropdown-item">
              <i className="ri-close-circle-line text-muted fs-16 align-middle me-1"></i>
              <span className="align-middle">{buttonText.deactive}</span>
            </div>
          </DropdownItem>
        ) : null}
      </DropdownMenu>
    </Dropdown>
  );
};
