import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { httpClient } from "app/datasources";
import { AxiosError, AxiosResponse } from "axios";
import { Material } from "./types";
import { Response } from "../types";

export const RETRIEVE_MATERIAL_BY_ID_URL = (id: ID) =>
  `/api/admin/materials/${id}`;

export type RetrieveMaterialByIdResponse = Response<Material>;

export type RetrieveMaterialByIdArgs = {
  id: ID;
};

export const retrieveMaterialById = ({ id }: RetrieveMaterialByIdArgs) => {
  return httpClient.get<RetrieveMaterialByIdResponse>(
    RETRIEVE_MATERIAL_BY_ID_URL(id),
  );
};

export const useRetrieveMaterialByIdQuery = (
  retrieveMaterialsArgs: RetrieveMaterialByIdArgs,
  opts?: Omit<
    UseQueryOptions<
      AxiosResponse<RetrieveMaterialByIdResponse>,
      AxiosError<any>
    >,
    "queryKey" | "queryFn"
  >,
) =>
  useQuery<AxiosResponse<RetrieveMaterialByIdResponse>, AxiosError<any>>({
    queryKey: [RETRIEVE_MATERIAL_BY_ID_URL(retrieveMaterialsArgs.id)],
    queryFn: () => retrieveMaterialById(retrieveMaterialsArgs),
    ...opts,
  });
