import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { httpClient } from "app/datasources";
import { Response } from "app/services/types";
import { AxiosError, AxiosResponse } from "axios";
import { Product } from "./types";

export const IMPORT_PRODUCT_URL = "/api/admin/products/import";

export enum ImportProductError {}

export const importProductErrorMessages = {};

export interface ImportProductArgs {
  filePath: string;
}

export type ImportProductResponse = Response<Product>;

export const importProduct = (args: ImportProductArgs) => {
  return httpClient.post<ImportProductResponse>(IMPORT_PRODUCT_URL, args);
};

export const useImportProductMutation = (
  opts?: Omit<
    UseMutationOptions<
      AxiosResponse<ImportProductResponse>,
      AxiosError<any>,
      ImportProductArgs
    >,
    "mutationFn"
  >,
) =>
  useMutation<
    AxiosResponse<ImportProductResponse>,
    AxiosError<any>,
    ImportProductArgs
  >({
    mutationFn: importProduct,
    mutationKey: [IMPORT_PRODUCT_URL],
    ...opts,
  });
