import { FileDTO } from "app/models";
import { MasterData } from "../masterData";
import { Material } from "../material";
import { IBaseModel } from "../types";
export interface Product extends IBaseModel {
  code: string;
  nameEn: string | null;
  nameVn: string;
  productGroup: MasterData | null;
  productUnit: MasterData | null;
  countryOfOrigin: string;
  description: string | null;
  lengthCm: number | null;
  widthCm: number | null;
  heightCm: number | null;
  seatSizeCm: string | null;
  isActive: boolean;
  color: string | null;
  productImage?: FileDTO | null;
  workSteps?: ProductStep[];
  materials: ProductMaterial[];

  // Bom version
  inventory?: any;
  productionLine?: any;
  bomVersion?: any;
  bomCode?: ID;

  attachments: FileDTO[];
}

export interface ProductStep extends IBaseModel {
  componentId: ID | null;
  step: MasterData;
  estimateTime: number | null;
  quantity: number | null;
  order: number | null;
}

export interface BomTreeItem extends BomTree {}

export interface BomTree {
  children: BomTreeItem[];
  component: Product;
  quantity: number;
  description: string | null;
  parentId: ID | null;
}

export enum ProductType {
  Product = "product",
  Cluster = "cluster",
  Detail = "detail",
}

export interface ProductMaterial extends Material {
  componentId: ID | null;
  quantity: number;
  note: string | null;
  material: Material;
}

export interface Component extends IBaseModel {
  name: string;
  code: string;
  quantity: number;
  description: string | null;
  type: MasterData;
  frameType: MasterData;
  workpieceType: MasterData;
  lengthOfBoxCm: number | null;
  widthOfBoxCm: number | null;
  lengthOfDetailCm: number | null;
  thicknessCm: number | null;
  parentId: ID | null;
  product: Product;
  workSteps?: ProductStep[];
  materials?: ProductMaterial[];
  componentImage: FileDTO | null;
  attachments: FileDTO[];
}

export interface ComponentWithChildren extends Component {
  children: ComponentWithChildren[];
  parentId: ID | null;
}
