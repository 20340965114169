import {
  RetrieveMaterialsArgs,
  useRetrieveMaterialsQuery,
} from "app/services/material";

export const useRetrieveMaterials = (args: RetrieveMaterialsArgs) => {
  const result = useRetrieveMaterialsQuery(args);
  return {
    materials: result.data?.data.data,
    isLoadingMaterials: result.isLoading,
    ...result,
  };
};
