import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { httpClient } from "app/datasources";
import { AxiosError, AxiosResponse } from "axios";
import { Response } from "../types";
import { Bom } from "./types";

export const ACTIVE_BOM_URL = (id: ID) => `/api/admin/boms/${id}/active`;

export enum ActiveBomError {}

export const activeBomErrorMessages = {};

export type ActiveBomResponse = Response<Bom>;

export type ActiveBomArgs = {
  id: ID;
};

export const activeBom = ({ id }: ActiveBomArgs) => {
  return httpClient.put<ActiveBomResponse>(ACTIVE_BOM_URL(id));
};

export const useActiveBomMutation = (
  opts?: Omit<
    UseMutationOptions<
      AxiosResponse<ActiveBomResponse>,
      AxiosError<any>,
      ActiveBomArgs
    >,
    "mutationFn"
  >,
) =>
  useMutation<AxiosResponse<ActiveBomResponse>, AxiosError<any>, ActiveBomArgs>(
    {
      mutationFn: activeBom,
      mutationKey: [ACTIVE_BOM_URL],
      ...opts,
    },
  );
