import { WithID } from "app/models";
import {
  CreateMaterialArgs,
  Material,
  UpdateMaterialArgs,
} from "app/services/material";
import { CommonProperties } from "app/services/types";
import { MaterialFormData } from "./components";

export const mapper = ({
  name,
  code,
  description,
  purchasePrice,
  unit,
  vendor,
}: MaterialFormData): CommonProperties<
  CreateMaterialArgs,
  UpdateMaterialArgs
> => {
  return {
    name,
    code,
    description: description || null,
    purchasePrice: purchasePrice || null,
    unitId: unit?.value || null,
    vendorId: vendor?.value || null,
  };
};

export const mapCreateMaterial = (
  data: MaterialFormData,
): CreateMaterialArgs => {
  return mapper(data);
};

export const mapUpdateMaterial = ({
  id,
  ...data
}: WithID<MaterialFormData>): UpdateMaterialArgs => {
  return { id, ...mapper(data) };
};

export const mapDefaultMaterial = ({
  name,
  code,
  description,
  purchasePrice,
  vendor,
  unit,
}: Partial<Material> = {}): MaterialFormData => {
  return {
    name: name || "",
    code: code || "",
    description: description || "",
    purchasePrice: purchasePrice || null,
    vendor: vendor
      ? {
          value: vendor.id,
          label: vendor.vendorName,
        }
      : null,
    unit: unit
      ? {
          value: unit.id,
          label: unit.name,
        }
      : null,
  };
};
