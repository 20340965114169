import { EMPLOYEE_INFO } from "app/helpers";
import { Employee } from "../employee";

export const employeeStorage = {
  storageKey: EMPLOYEE_INFO,
  set(user: Employee) {
    try {
      localStorage.setItem(this.storageKey, JSON.stringify(user));
    } catch (error) {
      console.warn(`employeeStorage.set error: ${JSON.stringify(error)}`);
    }
  },
  get(): Employee | null {
    try {
      const user = localStorage.getItem(this.storageKey);

      if (!user) return null;

      return JSON.parse(user); // TODO: Need validate user data here, using Zod?
    } catch (error) {
      console.warn(`employeeStorage.get error: ${JSON.stringify(error)}`);
      return null;
    }
  },
  remove() {
    try {
      localStorage.removeItem(this.storageKey);
    } catch (error) {
      console.warn(`employeeStorage.remove error: ${JSON.stringify(error)}`);
    }
  },
};
