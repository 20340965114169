import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "app/components/molecules/Button";
import { DateInput } from "app/components/molecules/DateInput";
import { FormHelper } from "app/components/molecules/FormHelper";
import { FormInput } from "app/components/molecules/FormInput";
import { FormLabel } from "app/components/molecules/FormLabel";
import { checkIsNull, yupSchema } from "app/helpers/schema";
import { formatDate } from "app/helpers/utils";
import { useAppTranslation, useGetEnableSubmitButton } from "app/hooks";
import {
  OrderItemOptionSelect,
  OrderItemSelect,
} from "app/modules/order/components/OrderItemSelect";
import { OrderSelect } from "app/modules/order/components/OrderSelect";
import { OptionSelect } from "app/services/types";
import { FC, FormEvent } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { Alert, Card, CardBody, Col, Form, FormGroup, Row } from "reactstrap";
import * as Yup from "yup";

export interface ProductionPlanFormProps {
  defaultValues?: ProductionPlanFormData;
  onSubmit: (data: ProductionPlanFormData) => void;
  submitting?: boolean;
}

export interface ProductPlanItem {
  id?: ID | null;
  order: OptionSelect<ID> | null;
  orderItem: OrderItemOptionSelect | null;
  quantity: number | null;
  remainingQuantity?: number | null;
  defaultQuantity: number | null;
}

export interface ProductionPlanFormData {
  name: string;
  description: string;
  startDate: Date | null;
  endDate: Date | null;
  items: ProductPlanItem[];
}

export const ProductionPlanForm: FC<ProductionPlanFormProps> = ({
  onSubmit,
  defaultValues,
  submitting,
}) => {
  const { productionPlanText, buttonText, generalInfomationText } =
    useAppTranslation();
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { isDirty, isValid, dirtyFields },
  } = useForm<ProductionPlanFormData>({
    mode: "onChange",
    resolver: yupResolver(
      Yup.object({
        name: yupSchema.stringRequired("productionPlan.error.nameRequired"),
        description: yupSchema.stringNotRequired(),
        startDate: yupSchema.dateRequired(
          "productionPlan.error.startDateRequired",
        ),
        endDate: yupSchema.dateRequired("productionPlan.error.endDateRequired"),
        items: Yup.array()
          .min(1)
          .of(
            Yup.object({
              order: yupSchema.objectRequired(
                "productionPlan.error.orderRequired",
              ),
              orderItem: Yup.object()
                .shape({
                  label: Yup.string().required(),
                  value: Yup.string().required(),
                  planQty: yupSchema.numberNotRequired(),
                  quantity: yupSchema.numberNotRequired(),
                })
                .required("productionPlan.error.orderItemRequired")
                .nullable()
                .test(
                  "is-null",
                  "productionPlan.error.orderItemRequired",
                  checkIsNull,
                ),
              defaultQuantity: yupSchema.numberNotRequired(),
              quantity: yupSchema.numberMoreThanRequired(
                0,
                "productionPlan.error.quantityMoreThanZero",
                "productionPlan.error.quantityRequired",
              ),
            }).test(
              "is-quantity-valid",
              "productionPlan.error.quantityInvalid",
              function () {
                const array = this.parent;
                const index = parseInt(
                  this.path.split("[")[1].split("]")[0],
                  10,
                );
                const totalQuantity = array[index]?.orderItem?.quantity || 0;
                const planQty = array[index]?.orderItem?.planQty || 0;
                const remainingQuantity = totalQuantity - planQty;
                const currentQuantity = array[index]?.defaultQuantity || 0;
                const newRemainingValue =
                  remainingQuantity -
                  (array[index]?.quantity || 0) +
                  currentQuantity;
                const isInvalid = newRemainingValue < 0;
                if (isInvalid) {
                  return this.createError({
                    path: `items.${index}.quantity`,
                    message: "productionPlan.error.quantityInvalid",
                  });
                }
                return true;
              },
            ),
          )
          .defined(),
      }),
    ),
    defaultValues,
  });

  const { fields, remove, append } = useFieldArray({
    control,
    name: "items",
  });

  const watchItems = watch("items");
  const itemsField = fields.map((field, index) => {
    return {
      ...field,
      ...watchItems[index],
    };
  });

  const isEnableSubmit = useGetEnableSubmitButton({
    isDirty,
    isValid,
    submitting,
  });

  const submit = async (e: FormEvent) => {
    e.preventDefault();
    handleSubmit(onSubmit)();
  };

  const watchOrderDate = watch("startDate");

  const handleQuantityChange = (index: number, value: number) => {
    const totalQuantity = watch(`items.${index}.orderItem.quantity`) || 0;
    const planQty = watch(`items.${index}.orderItem.planQty`) || 0;
    const remainingQuantity = totalQuantity - planQty;
    const currentQuantity = watch(`items.${index}.defaultQuantity`) || 0;
    const newRemainingValue =
      remainingQuantity - (value || 0) + currentQuantity;
    const isInvalid = newRemainingValue < 0 || value < 0;
    if (isInvalid) {
      setValue(
        `items.${index}.remainingQuantity`,
        remainingQuantity + currentQuantity,
      );
    } else {
      setValue(`items.${index}.remainingQuantity`, newRemainingValue);
    }
  };

  const selectedOrderItems = watch("items").map((item) => item.orderItem);

  const selectedDefaultOrderItem = defaultValues?.items;

  return (
    <Form onSubmit={submit} id="production-plan-form">
      <h5 className="py-2 fw-semibold">{generalInfomationText}</h5>
      <Row>
        <Col xs={12}>
          <FormGroup>
            <FormLabel required htmlFor="name" className="form-label">
              {productionPlanText.field.name}
            </FormLabel>
            <Controller
              name="name"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <FormInput
                    {...field}
                    type="text"
                    className="form-control"
                    id="name"
                    invalid={!!fieldState.error}
                  />
                  <FormHelper message={fieldState.error?.message} />
                </>
              )}
            />
          </FormGroup>
        </Col>
        <Col xs={12} md={6}>
          <FormGroup>
            <FormLabel required htmlFor="startDate" className="form-label">
              {productionPlanText.field.startDate}
            </FormLabel>
            <Controller
              name="startDate"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <DateInput
                    name={field.name}
                    id="startDate"
                    value={field.value || ""}
                    onChange={([date]) => {
                      field.onChange(date);
                      setValue("endDate", null, {
                        shouldValidate: dirtyFields.endDate ? true : false,
                      });
                    }}
                    isInvalid={fieldState.invalid}
                  />
                  <FormHelper message={fieldState.error?.message} />
                </>
              )}
            />
          </FormGroup>
        </Col>
        <Col xs={12} md={6}>
          <FormGroup>
            <FormLabel required htmlFor="endDate" className="form-label">
              {productionPlanText.field.endDate}
            </FormLabel>
            <Controller
              name="endDate"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <DateInput
                    name={field.name}
                    id="endDate"
                    value={field.value || ""}
                    onChange={([date]) => {
                      field.onChange(date);
                    }}
                    options={{
                      minDate: watchOrderDate
                        ? formatDate(watchOrderDate.toISOString())
                        : undefined,
                    }}
                    isInvalid={fieldState.invalid}
                  />
                  <FormHelper message={fieldState.error?.message} />
                </>
              )}
            />
          </FormGroup>
        </Col>
        <Col xs={12}>
          <FormGroup>
            <FormLabel htmlFor="description" className="form-label">
              {productionPlanText.field.description}
            </FormLabel>
            <Controller
              name="description"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <FormInput
                    {...field}
                    type="textarea"
                    className="form-control"
                    id="description"
                    invalid={!!fieldState.invalid}
                  />
                  <FormHelper message={fieldState.error?.message} />
                </>
              )}
            />
          </FormGroup>
        </Col>
        <h5 className="py-2 fw-semibold">{productionPlanText.field.items}</h5>
        <Col lg={12}>
          <Card className="card-gray">
            <CardBody>
              {itemsField.map((itemField, index) => (
                <Card key={itemField.id}>
                  <CardBody>
                    <Row>
                      <Col xs={12}>
                        <FormGroup>
                          <Row>
                            <Col>
                              <FormLabel
                                required
                                htmlFor={`items.${index}.order`}
                                className="form-label"
                              >
                                {productionPlanText.field.items}
                              </FormLabel>
                            </Col>
                            <Col>
                              <div className="text-end">
                                <i
                                  className="ri-delete-bin-line fs-18 cursor-pointer lh-1"
                                  onClick={() => remove(index)}
                                />
                              </div>
                            </Col>
                          </Row>
                          <Controller
                            name={`items.${index}.order` as const}
                            control={control}
                            render={({ field, fieldState }) => (
                              <>
                                <OrderSelect
                                  name={field.name}
                                  value={field.value}
                                  inputId={`items.${index}.order`}
                                  onChange={(value) => {
                                    field.onChange(value);
                                    setValue(`items.${index}.orderItem`, null);
                                    setValue(`items.${index}.quantity`, null);
                                  }}
                                  isInvalid={fieldState.invalid}
                                />
                                <FormHelper
                                  message={fieldState.error?.message}
                                />
                              </>
                            )}
                          />
                        </FormGroup>
                      </Col>
                      <Col xs={12}>
                        <FormGroup>
                          <FormLabel
                            required
                            htmlFor={`items.${index}.orderItem`}
                            className="form-label"
                          >
                            {productionPlanText.field.orderItem}
                          </FormLabel>
                          <Controller
                            name={`items.${index}.orderItem` as const}
                            control={control}
                            render={({ field, fieldState }) => (
                              <>
                                <OrderItemSelect
                                  id={watch(`items.${index}.order`)?.value}
                                  name={field.name}
                                  value={field.value}
                                  isOptionDisabled={(option) =>
                                    selectedOrderItems
                                      .map((item) => item?.value)
                                      .includes(option.value)
                                  }
                                  inputId={`items.${index}.orderItem`}
                                  onChange={(value) => {
                                    field.onChange(value);
                                    setValue(`items.${index}.quantity`, null);
                                    setValue(
                                      `items.${index}.remainingQuantity`,
                                      (value?.quantity || 0) -
                                        (value?.planQty || 0),
                                    );
                                    const defaultOrderItem =
                                      selectedDefaultOrderItem?.find(
                                        (item) =>
                                          item.orderItem?.value ===
                                          value?.value,
                                      );
                                    if (defaultOrderItem) {
                                      setValue(
                                        `items.${index}.quantity`,
                                        defaultOrderItem.defaultQuantity,
                                      );
                                    } else {
                                      setValue(`items.${index}.quantity`, null);
                                    }
                                  }}
                                  isInvalid={fieldState.invalid}
                                />
                                <FormHelper
                                  message={fieldState.error?.message}
                                />
                              </>
                            )}
                          />
                        </FormGroup>
                      </Col>
                      {watch(`items.${index}.orderItem`)?.value && (
                        <Col md={12}>
                          <Alert color="info" fade={false}>
                            {productionPlanText.field.quantityRemaining}:{" "}
                            {watch(`items.${index}.remainingQuantity`)}
                          </Alert>
                        </Col>
                      )}
                      <Col md={12}>
                        <FormGroup>
                          <FormLabel
                            required
                            htmlFor={`items.${index}.quantity`}
                            className="form-label"
                          >
                            {productionPlanText.field.quantity}
                          </FormLabel>
                          <Controller
                            name={`items.${index}.quantity` as const}
                            control={control}
                            render={({ field, fieldState }) => {
                              return (
                                <>
                                  <FormInput
                                    {...field}
                                    value={field.value ?? ""}
                                    onChange={(value) => {
                                      const inputValue = value.target.value;
                                      field.onChange(
                                        inputValue ? Number(inputValue) : null,
                                      );
                                      handleQuantityChange(
                                        index,
                                        inputValue ? Number(inputValue) : 0,
                                      );
                                    }}
                                    type="number"
                                    className="form-control"
                                    id={`orderItems.${index}.quantity`}
                                    invalid={fieldState.invalid}
                                  />
                                  <FormHelper
                                    message={fieldState.error?.message}
                                  />
                                </>
                              );
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              ))}
              <Button
                color="primary"
                size="sm"
                className="mb-4"
                onClick={() =>
                  append({
                    order: null,
                    orderItem: null,
                    quantity: null,
                    defaultQuantity: null,
                  })
                }
              >
                {buttonText.add}
              </Button>
            </CardBody>
          </Card>
        </Col>
        <Col xs={12}>
          <div className="hstack gap-2 justify-content-end">
            <Button
              disabled={!isEnableSubmit}
              type="submit"
              color="primary"
              loading={submitting}
            >
              {buttonText.save}
            </Button>
          </div>
        </Col>
      </Row>
    </Form>
  );
};
