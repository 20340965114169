import { FilePreview, WithID } from "app/models";
import {
  CreateEmployeeArgs,
  Employee,
  UpdateEmployeeArgs,
} from "app/services/employee";
import { CommonProperties } from "app/services/types";
import { EmployeeFormData } from "./components";
import { mapGender } from "./helpers";

export const mapper = ({
  birthdate,
  dateOfIssue,
  dateOfJoining,
  dateOfLeaving,
  department,
  email,
  employeeCode,
  firstName,
  gender,
  jobTitle,
  lastName,
  middleName,
  nationalId,
  permanentAddress,
  phone,
  placeOfIssue,
  taxCode,
  avatarId,
  skills,
  address,
  socialInsuranceCode,
  note,
}: EmployeeFormData & { avatarId?: ID | null }): CommonProperties<
  CreateEmployeeArgs,
  UpdateEmployeeArgs
> => {
  return {
    employeeCode,
    departmentId: department?.value || "",
    jobTitleId: jobTitle?.value || "",
    firstName,
    middleName: middleName || null,
    lastName,
    email: email || null,
    phone,
    taxCode: taxCode || null,
    nationalId: nationalId || null,
    birthdate: birthdate?.toISOString() || null,
    dateOfIssue: dateOfIssue?.toISOString() || null,
    placeOfIssue: placeOfIssue || null,
    permanentAddress: permanentAddress || null,
    dateOfJoining: dateOfJoining?.toISOString() || null,
    dateOfLeaving: dateOfLeaving?.toISOString() || null,
    gender: gender?.value || null,
    imageId: avatarId || null,
    status: 1,
    skillIds: skills?.map((skill) => skill.value) || [],
    currentAddress: address || null,
    socialInsuranceNumber: socialInsuranceCode || null,
    notes: note || null,
  };
};

export const mapCreateEmployee = (
  data: EmployeeFormData & { avatarId?: ID | null },
): CreateEmployeeArgs => {
  return mapper(data);
};

export const mapUpdateEmployee = ({
  id,
  ...data
}: WithID<EmployeeFormData> & { avatarId?: ID | null }): UpdateEmployeeArgs => {
  return { id, ...mapper(data) };
};

export const mapDefaultEmployee = ({
  birthdate,
  dateOfIssue,
  dateOfJoining,
  dateOfLeaving,
  department,
  email,
  employeeCode,
  firstName,
  gender,
  jobTitle,
  lastName,
  middleName,
  nationalId,
  permanentAddress,
  phone,
  placeOfIssue,
  taxCode,
  avatar,
  employeeSkills,
  notes,
  currentAddress,
  socialInsuranceNumber,
}: Partial<Employee> = {}): EmployeeFormData => {
  return {
    jobTitle: jobTitle ? { value: jobTitle.id, label: jobTitle.name } : null,
    department: department
      ? { value: department.id, label: department.name }
      : null,
    firstName: firstName || "",
    lastName: lastName || "",
    middleName: middleName || "",
    email: email || "",
    phone: phone || "",
    employeeCode: employeeCode || "",
    taxCode: taxCode || "",
    nationalId: nationalId || "",
    gender: gender ? { value: gender, label: mapGender(gender) } : null,
    birthdate: birthdate ? new Date(birthdate) : null,
    dateOfIssue: dateOfIssue ? new Date(dateOfIssue) : null,
    placeOfIssue: placeOfIssue || "",
    permanentAddress: permanentAddress || "",
    dateOfJoining: dateOfJoining ? new Date(dateOfJoining) : null,
    dateOfLeaving: dateOfLeaving ? new Date(dateOfLeaving) : null,
    avatar: avatar?.id
      ? ([
          {
            id: avatar?.id || "",
            preview: avatar?.attachmentUrl,
            name: avatar?.fileName,
          },
        ] as FilePreview[])
      : [],
    skills:
      employeeSkills?.map((skill) => ({
        value: skill.skill.id,
        label: skill.skill.name,
      })) || [],
    socialInsuranceCode: socialInsuranceNumber || "",
    note: notes || "",
    address: currentAddress || "",
  };
};
