import { useAppTranslation } from "app/hooks";
import { ComponentWithChildren } from "app/services/product";
import { FC, MouseEvent, useState } from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";

export type ProductStructureAction =
  | "detail"
  | "create-component"
  | "edit"
  | "delete"
  | "add-material";

export interface ProductStructureActionDropdownProps {
  data?: ComponentWithChildren;
  onAction: (
    action: ProductStructureAction,
    data?: ComponentWithChildren,
  ) => void;
}

export const ProductStructureActionDropdown: FC<
  ProductStructureActionDropdownProps
> = ({ data, onAction }) => {
  const { buttonText, productText } = useAppTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const handleToggle = () => setIsOpen(!isOpen);

  const handleAction = (action: ProductStructureAction) => () => {
    onAction(action, data);
    setIsOpen(false);
  };

  return (
    <Dropdown
      isOpen={isOpen}
      toggle={(e: MouseEvent) => {
        e.stopPropagation();
        handleToggle();
      }}
      direction="start"
    >
      <DropdownToggle
        tag="button"
        className="btn py-0 px-2 m-0"
        onClick={(e) => {
          const trClosest = e.currentTarget.closest("tr");
          trClosest?.classList.toggle("z-5", !isOpen);
        }}
        onBlur={(e) => {
          const trClosest = e.currentTarget.closest("tr");
          setTimeout(() => trClosest?.classList.remove("z-5"), 100);
        }}
      >
        <i className="ri-more-fill"></i>
      </DropdownToggle>
      <DropdownMenu className="dropdown-menu-end">
        <DropdownItem onClick={handleAction("detail")}>
          <i className=" ri-arrow-right-s-line text-muted fs-16 align-middle me-1"></i>
          <span className="align-middle">{buttonText.detail}</span>
        </DropdownItem>
        <DropdownItem onClick={handleAction("create-component")}>
          <i className=" ri-add-fill text-muted fs-16 align-middle me-1"></i>
          <span className="align-middle">{productText.addComponentTitle}</span>
        </DropdownItem>
        <DropdownItem onClick={handleAction("add-material")}>
          <i className="ri-settings-fill text-muted fs-16 align-middle me-1"></i>
          <span className="align-middle">{productText.addMaterialTitle}</span>
        </DropdownItem>
        <DropdownItem onClick={handleAction("edit")}>
          <i className="ri-pencil-line text-muted fs-16 align-middle me-1"></i>
          <span className="align-middle">{buttonText.edit}</span>
        </DropdownItem>
        <DropdownItem onClick={handleAction("delete")}>
          <i className="ri-delete-bin-line text-muted fs-16 align-middle me-1"></i>
          <span className="align-middle">{buttonText.delete}</span>
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};
