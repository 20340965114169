import { useQueryClient } from "@tanstack/react-query";
import { Button } from "app/components/molecules/Button";
import { useConfirm } from "app/components/molecules/ConfirmationProvider";
import { useAppTranslation } from "app/hooks";
import { Source } from "app/models";
import { getDeleteFileIds, getUploadedFileIds } from "app/modules/file/helpers";
import {
  ComponentFormData,
  ComponentFormModal,
  CreateProductMaterialFormModal,
  ProductMaterialFormData,
  ProductStructureAction,
} from "app/modules/product/components";
import {
  useAddProductMaterial,
  useCreateComponent,
  useDeleteComponent,
  useUpdateComponent,
} from "app/modules/product/hooks";
import { useRetrieveProductStructureById } from "app/modules/product/hooks/useRetrieveProductStructureById";
import {
  mapCreateComponent,
  mapCreateProductMaterial,
  mapUpdateComponent,
} from "app/modules/product/mapper";
import { useDeleteFile, useUploadFiles } from "app/services/file";
import {
  ComponentWithChildren,
  RETRIEVE_PRODUCT_BY_ID_URL,
  RETRIEVE_PRODUCT_STRUCTURE_BY_ID_URL,
  retrieveComponentById,
} from "app/services/product";
import { FC, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { NestTable } from "./NestTable";

export interface DetailProductStructureProps {}

const DetailProductStructure: FC<DetailProductStructureProps> = () => {
  const { id } = useParams();
  const { componentText, materialText, productText } = useAppTranslation();
  const rowSelected = useRef<ComponentWithChildren | null>(null);
  const { confirm } = useConfirm();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  // Component
  const [isCreateComponentModalOpen, toggleCreateComponentModalOpen] =
    useState(false);
  const [isEditComponentModalOpen, toggleEditComponentModalOpen] =
    useState(false);
  const [isAddComponentParent, toggleAddComponentParent] = useState(false);
  // Material
  const [isAddMaterialModalOpen, toggleAddMaterialModalOpen] = useState(false);

  const [isAddMaterialParent, toggleAddMaterialParent] = useState(false);

  const handleToggleCreateComponentModal = (data?: ComponentWithChildren) => {
    if (data) {
      rowSelected.current = data;
    }
    if (isCreateComponentModalOpen) {
      rowSelected.current = null;
    }
    if (isAddComponentParent) {
      toggleAddComponentParent(false);
    }
    toggleCreateComponentModalOpen((prevState) => !prevState);
  };

  const handleToggleEditComponentModal = (data?: ComponentWithChildren) => {
    if (data) {
      rowSelected.current = data;
    }
    if (isEditComponentModalOpen) {
      rowSelected.current = null;
    }
    toggleEditComponentModalOpen((prevState) => !prevState);
  };
  const handleToggleAddMaterialModal = (data?: ComponentWithChildren) => {
    if (data) {
      rowSelected.current = data;
    }
    if (isAddMaterialModalOpen) {
      rowSelected.current = null;
    }
    if (isAddMaterialParent) {
      toggleAddMaterialParent(false);
    }
    toggleAddMaterialModalOpen((prevState) => !prevState);
  };

  const { productStructure, isLoadingProductStructure } =
    useRetrieveProductStructureById({ id: id as string }, { enabled: !!id });

  const handleActionClick = (
    action: ProductStructureAction,
    data?: ComponentWithChildren,
  ) => {
    if (data) {
      rowSelected.current = data;
    }
    switch (action) {
      case "detail":
        if (data) {
          navigate(`/component/${data.id}`);
        }
        break;
      case "create-component":
        if (data) {
          handleToggleCreateComponentModal(data);
        }
        break;
      case "edit":
        if (data) {
          handleToggleEditComponentModal(data);
        }
        break;
      case "add-material":
        if (data) {
          handleToggleAddMaterialModal(data);
        }
        break;
      case "delete":
        if (data?.id) {
          confirm({
            title: productText.deleteComponentTitle,
            description: productText.deleteComponentContent,
            onConfirm: async () =>
              deleteComponent({
                id: id as ID,
                componentId: data.id,
              }),
          });
        }
        break;
      default:
        break;
    }
  };

  const { mutateAsync: upload, isPending: isUploading } = useUploadFiles();

  const { mutateAsync: deleteFile } = useDeleteFile();

  const { mutateAsync: createComponent, isPending: isComponentCreating } =
    useCreateComponent({
      id: rowSelected.current?.id,
      onSuccess: () => {
        handleToggleCreateComponentModal();
        queryClient.invalidateQueries({
          queryKey: [RETRIEVE_PRODUCT_STRUCTURE_BY_ID_URL(id as ID)],
        });
      },
    });

  const { mutateAsync: updateComponent, isPending: isComponentUpdating } =
    useUpdateComponent({
      id: rowSelected.current?.id,
      onSuccess: () => {
        handleToggleEditComponentModal();
        queryClient.invalidateQueries({
          queryKey: [RETRIEVE_PRODUCT_STRUCTURE_BY_ID_URL(id as ID)],
        });
      },
    });

  const { mutateAsync: deleteComponent } = useDeleteComponent({
    id,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [RETRIEVE_PRODUCT_STRUCTURE_BY_ID_URL(id as ID)],
      });
    },
  });
  const { mutateAsync: createMaterial, isPending: isMaterialCreating } =
    useAddProductMaterial({
      id,
      onSuccess: () => {
        handleToggleAddMaterialModal();
        queryClient.invalidateQueries({
          queryKey: [RETRIEVE_PRODUCT_BY_ID_URL(id as ID)],
        });
      },
    });

  const handleCreateComponent = async (data: ComponentFormData) => {
    const avatarIds =
      (
        await getUploadedFileIds({
          data: data.avatars,
          fileKey: "image",
          source: Source.Component,
          upload,
        })
      )?.fileIds || [];
    const avatarId = avatarIds[0];
    const imageIds =
      (
        await getUploadedFileIds({
          data: data.images,
          fileKey: "image",
          source: Source.Component,
          upload,
        })
      )?.fileIds || [];
    const documentIds =
      (
        await getUploadedFileIds({
          data: data.documents,
          fileKey: "document",
          source: Source.Component,
          upload,
        })
      )?.fileIds || [];
    await createComponent(
      {
        ...mapCreateComponent({
          ...data,
          productId: id as ID,
          imageId: avatarId,
          files: [...imageIds, ...documentIds],
        }),
        parentId: rowSelected.current?.id || data.parent?.value || null,
      },
      {
        onError: () => {
          if (avatarIds[0]) {
            deleteFile({ id: avatarId });
          }
          [...imageIds, ...documentIds]?.map((imageId) => {
            deleteFile({ id: imageId });
          });
        },
      },
    );
  };

  const handleUpdateComponent = async (data: ComponentFormData) => {
    if (rowSelected.current) {
      const currentAvatarId = data.avatars[0]?.id;
      const avatarIds =
        (
          await getUploadedFileIds({
            data: data.avatars,
            fileKey: "image",
            source: Source.Component,
            upload,
          })
        )?.fileIds || [];
      const avatarId = avatarIds[0];
      const imageIds =
        (
          await getUploadedFileIds({
            data: data.images,
            fileKey: "image",
            source: Source.Component,
            upload,
          })
        )?.fileIds || [];
      const documentIds =
        (
          await getUploadedFileIds({
            data: data.documents,
            fileKey: "document",
            source: Source.Component,
            upload,
          })
        )?.fileIds || [];
      let deleteImageIds: string[] = [];
      let deleteDocumentIds: string[] = [];
      if (data.documents.length || data.images.length) {
        const { data: _data } = await retrieveComponentById({
          id: rowSelected.current.id,
        });
        const imageData = _data.data.attachments.filter(
          (attachment) => attachment.fileType === "image",
        );
        const documentData = _data.data.attachments.filter(
          (attachment) => attachment.fileType === "document",
        );
        deleteImageIds = getDeleteFileIds(imageData, data.images);
        deleteDocumentIds = getDeleteFileIds(documentData, data.documents);
      }

      updateComponent(
        mapUpdateComponent({
          ...data,
          id: rowSelected.current.id,
          imageId: currentAvatarId || avatarId,
          files: [...imageIds, ...documentIds],
          deleteFileIds: [...deleteImageIds, ...deleteDocumentIds],
        }),
      );
    }
  };

  const handleCreateMaterial = async (data: ProductMaterialFormData) => {
    createMaterial({
      ...mapCreateProductMaterial({ ...data, productId: id as ID }),
      componentId: rowSelected.current?.id || data.parent?.value || null,
    });
  };

  return (
    <>
      <div className="p-3 py-0 h-100 d-flex flex-column">
        <div className="d-flex justify-content-end gap-2 my-2">
          <Button
            color="primary"
            size="sm"
            onClick={() => {
              toggleAddComponentParent(true);
              handleToggleCreateComponentModal();
            }}
          >
            {componentText.add}
          </Button>
          <Button
            color="primary"
            size="sm"
            onClick={() => {
              toggleAddMaterialParent(true);
              handleToggleAddMaterialModal();
            }}
          >
            {materialText.add}
          </Button>
        </div>
        <NestTable
          data={productStructure || []}
          loading={isLoadingProductStructure}
          handleActionClick={handleActionClick}
        />
      </div>
      {/* Create Component*/}
      <ComponentFormModal
        open={isCreateComponentModalOpen}
        onCancel={handleToggleCreateComponentModal}
        onSubmit={handleCreateComponent}
        submitting={isComponentCreating || isUploading}
        data={rowSelected.current || undefined}
        isAddParent={isAddComponentParent}
      />
      {/* Update Component*/}
      <ComponentFormModal
        open={isEditComponentModalOpen}
        onCancel={handleToggleEditComponentModal}
        onSubmit={handleUpdateComponent}
        submitting={isComponentUpdating || isUploading}
        edit={!!rowSelected.current}
        data={rowSelected.current || undefined}
      />
      {/* Add Material*/}
      <CreateProductMaterialFormModal
        open={isAddMaterialModalOpen}
        onToggle={handleToggleAddMaterialModal}
        onSubmit={handleCreateMaterial}
        submitting={isMaterialCreating}
        isAddParent={isAddMaterialParent}
        data={rowSelected.current || undefined}
      />
    </>
  );
};

export default DetailProductStructure;
