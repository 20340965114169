import { Chip } from "app/components/atoms/Chip";
import { useDetailPageContext } from "app/components/templates/DetailPage/DetailPageContext";
import { useAppTranslation } from "app/hooks";
import { TicketAction } from "app/modules/ticket/components";
import { mapTicketStatus } from "app/modules/ticket/helpers";
import { Ticket, TicketStatus } from "app/services/ticket";
import { FC } from "react";
import { Button } from "reactstrap";

export interface DetailTicketHeaderProps {
  onActionClick?: (action: TicketAction) => void;
}

const DetailTicketHeader: FC<DetailTicketHeaderProps> = ({ onActionClick }) => {
  const { buttonText, ticketText } = useAppTranslation();

  const { data: ticket } = useDetailPageContext<Ticket>();

  const avatar = false;

  const handleActionClick = (action: TicketAction) => () => {
    onActionClick?.(action);
  };

  const isInprogress = ticket?.status === TicketStatus.InProgress;
  const isCompleted = ticket?.status === TicketStatus.Completed;
  const isTodo = ticket?.status === TicketStatus.Todo;

  return (
    <div className="d-flex gap-4 flex-wrap">
      <div className="avatar-lg d-flex justify-content-center align-items-center">
        {avatar ? (
          <img
            src={avatar}
            alt="user-img"
            className="img-thumbnail rounded-4 w-100 h-100"
          />
        ) : (
          <i className="ri-task-line text-body"></i>
        )}
      </div>

      <div className="flex-grow-1 p-2">
        <h3>{ticket?.product?.nameVn}</h3>
        <div className="mb-1">
          Trạng thái: &nbsp;
          {ticket?.status ? (
            <Chip
              size="sm"
              color="primary"
              label={mapTicketStatus(ticket.status)}
            />
          ) : (
            "-"
          )}
        </div>
        <div className="text-muted">
          {ticketText.field.workStep}: {ticket?.workStep.step.name}
          &nbsp;-&nbsp;
          {ticketText.field.quantity}: {ticket?.quantity}
        </div>
      </div>
      {!isCompleted ? (
        <div className="text-end">
          <div className="d-flex gap-2 justify-content-end">
            <Button color="primary" onClick={handleActionClick("edit")}>
              {buttonText.edit}
            </Button>
            {isTodo ? (
              <Button color="primary" onClick={handleActionClick("start")}>
                {ticketText.startTitle}
              </Button>
            ) : null}
            {isInprogress ? (
              <Button color="primary" onClick={handleActionClick("checkIn")}>
                {ticketText.checkInTitle}
              </Button>
            ) : null}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default DetailTicketHeader;
