import FormPage from "app/components/templates/FormPage";
import { useAppTranslation } from "app/hooks";
import { Source } from "app/models";
import {
  EmployeeForm,
  EmployeeFormData,
} from "app/modules/employee/components";
import { useCreateEmployee } from "app/modules/employee/hooks";
import {
  mapCreateEmployee,
  mapDefaultEmployee,
} from "app/modules/employee/mapper";
import { getUploadedFileIds } from "app/modules/file/helpers";
import { useDeleteFile, useUploadFiles } from "app/services/file";
import BreadCrumb from "Components/Common/BreadCrumb";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { Card, CardBody } from "reactstrap";

export interface CreateEmployeeProps {}

const CreateEmployee: FC<CreateEmployeeProps> = () => {
  const navigate = useNavigate();
  const { employeeText } = useAppTranslation();
  const { mutateAsync, isPending } = useCreateEmployee({
    onSuccess: () => {
      navigate("/employees");
    },
  });

  const { mutateAsync: upload, isPending: isUploading } = useUploadFiles();

  const { mutateAsync: deleteFile } = useDeleteFile();

  const handleSubmit = async (data: EmployeeFormData) => {
    const imageIds =
      (
        await getUploadedFileIds({
          data: data.avatar,
          fileKey: "image",
          source: Source.Employee,
          upload,
        })
      )?.fileIds || [];
    const imageId = imageIds[0];
    await mutateAsync(mapCreateEmployee({ ...data, avatarId: imageId }), {
      onError: () => {
        if (imageIds[0]) {
          deleteFile({ id: imageId });
        }
      },
    });
  };

  return (
    <FormPage>
      <FormPage.BreadCrumb>
        <BreadCrumb
          title={employeeText.add}
          pageTitle={employeeText.title}
          isBack
        />
      </FormPage.BreadCrumb>
      <FormPage.Main>
        <Card>
          <CardBody>
            <EmployeeForm
              onSubmit={handleSubmit}
              defaultValues={mapDefaultEmployee()}
              submitting={isPending || isUploading}
            />
          </CardBody>
        </Card>
      </FormPage.Main>
    </FormPage>
  );
};

export default CreateEmployee;
