import { FileDTO } from "app/models";
import { MasterData } from "../masterData";
import { IBaseModel } from "../types";
import { User } from "../user";

export enum EmployeeGender {
  Male = "male",
  Female = "female",
}

export interface Employee extends IBaseModel {
  idCode: ID;
  user: User;
  employeeCode: string;
  department: MasterData;
  jobTitle: MasterData;
  firstName: string;
  middleName: string | null;
  lastName: string;
  fullName: string;
  email: string | null;
  phone: string;
  taxCode: string | null;
  nationalId: string | null;
  gender: EmployeeGender | null;
  birthdate: DateTimeString | null;
  dateOfIssue: DateTimeString | null;
  placeOfIssue: string | null;
  permanentAddress: string | null;
  dateOfJoining: DateTimeString | null;
  dateOfLeaving: DateTimeString | null;
  status: number;
  avatar: FileDTO | null;
  employeeSkills: (IBaseModel & { skill: MasterData })[];
  currentAddress: string | null;
  socialInsuranceNumber: string | null;
  notes: string | null;
  employeeIdCodes: EmployeeIdCode[];
}

export interface EmployeeIdCode extends IBaseModel {
  employeeId: ID;
  idCode: ID;
  deactiveAt: DateTimeString | null;
  deactiveBy: ID | null;
  isActive: boolean;
  notes: string | null;
}
