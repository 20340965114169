import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { httpClient } from "app/datasources";
import { AxiosError, AxiosResponse } from "axios";
import { Response } from "../types";
import { Product } from "./types";

export const UPDATE_COMPONENT_URL = (id: ID) => `/api/admin/components/${id}`;

export enum UpdateComponentError {
  ProductCodeAlreadyExists = "Product Code Already Exists",
}

export const updateComponentErrorMessages = {
  [UpdateComponentError.ProductCodeAlreadyExists]:
    "Product Code Already Exists",
};

export type UpdateComponentResponse = Response<Product>;

export type UpdateComponentArgs = {
  id: ID;
  parentId: ID | null;
  // Product Information
  name: string;
  typeId: ID | null;
  workpieceTypeId: ID | null;
  frameTypeId: ID | null;
  thicknessCm: number | null;
  lengthOfBoxCm: number | null;
  widthOfBoxCm: number | null;
  lengthOfDetailCm: number | null;
  imageId: ID | null;
  workSteps: {
    stepId: ID;
    estimateTime: number | null;
    quantity: number | null;
    order: number;
  }[];
  // Other Infomation
  quantity: number;
  description: string | null;
  addFileIds?: ID[];
  deleteFileIds?: ID[];
};

export const updateComponent = ({ id, ...args }: UpdateComponentArgs) => {
  return httpClient.put<UpdateComponentResponse>(
    UPDATE_COMPONENT_URL(id),
    args,
  );
};

export const useUpdateComponentMutation = (
  opts?: Omit<
    UseMutationOptions<
      AxiosResponse<UpdateComponentResponse>,
      AxiosError<any>,
      UpdateComponentArgs
    >,
    "mutationFn"
  >,
) =>
  useMutation<
    AxiosResponse<UpdateComponentResponse>,
    AxiosError<any>,
    UpdateComponentArgs
  >({
    mutationFn: updateComponent,
    mutationKey: [UPDATE_COMPONENT_URL],
    ...opts,
  });
