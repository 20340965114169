import {
  RetrieveEmployeesArgs,
  useRetrieveEmployeesQuery,
} from "app/services/employee";

export const useRetrieveEmployees = (args: RetrieveEmployeesArgs) => {
  const result = useRetrieveEmployeesQuery(args);
  return {
    employees: result.data?.data.data,
    isLoadingEmployees: result.isPending,
    ...result,
  };
};
