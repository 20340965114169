import { PagingData } from "./../types";
import {
  keepPreviousData,
  useQuery,
  UseQueryOptions,
} from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import { Response } from "../types";
import { httpClient } from "app/datasources";
import { Bom } from "./types";

export const RETRIEVE_BOMS_URL = "/api/admin/boms";

export interface RetrieveBomsArgs {
  page: number;
  limit: number;
  searchString?: string;
  productId?: ID;
}

export type RetrieveBomsResponse = Response<PagingData<Bom[]>>;

export const retrieveBoms = (args: RetrieveBomsArgs) => {
  return httpClient.get<RetrieveBomsResponse>(RETRIEVE_BOMS_URL, {
    params: args,
  });
};

export const useRetrieveBomsQuery = (
  retrieveBomsArgs: RetrieveBomsArgs,
  opts?: Omit<
    UseQueryOptions<AxiosResponse<RetrieveBomsResponse>, AxiosError<any>>,
    "queryKey" | "queryFn"
  >,
) =>
  useQuery<AxiosResponse<RetrieveBomsResponse>, AxiosError<any>>({
    queryKey: [RETRIEVE_BOMS_URL, retrieveBomsArgs],
    queryFn: () => retrieveBoms(retrieveBomsArgs),
    placeholderData: keepPreviousData,
    ...opts,
  });
