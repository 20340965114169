import { useAppTranslation } from "app/hooks";
import { useRetrieveCustomerById } from "app/modules/customer/hooks";
import { FC } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "reactstrap";

export interface DetailCustomerHeaderProps {}

export const DetailCustomerHeader: FC<DetailCustomerHeaderProps> = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { buttonText } = useAppTranslation();
  const { customer } = useRetrieveCustomerById(
    { id: id as string },
    { enabled: !!id },
  );

  const avatar = false;

  return (
    <div className="d-flex gap-4 flex-wrap">
      <div className="avatar-lg d-flex justify-content-center align-items-center">
        {avatar ? (
          <img
            src={avatar}
            alt="user-img"
            className="img-thumbnail rounded-circle w-100 h-100"
          />
        ) : (
          <i className="ri-account-circle-fill text-body"></i>
        )}
      </div>

      <div className="flex-grow-1 p-2">
        <h3 className="mb-1">{customer?.customerName}</h3>
        <p className="text-muted">{customer?.customerCode}</p>
        <div className="hstack text-muted gap-1">
          <div className="me-2">
            <i className="ri-mail-line me-1 fs-16 align-bottom text-body"></i>
            {customer?.email}
          </div>
          <div className="me-2">
            <i className="ri-phone-line me-1 fs-16 align-bottom text-body"></i>
            {customer?.phoneNumber}
          </div>
        </div>
      </div>
      <div className="text-end">
        <div className="d-flex gap-2 justify-content-end">
          <Button
            color="primary"
            onClick={() => navigate(`/customer/${id}/update`)}
          >
            {buttonText.edit}
          </Button>
        </div>
      </div>
    </div>
  );
};
