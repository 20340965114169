import { Chip } from "app/components/atoms/Chip";
import { AvatarRow } from "app/components/molecules/AvatarRow";
import { Button } from "app/components/molecules/Button";
import { useAppTranslation } from "app/hooks";
import { useRetrievePanelTicketsByEmployeeId } from "app/modules/ticket/hooks";
import { employeeStorage } from "app/services/auth";
import { TicketStatus } from "app/services/ticket";
import { FC } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Card, Col, Row } from "reactstrap";
import { usePanelEmployee } from "Routes/PanelEmployeeProtected";

export interface ListTicketPanelProps {}

const ListTicketPanel: FC<ListTicketPanelProps> = () => {
  const navigate = useNavigate();
  const { ticketText, authText } = useAppTranslation();

  const { employee } = usePanelEmployee();

  const { tickets } = useRetrievePanelTicketsByEmployeeId({
    id: employee?.id || "",
  });

  const handleLogout = () => {
    employeeStorage.remove();
    navigate("/");
  };

  const avatar = employee?.avatar;

  const isInProgress = tickets?.hasTicketInprogress;

  const isInProgressTickets = tickets?.result?.filter(
    (ticket) => ticket.status === TicketStatus.InProgress,
  );

  const isTodoTickets = tickets?.result?.filter(
    (ticket) => ticket.status === TicketStatus.Todo,
  );

  const list = isInProgress ? isInProgressTickets : isTodoTickets;

  return (
    <div className="p-3 w-full">
      <div className="p-3 border border-2 border-black rounded">
        <div className="d-flex flex-column flex-sm-row gap-3 align-items-center">
          <div className="avatar-lg d-flex justify-content-center align-items-center">
            {avatar ? (
              <img
                src={avatar?.attachmentUrl}
                alt={employee.fullName}
                className="img-thumbnail rounded-4 w-100 h-100"
              />
            ) : (
              <i className="ri-account-circle-fill text-body"></i>
            )}
          </div>
          <div>
            <h3 className="mb-1">{employee?.fullName}</h3>
            <div className="d-flex flex-wrap gap-2">
              Kỹ năng:
              {employee?.employeeSkills?.map((skill) => (
                <Chip size="sm" key={skill.id} label={skill.skill.name} />
              ))}
            </div>
          </div>
          <div className="ms-auto">
            <Button color="primary" onClick={handleLogout}>
              {authText.logout}
            </Button>
          </div>
        </div>
      </div>
      <div className="my-3">
        <h3 className="mb-3">
          {isInProgress
            ? ticketText.detail.progressTicket
            : ticketText.detail.ticketList}
        </h3>
        <Row>
          {list?.length ? (
            list?.map((ticket) => (
              <Col key={ticket.id} md={4} sm={6} xs={12}>
                <Link to={`/panel/ticket/${ticket.id}`}>
                  <Card className="card-body card-hover-shadow">
                    <div className="d-flex align-items-center">
                      <div className="avatar-md d-flex justify-content-center align-items-center">
                        <AvatarRow
                          avatarUrl={
                            ticket?.workStep.product.productImage?.attachmentUrl
                          }
                          name={ticket?.workStep.product.nameVn}
                          size="md"
                          isClickable
                          isHidenName
                        />
                      </div>
                      <div className="flex-grow-1 ms-2">
                        <h4 className="mb-2">
                          {ticket.workStep.product.nameVn}
                        </h4>
                        <h4 className="mb-2">
                          {ticketText.field.step}: {ticket.workStep.step.name}
                        </h4>
                        <h4 className="mb-0">
                          {ticketText.field.quantity}: {ticket.quantity}
                        </h4>
                      </div>
                    </div>
                  </Card>
                </Link>
              </Col>
            ))
          ) : (
            <h5>Không có dữ liệu</h5>
          )}
        </Row>
      </div>
    </div>
  );
};

export default ListTicketPanel;
