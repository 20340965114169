import DetailPage from "app/components/templates/DetailPage";
import { useAppTranslation } from "app/hooks";
import { useRetrieveBomById } from "app/modules/bom/hooks";
import { Bom } from "app/services/bom";
import BreadCrumb from "Components/Common/BreadCrumb";
import { Outlet, useParams } from "react-router-dom";
import DetailBomHeader from "./DetailBomHeader";
import { DetailBomTabs } from "./DetailBomTabs";

const DetailBom = () => {
  const { id } = useParams();
  const { bomText } = useAppTranslation();

  const { bom, isLoadingBom } = useRetrieveBomById(
    { id: id as string },
    { enabled: !!id },
  );

  return (
    <DetailPage<Bom> data={bom as Bom} loading={isLoadingBom}>
      <DetailPage.BreadCrumb>
        <BreadCrumb
          title={bomText.detail.title}
          pageTitle={bomText.title}
          isBack
        />
      </DetailPage.BreadCrumb>
      <DetailPage.Header>
        <DetailBomHeader />
      </DetailPage.Header>
      <DetailPage.Main tabs={<DetailBomTabs />}>
        <Outlet />
      </DetailPage.Main>
    </DetailPage>
  );
};

export default DetailBom;
