import { Product, ProductStep } from "app/services/product";

export const getProductLabel = (product: Product) =>
  `${product.nameVn} - ${product.code}`;

export const sortProductStepOrder = (productStep: ProductStep[]) =>
  productStep.sort((a, b) => {
    if (a.order === null && b.order === null) return 0;
    if (a.order === null) return 1;
    if (b.order === null) return -1;
    return a.order - b.order;
  });
