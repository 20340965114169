import { ColumnDef } from "@tanstack/react-table";
import { useConfirm } from "app/components/molecules/ConfirmationProvider";
import { DataTable } from "app/components/organisms/DataTable";
import { useAppTranslation } from "app/hooks";
import {
  ProductStructureAction,
  ProductStructureActionDropdown,
} from "app/modules/product/components";
import { useDeleteComponent } from "app/modules/product/hooks";
import { useRetrieveProductStructureById } from "app/modules/product/hooks/useRetrieveProductStructureById";
import { ComponentWithChildren } from "app/services/product";
import { FC, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";

export interface DetailDetailStructureProps {}

const DetailDetailStructure: FC<DetailDetailStructureProps> = () => {
  const { id } = useParams();
  const { componentText, productText } = useAppTranslation();
  const [isAddModalOpen, toggleAddModalOpen] = useState(false);
  const rowSelected = useRef<ComponentWithChildren | null>(null);
  const { confirm } = useConfirm();

  const handleToggleAddModal = (data?: ComponentWithChildren) => {
    if (data) {
      rowSelected.current = data;
    }
    if (isAddModalOpen) {
      rowSelected.current = null;
    }
    toggleAddModalOpen((prevState) => !prevState);
  };

  const { productStructure, isLoadingProductStructure } =
    useRetrieveProductStructureById({ id: id as string }, { enabled: !!id });

  const handleActionClick = (
    action: ProductStructureAction,
    data?: ComponentWithChildren,
  ) => {
    if (data) {
      rowSelected.current = data;
    }
    switch (action) {
      case "create-component":
        if (rowSelected.current) {
          handleToggleAddModal(rowSelected.current);
        }
        break;
      case "delete": {
        if (data?.id) {
          confirm({
            title: productText.deleteComponentTitle,
            description: productText.deleteComponentContent,
            onConfirm: async () =>
              deleteComponent({
                id: data.parentId as ID,
                componentId: data.id,
              }),
          });
        }
        break;
      }
      default:
        break;
    }
  };

  const columns: ColumnDef<ComponentWithChildren>[] = useMemo(
    () => [
      {
        header: componentText.field.code,
        accessorKey: "productCode",
        cell: ({ row }) => row.original?.code || "-",
      },
      {
        header: componentText.field.name,
        accessorKey: "productName",
        cell: ({ row }) => row.original?.name || "-",
      },
      {
        header: componentText.field.quantity,
        accessorKey: "quantity",
        cell: ({ row }) => row.original.quantity || "-",
      },
      {
        header: componentText.field.thicknessCm,
        accessorKey: "thicknessCm",
        cell: ({ row }) => row.original.thicknessCm || "-",
      },
      {
        header: componentText.field.lengthOfBoxCm,
        accessorKey: "lengthOfBoxCm",
        cell: ({ row }) => row.original.lengthOfBoxCm || "-",
      },
      {
        header: componentText.field.widthOfBoxCm,
        accessorKey: "widthOfBoxCm",
        cell: ({ row }) => row.original.widthOfBoxCm || "-",
      },
      {
        header: componentText.field.lengthOfDetailCm,
        accessorKey: "lengthOfDetailCm",
        cell: ({ row }) => row.original.lengthOfDetailCm || "-",
      },
      {
        header: componentText.field.description,
        accessorKey: "description",
        cell: ({ row }) => row.original.description || "-",
      },
      {
        header: "",
        accessorKey: "action",
        size: 40,
        cell: ({ row }) => (
          <ProductStructureActionDropdown
            onAction={handleActionClick}
            data={row.original}
          />
        ),
      },
    ],
    [componentText],
  );

  const { mutateAsync: deleteComponent } = useDeleteComponent({
    id,
  });

  return (
    <>
      <div className="p-3 py-0 h-100 d-flex flex-column">
        <DataTable<ComponentWithChildren>
          columns={columns}
          data={productStructure || []}
          total={productStructure?.length || 0}
          loading={isLoadingProductStructure}
          isHiddenPagination
          isTreeTable
          getSubRows={(row) => row.children}
        />
      </div>
    </>
  );
};

export default DetailDetailStructure;
