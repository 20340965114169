import { useAppTranslation } from "app/hooks";
import { Panel } from "app/services/panel";
import isEmpty from "lodash/isEmpty";
import { FC, MouseEvent, useState } from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";

export type WorkshopPanelAction = "create" | "edit" | "delete" | "create-user";

export interface WorkshopPanelActionDropdownProps {
  data?: Panel;
  onAction: (action: WorkshopPanelAction, data?: Panel) => void;
}

export const WorkshopPanelActionDropdown: FC<
  WorkshopPanelActionDropdownProps
> = ({ data, onAction }) => {
  const { buttonText, panelText } = useAppTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const handleToggle = () => setIsOpen(!isOpen);

  const handleAction = (action: WorkshopPanelAction) => () => {
    onAction(action, data);
    setIsOpen(false);
  };

  const isHaveUser = !isEmpty(data?.user);

  return (
    <Dropdown
      isOpen={isOpen}
      toggle={(e: MouseEvent) => {
        e.stopPropagation();
        handleToggle();
      }}
      direction="start"
    >
      <DropdownToggle
        tag="button"
        className="btn py-0 px-2 m-0"
        onClick={(e) => {
          const trClosest = e.currentTarget.closest("tr");
          trClosest?.classList.toggle("z-3", !isOpen);
        }}
        onBlur={(e) => {
          const trClosest = e.currentTarget.closest("tr");
          setTimeout(() => trClosest?.classList.remove("z-3"), 100);
        }}
      >
        <i className="ri-more-fill"></i>
      </DropdownToggle>
      <DropdownMenu className="dropdown-menu-start mr-2">
        {!isHaveUser ? (
          <DropdownItem className="p-0" onClick={handleAction("create-user")}>
            <div className="dropdown-item">
              <i className="ri-user-add-line text-muted fs-16 align-middle me-1"></i>
              <span className="align-middle">{panelText.addAccount}</span>
            </div>
          </DropdownItem>
        ) : null}
        <DropdownItem className="p-0" onClick={handleAction("edit")}>
          <div className="dropdown-item">
            <i className="ri-pencil-line text-muted fs-16 align-middle me-1"></i>
            <span className="align-middle">{buttonText.edit}</span>
          </div>
        </DropdownItem>
        <DropdownItem className="p-0" onClick={handleAction("delete")}>
          <div className="dropdown-item">
            <i className="ri-delete-bin-line text-muted fs-16 align-middle me-1"></i>
            <span className="align-middle">{buttonText.delete}</span>
          </div>
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};
