import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export const useAppTranslation = () => {
  const { t, i18n } = useTranslation();

  const placeHolderText = {
    search: t("placeholder.search"),
    input: t("placeholder.input"),
    select: t("placeholder.select"),
    date: t("placeholder.date"),
    createNew: t("placeholder.createNew"),
  };

  const uploadFileText = {
    title: t("uploadFile.title"),
    deleteBtn: t("uploadFile.deleteBtn"),
    imageTitle: t("uploadFile.imageTitle"),
    documentTitle: t("uploadFile.documentTitle"),
  };

  const buttonText = {
    cancel: t("button.cancel"),
    save: t("button.save"),
    edit: t("button.edit"),
    delete: t("button.delete"),
    add: t("button.add"),
    detail: t("button.detail"),
    back: t("button.back"),
    confirm: t("button.confirm"),
    duplicate: t("button.duplicate"),
    download: t("button.download"),
    upload: t("button.upload"),
    active: t("button.active"),
    deactive: t("button.deactive"),
    view: t("button.view"),
    approve: t("button.approve"),
    reject: t("button.reject"),
    start: t("button.start"),
    checkIn: t("button.checkIn"),
    downloadTemplate: t("button.downloadTemplate"),
    import: t("button.import"),
    importAvatar: t("button.importAvatar"),
    export: t("button.export"),
  };

  const confirmText = {
    deleteTitle: (name: string) => t("confirm.delete.title", { name }),
    deleteContent: (name: string) => t("confirm.delete.content", { name }),
    approveTitle: (name: string) => t("confirm.approve.title", { name }),
    approveContent: (name: string) => t("confirm.approve.content", { name }),
    rejectTitle: (name: string) => t("confirm.reject.title", { name }),
    rejectContent: (name: string) => t("confirm.reject.content", { name }),
    activeTitle: (name: string) => t("confirm.active.title", { name }),
    activeContent: (name: string) => t("confirm.active.content", { name }),
  };

  const limitPageText = {
    show: t("limitPage.show"),
    in: t("limitPage.in"),
    result: t("limitPage.result"),
  };

  const noDataTableText = t("noDataTable");

  const menuText = {
    title: t("menu.title"),
    dashboard: t("menu.dashboar"),
    employee: t("menu.employee"),
    account: t("menu.account"),
    vendor: t("menu.vendor"),
    customer: t("menu.customer"),
    order: t("menu.order"),
    product: t("menu.product"),
    role: t("menu.role"),
    masterData: t("menu.masterData"),
  };

  const authText = {
    login: t("auth.login"),
    titleLogin: t("auth.titleLogin"),
    username: t("auth.username"),
    password: t("auth.password"),
    forgotPassword: t("auth.forgotPassword"),
    logout: t("auth.logout"),
    userInfo: t("auth.userInfo"),
    greeting: t("auth.greeting"),
    oldPassword: t("auth.oldPassword"),
    newPassword: t("auth.newPassword"),
    confirmPassword: t("auth.confirmPassword"),
    changePassword: t("auth.changePassword"),
  };

  const employeeText = {
    title: t("employee.title"),
    add: t("employee.add"),
    edit: t("employee.edit"),
    addAccount: t("employee.addAccount"),
    createEmployeeIdCode: t("employee.createEmployeeIdCode"),
    deactiveEmployeeIdCode: t("employee.deactiveEmployeeIdCode"),
    createEmployeeIdCodeContent: t("employee.createEmployeeIdCodeContent"),
    deactiveEmployeeIdCodeContent: t("employee.deactiveEmployeeIdCodeContent"),
    uploadAvatarTitle: t("employee.uploadAvatarTitle"),
    uploadAvatarContent: t("employee.uploadAvatarContent"),
    uploadAvatarNote: t("employee.uploadAvatarNote"),
    detail: {
      title: t("employee.detail.title"),
      infomation: t("employee.detail.infomation"),
      tab: {
        general: t("employee.detail.tab.general"),
        idCode: t("employee.detail.tab.idCode"),
      },
    },
    field: {
      fullName: t("employee.field.fullName"),
      firstName: t("employee.field.firstName"),
      middleName: t("employee.field.middleName"),
      lastName: t("employee.field.lastName"),
      employeeCode: t("employee.field.employeeCode"),
      username: t("employee.field.username"),
      email: t("employee.field.email"),
      phone: t("employee.field.phone"),
      jobTitle: t("employee.field.jobTitle"),
      department: t("employee.field.department"),
      gender: t("employee.field.gender"),
      dateOfBirth: t("employee.field.dateOfBirth"),
      taxCode: t("employee.field.taxCode"),
      nationalId: t("employee.field.nationalId"),
      dateOfIssue: t("employee.field.dateOfIssue"),
      placeOfIssue: t("employee.field.placeOfIssue"),
      permanentAddress: t("employee.field.permanentAddress"),
      dateOfJoining: t("employee.field.dateOfJoining"),
      dateOfLeaving: t("employee.field.dateOfLeaving"),
      avatar: t("employee.field.avatar"),
      skill: t("employee.field.skill"),
      address: t("employee.field.address"),
      socialInsuranceCode: t("employee.field.socialInsuranceCode"),
      note: t("employee.field.note"),
      employeeIdCode: t("employee.field.employeeIdCode"),
      isActive: t("employee.field.isActive"),
    },
    error: {
      departmentRequired: t("employee.error.departmentRequired"),
      jobTitleRequired: t("employee.error.jobTitleRequired"),
      employeeCodeRequired: t("employee.error.employeeCodeRequired"),
      firstNameRequired: t("employee.error.firstNameRequired"),
      lastNameRequired: t("employee.error.lastNameRequired"),
      emailRequired: t("employee.error.emailRequired"),
      emailInvalid: t("employee.error.emailInvalid"),
      phoneRequired: t("employee.error.phoneRequired"),
    },
  };

  const accountText = {
    title: t("account.title"),
    activeTitle: t("account.activeTitle"),
    activeContent: t("account.activeContent"),
    deactiveTitle: t("account.deactiveTitle"),
    deactiveContent: t("account.deactiveContent"),
    field: {
      name: t("account.field.name"),
      username: t("account.field.username"),
      fullName: t("account.field.fullName"),
      role: t("account.field.role"),
      status: t("account.field.status"),
      password: t("account.field.password"),
      accessType: t("account.field.accessType"),
    },
  };

  const vendorText = {
    title: t("vendor.title"),
    add: t("vendor.add"),
    edit: t("vendor.edit"),
    detail: {
      infomation: t("vendor.detail.infomation"),
      title: t("vendor.detail.title"),
      tab: {
        general: t("vendor.detail.tab.general"),
      },
    },
    field: {
      vendorCode: t("vendor.field.vendorCode"),
      vendorName: t("vendor.field.vendorName"),
      vendorType: t("vendor.field.vendorType"),
      address: t("vendor.field.address"),
      phoneNumber: t("vendor.field.phoneNumber"),
      email: t("vendor.field.email"),
      website: t("vendor.field.website"),
      representative: t("vendor.field.representative"),
      representativeRole: t("vendor.field.representativeRole"),
      notes: t("vendor.field.notes"),
      startDate: t("vendor.field.startDate"),
      vendorStatus: t("vendor.field.vendorStatus"),
      assignee: t("vendor.field.assignee"),
    },
  };

  const customerText = {
    title: t("customer.title"),
    add: t("customer.add"),
    edit: t("customer.edit"),
    detail: {
      infomation: t("customer.detail.infomation"),
      title: t("customer.detail.title"),
      tab: {
        general: t("customer.detail.tab.general"),
      },
    },
    field: {
      customerCode: t("customer.field.customerCode"),
      customerName: t("customer.field.customerName"),
      customerType: t("customer.field.customerType"),
      address: t("customer.field.address"),
      phoneNumber: t("customer.field.phoneNumber"),
      email: t("customer.field.email"),
      website: t("customer.field.website"),
      representative: t("customer.field.representative"),
      representativeRole: t("customer.field.representativeRole"),
      paymentTerms: t("customer.field.paymentTerms"),
      depositPercentage: t("customer.field.depositPercentage"),
      paymentDueDays: t("customer.field.paymentDueDays"),
      notes: t("customer.field.notes"),
      startDate: t("customer.field.startDate"),
      customerStatus: t("customer.field.customerStatus"),
      assignee: t("customer.field.assignee"),
      avatar: t("customer.field.avatar"),
    },
    error: {
      customerNameRequired: t("customer.error.customerNameRequired"),
      emailInvalid: t("customer.error.emailInvalid"),
      websiteInvalid: t("customer.error.websiteInvalid"),
      depositPercentageInvalid: t("customer.error.depositPercentageInvalid"),
      paymentDueDaysInvalid: t("customer.error.paymentDueDaysInvalid"),
    },
  };

  const orderText = {
    title: t("order.title"),
    add: t("order.add"),
    edit: t("order.edit"),
    detail: {
      infomation: t("order.detail.infomation"),
      title: t("order.detail.title"),
      tab: {
        general: t("order.detail.tab.general"),
        orderItems: t("order.detail.tab.orderItems"),
      },
    },
    field: {
      orderCode: t("order.field.orderCode"),
      orderDate: t("order.field.orderDate"),
      customer: t("order.field.customer"),
      expectedDeliveryDate: t("order.field.expectedDeliveryDate"),
      notes: t("order.field.notes"),
      productInfomation: t("order.field.productInfomation"),
      product: t("order.field.product"),
      quantity: t("order.field.quantity"),
      deliveryDate: t("order.field.deliveryDate"),
      image: t("order.field.image"),
      document: t("order.field.document"),
    },
  };

  const productText = useMemo(
    () => ({
      title: t("product.title"),
      add: t("product.add"),
      edit: t("product.edit"),
      addComponentTitle: t("product.addComponentTitle"),
      editComponentTitle: t("product.editComponentTitle"),
      deleteComponentTitle: t("product.deleteComponentTitle"),
      deleteComponentContent: t("product.deleteComponentContent"),
      addMaterialTitle: t("product.addMaterialTitle"),
      editMaterialTitle: t("product.editMaterialTitle"),
      deleteMaterialTitle: t("product.deleteMaterialTitle"),
      deleteMaterialContent: t("product.deleteMaterialContent"),
      activeTitle: t("product.activeTitle"),
      activeContent: t("product.activeContent"),
      deactiveTitle: t("product.deactiveTitle"),
      deactiveContent: t("product.deactiveContent"),
      list: {
        tab: {
          all: t("product.list.tab.all"),
          product: t("product.list.tab.product"),
          cluster: t("product.list.tab.cluster"),
          detail: t("product.list.tab.detail"),
        },
      },
      detail: {
        infomation: t("product.detail.infomation"),
        generalInfomation: t("product.detail.generalInfomation"),
        detailInfomation: t("product.detail.detailInfomation"),
        title: t("product.detail.title"),
        tab: {
          general: t("product.detail.tab.general"),
          orders: t("product.detail.tab.orders"),
          structure: t("product.detail.tab.structure"),
          bom: t("product.detail.tab.bom"),
        },
      },
      field: {
        code: t("product.field.code"),
        nameEn: t("product.field.nameEn"),
        nameVn: t("product.field.nameVn"),
        name: t("product.field.name"),
        group: t("product.field.group"),
        unit: t("product.field.unit"),
        countryOfOrigin: t("product.field.countryOfOrigin"),
        description: t("product.field.description"),
        lengthCm: t("product.field.lengthCm"),
        widthCm: t("product.field.widthCm"),
        heightCm: t("product.field.heightCm"),
        seatSizeCm: t("product.field.seatSizeCm"),
        color: t("product.field.color"),
        isActive: t("product.field.isActive"),
        status: t("product.field.status"),
        avatar: t("product.field.avatar"),
        workStep: t("product.field.workStep"),
        workStepName: t("product.field.workStepName"),
        order: t("product.field.order"),
        estimateTime: t("product.field.estimateTime"),
        time: t("product.field.time"),
        image: t("product.field.image"),
        document: t("product.field.document"),
        quantity: t("product.field.quantity"),
        note: t("product.field.note"),
        material: t("product.field.material"),
      },
    }),
    [t],
  );

  const componentText = {
    title: t("component.title"),
    childTitle: t("component.childTitle"),
    parentTitle: t("component.parentTitle"),
    add: t("component.add"),
    edit: t("component.edit"),
    detail: {
      infomation: t("component.detail.infomation"),
      generalInfomation: t("component.detail.generalInfomation"),
      detailInfomation: t("component.detail.detailInfomation"),
      title: t("component.detail.title"),
      tab: {
        general: t("component.detail.tab.general"),
        structure: t("component.detail.tab.structure"),
      },
    },
    field: {
      name: t("component.field.name"),
      code: t("component.field.code"),
      quantity: t("component.field.quantity"),
      description: t("component.field.description"),
      type: t("component.field.type"),
      frameType: t("component.field.frameType"),
      workpieceType: t("component.field.workpieceType"),
      lengthOfBoxCm: t("component.field.lengthOfBoxCm"),
      widthOfBoxCm: t("component.field.widthOfBoxCm"),
      lengthOfDetailCm: t("component.field.lengthOfDetailCm"),
      thicknessCm: t("component.field.thicknessCm"),
      workStep: t("component.field.workStep"),
      workStepName: t("component.field.workStepName"),
      order: t("component.field.order"),
      estimateTime: t("component.field.estimateTime"),
      image: t("component.field.image"),
      document: t("component.field.document"),
      avatar: t("component.field.avatar"),
    },
    createNote: t("component.createNote"),
    createFor: t("component.createFor"),
  };

  const roleText = {
    title: t("role.title"),
    add: t("role.add"),
    edit: t("role.edit"),
    field: {
      name: t("role.field.name"),
      description: t("role.field.description"),
      permission: t("role.field.permission"),
    },
    error: {
      nameRequired: "Role name is required",
    },
  };

  const masterDataText = {
    title: t("masterData.title"),
    add: t("masterData.add"),
    edit: t("masterData.edit"),
    field: {
      name: t("masterData.field.name"),
      description: t("masterData.field.description"),
      type: t("masterData.field.type"),
      color: t("masterData.field.color"),
    },
  };

  const productionPlanText = {
    title: t("productionPlan.title"),
    add: t("productionPlan.add"),
    edit: t("productionPlan.edit"),
    field: {
      name: t("productionPlan.field.name"),
      description: t("productionPlan.field.description"),
      startDate: t("productionPlan.field.startDate"),
      endDate: t("productionPlan.field.endDate"),
      items: t("productionPlan.field.items"),
      order: t("productionPlan.field.order"),
      orderItem: t("productionPlan.field.orderItem"),
      quantity: t("productionPlan.field.quantity"),
      totalQuantity: t("productionPlan.field.totalQuantity"),
      quantityRemaining: t("productionPlan.field.quantityRemaining"),
      status: t("productionPlan.field.status"),
    },
    detail: {
      infomation: t("productionPlan.detail.infomation"),
      generalInfomation: t("productionPlan.detail.generalInfomation"),
      detailInfomation: t("productionPlan.detail.detailInfomation"),
      title: t("productionPlan.detail.title"),
      tab: {
        general: t("productionPlan.detail.tab.general"),
        item: t("productionPlan.detail.tab.item"),
        order: t("productionPlan.detail.tab.order"),
      },
    },
  };

  const productionOrderText = {
    title: t("productionOrder.title"),
    add: t("productionOrder.add"),
    edit: t("productionOrder.edit"),
    field: {
      name: t("productionOrder.field.name"),
      note: t("productionOrder.field.note"),
      startDate: t("productionOrder.field.startDate"),
      endDate: t("productionOrder.field.endDate"),
      items: t("productionOrder.field.items"),
      order: t("productionOrder.field.order"),
      orderItem: t("productionOrder.field.orderItem"),
      quantity: t("productionOrder.field.quantity"),
      totalQuantity: t("productionOrder.field.totalQuantity"),
      quantityRemaining: t("productionOrder.field.quantityRemaining"),
      status: t("productionOrder.field.status"),
      planItem: t("productionOrder.field.planItem"),
      plan: t("productionOrder.field.plan"),
      assignee: t("productionOrder.field.assignee"),
    },
    detail: {
      infomation: t("productionOrder.detail.infomation"),
      generalInfomation: t("productionOrder.detail.generalInfomation"),
      detailInfomation: t("productionOrder.detail.detailInfomation"),
      title: t("productionOrder.detail.title"),
      tab: {
        general: t("productionOrder.detail.tab.general"),
        item: t("productionOrder.detail.tab.item"),
        ticket: t("productionOrder.detail.tab.ticket"),
      },
    },
    button: {
      createTicket: t("productionOrder.button.createTicket"),
    },
  };

  const filterText = {
    title: t("filter.title"),
    button: {
      clear: t("filter.button.clear"),
      filter: t("filter.button.filter"),
    },
  };

  const workshopText = {
    title: t("workshop.title"),
    add: t("workshop.add"),
    edit: t("workshop.edit"),
    detail: {
      infomation: t("workshop.detail.infomation"),
      title: t("workshop.detail.title"),
      tab: {
        general: t("workshop.detail.tab.general"),
        panel: t("workshop.detail.tab.panel"),
      },
    },
    field: {
      name: t("workshop.field.name"),
      address: t("workshop.field.address"),
      description: t("workshop.field.description"),
    },
  };

  const ticketText = {
    title: t("ticket.title"),
    add: t("ticket.add"),
    edit: t("ticket.edit"),
    addLog: t("ticket.addLog"),
    editLog: t("ticket.editLog"),
    startTitle: t("ticket.startTitle"),
    startDescription: t("ticket.startDescription"),
    checkInTitle: t("ticket.checkInTitle"),
    editCheckInTitle: t("ticket.editCheckInTitle"),
    checkInDescription: t("ticket.checkInDescription"),
    detail: {
      ticketList: t("ticket.detail.ticketList"),
      progressTicket: t("ticket.detail.progressTicket"),
      infomation: t("ticket.detail.infomation"),
      productInfomation: t("ticket.detail.productInfomation"),
      title: t("ticket.detail.title"),
      tab: {
        general: t("ticket.detail.tab.general"),
        log: t("ticket.detail.tab.log"),
      },
    },
    field: {
      name: t("ticket.field.name"),
      component: t("ticket.field.component"),
      detail: t("ticket.field.detail"),
      product: t("ticket.field.product"),
      productionOrderItem: t("ticket.field.productionOrderItem"),
      workStep: t("ticket.field.workStep"),
      step: t("step.title"),
      assignee: t("ticket.field.assignee"),
      workshop: t("ticket.field.workshop"),
      quantity: t("ticket.field.quantity"),
      note: t("ticket.field.note"),
      description: t("ticket.field.description"),
      productionPlan: t("ticket.field.productionPlan"),
      productionOrder: t("ticket.field.productionOrder"),
      status: t("ticket.field.status"),
      createdAt: t("ticket.field.createdAt"),
      checkInAt: t("ticket.field.checkInAt"),
      employee: t("ticket.field.employee"),
      startDate: t("ticket.field.startDate"),
      endDate: t("ticket.field.endDate"),
    },
  };

  const stepText = {
    title: t("step.title"),
    add: t("step.add"),
    edit: t("step.edit"),
    field: {
      name: t("step.field.name"),
      description: t("step.field.description"),
      skill: t("step.field.skill"),
    },
    detail: {
      infomation: t("step.detail.infomation"),
      title: t("step.detail.title"),
      tab: {
        general: t("step.detail.tab.general"),
      },
    },
  };

  const panelText = {
    title: t("panel.title"),
    add: t("panel.add"),
    addAccount: t("panel.addAccount"),
    edit: t("panel.edit"),
    detail: {
      infomation: t("panel.detail.infomation"),
      title: t("panel.detail.title"),
      tab: {
        general: t("panel.detail.tab.general"),
      },
    },
    field: {
      name: t("panel.field.name"),
      username: t("panel.field.username"),
      description: t("panel.field.description"),
    },
  };

  const generalInfomationText = t("generalInfomation");

  const materialText = {
    title: t("material.title"),
    add: t("material.add"),
    edit: t("material.edit"),
    detail: {
      infomation: t("material.detail.infomation"),
      generalInfomation: t("productionOrder.detail.generalInfomation"),
      title: t("material.detail.title"),
      tab: {
        general: t("material.detail.tab.general"),
      },
    },
    field: {
      code: t("material.field.code"),
      name: t("material.field.name"),
      purchasePrice: t("material.field.purchasePrice"),
      description: t("material.field.description"),
      unit: t("material.field.unit"),
      vendor: t("material.field.vendor"),
      quantity: t("material.field.quantity"),
      note: t("material.field.note"),
    },
    createNote: t("material.createNote"),
    createFor: t("material.createFor"),
  };

  const bomText = {
    title: t("bom.title"),
    add: t("bom.add"),
    edit: t("bom.edit"),
    detail: {
      infomation: t("bom.detail.infomation"),
      generalInfomation: t("component.detail.generalInfomation"),
      title: t("bom.detail.title"),
      tab: {
        general: t("bom.detail.tab.general"),
      },
    },
    field: {
      product: t("bom.field.product"),
      name: t("bom.field.name"),
      description: t("bom.field.description"),
      version: t("bom.field.version"),
      status: t("bom.field.status"),
    },
  };

  const excelText = {
    downloadTemplate: t("excel.downloadTemplate"),
    import: t("excel.import"),
    export: t("excel.export"),
    uploadTitle: t("excel.uploadTitle"),
    uploadContent1: t("excel.uploadContent1"),
    uploadContent2: t("excel.uploadContent2"),
    uploadContent3: t("excel.uploadContent3"),
    errorInvalidType: t("excel.errorInvalidType"),
  };

  return {
    t,
    uploadFileText,
    i18n,
    authText,
    employeeText,
    placeHolderText,
    menuText,
    limitPageText,
    noDataTableText,
    buttonText,
    confirmText,
    accountText,
    vendorText,
    customerText,
    orderText,
    productText,
    componentText,
    roleText,
    masterDataText,
    productionPlanText,
    filterText,
    productionOrderText,
    workshopText,
    ticketText,
    stepText,
    panelText,
    generalInfomationText,
    excelText,
    materialText,
    bomText,
  };
};
