import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { httpClient } from "app/datasources";
import { AxiosError, AxiosResponse } from "axios";
import { Response } from "../types";
import { Product } from "./types";

export const UPDATE_PRODUCT_MATERIAL_URL = (id: ID) =>
  `/api/admin/product-materials/${id}`;

export enum UpdateProductMaterialError {}

export const updateProductMaterialErrorMessages = {};

export type UpdateProductMaterialResponse = Response<Product>;

export type UpdateProductMaterialArgs = {
  id: ID;
  materialId: ID;
  quantity: number;
  note: string | null;
};

export const updateProductMaterial = ({
  id,
  ...args
}: UpdateProductMaterialArgs) => {
  return httpClient.put<UpdateProductMaterialResponse>(
    UPDATE_PRODUCT_MATERIAL_URL(id),
    args,
  );
};

export const useUpdateProductMaterialMutation = (
  opts?: Omit<
    UseMutationOptions<
      AxiosResponse<UpdateProductMaterialResponse>,
      AxiosError<any>,
      UpdateProductMaterialArgs
    >,
    "mutationFn"
  >,
) =>
  useMutation<
    AxiosResponse<UpdateProductMaterialResponse>,
    AxiosError<any>,
    UpdateProductMaterialArgs
  >({
    mutationFn: updateProductMaterial,
    mutationKey: [UPDATE_PRODUCT_MATERIAL_URL],
    ...opts,
  });
