import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { httpClient } from "app/datasources";
import { AxiosError, AxiosResponse } from "axios";
import { ComponentWithChildren } from ".";
import { Response } from "../types";

export const RETRIEVE_PRODUCT_STRUCTURE_BY_ID_URL = (id: ID) =>
  `/api/admin/products/${id}/structure`;

export type RetrieveProductStructureByIdResponse = Response<
  ComponentWithChildren[]
>;

export type RetrieveProductStructureByIdArgs = {
  id: ID;
};

export const retrieveProductStructureById = ({
  id,
}: RetrieveProductStructureByIdArgs) => {
  return httpClient.get<RetrieveProductStructureByIdResponse>(
    RETRIEVE_PRODUCT_STRUCTURE_BY_ID_URL(id),
  );
};

export const useRetrieveProductStructureByIdQuery = (
  retrieveProductStructuresArgs: RetrieveProductStructureByIdArgs,
  opts?: Omit<
    UseQueryOptions<
      AxiosResponse<RetrieveProductStructureByIdResponse>,
      AxiosError<any>
    >,
    "queryKey" | "queryFn"
  >,
) =>
  useQuery<
    AxiosResponse<RetrieveProductStructureByIdResponse>,
    AxiosError<any>
  >({
    queryKey: [
      RETRIEVE_PRODUCT_STRUCTURE_BY_ID_URL(retrieveProductStructuresArgs.id),
    ],
    queryFn: () => retrieveProductStructureById(retrieveProductStructuresArgs),
    ...opts,
  });
