import { DebouncedInput } from "app/components/molecules/DebounceInput";
import { OptionSelect } from "app/services/types";
import { FC } from "react";

export interface ProductFilterParams {
  type: OptionSelect | null;
}

export interface ListProductFilterProps {
  searchString: string;
  handleSearchChange: (value: string) => void;
}

export const ListProductFilter: FC<ListProductFilterProps> = ({
  handleSearchChange,
  searchString,
}) => {
  return (
    <div className="d-flex align-items-center mb-2">
      <div className="search-box me-2 d-inline-block">
        <DebouncedInput
          name="list-product-search"
          value={searchString || ""}
          onChange={(value) => {
            handleSearchChange(String(value));
          }}
        />
        <i className="bx bx-search-alt search-icon"></i>
      </div>
    </div>
  );
};
