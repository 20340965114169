import { ColumnDef } from "@tanstack/react-table";
import { useConfirm } from "app/components/molecules/ConfirmationProvider";
import { DataTable } from "app/components/organisms/DataTable";
import { useDetailPageContext } from "app/components/templates/DetailPage/DetailPageContext";
import { formatDateTime } from "app/helpers/utils";
import { useAppTranslation } from "app/hooks";
import {
  TicketLogFormData,
  TicketLogFormModal,
} from "app/modules/ticket/components";
import {
  TicketLogAction,
  TicketLogActionDropdown,
} from "app/modules/ticket/components/TicketLogActionDropdown";
import {
  useDeleteTicketLog,
  useUpdateTicketLog,
} from "app/modules/ticketLog/hooks";
import { mapUpdateTicketLog } from "app/modules/ticketLog/mapper";
import { Ticket, TicketStatus } from "app/services/ticket";
import { TicketLog } from "app/services/ticketLog";
import { FC, useMemo, useRef, useState } from "react";

export interface DetailTicketLogsProps {}

const DetailTicketLogs: FC<DetailTicketLogsProps> = () => {
  const { ticketText, confirmText } = useAppTranslation();
  const { data, loading } = useDetailPageContext<Ticket>();
  const [isCheckInModalOpen, toggleCheckInModal] = useState(false);
  const rowSelected = useRef<TicketLog | null>(null);
  const { confirm } = useConfirm();

  const handleToggleCheckInModal = (data?: TicketLog) => {
    if (data) {
      rowSelected.current = data;
    }
    if (isCheckInModalOpen) {
      rowSelected.current = null;
    }
    toggleCheckInModal((prevState) => !prevState);
  };

  const ticketLogs = data?.ticketLogs;

  const { mutateAsync: deleteLog } = useDeleteTicketLog({ id: data?.id });

  const handleActionClick = (action: TicketLogAction, data?: TicketLog) => {
    if (data) {
      rowSelected.current = data;
    }
    switch (action) {
      case "edit":
        if (data) {
          handleToggleCheckInModal(data);
        }
        break;
      case "delete":
        if (rowSelected.current) {
          confirm({
            title: confirmText.deleteTitle(ticketText.detail.infomation),
            description: confirmText.deleteContent(
              ticketText.detail.infomation,
            ),
            onConfirm: () => deleteLog({ id: rowSelected.current?.id as ID }),
          });
        }
        break;
      default:
        break;
    }
  };

  const columns: ColumnDef<TicketLog>[] = useMemo(
    () => [
      {
        header: ticketText.field.checkInAt,
        accessorKey: "checkInAt",
        cell: ({ row }) =>
          row.original.checkinAt ? formatDateTime(row.original.checkinAt) : "-",
      },
      {
        header: ticketText.field.quantity,
        accessorKey: "quantity",
        cell: ({ row }) => row.original.quantity || "-",
      },
      {
        header: ticketText.field.assignee,
        accessorKey: "assignee",
        cell: ({ row }) => row.original.employee?.fullName || "-",
      },
      {
        header: "",
        accessorKey: "action",
        size: 40,
        cell: ({ row }) => (
          <TicketLogActionDropdown
            onAction={handleActionClick}
            data={row.original}
            isTicketCompleted={data?.status === TicketStatus.Completed}
          />
        ),
      },
    ],
    [ticketText],
  );

  const { mutateAsync: updateLog, isPending: isUpdating } = useUpdateTicketLog({
    ticketId: data?.id,
    id: rowSelected.current?.id,
    onSuccess: handleToggleCheckInModal,
  });

  const handleUpdateLog = (data: TicketLogFormData) => {
    updateLog(
      mapUpdateTicketLog({ ...data, id: rowSelected.current?.id as ID }),
    );
  };

  return (
    <>
      <div className="p-3 py-0 h-100 d-flex flex-column">
        <DataTable<TicketLog>
          columns={columns}
          data={ticketLogs || []}
          loading={loading}
          total={ticketLogs?.length || 0}
          isHiddenPagination
        />
      </div>
      <TicketLogFormModal
        open={isCheckInModalOpen}
        onToggle={handleToggleCheckInModal}
        onSubmit={handleUpdateLog}
        submitting={isUpdating}
        edit={!!rowSelected.current}
        data={rowSelected.current || undefined}
      />
    </>
  );
};

export default DetailTicketLogs;
