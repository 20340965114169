import FormPage from "app/components/templates/FormPage";
import { useAppTranslation } from "app/hooks";
import {
  MaterialForm,
  MaterialFormData,
} from "app/modules/material/components";
import {
  useRetrieveMaterialById,
  useUpdateMaterial,
} from "app/modules/material/hooks";
import {
  mapDefaultMaterial,
  mapUpdateMaterial,
} from "app/modules/material/mapper";
import BreadCrumb from "Components/Common/BreadCrumb";
import { FC } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Card, CardBody } from "reactstrap";

export interface UpdateMaterialProps {}

const UpdateMaterial: FC<UpdateMaterialProps> = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { materialText } = useAppTranslation();

  const { material, isLoading } = useRetrieveMaterialById(
    { id: id as string },
    { enabled: !!id },
  );

  const { mutateAsync, isPending } = useUpdateMaterial({
    id,
    onSuccess: () => {
      navigate("/materials");
    },
  });

  const handleSubmit = async (data: MaterialFormData) => {
    mutateAsync(mapUpdateMaterial({ id: id as string, ...data }));
  };

  return (
    <FormPage loading={isLoading}>
      <FormPage.BreadCrumb>
        <BreadCrumb
          title={materialText.edit}
          pageTitle={materialText.title}
          isBack
        />
      </FormPage.BreadCrumb>
      <FormPage.Main>
        <Card>
          <CardBody>
            <MaterialForm
              onSubmit={handleSubmit}
              defaultValues={mapDefaultMaterial(material)}
              submitting={isPending}
            />
          </CardBody>
        </Card>
      </FormPage.Main>
    </FormPage>
  );
};

export default UpdateMaterial;
