import {
  CreateTicketLogArgs,
  TicketLog,
  UpdateTicketLogArgs,
} from "app/services/ticketLog";
import { TicketLogFormData } from "../ticket/components/TicketLogFormModal";

export const mapDefaultTicketLog = ({
  employee,
  note,
  quantity,
  ticket,
}: Partial<TicketLog>): TicketLogFormData => {
  return {
    employee: employee
      ? {
          label: employee?.fullName || "-",
          value: employee.id,
        }
      : null,
    note: note || "",
    quantity: quantity || null,
    defaultQuantity: quantity,
    defaultRemaningQuantity: ticket?.remainingQuantity,
    remainingQuantity: ticket?.remainingQuantity,
    totalQuantity: ticket?.quantity,
  };
};

export const mapCreateTicketLog = ({
  employee,
  note,
  quantity,
  ticketId,
}: TicketLogFormData & { ticketId: ID }): CreateTicketLogArgs => {
  return {
    ticketId,
    employeeId: employee?.value as ID,
    note,
    quantity: quantity as number,
  };
};

export const mapUpdateTicketLog = ({
  quantity,
  id,
}: TicketLogFormData & { id: ID }): UpdateTicketLogArgs => {
  return {
    id,
    quantity: quantity as number,
  };
};
