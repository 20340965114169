import { useAppTranslation } from "app/hooks";
import { Bom, BomStatus } from "app/services/bom";
import { FC, MouseEvent, useState } from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";

export type BomAction =
  | "edit"
  | "delete"
  | "detail"
  | "active"
  | "deactive"
  | "approve"
  | "reject";

export interface BomActionDropdownProps {
  data?: Bom;
  onAction: (action: BomAction, data?: Bom) => void;
}

export const BomActionDropdown: FC<BomActionDropdownProps> = ({
  data,
  onAction,
}) => {
  const { buttonText } = useAppTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const handleToggle = () => setIsOpen(!isOpen);

  const handleAction = (action: BomAction) => () => {
    onAction(action, data);
    setIsOpen(false);
  };

  const isWaitForApprove = data?.status === BomStatus.WaitForApprove;
  const isActive = data?.status === BomStatus.Active;
  const isInactive = data?.status === BomStatus.InActive;

  return (
    <Dropdown
      isOpen={isOpen}
      toggle={(e: MouseEvent) => {
        e.stopPropagation();
        handleToggle();
      }}
      direction="start"
    >
      <DropdownToggle
        tag="button"
        className="btn py-0 px-2 m-0"
        onClick={(e) => {
          const trClosest = e.currentTarget.closest("tr");
          trClosest?.classList.toggle("z-3", !isOpen);
        }}
        onBlur={(e) => {
          const trClosest = e.currentTarget.closest("tr");
          setTimeout(() => trClosest?.classList.remove("z-3"), 100);
        }}
      >
        <i className="ri-more-fill"></i>
      </DropdownToggle>
      <DropdownMenu className="dropdown-menu-end">
        <DropdownItem onClick={handleAction("detail")}>
          <i className="ri-arrow-right-s-line text-muted fs-16 align-middle me-1"></i>
          <span className="align-middle">{buttonText.detail}</span>
        </DropdownItem>
        <DropdownItem onClick={handleAction("edit")}>
          <i className="ri-pencil-line text-muted fs-16 align-middle me-1"></i>
          <span className="align-middle">{buttonText.edit}</span>
        </DropdownItem>
        <DropdownItem onClick={handleAction("delete")}>
          <i className="ri-delete-bin-line text-muted fs-16 align-middle me-1"></i>
          <span className="align-middle">{buttonText.delete}</span>
        </DropdownItem>
        {isWaitForApprove ? (
          <DropdownItem onClick={handleAction("approve")}>
            <i className="ri-checkbox-circle-line text-muted fs-16 align-middle me-1"></i>
            <span className="align-middle">{buttonText.approve}</span>
          </DropdownItem>
        ) : null}
        {isWaitForApprove ? (
          <DropdownItem onClick={handleAction("reject")}>
            <i className="ri-close-circle-line text-muted fs-16 align-middle me-1"></i>
            <span className="align-middle">{buttonText.reject}</span>
          </DropdownItem>
        ) : null}
        {isInactive ? (
          <DropdownItem onClick={handleAction("active")}>
            <i className="ri-checkbox-circle-line text-muted fs-16 align-middle me-1"></i>
            <span className="align-middle">{buttonText.active}</span>
          </DropdownItem>
        ) : null}
        {isActive ? (
          <DropdownItem onClick={handleAction("deactive")}>
            <i className="ri-close-circle-line text-muted fs-16 align-middle me-1"></i>
            <span className="align-middle">{buttonText.deactive}</span>
          </DropdownItem>
        ) : null}
      </DropdownMenu>
    </Dropdown>
  );
};
