import { ColumnDef, Row as RowType } from "@tanstack/react-table";
import { Chip } from "app/components/atoms/Chip";
import { NestedDataTable } from "app/components/organisms/NestedDataTable";
import { useAppTranslation } from "app/hooks";
import {
  ProductStructureAction,
  ProductStructureActionDropdown,
} from "app/modules/product/components";
import { ComponentWithChildren } from "app/services/product";
import { FC, useCallback, useMemo, useState } from "react";
import { MaterialRow } from "./MaterialRow";
import { StepRow } from "./StepRow";

export interface NestTableProps {
  data: ComponentWithChildren[];
  loading?: boolean;
  handleActionClick: (
    action: ProductStructureAction,
    data?: ComponentWithChildren,
  ) => void;
}

export const NestTable: FC<NestTableProps> = ({
  handleActionClick,
  data,
  loading,
}) => {
  const [showIds, setShowIds] = useState<ID[]>([]);

  const getIdsCanEyeToggle = (data: ComponentWithChildren[]) => {
    const result: ID[] = [];
    data.forEach((row) => {
      if (
        (row.workSteps?.length || 0) > 0 ||
        (row.materials?.length || 0) > 0
      ) {
        result.push(row.id);
      }
      if (row.children) {
        const childResult = getIdsCanEyeToggle(row.children);
        result.push(...childResult);
      }
    });
    return result;
  };

  const idsCanToggle = getIdsCanEyeToggle(data || []);

  const handleAddShowId = useCallback((id: ID) => {
    setShowIds((prev) => [...prev, id]);
  }, []);

  const handleRemoveShowId = useCallback((id: ID) => {
    setShowIds((prev) => prev.filter((i) => i !== id));
  }, []);

  const { componentText } = useAppTranslation();
  const columns: ColumnDef<ComponentWithChildren>[] = useMemo(
    () => [
      {
        header: componentText.field.code,
        accessorKey: "code",
        size: 120,
        cell: ({ row }) => row.original?.code || "-",
      },
      {
        header: componentText.field.name,
        accessorKey: "name",
        size: 250,
        cell: ({ row }) => row.original?.name || "-",
      },
      {
        header: componentText.field.quantity,
        accessorKey: "quantity",
        cell: ({ row }) => row.original.quantity || "-",
      },
      {
        header: componentText.field.type,
        accessorKey: "type",
        cell: ({ row }) =>
          row.original.type?.name ? (
            <Chip
              size="sm"
              color={row.original.type?.colorCode}
              label={row.original.type?.name}
            />
          ) : (
            "-"
          ),
      },
      {
        header: componentText.field.frameType,
        accessorKey: "frameType",
        cell: ({ row }) =>
          row.original.frameType?.name ? (
            <Chip
              size="sm"
              color={row.original.frameType?.colorCode}
              label={row.original.frameType?.name}
            />
          ) : (
            "-"
          ),
      },
      {
        header: componentText.field.workpieceType,
        accessorKey: "workpieceType",
        cell: ({ row }) =>
          row.original.workpieceType?.name ? (
            <Chip
              size="sm"
              color={row.original.workpieceType?.colorCode}
              label={row.original.workpieceType?.name}
            />
          ) : (
            "-"
          ),
      },
      {
        header: componentText.field.thicknessCm,
        accessorKey: "thicknessCm",
        cell: ({ row }) => row.original.thicknessCm || "-",
      },
      {
        header: componentText.field.lengthOfBoxCm,
        accessorKey: "lengthOfBoxCm",
        cell: ({ row }) => row.original.lengthOfBoxCm || "-",
      },
      {
        header: componentText.field.widthOfBoxCm,
        accessorKey: "widthOfBoxCm",
        cell: ({ row }) => row.original.widthOfBoxCm || "-",
      },
      {
        header: componentText.field.lengthOfDetailCm,
        accessorKey: "lengthOfDetailCm",
        cell: ({ row }) => row.original.lengthOfDetailCm || "-",
      },
      {
        header: componentText.field.description,
        accessorKey: "description",
        cell: ({ row }) => row.original.description || "-",
      },
      {
        header: ({ table }) => {
          const rows = table.getGlobalFacetedRowModel().flatRows;
          return idsCanToggle.length === showIds.length ? (
            <i
              onClick={() => {
                setShowIds([]);
              }}
              className="text-muted z-0 fs-16 align-middle me-1 ri-eye-off-line cursor-pointer"
            />
          ) : (
            <i
              onClick={() => {
                setShowIds(idsCanToggle);
                rows.forEach((row) => {
                  if (!row.getIsExpanded()) {
                    row.toggleExpanded();
                  }
                });
              }}
              className="text-muted z-0 fs-16 align-middle me-1 ri-eye-line cursor-pointer"
            />
          );
        },
        accessorKey: "action",
        size: 40,
        cell: ({ row }) => {
          const workSteps = row.original.workSteps || [];
          const productMaterials = row.original.materials || [];
          const isShowEyeButton =
            workSteps.length > 0 || productMaterials.length > 0;
          return (
            <div className="d-flex gap-2 justify-content-end">
              {isShowEyeButton ? (
                showIds.includes(row.original.id) ? (
                  <i
                    onClick={() => handleRemoveShowId(row.original.id)}
                    className="text-muted z-0 fs-16 align-middle me-1 ri-eye-off-line cursor-pointer"
                  />
                ) : (
                  <i
                    onClick={() => {
                      if (!row.getIsExpanded()) {
                        row.toggleExpanded();
                      }
                      handleAddShowId(row.original.id);
                    }}
                    className="text-muted z-0 fs-16 align-middle me-1 ri-eye-line cursor-pointer"
                  />
                )
              ) : null}
              <ProductStructureActionDropdown
                onAction={handleActionClick}
                data={row.original}
              />
            </div>
          );
        },
      },
    ],
    [componentText, showIds],
  );

  const renderSubComponent = useCallback(
    ({ row }: { row: RowType<ComponentWithChildren> }) => {
      const workSteps = row.original.workSteps || [];
      const productMaterials = row.original.materials || [];
      if (!showIds.includes(row.original.id)) {
        return null;
      }

      return workSteps.length > 0 || productMaterials.length > 0 ? (
        <>
          {workSteps?.length > 0 ? (
            <StepRow data={row.original.workSteps || []} />
          ) : null}
          {productMaterials.length > 0 ? (
            <MaterialRow data={row.original.materials || []} />
          ) : null}
        </>
      ) : null;
    },
    [showIds],
  );

  return (
    <NestedDataTable<ComponentWithChildren>
      columns={columns}
      data={data || []}
      total={data?.length || 0}
      loading={loading}
      renderSubComponent={renderSubComponent}
      isHiddenPagination
      isTreeTable
      getSubRows={(row) => row.children}
      getRowCanExpand={(row) => {
        const hasChildren = (row.original.children || []).length > 0;
        return hasChildren;
      }}
    />
  );
};
