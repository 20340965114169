import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { httpClient } from "app/datasources";
import { AxiosError, AxiosResponse } from "axios";
import { Response } from "../types";
import { Bom } from "./types";

export const DELETE_BOM_URL = (id: ID) => `/api/admin/boms/${id}`;

export enum DeleteBomError {}

export const deletedBomErrorMessages = {};

export type DeleteBomResponse = Response<Bom>;

export type DeleteBomArgs = {
  id: ID;
};

export const deleteBom = ({ id }: DeleteBomArgs) => {
  return httpClient.delete<DeleteBomResponse>(DELETE_BOM_URL(id));
};

export const useDeleteBomMutation = (
  opts?: UseMutationOptions<
    AxiosResponse<DeleteBomResponse>,
    AxiosError<Response<null>>,
    DeleteBomArgs
  >,
) => {
  return useMutation({
    mutationKey: [DELETE_BOM_URL],
    mutationFn: (deletedBomArgs: DeleteBomArgs) => deleteBom(deletedBomArgs),
    ...opts,
  });
};
