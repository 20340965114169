import { Chip } from "app/components/atoms/Chip";
import { ListInfoItem } from "app/components/molecules/ListInfoItem";
import { useDetailPageContext } from "app/components/templates/DetailPage/DetailPageContext";
import { useAppTranslation } from "app/hooks";
import { Product } from "app/services/product";
import { FC } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import { DetailProductGeneralMaterial } from "./DetailProductGeneralMaterial";
import { DetailProductGeneralStep } from "./DetailProductGeneralStep";

export interface DetailProductGeneralProps {}

const DetailProductGeneral: FC<DetailProductGeneralProps> = () => {
  const { productText } = useAppTranslation();
  const { data: product, loading } = useDetailPageContext<Product>();
  return (
    <div className="p-3 pb-0">
      <Row>
        <Col xs={12} lg={5}>
          <Card>
            <CardBody>
              <h5 className="card-title mb-3">
                {productText.detail.infomation}
              </h5>
              <Row className="gap-2">
                <Col xs={12}>
                  <ListInfoItem
                    label={productText.field.code}
                    value={product?.code}
                  />
                </Col>
                <Col xs={12}>
                  <ListInfoItem
                    label={productText.field.nameVn}
                    value={product?.nameVn}
                  />
                </Col>
                <Col xs={12}>
                  <ListInfoItem
                    label={productText.field.nameEn}
                    value={product?.nameEn}
                  />
                </Col>
                <Col xs={12}>
                  <ListInfoItem
                    label={productText.field.group}
                    value={
                      product?.productGroup ? (
                        <Chip
                          size="sm"
                          color={product?.productGroup?.colorCode}
                          label={product?.productGroup?.name}
                        />
                      ) : (
                        "-"
                      )
                    }
                  />
                </Col>
                <Col xs={12}>
                  <ListInfoItem
                    label={productText.field.unit}
                    value={
                      product?.productUnit ? (
                        <Chip
                          size="sm"
                          color={product?.productUnit?.colorCode}
                          label={product?.productUnit?.name}
                        />
                      ) : (
                        "-"
                      )
                    }
                  />
                </Col>
                <Col xs={12}>
                  <ListInfoItem
                    label={productText.field.countryOfOrigin}
                    value={product?.countryOfOrigin}
                  />
                </Col>
                <Col xs={12}>
                  <ListInfoItem
                    label={productText.field.isActive}
                    value={product?.isActive ? "Có" : "Không"}
                  />
                </Col>
                <Col xs={12}>
                  <ListInfoItem
                    label={productText.field.description}
                    value={product?.description}
                  />
                </Col>
                <Col xs={12}>
                  <ListInfoItem
                    label={productText.field.lengthCm}
                    value={product?.lengthCm}
                  />
                </Col>
                <Col xs={12}>
                  <ListInfoItem
                    label={productText.field.widthCm}
                    value={product?.widthCm}
                  />
                </Col>
                <Col xs={12}>
                  <ListInfoItem
                    label={productText.field.heightCm}
                    value={product?.heightCm}
                  />
                </Col>
                <Col xs={12}>
                  <ListInfoItem
                    label={productText.field.seatSizeCm}
                    value={product?.seatSizeCm}
                  />
                </Col>
                <Col xs={12}>
                  <ListInfoItem
                    label={productText.field.color}
                    value={product?.color}
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col xs={12} lg={7}>
          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  <h5 className="card-title mb-3">
                    {productText.field.workStep}
                  </h5>
                  <DetailProductGeneralStep
                    data={
                      product?.workSteps?.filter(
                        (i) => i.componentId === null,
                      ) || []
                    }
                    loading={loading}
                  />
                </CardBody>
              </Card>
            </Col>
            <Col xs={12}>
              <Card>
                <CardBody>
                  <h5 className="card-title mb-3">
                    {productText.field.material}
                  </h5>
                  <DetailProductGeneralMaterial
                    data={
                      product?.materials?.filter(
                        (i) => i.componentId === null,
                      ) || []
                    }
                    loading={loading}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default DetailProductGeneral;
