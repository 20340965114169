import { AttachmentAction } from "app/components/molecules/AttachmentActionDropdown";
import { Button } from "app/components/molecules/Button";
import { useConfirm } from "app/components/molecules/ConfirmationProvider";
import { DebouncedInput } from "app/components/molecules/DebounceInput";
import { Loading } from "app/components/molecules/Loading";
import { httpClient } from "app/datasources";
import { handleDownloadFile } from "app/helpers/handleDownloadFile";
import { useAppTranslation } from "app/hooks";
import { FileDTO } from "app/models";
import { FC, Fragment, UIEventHandler, useState } from "react";
import { Row } from "reactstrap";
import { AttachmentBarProps } from "../AttachmentBar";
import { FileUpload } from "../FileUpload/FileUpload";
import { ImagePopup } from "../ImagePopup";
import { SidebarImageGridItem } from "./SidebarImageGridItem";
import { SidebarImageListItem } from "./SidebarImageListItem";

export interface SidebarImageProps {
  imageProps: AttachmentBarProps["imageProps"];
  onSearch?: (search: string) => void;
}

export const SidebarImage: FC<SidebarImageProps> = ({
  imageProps: {
    data,
    loading,
    uploading,
    onUpload,
    onDelete,
    fetchingProps: { fetchNextPage, hasNextPage, isFetchingNextPage } = {},
  },
  onSearch = () => null,
}) => {
  const [viewType, setViewType] = useState<"grid" | "list">("grid");
  const [uploadFiles, setUploadFiles] = useState<File[]>([]);
  const { confirm } = useConfirm();
  const [index, setIndex] = useState<number | undefined>();

  const updateIndex = ({ index }: { index: number | undefined }) => {
    setIndex(index);
  };

  const [showImage, setShowImage] = useState<FileDTO | null>(null);
  const { uploadFileText, buttonText, confirmText, noDataTableText } =
    useAppTranslation();

  const handleToggleViewType = (type: "grid" | "list") => () => {
    setViewType(type);
  };

  const handleScroll: UIEventHandler<HTMLElement> = (event) => {
    const { scrollHeight, scrollTop, clientHeight } = event.currentTarget;
    const threshold = 50;
    const isMeetThreshold =
      scrollHeight - (scrollTop + threshold) <= clientHeight;
    const shouldFetchNextPage =
      isMeetThreshold && hasNextPage && !isFetchingNextPage;

    if (shouldFetchNextPage) {
      fetchNextPage?.();
    }
  };

  const handleDownload = async (url?: string) => {
    if (!url) return;
    const blob = await httpClient.get(url, { responseType: "blob" });
    const objectURL = URL.createObjectURL(blob.data);
    handleDownloadFile(objectURL);
  };

  const handleUpload = async () => {
    const res = await onUpload?.(uploadFiles);
    if (res) {
      setUploadFiles([]);
    }
  };

  const handleActionClick = (action: AttachmentAction, data?: FileDTO) => {
    switch (action) {
      case "view":
        if (data) setShowImage(data);
        break;
      case "download":
        handleDownload(data?.attachmentUrl);
        break;
      case "delete":
        if (data?.id) {
          confirm({
            title: confirmText.deleteTitle(uploadFileText.imageTitle),
            description: confirmText.deleteContent(uploadFileText.imageTitle),
            onConfirm: async () => await onDelete?.(data.id),
          });
        }
        break;
      default:
        break;
    }
  };

  return (
    <div className="d-flex flex-column h-100" onScroll={handleScroll}>
      <div className="p-3 border-bottom">
        <h5>{uploadFileText.imageTitle}</h5>
        <div className="d-flex gap-2 align-items-center">
          <DebouncedInput
            isBorder
            name="list-vendor-search"
            value=""
            onChange={onSearch}
          />
          <div>
            <Button
              color={viewType === "grid" ? "primary" : ""}
              onClick={handleToggleViewType("grid")}
              className="btn btn-sm btn-icon border"
            >
              <i className="fs-20 ri-layout-grid-line" />
            </Button>
          </div>
          <div>
            <Button
              color={viewType === "list" ? "primary" : ""}
              onClick={handleToggleViewType("list")}
              className="btn btn-sm btn-icon border"
            >
              <i className="fs-20 ri-list-unordered" />
            </Button>
          </div>
        </div>
      </div>
      {data.length > 0 && !loading ? (
        <>
          {viewType === "list" ? (
            <div className="flex-fill px-3 py-2 overflow-auto">
              <Row>
                {data.map((image, index) => (
                  <Fragment key={image.id}>
                    <SidebarImageListItem
                      image={image}
                      onActionClick={handleActionClick}
                    />
                    {index < data.length - 1 ? (
                      <hr className="text-muted my-2" />
                    ) : null}
                  </Fragment>
                ))}
              </Row>
            </div>
          ) : null}
          {viewType === "grid" ? (
            data.length ? (
              <div className="flex-fill overflow-auto overflow-x-hidden">
                <Row className="p-3 g-3">
                  {data.map((image) => (
                    <SidebarImageGridItem
                      key={image.id}
                      image={image}
                      onActionClick={handleActionClick}
                    />
                  ))}
                </Row>
              </div>
            ) : null
          ) : null}
        </>
      ) : null}
      {data.length === 0 && !loading ? (
        <div className="flex-fill px-3 py-2 overflow-auto">
          {noDataTableText}
        </div>
      ) : null}
      {loading ? (
        <div className="flex-fill px-3 py-2 overflow-auto">
          <Loading />
        </div>
      ) : null}
      {isFetchingNextPage ? <Loading /> : null}
      <div className="border-top p-3 overflow-auto">
        <FileUpload
          size="sm"
          value={uploadFiles}
          onChange={setUploadFiles}
          multiple={false}
        />
        {uploadFiles.length ? (
          <Button
            onClick={handleUpload}
            loading={uploading}
            color="primary mt-2 w-100"
          >
            {buttonText.upload}
          </Button>
        ) : null}
        {showImage?.attachmentUrl ? (
          <ImagePopup
            open={!!showImage}
            onClose={() => setShowImage(null)}
            slides={data.map((image) => ({
              src: image.attachmentUrl || "",
              alt: image.fileName,
            }))}
            index={index}
            updateIndex={updateIndex}
          />
        ) : null}
      </div>
    </div>
  );
};
