import isNil from "lodash/isNil";
import * as Yup from "yup";

export const checkIsNull = (value: any) => {
  if (isNil(value)) return false;
  return true;
};

const stringRequired = (message: string) => Yup.string().required(message);

const stringMoreThanOrEqualRequired = (
  count: number,
  moreThanMessage: string,
  message: string,
) => Yup.string().required(message).min(count, moreThanMessage);
const stringNotRequired = () => Yup.string().defined();

const stringMatchRequired = (
  field: string,
  matchMessage: string,
  requiredMessage: string,
) =>
  Yup.string()
    .oneOf([Yup.ref(field), ""], matchMessage)
    .required(requiredMessage);

const objectNotRequired = () =>
  Yup.object()
    .shape({
      label: Yup.string().required(),
      value: Yup.string().required(),
    })
    .nullable()
    .defined();

const objectRequired = (message: string) =>
  Yup.object()
    .shape({
      label: Yup.string().required(),
      value: Yup.string().required(),
    })
    .required(message)
    .nullable()
    .test("is-null", message, checkIsNull);

const objectOrStringRequired = (message: string) =>
  Yup.lazy((value) => {
    if (typeof value === "string") {
      return stringRequired(message);
    }
    return objectRequired(message);
  });

const numberNotRequired = () =>
  Yup.number()
    .nullable()
    .transform((_, val) => (val === Number(val) ? val : null))
    .defined();

const numberMoreThanNotRequired = (number: number, message: string) =>
  Yup.number()
    .moreThan(number, message)
    .nullable()
    .transform((_, val) => (val === Number(val) ? val : null))
    .defined();

const numberMoreThanRequired = (
  number: number,
  moreThanMessage: string,
  requiredMessage: string,
) =>
  Yup.number()
    .moreThan(number, moreThanMessage)
    .required(requiredMessage)
    .nullable()
    .transform((_, val) => (val === Number(val) ? val : null))
    .test("is-null", requiredMessage, checkIsNull);

const numberMoreThanOrEqualRequired = (
  number: number,
  moreThanMessage: string,
  requiredMessage: string,
) =>
  Yup.number()
    .min(number, moreThanMessage)
    .required(requiredMessage)
    .nullable()
    .transform((_, val) => (val === Number(val) ? val : null))
    .test("is-null", requiredMessage, checkIsNull);

const numberMoreThanOrEqualNotRequired = (number: number, message: string) =>
  Yup.number()
    .min(number, message)
    .nullable()
    .transform((_, val) => (val === Number(val) ? val : null))
    .defined();

const dateRequired = (message: string) =>
  Yup.date().required(message).nullable().test("is-null", message, checkIsNull);

const dateNotRequired = () =>
  Yup.date()
    .nullable()
    .transform((_, val) => (val ? new Date(val) : null))
    .defined();

const dateMoreThanOrEqualRequired = (
  field: string,
  requireMessage: string,
  fieldMessage: string,
) =>
  Yup.date()
    .required(requireMessage)
    .nullable()
    .min(Yup.ref(field), fieldMessage)
    .test("is-null", requireMessage, checkIsNull);

const emailRequired = (emailMessage: string, requiredMessage: string) =>
  Yup.string().email(emailMessage).required(requiredMessage);

const emailNotRequired = (emailMessage: string) =>
  Yup.string().email(emailMessage).defined();

const websiteNotRequired = (websiteMessage: string) =>
  Yup.string()
    .url(websiteMessage)
    .test("is-website", websiteMessage, (value) => {
      if (!value) return true; // Allow empty values if the field is not required
      try {
        const url = new URL(value);
        return url.protocol === "http:" || url.protocol === "https:";
      } catch {
        return false;
      }
    })
    .defined();

const fileNotRequired = () =>
  Yup.array().of(Yup.mixed<File>().defined()).defined();

const fileRequired = (min: number, requiredMessage: string) =>
  Yup.array()
    .min(min, requiredMessage)
    .of(Yup.mixed<File>().required())
    .required();

const any = () => Yup.mixed().nullable();

const boolean = () => Yup.boolean().nullable();

const arrayNotRequired = () => Yup.array().defined();

export const yupSchema = {
  stringRequired,
  stringNotRequired,
  stringMatchRequired,
  stringMoreThanOrEqualRequired,
  objectNotRequired,
  objectRequired,
  numberNotRequired,
  numberMoreThanRequired,
  numberMoreThanNotRequired,
  numberMoreThanOrEqualRequired,
  numberMoreThanOrEqualNotRequired,
  dateRequired,
  dateNotRequired,
  dateMoreThanOrEqualRequired,
  emailRequired,
  emailNotRequired,
  websiteNotRequired,
  fileNotRequired,
  fileRequired,
  any,
  boolean,
  arrayNotRequired,
  objectOrStringRequired,
};
