import { useQueryClient } from "@tanstack/react-query";
import { ColumnDef } from "@tanstack/react-table";
import { useConfirm } from "app/components/molecules/ConfirmationProvider";
import { NestedDataTable } from "app/components/organisms/NestedDataTable";
import { useAppTranslation } from "app/hooks";
import {
  ProductMaterialAction,
  ProductMaterialActionDropdown,
} from "app/modules/product/components/ProductMaterialActionDropdown";
import { ProductMaterialFormData } from "app/modules/product/components/ProductMaterialFormModal/ProductMaterialForm";
import { ProductMaterialFormModal } from "app/modules/product/components/ProductMaterialFormModal/ProductMaterialFormModal";
import {
  useRemoveProductMaterial,
  useUpdateProductMaterial,
} from "app/modules/product/hooks";
import { mapUpdateProductMaterial } from "app/modules/product/mapper";
import {
  ProductMaterial,
  RETRIEVE_PRODUCT_STRUCTURE_BY_ID_URL,
} from "app/services/product";
import { useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { Card, CardBody } from "reactstrap";

export const MaterialRow = ({ data }: { data: ProductMaterial[] }) => {
  const { materialText, productText } = useAppTranslation();
  const rowSelected = useRef<ProductMaterial | null>(null);
  const { confirm } = useConfirm();
  const { id } = useParams();
  const queryClient = useQueryClient();
  const columns: ColumnDef<ProductMaterial>[] = useMemo(
    () => [
      {
        header: materialText.title,
        accessorKey: "material",
        size: 200,
        cell: ({ row }) => row.original.material?.name,
      },
      {
        header: materialText.field.quantity,
        accessorKey: "quantity",
        size: 200,
        cell: ({ row }) => row.original.quantity,
      },
      {
        header: materialText.field.note,
        accessorKey: "note",
        size: 200,
        cell: ({ row }) => row.original.note || "-",
      },
      {
        header: "",
        accessorKey: "action",
        size: 40,
        cell: ({ row }) => {
          return (
            <ProductMaterialActionDropdown
              data={row.original}
              onAction={handleActionClick}
            />
          );
        },
      },
    ],
    [materialText],
  );

  const handleActionClick = (
    action: ProductMaterialAction,
    data?: ProductMaterial,
  ) => {
    if (data) {
      rowSelected.current = data;
    }
    switch (action) {
      case "edit":
        if (data) {
          handleToggleEditMaterialModal(data);
        }
        break;
      case "delete":
        if (data?.id) {
          confirm({
            title: productText.deleteMaterialTitle,
            description: productText.deleteMaterialContent,
            onConfirm: async () =>
              removeMaterial({
                id: id as ID,
                productMaterialId: data.id,
              }),
          });
        }
        break;
      default:
        break;
    }
  };

  const [isEditMaterialModalOpen, toggleEditMaterialModalOpen] =
    useState(false);

  const handleToggleEditMaterialModal = (data?: ProductMaterial) => {
    if (data) {
      rowSelected.current = data;
    }
    if (isEditMaterialModalOpen) {
      rowSelected.current = null;
    }
    toggleEditMaterialModalOpen((prevState) => !prevState);
  };

  const { mutateAsync: updateMaterial, isPending: isMaterialUpdating } =
    useUpdateProductMaterial({
      id: rowSelected.current?.id,
      onSuccess: () => {
        handleToggleEditMaterialModal();
        if (id)
          queryClient.invalidateQueries({
            queryKey: [RETRIEVE_PRODUCT_STRUCTURE_BY_ID_URL(id)],
          });
      },
    });

  const { mutateAsync: removeMaterial } = useRemoveProductMaterial({
    id,
  });

  const handleUpdateMaterial = async (data: ProductMaterialFormData) => {
    updateMaterial(
      mapUpdateProductMaterial({ ...data, id: rowSelected.current?.id as ID }),
    );
  };

  return (
    <>
      <Card>
        <CardBody className="card-gray">
          <div className="d-flex flex-column gap-2">
            <h5 className="fw-semibold">{materialText.title}</h5>
            <NestedDataTable<ProductMaterial>
              className="table-flat overflow-visible"
              columns={columns}
              data={data || []}
              loading={false}
              total={data.length || 0}
              isHiddenPagination
            />
          </div>
        </CardBody>
      </Card>
      <ProductMaterialFormModal
        open={isEditMaterialModalOpen}
        onToggle={handleToggleEditMaterialModal}
        onSubmit={handleUpdateMaterial}
        submitting={isMaterialUpdating}
        edit={!!rowSelected.current}
        data={rowSelected.current || undefined}
      />
    </>
  );
};
