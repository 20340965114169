import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { httpClient } from "app/datasources";
import { AxiosError, AxiosResponse } from "axios";
import { Response } from "../types";

export const REMOVE_PRODUCT_MATERIAL_URL = (id: ID) =>
  `/api/admin/products/${id}/remove-material`;

export enum RemoveProductMaterialError {
  ProductCodeAlreadyExists = "Product Code Already Exists",
}

export const removeProductMaterialErrorMessages = {
  [RemoveProductMaterialError.ProductCodeAlreadyExists]:
    "Product Code Already Exists",
};

export type RemoveProductMaterialResponse = Response<unknown>;

export type RemoveProductMaterialArgs = {
  id: ID;
  productMaterialId: ID;
};

export const removeProductMaterial = ({
  id,
  ...args
}: RemoveProductMaterialArgs) => {
  return httpClient.put<RemoveProductMaterialResponse>(
    REMOVE_PRODUCT_MATERIAL_URL(id),
    args,
  );
};

export const useRemoveProductMaterialMutation = (
  opts?: Omit<
    UseMutationOptions<
      AxiosResponse<RemoveProductMaterialResponse>,
      AxiosError<any>,
      RemoveProductMaterialArgs
    >,
    "mutationFn"
  >,
) =>
  useMutation<
    AxiosResponse<RemoveProductMaterialResponse>,
    AxiosError<any>,
    RemoveProductMaterialArgs
  >({
    mutationFn: removeProductMaterial,
    mutationKey: [REMOVE_PRODUCT_MATERIAL_URL],
    ...opts,
  });
