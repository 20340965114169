import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const Navdata = () => {
  const history = useNavigate();

  //state data
  const [isDashboard, setIsDashboard] = useState(false);
  const [isEmployees, setIsEmployees] = useState(false);
  const [isRoles, setIsRoles] = useState(false);
  const [isUsers, setIsUsers] = useState(false);
  const [isCustomers, setIsCustomers] = useState(false);
  const [isOrders, setIsOrders] = useState(false);
  const [isProducts, setIsProducts] = useState(false);
  const [isProductionPlans, setIsProductionPlans] = useState(false);
  const [isProductionOrders, setIsProductionOrders] = useState(false);
  const [isBomProducts, setIsBomProducts] = useState(false);
  const [isWorkshops, setIsWorkshops] = useState(false);
  const [isTickets, setIsTickets] = useState(false);
  const [isSteps, setIsSteps] = useState(false);
  const [isVendors, setIsVendors] = useState(false);

  // Group
  const [isProductAndBoms, setIsProductAndBoms] = useState(false);
  const [isOperations, setIsOperations] = useState(false);
  const [isAccounts, setIsAccounts] = useState(false);
  const [isSales, setIsSales] = useState(false);
  const [isMasterDatas, setIsMasterDatas] = useState(false);

  const [iscurrentState, setIscurrentState] = useState("Dashboard");

  function updateIconSidebar(e: any) {
    if (e && e.target && e.target.getAttribute("sub-items")) {
      const ul: any = document.getElementById("two-column-menu");
      const iconItems: any = ul.querySelectorAll(".nav-icon.active");
      const activeIconItems = [...iconItems];
      activeIconItems.forEach((item) => {
        item.classList.remove("active");
        const id = item.getAttribute("sub-items");
        const getID = document.getElementById(id) as HTMLElement;
        if (getID) getID.classList.remove("show");
      });
    }
  }

  useEffect(() => {
    document.body.classList.remove("twocolumn-panel");
    if (iscurrentState !== "Dashboard") {
      setIsDashboard(false);
    }
    if (iscurrentState !== "Employees") {
      setIsEmployees(false);
    }
    if (iscurrentState !== "Roles") {
      setIsRoles(false);
    }
    if (iscurrentState !== "Users") {
      setIsUsers(false);
    }
    if (iscurrentState !== "MasterDatas") {
      setIsMasterDatas(false);
    }
    if (iscurrentState !== "Customers") {
      setIsCustomers(false);
    }
    if (iscurrentState !== "Orders") {
      setIsOrders(false);
    }
    if (iscurrentState !== "Products") {
      setIsProducts(false);
    }
    if (iscurrentState !== "BomProducts") {
      setIsBomProducts(false);
    }
    if (iscurrentState !== "ProductionPlans") {
      setIsProductionPlans(false);
    }
    if (iscurrentState !== "ProductionOrders") {
      setIsProductionOrders(false);
    }
    if (iscurrentState !== "Workshops") {
      setIsWorkshops(false);
    }
    if (iscurrentState !== "Tickets") {
      setIsTickets(false);
    }
    if (iscurrentState !== "Steps") {
      setIsSteps(false);
    }
    if (iscurrentState !== "ProductAndBoms") {
      setIsProductAndBoms(false);
    }
    if (iscurrentState !== "Accounts") {
      setIsAccounts(false);
    }
    if (iscurrentState !== "Sales") {
      setIsSales(false);
    }
    if (iscurrentState !== "Vendors") {
      setIsVendors(false);
    }
  }, [
    history,
    iscurrentState,
    isDashboard,
    isEmployees,
    isRoles,
    isUsers,
    isMasterDatas,
    isCustomers,
    isOrders,
    isProducts,
    isBomProducts,
    isProductionPlans,
    isProductionOrders,
    isWorkshops,
    isTickets,
    isSteps,
    isProductAndBoms,
    isAccounts,
    isSales,
    isVendors,
  ]);

  const menuItems: any = [
    {
      label: "menu.title",
      isHeader: true,
    },
    {
      id: "productAndBoms",
      label: "menu.productAndBom",
      icon: "bx bx-package",
      link: "/#",
      stateVariables: isProductAndBoms,
      click: function (e: any) {
        e.preventDefault();
        setIsProductAndBoms(!isProductAndBoms);
        setIscurrentState("ProductAndBoms");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "boms",
          label: "menu.bom",
          link: "/boms",
          parentId: "productAndBoms",
        },
        {
          id: "products",
          label: "menu.product",
          link: "/products",
          parentId: "productAndBoms",
        },
        {
          id: "components",
          label: "menu.component",
          link: "/components",
          parentId: "productAndBoms",
        },
        {
          id: "materials",
          label: "menu.material",
          link: "/materials",
          parentId: "productAndBoms",
        },
        {
          id: "steps",
          label: "menu.step",
          link: "/steps",
          parentId: "productAndBoms",
        },
      ],
    },
    {
      id: "operations",
      label: "menu.manufacture",
      icon: "ri-list-settings-line",
      link: "/#",
      stateVariables: isOperations,
      click: function (e: any) {
        e.preventDefault();
        setIsOperations(!isOperations);
        setIscurrentState("Operations");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "workshops",
          label: "menu.workshop",
          link: "/workshops",
          parentId: "operations",
        },
        {
          id: "production-plans",
          label: "menu.productionPlan",
          link: "/production-plans",
          parentId: "operations",
        },
        {
          id: "production-orders",
          label: "menu.productionOrder",
          link: "/production-orders",
          parentId: "operations",
        },
        {
          id: "tickets",
          label: "menu.ticket",
          link: "/tickets",
          parentId: "operations",
        },
      ],
    },
    {
      id: "employees",
      label: "menu.employee",
      icon: "ri-user-line",
      link: "/employees",
      stateVariables: isEmployees,
      click: function (e: any) {
        e.preventDefault();
        setIsEmployees(!isEmployees);
        setIscurrentState("Employees");
        updateIconSidebar(e);
      },
    },
    {
      id: "accounts",
      label: "menu.account",
      icon: "ri-account-circle-line",
      link: "/#",
      stateVariables: isAccounts,
      click: function (e: any) {
        e.preventDefault();
        setIsAccounts(!isAccounts);
        setIscurrentState("Accounts");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "roles",
          label: "menu.role",
          link: "/roles",
          parentId: "accounts",
        },
        {
          id: "employeeUsers",
          label: "menu.employeeAccount",
          link: "/users?type=admin",
          parentId: "accounts",
        },
        {
          id: "panelUsers",
          label: "menu.panelAccount",
          link: "/users?type=panel",
          parentId: "accounts",
        },
      ],
    },
    {
      id: "sales",
      label: "menu.sale",
      icon: "ri-team-line",
      link: "/#",
      stateVariables: isUsers,
      click: function (e: any) {
        e.preventDefault();
        setIsUsers(!isUsers);
        setIscurrentState("Users");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "customers",
          label: "menu.customer",
          link: "/customers",
          parentId: "sales",
        },
        {
          id: "orders",
          label: "menu.order",
          link: "/orders",
          parentId: "sales",
        },
      ],
    },
    {
      id: "vendors",
      label: "menu.vendor",
      icon: "ri-store-line",
      link: "/vendors",
      stateVariables: isVendors,
      click: function (e: any) {
        e.preventDefault();
        setIsVendors(!isVendors);
        setIscurrentState("Vendors");
        updateIconSidebar(e);
      },
    },
    {
      id: "masterDatas",
      label: "menu.masterData",
      icon: "ri-database-2-line",
      link: "/#",
      stateVariables: isMasterDatas,
      click: function (e: any) {
        e.preventDefault();
        setIsMasterDatas(!isMasterDatas);
        setIscurrentState("MasterDatas");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "master-datas?type=department",
          label: "menu.department",
          link: "/master-datas?type=department",
          parentId: "masterDatas",
        },
        {
          id: "master-datas?type=job_title",
          label: "menu.job_title",
          link: "/master-datas?type=job_title",
          parentId: "masterDatas",
        },
        {
          id: "master-datas?type=vendor_status",
          label: "menu.vendor_status",
          link: "/master-datas?type=vendor_status",
          parentId: "masterDatas",
        },
        {
          id: "vendorTypes",
          label: "menu.vendor_type",
          link: "/master-datas?type=vendor_type",
          parentId: "masterDatas",
        },
        {
          id: "customerStatuses",
          label: "menu.customer_status",
          link: "/master-datas?type=customer_status",
          parentId: "masterDatas",
        },
        {
          id: "customerTypes",
          label: "menu.customer_type",
          link: "/master-datas?type=customer_type",
          parentId: "masterDatas",
        },
        {
          id: "productTypes",
          label: "menu.product_type",
          link: "/master-datas?type=product_type",
          parentId: "masterDatas",
        },
        {
          id: "productGroups",
          label: "menu.product_group",
          link: "/master-datas?type=product_group",
          parentId: "masterDatas",
        },
        {
          id: "productUnits",
          label: "menu.product_unit",
          link: "/master-datas?type=product_unit",
          parentId: "masterDatas",
        },
        {
          id: "frameTypes",
          label: "menu.frame_type",
          link: "/master-datas?type=frame_type",
          parentId: "masterDatas",
        },
        {
          id: "workpieceTypes",
          label: "menu.workpiece_type",
          link: "/master-datas?type=workpiece_type",
          parentId: "masterDatas",
        },
        {
          id: "skills",
          label: "menu.skill",
          link: "/master-datas?type=skill",
          parentId: "masterDatas",
        },
      ],
    },
  ];
  return <React.Fragment>{menuItems}</React.Fragment>;
};
export default Navdata;
