import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { httpClient } from "app/datasources";
import { Response } from "app/services/types";
import { AxiosError, AxiosResponse } from "axios";
import { Product } from "./types";

export const CREATE_PRODUCT_URL = "/api/admin/products";

export enum CreateProductError {
  ProductCodeAlreadyExists = "Product Code Already Exists",
}

export const createProductErrorMessages = {
  [CreateProductError.ProductCodeAlreadyExists]: "Product Code Already Exists",
};

export interface CreateProductArgs {
  code: string;
  nameEn: string | null;
  nameVn: string;
  groupId: ID | null;
  unitId: ID | null;
  countryOfOrigin: string | null;
  description: string | null;
  lengthCm: number | null;
  widthCm: number | null;
  heightCm: number | null;
  seatSizeCm: string | null;
  color: string | null;
  imageId: ID | null;
  workSteps: {
    stepId: ID;
    estimateTime: number | null;
    quantity: number | null;
  }[];
  fileIds?: ID[];
}

export type CreateProductResponse = Response<Product>;

export const createProduct = (args: CreateProductArgs) => {
  return httpClient.post<CreateProductResponse>(CREATE_PRODUCT_URL, args);
};

export const useCreateProductMutation = (
  opts?: Omit<
    UseMutationOptions<
      AxiosResponse<CreateProductResponse>,
      AxiosError<any>,
      CreateProductArgs
    >,
    "mutationFn"
  >,
) =>
  useMutation<
    AxiosResponse<CreateProductResponse>,
    AxiosError<any>,
    CreateProductArgs
  >({
    mutationFn: createProduct,
    mutationKey: [CREATE_PRODUCT_URL],
    ...opts,
  });
