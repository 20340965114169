import { AvatarRow } from "app/components/molecules/AvatarRow";
import { ListInfoItem } from "app/components/molecules/ListInfoItem";
import { useAppTranslation } from "app/hooks";
import { Ticket } from "app/services/ticket";
import { FC } from "react";
import { Col, Row } from "reactstrap";

export interface DetailTicketPanelProductProps {
  ticket?: Ticket;
}

export const DetailTicketPanelProduct: FC<DetailTicketPanelProductProps> = ({
  ticket,
}) => {
  const { productText, ticketText } = useAppTranslation();
  return (
    <Row>
      <Col xs={6}>
        <ListInfoItem
          label={productText.field.avatar}
          value={
            <AvatarRow
              avatarUrl={ticket?.workStep.product.productImage?.attachmentUrl}
              name={ticket?.workStep.product.nameVn}
              size="md"
              isClickable
              isHidenName
            />
          }
        />
        <ListInfoItem
          label={productText.field.name}
          value={ticket?.workStep.product.nameVn}
        />
        <ListInfoItem
          label={productText.field.workStep}
          value={ticket?.workStep.step.name}
        />
        <ListInfoItem
          label={ticketText.field.quantity}
          value={ticket?.quantity}
        />
        <ListInfoItem label={ticketText.field.note} value={ticket?.note} />
      </Col>
      <Col xs={6}>
        <Col xs={12}>
          <ListInfoItem
            label={productText.field.lengthCm}
            value={ticket?.workStep.product?.lengthCm}
          />
        </Col>
        <Col xs={12}>
          <ListInfoItem
            label={productText.field.widthCm}
            value={ticket?.workStep.product?.widthCm}
          />
        </Col>
        <Col xs={12}>
          <ListInfoItem
            label={productText.field.heightCm}
            value={ticket?.workStep.product?.heightCm}
          />
        </Col>
        <Col xs={12}>
          <ListInfoItem
            label={productText.field.color}
            value={ticket?.workStep.product?.color}
          />
        </Col>
      </Col>
    </Row>
  );
};
