import { Employee } from "../employee";
import { Product, ProductStep } from "../product";
import { ProductionOrderItem } from "../productionOrder";
import { TicketLog } from "../ticketLog";
import { IBaseModel } from "../types";
import { Workshop } from "../workshop";

export enum TicketStatus {
  Todo = "Todo",
  InProgress = "InProgress",
  Completed = "Completed",
}

interface TicketProductStep extends ProductStep {
  product: Product; // product of this step
}

export interface Ticket extends IBaseModel {
  status: TicketStatus;
  product: Product | null; // main product
  workStep: TicketProductStep;
  productionOrderItem: ProductionOrderItem;
  workshop: Workshop;
  quantity: number;
  remainingQuantity: number;
  quantityCompleted: number;
  note: string | null;
  ticketLogs: TicketLog[];
  assignee: Employee | null;
  startedAt: DateTimeString | null;
  completedAt: DateTimeString | null;
}
