import {
  RetrieveMaterialByIdArgs,
  useRetrieveMaterialByIdQuery,
} from "app/services/material";

export const useRetrieveMaterialById = (
  args: RetrieveMaterialByIdArgs,
  opts?: Parameters<typeof useRetrieveMaterialByIdQuery>[1],
) => {
  const result = useRetrieveMaterialByIdQuery(args, opts as any);
  return {
    material: result.data?.data.data,
    isLoadingMaterial: result.isLoading,
    ...result,
  };
};
