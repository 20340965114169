import { ColumnDef } from "@tanstack/react-table";
import { DataTable } from "app/components/organisms/DataTable";
import { useAppTranslation } from "app/hooks";
import { sortProductStepOrder } from "app/modules/product/helpers";
import { ProductStep } from "app/services/product";
import { FC, useMemo } from "react";

export interface DetailProductGeneralStepProps {
  data: ProductStep[];
  loading?: boolean;
}

export const DetailProductGeneralStep: FC<DetailProductGeneralStepProps> = ({
  data,
  loading,
}) => {
  const { productText } = useAppTranslation();
  const columns: ColumnDef<ProductStep>[] = useMemo(
    () => [
      {
        header: productText.field.order,
        accessorKey: "order",
        size: 60,
        cell: ({ row }) => row.original.order || "-",
      },
      {
        header: productText.field.workStepName,
        accessorKey: "workStepName",
        cell: ({ row }) => row.original.step.name || "-",
      },
      {
        header: productText.field.estimateTime,
        accessorKey: "estimateTime",
        cell: ({ row }) => row.original.estimateTime || "-",
      },
      {
        header: productText.field.quantity,
        accessorKey: "quantity",
        size: 100,
        cell: ({ row }) => row.original.quantity || "-",
      },
    ],
    [productText],
  );
  return (
    <DataTable<ProductStep>
      columns={columns}
      data={sortProductStepOrder(data || [])}
      loading={loading}
      total={0}
      isHiddenPagination
    />
  );
};
