import FormPage from "app/components/templates/FormPage";
import { useAppTranslation } from "app/hooks";
import { BomForm, BomFormData } from "app/modules/bom/components";
import { useRetrieveBomById, useUpdateBom } from "app/modules/bom/hooks";
import { mapDefaultBom, mapUpdateBom } from "app/modules/bom/mapper";
import BreadCrumb from "Components/Common/BreadCrumb";
import { FC } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Card, CardBody } from "reactstrap";

export interface UpdateBomProps {}

const UpdateBom: FC<UpdateBomProps> = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { bomText } = useAppTranslation();

  const { bom, isLoading } = useRetrieveBomById(
    { id: id as string },
    { enabled: !!id },
  );

  const { mutateAsync, isPending } = useUpdateBom({
    id,
    onSuccess: () => {
      navigate(-1);
    },
  });

  const handleSubmit = async (data: BomFormData) => {
    mutateAsync(mapUpdateBom({ id: id as string, ...data }));
  };

  return (
    <FormPage loading={isLoading}>
      <FormPage.BreadCrumb>
        <BreadCrumb title={bomText.edit} pageTitle={bomText.title} isBack />
      </FormPage.BreadCrumb>
      <FormPage.Main>
        <Card>
          <CardBody>
            <BomForm
              onSubmit={handleSubmit}
              defaultValues={mapDefaultBom(bom)}
              submitting={isPending}
            />
          </CardBody>
        </Card>
      </FormPage.Main>
    </FormPage>
  );
};

export default UpdateBom;
