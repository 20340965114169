import { useAppTranslation } from "app/hooks";
import { FilePreview } from "app/models";
import { FC, useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import { Button, Card, Col, Row } from "reactstrap";

export interface ExcelUploadProps {
  size?: "sm" | "md";
  value: FilePreview[];
  onChange?: (files: FilePreview[]) => void;
}

export const ExcelUpload: FC<ExcelUploadProps> = ({
  onChange,
  value,
  size = "md",
}) => {
  const { excelText } = useAppTranslation();
  const [selectedFiles, setselectedFiles] = useState<FilePreview[]>(value);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    setselectedFiles(value);
  }, [value]);

  const { uploadFileText } = useAppTranslation();
  function handleAcceptedFiles(files: File[]) {
    setError(null);
    files.map((file: File) => {
      return Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      });
    });
    onChange?.([...selectedFiles, ...files]);
    setselectedFiles([...selectedFiles, ...files]);
  }

  const handleDelete = (file: File & { preview?: string }) => {
    const newFiles = selectedFiles.filter(
      (item) => item.preview !== file.preview,
    );
    onChange?.(newFiles);
    setselectedFiles(newFiles);
  };

  /**
   * Formats the size
   */
  function formatBytes(bytes: any, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  return (
    <>
      <Dropzone
        multiple={false}
        maxFiles={1}
        onDrop={(acceptedFiles) => {
          handleAcceptedFiles(acceptedFiles);
        }}
        accept={{
          "application/xlsx": [".xlsx", ".xls"],
        }}
        onDropRejected={() => {
          setError(excelText.errorInvalidType);
        }}
      >
        {({ getRootProps }) =>
          selectedFiles.length === 1 ? (
            <></>
          ) : (
            <>
              <div className="dropzone dz-clickable cursor-pointer">
                {size === "md" ? (
                  <div className="dz-message needsclick" {...getRootProps()}>
                    <div className="mb-0">
                      <i className="display-6 text-muted ri-upload-cloud-2-fill" />
                    </div>
                    <h5>{uploadFileText.title}</h5>
                  </div>
                ) : (
                  <div
                    className="dz-message needsclick m-1"
                    {...getRootProps()}
                  >
                    <div className="mb-0 d-flex align-items-center gap-2">
                      <i className="text-muted fs-20 ri-upload-cloud-2-fill" />
                      <p className="fs-14 mb-0 text-muted">
                        {uploadFileText.title}
                      </p>
                    </div>
                  </div>
                )}
              </div>
              {error && <p className="text-danger">{error}</p>}
            </>
          )
        }
      </Dropzone>
      <div className="file-previews list-unstyled mb-0" id="file-previews">
        {selectedFiles.map((f: any, index) => {
          return (
            <Card
              className="mt-2 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
              key={index + "-file"}
            >
              <div className="p-2">
                <Row className="align-items-center">
                  <Col className="col-auto">
                    <i className="ri-file-excel-line fs-24" />
                  </Col>
                  <Col className="text-truncate">
                    <div className="d-flex gap-2 justify-content-between">
                      <div className="text-truncate text-muted font-weight-bold">
                        {f.name}
                      </div>
                      <Button
                        size="sm"
                        color="danger"
                        type="button"
                        onClick={() => handleDelete(f)}
                      >
                        {uploadFileText.deleteBtn}
                      </Button>
                    </div>
                    {f.formattedSize ? (
                      <p className="mb-0">
                        <strong>{f.formattedSize}</strong>
                      </p>
                    ) : null}
                  </Col>
                </Row>
              </div>
            </Card>
          );
        })}
      </div>
    </>
  );
};
