import {
  keepPreviousData,
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  useQuery,
  UseQueryOptions,
} from "@tanstack/react-query";
import { httpClient } from "app/datasources";
import { AxiosError, AxiosResponse } from "axios";
import { Response } from "../types";
import { PagingData } from "./../types";
import { Material } from "./types";
import { DEFAULT_LIMIT_PAGE, DEFAULT_PAGE } from "app/helpers";

export const RETRIEVE_MATERIALS_URL = "/api/admin/materials";

export interface RetrieveMaterialsArgs {
  page: number;
  limit: number;
  searchString?: string;
}

export type RetrieveMaterialsResponse = Response<PagingData<Material[]>>;

export const retrieveMaterials = (args: RetrieveMaterialsArgs) => {
  return httpClient.get<RetrieveMaterialsResponse>(RETRIEVE_MATERIALS_URL, {
    params: args,
  });
};

export const useRetrieveMaterialsQuery = (
  retrieveMaterialsArgs: RetrieveMaterialsArgs,
  opts?: Omit<
    UseQueryOptions<AxiosResponse<RetrieveMaterialsResponse>, AxiosError<any>>,
    "queryKey" | "queryFn"
  >,
) =>
  useQuery<AxiosResponse<RetrieveMaterialsResponse>, AxiosError<any>>({
    queryKey: [RETRIEVE_MATERIALS_URL, retrieveMaterialsArgs],
    queryFn: () => retrieveMaterials(retrieveMaterialsArgs),
    placeholderData: keepPreviousData,
    ...opts,
  });

export const useRetrieveMaterialsInfiniteQuery = (
  retrieveMaterialsArgs: RetrieveMaterialsArgs,
  opts?: Omit<
    UseInfiniteQueryOptions<
      AxiosResponse<RetrieveMaterialsResponse>,
      AxiosError<any>
    >,
    "initialPageParam" | "queryFn" | "queryKey" | "getNextPageParam"
  >,
): any => {
  return useInfiniteQuery<
    AxiosResponse<RetrieveMaterialsResponse>,
    AxiosError<any>,
    AxiosResponse<RetrieveMaterialsResponse, any>
  >({
    initialPageParam: {
      page: DEFAULT_PAGE,
      limit: DEFAULT_LIMIT_PAGE,
    },
    queryKey: [RETRIEVE_MATERIALS_URL, "INFINITE_QUERY"],
    queryFn: ({ pageParam }) => {
      const { page, limit } = pageParam as RetrieveMaterialsArgs;
      return retrieveMaterials({
        ...retrieveMaterialsArgs,
        page,
        limit,
      });
    },
    getNextPageParam: (lastPage) => {
      const total = lastPage.data.data.total;
      const { limit, page } = lastPage.config.params as RetrieveMaterialsArgs;
      let nextPageParam: RetrieveMaterialsArgs | undefined;

      if (page) {
        const hasNextPage = page * (limit || DEFAULT_LIMIT_PAGE) < total;

        if (hasNextPage) {
          nextPageParam = {
            ...retrieveMaterialsArgs,
            page: page + 1,
            limit,
          };
        }
      }
      return nextPageParam;
    },
    ...opts,
  });
};
