import { useAppTranslation } from "app/hooks";
import { Role } from "app/services/role";
import { FC, MouseEvent, useState } from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";

export type RoleAction = "edit" | "delete";

export interface RoleActionDropdownProps {
  data?: Role;
  onAction: (action: RoleAction, data?: Role) => void;
}

export const RoleActionDropdown: FC<RoleActionDropdownProps> = ({
  data,
  onAction,
}) => {
  const { buttonText } = useAppTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const handleToggle = () => setIsOpen(!isOpen);

  const handleAction = (action: RoleAction) => () => {
    onAction(action, data);
    setIsOpen(false);
  };

  return (
    <Dropdown
      isOpen={isOpen}
      toggle={(e: MouseEvent) => {
        e.stopPropagation();
        handleToggle();
      }}
      direction="start"
    >
      <DropdownToggle
        tag="button"
        className="btn py-0 px-2 m-0"
        onClick={(e) => {
          const trClosest = e.currentTarget.closest("tr");
          trClosest?.classList.toggle("z-3", !isOpen);
        }}
        onBlur={(e) => {
          const trClosest = e.currentTarget.closest("tr");
          setTimeout(() => trClosest?.classList.remove("z-3"), 100);
        }}
      >
        <i className="ri-more-fill"></i>
      </DropdownToggle>
      <DropdownMenu className="dropdown-menu-end">
        <DropdownItem className="p-0" onClick={handleAction("edit")}>
          <div className="dropdown-item">
            <i className="ri-pencil-line text-muted fs-16 align-middle me-1"></i>
            <span className="align-middle">{buttonText.edit}</span>
          </div>
        </DropdownItem>
        <DropdownItem className="p-0" onClick={handleAction("delete")}>
          <div className="dropdown-item">
            <i className="ri-delete-bin-line text-muted fs-16 align-middle me-1"></i>
            <span className="align-middle">{buttonText.delete}</span>
          </div>
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};
