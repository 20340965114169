import FormPage from "app/components/templates/FormPage";
import { useAppTranslation } from "app/hooks";
import { Source } from "app/models";
import { getUploadedFileIds } from "app/modules/file/helpers";
import { OrderForm, OrderFormData } from "app/modules/order/components";
import { useCreateOrder } from "app/modules/order/hooks";
import { mapCreateOrder, mapDefaultOrder } from "app/modules/order/mapper";
import { useDeleteFile, useUploadFiles } from "app/services/file";
import BreadCrumb from "Components/Common/BreadCrumb";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { Card, CardBody } from "reactstrap";

export interface CreateOrderProps {}

const CreateOrder: FC<CreateOrderProps> = () => {
  const navigate = useNavigate();
  const { orderText } = useAppTranslation();
  const { mutateAsync, isPending } = useCreateOrder({
    onSuccess: () => {
      navigate("/orders");
    },
  });

  const { mutateAsync: upload, isPending: isUploading } = useUploadFiles();

  const { mutateAsync: deleteFile } = useDeleteFile();

  const handleSubmit = async (data: OrderFormData) => {
    const imageIds =
      (
        await getUploadedFileIds({
          data: data.images,
          fileKey: "image",
          source: Source.Order,
          upload,
        })
      )?.fileIds || [];
    const documentIds =
      (
        await getUploadedFileIds({
          data: data.documents,
          fileKey: "document",
          source: Source.Order,
          upload,
        })
      )?.fileIds || [];
    await mutateAsync(
      mapCreateOrder({ ...data, files: [...imageIds, ...documentIds] }),
      {
        onError: () => {
          imageIds?.map((imageId) => {
            deleteFile({ id: imageId });
          });
        },
      },
    );
  };

  return (
    <FormPage>
      <FormPage.BreadCrumb>
        <BreadCrumb title={orderText.add} pageTitle={orderText.title} isBack />
      </FormPage.BreadCrumb>
      <FormPage.Main>
        <Card>
          <CardBody>
            <OrderForm
              onSubmit={handleSubmit}
              defaultValues={mapDefaultOrder()}
              submitting={isPending || isUploading}
            />
          </CardBody>
        </Card>
      </FormPage.Main>
    </FormPage>
  );
};

export default CreateOrder;
