import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "app/components/molecules/Button";
import { DateInput } from "app/components/molecules/DateInput";
import { FormHelper } from "app/components/molecules/FormHelper";
import { FormInput } from "app/components/molecules/FormInput";
import { FormLabel } from "app/components/molecules/FormLabel";
import { checkIsNull, yupSchema } from "app/helpers/schema";
import { formatDate, getMaxDate } from "app/helpers/utils";
import { useAppTranslation, useGetEnableSubmitButton } from "app/hooks";
import { OrderItemOptionSelect } from "app/modules/order/components/OrderItemSelect";
import { ProductionPlanItemSelect } from "app/modules/productionPlan/components/ProductionPlanItemSelect";
import { ProductionPlanSelect } from "app/modules/productionPlan/components/ProductionPlanSelect";
import { useRetrieveProductionPlanById } from "app/modules/productionPlan/hooks";
import { OptionSelect } from "app/services/types";
import { FC, FormEvent } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { Alert, Card, CardBody, Col, Form, FormGroup, Row } from "reactstrap";
import * as Yup from "yup";

export interface ProductionOrderFormProps {
  defaultValues?: ProductionOrderFormData;
  onSubmit: (data: ProductionOrderFormData) => void;
  submitting?: boolean;
}

export interface ProductionOrderItem {
  id?: ID | null;
  planItem: OrderItemOptionSelect | null;
  quantity: number | null;
  remainingQuantity?: number | null;
  defaultQuantity: number | null;
  note: string;
}

export interface ProductionOrderFormData {
  name: string;
  startDate: Date | null;
  endDate: Date | null;
  note: string;
  plan: OptionSelect<ID> | null;
  items: ProductionOrderItem[];
}

export const ProductionOrderForm: FC<ProductionOrderFormProps> = ({
  onSubmit,
  defaultValues,
  submitting,
}) => {
  const { productionOrderText, buttonText, generalInfomationText } =
    useAppTranslation();
  const {
    control,
    setValue,
    watch,
    clearErrors,
    formState: { dirtyFields },
    handleSubmit,
    formState: { isDirty, isValid },
  } = useForm<ProductionOrderFormData>({
    mode: "onChange",
    resolver: yupResolver(
      Yup.object({
        name: yupSchema.stringRequired("productionOrder.error.nameRequired"),
        note: yupSchema.stringNotRequired(),
        startDate: yupSchema.dateRequired(
          "productionOrder.error.startDateRequired",
        ),
        endDate: yupSchema.dateRequired(
          "productionOrder.error.endDateRequired",
        ),
        plan: yupSchema.objectRequired("productionOrder.error.planRequired"),
        items: Yup.array()
          .min(1)
          .of(
            Yup.object({
              planItem: Yup.object()
                .shape({
                  label: Yup.string().required(),
                  value: Yup.string().required(),
                  planQty: yupSchema.numberNotRequired(),
                  quantity: yupSchema.numberNotRequired(),
                })
                .required("productionOrder.error.planItemRequired")
                .nullable()
                .test(
                  "is-null",
                  "productionOrder.error.planItemRequired",
                  checkIsNull,
                ),
              defaultQuantity: yupSchema.numberNotRequired(),
              quantity: yupSchema.numberMoreThanRequired(
                0,
                "productionPlan.error.quantityMoreThanZero",
                "productionPlan.error.quantityRequired",
              ),
              note: yupSchema.stringNotRequired(),
            }).test(
              "is-quantity-valid",
              "productionPlan.error.quantityInvalid",
              function () {
                const array = this.parent;
                const index = parseInt(
                  this.path.split("[")[1].split("]")[0],
                  10,
                );
                const totalQuantity = array[index]?.planItem?.quantity || 0;
                const planQty = array[index]?.planItem?.planQty || 0;
                const remainingQuantity = totalQuantity - planQty;
                const currentQuantity = array[index]?.defaultQuantity || 0;
                const newRemainingValue =
                  remainingQuantity -
                  (array[index]?.quantity || 0) +
                  currentQuantity;
                const isInvalid = newRemainingValue < 0;
                if (isInvalid) {
                  return this.createError({
                    path: `items.${index}.quantity`,
                    message: "productionPlan.error.quantityInvalid",
                  });
                }
                return true;
              },
            ),
          )
          .defined(),
      }),
    ),
    defaultValues,
  });

  const watchStartDate = watch("startDate");

  const { fields, remove, append } = useFieldArray({
    control,
    name: "items",
  });

  const watchItems = watch("items");
  const itemsField = fields.map((field, index) => {
    return {
      ...field,
      ...watchItems[index],
    };
  });

  const isEnableSubmit = useGetEnableSubmitButton({
    isDirty,
    isValid,
    submitting,
  });

  const handleQuantityChange = (index: number, value: number) => {
    const totalQuantity = watch(`items.${index}.planItem.quantity`) || 0;
    const planQty = watch(`items.${index}.planItem.planQty`) || 0;
    const remainingQuantity = totalQuantity - planQty;
    const currentQuantity = watch(`items.${index}.defaultQuantity`) || 0;
    const newRemainingValue =
      remainingQuantity - (value || 0) + currentQuantity;
    const isInvalid = newRemainingValue < 0 || value < 0;
    if (isInvalid) {
      setValue(
        `items.${index}.remainingQuantity`,
        remainingQuantity + currentQuantity,
      );
    } else {
      setValue(`items.${index}.remainingQuantity`, newRemainingValue);
    }
  };

  const submit = async (e: FormEvent) => {
    e.preventDefault();
    handleSubmit(onSubmit)();
  };

  const watchPlan = watch("plan");

  const { productionPlan } = useRetrieveProductionPlanById(
    { id: watchPlan?.value as ID },
    { enabled: !!watchPlan?.value },
  );

  const startDatePlan = productionPlan?.startDate
    ? new Date(productionPlan.startDate)
    : null;
  const endDatePlan = productionPlan?.endDate
    ? new Date(productionPlan.endDate)
    : null;

  const maxStartDate = getMaxDate(
    startDatePlan || null,
    watchStartDate || null,
  );

  const selectedOrderItems = watch("items").map((item) => item.planItem);

  const selectedDefaultOrderItem = defaultValues?.items;

  return (
    <Form onSubmit={submit} id="production-order-form">
      <h5 className="py-2 fw-semibold">{generalInfomationText}</h5>
      <Row>
        <Col xs={12}>
          <FormGroup>
            <FormLabel required htmlFor="name" className="form-label">
              {productionOrderText.field.name}
            </FormLabel>
            <Controller
              name="name"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <FormInput
                    {...field}
                    type="text"
                    className="form-control"
                    id="name"
                    invalid={!!fieldState.error}
                  />
                  <FormHelper message={fieldState.error?.message} />
                </>
              )}
            />
          </FormGroup>
        </Col>
        <Col xs={12}>
          <FormGroup>
            <FormLabel required htmlFor="plan" className="form-label">
              {productionOrderText.field.plan}
            </FormLabel>
            <Controller
              name="plan"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <ProductionPlanSelect
                    name={field.name}
                    value={field.value}
                    inputId="plan"
                    onChange={(value) => {
                      field.onChange(value);
                      setValue("items", [
                        {
                          note: "",
                          planItem: null,
                          quantity: null,
                          defaultQuantity: null,
                        },
                      ]);
                      setValue("startDate", null);
                      setValue("endDate", null);
                      clearErrors("items");
                    }}
                    isInvalid={fieldState.invalid}
                  />
                  <FormHelper message={fieldState.error?.message} />
                </>
              )}
            />
          </FormGroup>
        </Col>
        {startDatePlan && endDatePlan ? (
          <Col md={12}>
            <Alert color="info" fade={false}>
              Thời gian kế hoạch: {formatDate(startDatePlan)} -{" "}
              {formatDate(endDatePlan)}
            </Alert>
          </Col>
        ) : null}
        <Col xs={12} md={6}>
          <FormGroup>
            <FormLabel required htmlFor="startDate" className="form-label">
              {productionOrderText.field.startDate}
            </FormLabel>
            <Controller
              name="startDate"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <DateInput
                    name={field.name}
                    id="startDate"
                    value={field.value || ""}
                    onChange={([date]) => {
                      field.onChange(date);
                      setValue("endDate", null, {
                        shouldValidate: dirtyFields.endDate ? true : false,
                      });
                    }}
                    options={{
                      minDate: startDatePlan || undefined,
                      maxDate: endDatePlan || undefined,
                    }}
                    isInvalid={fieldState.invalid}
                  />
                  <FormHelper message={fieldState.error?.message} />
                </>
              )}
            />
          </FormGroup>
        </Col>
        <Col xs={12} md={6}>
          <FormGroup>
            <FormLabel required htmlFor="endDate" className="form-label">
              {productionOrderText.field.endDate}
            </FormLabel>
            <Controller
              name="endDate"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <DateInput
                    name={field.name}
                    id="endDate"
                    value={field.value || ""}
                    onChange={([date]) => {
                      field.onChange(date);
                    }}
                    options={{
                      minDate: maxStartDate || undefined,
                      maxDate: endDatePlan || undefined,
                    }}
                    isInvalid={fieldState.invalid}
                  />
                  <FormHelper message={fieldState.error?.message} />
                </>
              )}
            />
          </FormGroup>
        </Col>
        <Col xs={12}>
          <FormGroup>
            <FormLabel htmlFor="note" className="form-label">
              {productionOrderText.field.note}
            </FormLabel>
            <Controller
              name="note"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <FormInput
                    {...field}
                    type="textarea"
                    className="form-control"
                    id="note"
                    invalid={!!fieldState.invalid}
                  />
                  <FormHelper message={fieldState.error?.message} />
                </>
              )}
            />
          </FormGroup>
        </Col>
        {watchPlan?.value && (
          <>
            <h5 className="py-2 fw-semibold">
              {productionOrderText.field.items}
            </h5>
            <Col lg={12}>
              <Card>
                <CardBody className="card-gray">
                  {itemsField.map((itemField, index) => (
                    <Card key={itemField.id}>
                      <CardBody>
                        <Row>
                          <Col xs={12}>
                            <FormGroup>
                              <Row>
                                <Col>
                                  <FormLabel
                                    htmlFor={`items.${index}.order`}
                                    className="form-label"
                                  >
                                    {productionOrderText.field.items}
                                  </FormLabel>
                                </Col>
                                <Col>
                                  <div className="text-end">
                                    <i
                                      className="ri-delete-bin-line fs-18 cursor-pointer lh-1"
                                      onClick={() => remove(index)}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </FormGroup>
                          </Col>
                          <Col xs={12}>
                            <FormGroup>
                              <FormLabel
                                required
                                htmlFor={`items.${index}.planItem`}
                                className="form-label"
                              >
                                {productionOrderText.field.planItem}
                              </FormLabel>
                              <Controller
                                name={`items.${index}.planItem` as const}
                                control={control}
                                render={({ field, fieldState }) => (
                                  <>
                                    <ProductionPlanItemSelect
                                      id={watch("plan")?.value}
                                      name={field.name}
                                      value={field.value}
                                      isOptionDisabled={(option) =>
                                        selectedOrderItems
                                          .map((item) => item?.value)
                                          .includes(option.value)
                                      }
                                      inputId={`items.${index}.planItem`}
                                      onChange={(value) => {
                                        field.onChange(value);
                                        setValue(
                                          `items.${index}.quantity`,
                                          null,
                                        );
                                        setValue(
                                          `items.${index}.remainingQuantity`,
                                          (value?.quantity || 0) -
                                            (value?.planQty || 0),
                                        );
                                        clearErrors(`items.${index}.quantity`);
                                        const defaultOrderItem =
                                          selectedDefaultOrderItem?.find(
                                            (item) =>
                                              item.planItem?.value ===
                                              value?.value,
                                          );
                                        if (defaultOrderItem) {
                                          setValue(
                                            `items.${index}.quantity`,
                                            defaultOrderItem.defaultQuantity,
                                          );
                                        } else {
                                          setValue(
                                            `items.${index}.quantity`,
                                            null,
                                          );
                                        }
                                      }}
                                      isInvalid={fieldState.invalid}
                                    />
                                    <FormHelper
                                      message={fieldState.error?.message}
                                    />
                                  </>
                                )}
                              />
                            </FormGroup>
                          </Col>
                          {watch(`items.${index}.planItem`)?.value && (
                            <Col md={12}>
                              <Alert color="info" fade={false}>
                                {productionOrderText.field.quantityRemaining}:{" "}
                                {watch(`items.${index}.remainingQuantity`)}
                              </Alert>
                            </Col>
                          )}
                          <Col md={12}>
                            <FormGroup>
                              <FormLabel
                                required
                                htmlFor={`items.${index}.quantity`}
                                className="form-label"
                              >
                                {productionOrderText.field.quantity}
                              </FormLabel>
                              <Controller
                                name={`items.${index}.quantity` as const}
                                control={control}
                                render={({ field, fieldState }) => {
                                  return (
                                    <>
                                      <FormInput
                                        {...field}
                                        value={field.value ?? ""}
                                        onChange={(value) => {
                                          const inputValue = value.target.value;
                                          field.onChange(
                                            inputValue
                                              ? Number(inputValue)
                                              : null,
                                          );
                                          handleQuantityChange(
                                            index,
                                            inputValue ? Number(inputValue) : 0,
                                          );
                                        }}
                                        type="number"
                                        className="form-control"
                                        id={`items.${index}.quantity`}
                                        invalid={fieldState.invalid}
                                      />
                                      <FormHelper
                                        message={fieldState.error?.message}
                                      />
                                    </>
                                  );
                                }}
                              />
                            </FormGroup>
                          </Col>
                          <Col xs={12}>
                            <FormGroup>
                              <FormLabel
                                htmlFor={`items.${index}.note`}
                                className="form-label"
                              >
                                {productionOrderText.field.note}
                              </FormLabel>
                              <Controller
                                name={`items.${index}.note`}
                                control={control}
                                render={({ field, fieldState }) => (
                                  <>
                                    <FormInput
                                      {...field}
                                      type="textarea"
                                      className="form-control"
                                      id={`items.${index}.note`}
                                      invalid={!!fieldState.invalid}
                                    />
                                    <FormHelper
                                      message={fieldState.error?.message}
                                    />
                                  </>
                                )}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  ))}
                  <Button
                    color="primary"
                    size="sm"
                    className="mb-4"
                    onClick={() =>
                      append({
                        note: "",
                        planItem: null,
                        quantity: null,
                        defaultQuantity: null,
                      })
                    }
                  >
                    {buttonText.add}
                  </Button>
                </CardBody>
              </Card>
            </Col>
            <Col xs={12}>
              <div className="hstack gap-2 justify-content-end">
                <Button
                  disabled={!isEnableSubmit}
                  type="submit"
                  color="primary"
                  loading={submitting}
                >
                  {buttonText.save}
                </Button>
              </div>
            </Col>
          </>
        )}
      </Row>
    </Form>
  );
};
