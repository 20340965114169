import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "app/components/molecules/Button";
import { FormHelper } from "app/components/molecules/FormHelper";
import { FormInput } from "app/components/molecules/FormInput";
import { FormLabel } from "app/components/molecules/FormLabel";
import { ReactSelectCustom } from "app/components/molecules/ReactSelectCustom";
import { yupSchema } from "app/helpers/schema";
import { useAppTranslation } from "app/hooks";
import { OptionSelect } from "app/services/types";
import { FC, FormEvent, useMemo, useState } from "react";
import { SketchPicker } from "react-color";
import { Controller, useForm } from "react-hook-form";
import { Col, Form, FormGroup, Label, Row } from "reactstrap";
import * as Yup from "yup";
import { useRetrieveMasterTypes } from "../../hooks/useRetrieveMasterTypes";

export interface MasterDataFormProps {
  defaultValues?: MasterDataFormData;
  onSubmit: (data: MasterDataFormData) => void;
  submitting?: boolean;
  onToggle: () => void;
  isQuickAdd?: boolean;
}

export interface MasterDataFormData {
  name: string;
  description: string;
  type: OptionSelect<ID> | null;
  colorCode: string;
}

export const MasterDataForm: FC<MasterDataFormProps> = ({
  onSubmit,
  defaultValues,
  submitting,
  onToggle,
  isQuickAdd = false,
}) => {
  const { masterDataText, buttonText } = useAppTranslation();
  const [isShowColorPicker, toggleShowColorPicker] = useState(false);
  const {
    control,
    handleSubmit,
    watch,
    formState: { isDirty, isValid },
  } = useForm<MasterDataFormData>({
    mode: "onChange",
    resolver: yupResolver(
      Yup.object({
        name: yupSchema.stringRequired("masterData.error.nameRequired"),
        description: yupSchema.stringNotRequired(),
        type: isQuickAdd
          ? yupSchema.objectRequired("masterData.error.typeRequired")
          : yupSchema.objectNotRequired(),
        colorCode: yupSchema.stringNotRequired(),
      }),
    ),
    defaultValues,
  });

  const isEnableSubmit = useMemo(
    () => (isQuickAdd ? isValid : isDirty && isValid && !submitting),
    [isDirty, isValid, submitting, isQuickAdd],
  );

  const submit = async (e: FormEvent) => {
    e.preventDefault();
    e.stopPropagation();
    handleSubmit(onSubmit)();
  };

  const watchColorCode = watch("colorCode");

  const { masterTypes } = useRetrieveMasterTypes({ page: 1, limit: 100 });
  const masterTypeOptions = masterTypes?.result?.map((masterType) => ({
    label: masterType.name,
    value: masterType.key,
  }));

  return (
    <Form onSubmit={submit} id="master-data-form">
      <Row>
        <Col xs={12}>
          <FormGroup>
            <FormLabel required htmlFor="name" className="form-label">
              {masterDataText.field.name}
            </FormLabel>
            <Controller
              name="name"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <FormInput
                    {...field}
                    type="text"
                    className="form-control"
                    id="name"
                    placeholder="Nhập"
                    invalid={!!fieldState.error}
                  />
                  <FormHelper message={fieldState.error?.message} />
                </>
              )}
            />
          </FormGroup>
        </Col>
        <Col xs={12}>
          <FormGroup>
            <FormLabel htmlFor="description" className="form-label">
              {masterDataText.field.description}
            </FormLabel>
            <Controller
              name="description"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <FormInput
                    {...field}
                    type="text"
                    className="form-control"
                    id="description"
                    placeholder="Nhập"
                    invalid={!!fieldState.error}
                  />
                  <FormHelper message={fieldState.error?.message} />
                </>
              )}
            />
          </FormGroup>
        </Col>
        {isQuickAdd ? (
          <Col xs={12}>
            <FormGroup>
              <FormLabel required htmlFor="type" className="form-label">
                {masterDataText.field.type}
              </FormLabel>
              <Controller
                name="type"
                control={control}
                render={({ field, fieldState }) => (
                  <>
                    <ReactSelectCustom
                      isDisabled={isQuickAdd}
                      value={field.value}
                      inputId="type"
                      onChange={field.onChange}
                      options={masterTypeOptions}
                      isInvalid={fieldState.invalid}
                    />
                    <FormHelper message={fieldState.error?.message} />
                  </>
                )}
              />
            </FormGroup>
          </Col>
        ) : null}
        <Col xs={12}>
          <FormGroup>
            <Label htmlFor="description" className="form-label">
              {masterDataText.field.color}
            </Label>
            <Controller
              name="colorCode"
              control={control}
              render={({ field }) => (
                <>
                  <div
                    className="nano-colorpicker mb-2"
                    onClick={() =>
                      toggleShowColorPicker((prevState) => !prevState)
                    }
                    onBlur={() =>
                      toggleShowColorPicker((prevState) => !prevState)
                    }
                  >
                    <i
                      style={{
                        border:
                          watchColorCode === "#ffffff" ||
                          watchColorCode === "#fff"
                            ? "1px solid black"
                            : undefined,
                        borderRadius: "8px",
                        height: "28px",
                        width: "28px",
                        background: watchColorCode,
                        display: "block",
                      }}
                    />
                  </div>
                  {isShowColorPicker ? (
                    <SketchPicker
                      color={watchColorCode}
                      width="auto"
                      onChangeComplete={({ hex }) => field.onChange(hex)}
                    />
                  ) : null}
                </>
              )}
            />
          </FormGroup>
        </Col>
        <Col xs={12}>
          <div className="hstack gap-2 justify-content-end">
            <Button color="light" onClick={onToggle}>
              {buttonText.cancel}
            </Button>
            <Button
              disabled={!isEnableSubmit}
              type="submit"
              color="primary"
              loading={submitting}
            >
              {buttonText.save}
            </Button>
          </div>
        </Col>
      </Row>
    </Form>
  );
};
