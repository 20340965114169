import { WithID } from "app/models";
import { Bom, CreateBomArgs, UpdateBomArgs } from "app/services/bom";
import { CommonProperties } from "app/services/types";
import { BomFormData } from "./components";

export const mapper = ({
  description,
  name,
  version,
}: BomFormData): CommonProperties<CreateBomArgs, UpdateBomArgs> => {
  return {
    name,
    version,
    description: description || null,
  };
};

export const mapCreateBom = (data: BomFormData): CreateBomArgs => {
  return { ...mapper(data), productId: data.product?.value as string };
};

export const mapUpdateBom = ({
  id,
  ...data
}: WithID<BomFormData>): UpdateBomArgs => {
  return { id, ...mapper(data) };
};

export const mapDefaultBom = ({
  name,
  version,
  description,
  product,
}: Partial<Bom> = {}): BomFormData => {
  return {
    product: product ? { label: product.nameVn, value: product.id } : null,
    version: version || "",
    name: name || "",
    description: description || "",
    bom: [],
  };
};
