import { useAppTranslation } from "app/hooks";
import { EmployeeIdCode } from "app/services/employee";
import { FC, MouseEvent, useEffect, useState } from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";

export type EmployeeIdCodeAction =
  | "create-employee-id-code"
  | "deactive-employee-id-code";

export interface EmployeeIdCodeActionDropdownProps {
  data?: EmployeeIdCode;
  onAction: (action: EmployeeIdCodeAction, data?: EmployeeIdCode) => void;
}

export const EmployeeIdCodeActionDropdown: FC<
  EmployeeIdCodeActionDropdownProps
> = ({ data, onAction }) => {
  const { employeeText } = useAppTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const handleToggle = () => setIsOpen(!isOpen);

  const handleAction = (action: EmployeeIdCodeAction) => () => {
    onAction(action, data);
    setIsOpen(false);
  };

  const isHaveEmployeeIdCode = !!data?.isActive;

  useEffect(() => {
    setTimeout(() => {
      const ulElements = document.querySelectorAll(
        ".dropdown-menu.dropdown-action",
      );
      ulElements.forEach((ulElement) => {
        const elm = ulElement.querySelectorAll("button");
        if (!elm.length) {
          const el = document.createElement("div");
          el.style.paddingLeft = "16px";
          el.textContent = "Không thể thao tác";
          ulElement?.append(el);
        }
      });
    }, 0);
  }, [isOpen]);

  return (
    <Dropdown
      isOpen={isOpen}
      toggle={(e: MouseEvent) => {
        e.stopPropagation();
        handleToggle();
      }}
      direction="start"
    >
      <DropdownToggle
        tag="button"
        className="btn py-0 px-2 m-0"
        onClick={(e) => {
          const trClosest = e.currentTarget.closest("tr");
          trClosest?.classList.toggle("z-3", !isOpen);
        }}
        onBlur={(e) => {
          const trClosest = e.currentTarget.closest("tr");
          setTimeout(() => trClosest?.classList.remove("z-3"), 100);
        }}
      >
        <i className="ri-more-fill"></i>
      </DropdownToggle>
      <DropdownMenu className="dropdown-menu-start mr-2 dropdown-action">
        {isHaveEmployeeIdCode ? (
          <DropdownItem
            className="p-0"
            onClick={handleAction("deactive-employee-id-code")}
          >
            <div className="dropdown-item">
              <i className="ri-user-add-line text-muted fs-16 align-middle me-1"></i>
              <span className="align-middle">
                {employeeText.deactiveEmployeeIdCode}
              </span>
            </div>
          </DropdownItem>
        ) : null}
        <div />
      </DropdownMenu>
    </Dropdown>
  );
};
