import { closestCenter, DndContext } from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "app/components/molecules/Button";
import { FormHelper } from "app/components/molecules/FormHelper";
import { FormInput } from "app/components/molecules/FormInput";
import { FormLabel } from "app/components/molecules/FormLabel";
import { AvatarUpload } from "app/components/organisms/AvatarUpload/AvatarUpload";
import { FileUpload } from "app/components/organisms/FileUpload";
import { yupSchema } from "app/helpers/schema";
import { useAppTranslation, useGetEnableSubmitButton } from "app/hooks";
import { FilePreview } from "app/models";
import { MasterDataInfiniteSelect } from "app/modules/masterData/components/MasterDataInfinitySelect/MasterDataInfinitySelect";
import { MasterData, MasterDataType } from "app/services/masterData";
import { OptionSelect } from "app/services/types";
import { FC, FormEvent } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { Card, CardBody, Col, Form, FormGroup, Row } from "reactstrap";
import * as Yup from "yup";
import { StepCard } from "./StepCard";

export interface ProductFormProps {
  defaultValues?: ProductFormData;
  onSubmit: (data: ProductFormData) => void;
  submitting?: boolean;
  onCancel?: () => void;
}

export interface ProductStepItem {
  id?: ID | null;
  step: OptionSelect<ID> | null;
  quantity: number | null;
  estimateTime: number | null;
  order: number | null;
}

export interface ProductFormData {
  code: string;
  nameEn: string;
  nameVn: string;
  group: OptionSelect<ID> | null;
  unit: OptionSelect<ID> | null;
  countryOfOrigin: string;
  description: string;
  lengthCm: number | null;
  widthCm: number | null;
  heightCm: number | null;
  seatSizeCm: string;
  color: string;
  avatars: FilePreview[];
  workSteps: ProductStepItem[];
  images: FilePreview[];
  documents: FilePreview[];
}

export const ProductForm: FC<ProductFormProps> = ({
  onSubmit,
  defaultValues,
  submitting,
  onCancel,
}) => {
  const { productText, buttonText } = useAppTranslation();
  const {
    control,
    watch,
    setValue,
    handleSubmit,
    formState: { isDirty, isValid },
  } = useForm<ProductFormData>({
    mode: "onChange",
    resolver: yupResolver(
      Yup.object({
        code: yupSchema.stringRequired("product.error.codeRequired"),
        nameEn: yupSchema.stringNotRequired(),
        nameVn: yupSchema.stringRequired("product.error.nameVnRequired"),
        group: yupSchema.objectNotRequired(),
        unit: yupSchema.objectNotRequired(),
        countryOfOrigin: yupSchema.stringNotRequired(),
        description: yupSchema.stringNotRequired(),
        lengthCm: yupSchema.numberMoreThanNotRequired(
          0,
          "product.error.lengthCmMoreThanZero",
        ),
        widthCm: yupSchema.numberMoreThanNotRequired(
          0,
          "product.error.widthCmMoreThanZero",
        ),
        heightCm: yupSchema.numberMoreThanNotRequired(
          0,
          "product.error.heightCmMoreThanZero",
        ),
        seatSizeCm: yupSchema.stringNotRequired(),
        color: yupSchema.stringNotRequired(),
        avatars: yupSchema.fileNotRequired(),
        workSteps: Yup.array()
          .of(
            Yup.object({
              step: yupSchema.objectRequired("product.error.workStepRequired"),
              estimateTime: yupSchema.numberMoreThanOrEqualNotRequired(
                0,
                "product.error.estimateTimeMoreThanOrEqualZero",
              ),
              quantity: yupSchema.numberMoreThanNotRequired(
                0,
                "product.error.quantityMoreThanZero",
              ),
              order: yupSchema.numberMoreThanOrEqualRequired(0, "", ""),
            }),
          )
          .defined(),
        images: yupSchema.fileNotRequired(),
        documents: yupSchema.fileNotRequired(),
      }),
    ),
    defaultValues,
  });

  const isEnableSubmit = useGetEnableSubmitButton({
    isDirty,
    isValid,
    submitting,
  });

  const submit = async (e: FormEvent) => {
    e.preventDefault();
    handleSubmit(onSubmit)();
  };

  const handleSetValue =
    (name: keyof ProductFormData) => (data: MasterData) => {
      setValue(
        name,
        {
          value: data.id,
          label: data.name,
        },
        { shouldDirty: true },
      );
    };

  const {
    fields: _workStepsField,
    remove: removeStep,
    append: appendStep,
  } = useFieldArray({
    control,
    name: "workSteps",
  });

  const watchProductSteps = watch("workSteps");
  const workStepsField = _workStepsField.map((field, index) => {
    return {
      ...field,
      ...watchProductSteps[index],
    };
  });

  const handleDragEnd = (event: any) => {
    const { active, over } = event;
    if (active.id !== over.id) {
      const oldIndex = workStepsField.findIndex(
        (item) => item.id === active.id,
      );
      const newIndex = workStepsField.findIndex((item) => item.id === over.id);
      const newOrder = arrayMove(workStepsField, oldIndex, newIndex).map(
        (item, index) => ({
          ...item,
          order: index + 1,
        }),
      );
      setValue("workSteps", newOrder, { shouldDirty: true });
    }
  };

  return (
    <Form onSubmit={submit} id="product-form">
      <h5 className="py-2 fw-semibold">
        {productText.detail.generalInfomation}
      </h5>
      <Row>
        <div className="d-flex gap-4">
          <FormGroup>
            <FormLabel htmlFor="avatars" className="form-label">
              {productText.field.avatar}
            </FormLabel>
            <Controller
              name="avatars"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <AvatarUpload
                    value={field.value || []}
                    onChange={field.onChange}
                  />
                  <FormHelper message={fieldState.error?.message} />
                </>
              )}
            />
          </FormGroup>
          <Row className="flex-grow-1">
            <Col xs={12}>
              <FormGroup>
                <FormLabel required htmlFor="code" className="form-label">
                  {productText.field.code}
                </FormLabel>
                <Controller
                  name="code"
                  control={control}
                  render={({ field, fieldState }) => (
                    <>
                      <FormInput
                        {...field}
                        type="text"
                        className="form-control"
                        id="code"
                        invalid={!!fieldState.invalid}
                      />
                      <FormHelper message={fieldState.error?.message} />
                    </>
                  )}
                />
              </FormGroup>
            </Col>
            <Col xs={12} md={6}>
              <FormGroup>
                <FormLabel required htmlFor="nameVn" className="form-label">
                  {productText.field.nameVn}
                </FormLabel>
                <Controller
                  name="nameVn"
                  control={control}
                  render={({ field, fieldState }) => (
                    <>
                      <FormInput
                        {...field}
                        type="text"
                        className="form-control"
                        id="nameVn"
                        invalid={!!fieldState.invalid}
                      />
                      <FormHelper message={fieldState.error?.message} />
                    </>
                  )}
                />
              </FormGroup>
            </Col>
            <Col xs={12} md={6}>
              <FormGroup>
                <FormLabel htmlFor="nameEn" className="form-label">
                  {productText.field.nameEn}
                </FormLabel>
                <Controller
                  name="nameEn"
                  control={control}
                  render={({ field, fieldState }) => (
                    <>
                      <FormInput
                        {...field}
                        type="text"
                        className="form-control"
                        id="nameEn"
                        invalid={!!fieldState.invalid}
                      />
                      <FormHelper message={fieldState.error?.message} />
                    </>
                  )}
                />
              </FormGroup>
            </Col>
          </Row>
        </div>

        <Col xs={12} md={4}>
          <FormGroup>
            <FormLabel htmlFor="group" className="form-label">
              {productText.field.group}
            </FormLabel>
            <Controller
              name="group"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <MasterDataInfiniteSelect
                    isCreatable
                    key="group"
                    name="group"
                    inputId="group"
                    value={field.value}
                    onChange={field.onChange}
                    isInvalid={fieldState.invalid}
                    retrieveKeys={{ type: MasterDataType.ProductGroup }}
                    onCreateSuccess={handleSetValue("group")}
                  />
                  <FormHelper message={fieldState.error?.message} />
                </>
              )}
            />
          </FormGroup>
        </Col>
        <Col xs={12} md={4}>
          <FormGroup>
            <FormLabel htmlFor="unit" className="form-label">
              {productText.field.unit}
            </FormLabel>
            <Controller
              name="unit"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <MasterDataInfiniteSelect
                    isCreatable
                    key="unit"
                    name="unit"
                    inputId="unit"
                    value={field.value}
                    onChange={field.onChange}
                    isInvalid={fieldState.invalid}
                    retrieveKeys={{ type: MasterDataType.ProductUnit }}
                    onCreateSuccess={handleSetValue("unit")}
                  />
                  <FormHelper message={fieldState.error?.message} />
                </>
              )}
            />
          </FormGroup>
        </Col>
        <Col xs={12} md={4}>
          <FormGroup>
            <FormLabel htmlFor="countryOfOrigin" className="form-label">
              {productText.field.countryOfOrigin}
            </FormLabel>
            <Controller
              name="countryOfOrigin"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <FormInput
                    {...field}
                    type="text"
                    className="form-control"
                    id="countryOfOrigin"
                    invalid={!!fieldState.invalid}
                  />
                  <FormHelper message={fieldState.error?.message} />
                </>
              )}
            />
          </FormGroup>
        </Col>
        <Col xs={12} md={4}>
          <FormGroup>
            <FormLabel htmlFor="lengthCm" className="form-label">
              {productText.field.lengthCm}
            </FormLabel>
            <Controller
              name="lengthCm"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <FormInput
                    {...field}
                    value={field.value ?? ""}
                    onChange={(value) => {
                      field.onChange(
                        value.target.value === ""
                          ? null
                          : Number(value.target.value),
                      );
                    }}
                    type="number"
                    className="form-control"
                    id="lengthCm"
                    invalid={!!fieldState.invalid}
                  />
                  <FormHelper message={fieldState.error?.message} />
                </>
              )}
            />
          </FormGroup>
        </Col>
        <Col xs={12} md={4}>
          <FormGroup>
            <FormLabel htmlFor="widthCm" className="form-label">
              {productText.field.widthCm}
            </FormLabel>
            <Controller
              name="widthCm"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <FormInput
                    {...field}
                    value={field.value ?? ""}
                    onChange={(value) => {
                      field.onChange(
                        value.target.value === ""
                          ? null
                          : Number(value.target.value),
                      );
                    }}
                    type="number"
                    className="form-control"
                    id="widthCm"
                    invalid={!!fieldState.invalid}
                  />
                  <FormHelper message={fieldState.error?.message} />
                </>
              )}
            />
          </FormGroup>
        </Col>
        <Col xs={12} md={4}>
          <FormGroup>
            <FormLabel htmlFor="heightCm" className="form-label">
              {productText.field.heightCm}
            </FormLabel>
            <Controller
              name="heightCm"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <FormInput
                    {...field}
                    value={field.value ?? ""}
                    onChange={(value) => {
                      field.onChange(
                        value.target.value === ""
                          ? null
                          : Number(value.target.value),
                      );
                    }}
                    type="number"
                    className="form-control"
                    id="heightCm"
                    invalid={!!fieldState.invalid}
                  />
                  <FormHelper message={fieldState.error?.message} />
                </>
              )}
            />
          </FormGroup>
        </Col>
        <Col xs={12} md={6}>
          <FormGroup>
            <FormLabel htmlFor="seatSizeCm" className="form-label">
              {productText.field.seatSizeCm}
            </FormLabel>
            <Controller
              name="seatSizeCm"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <FormInput
                    {...field}
                    type="text"
                    className="form-control"
                    id="seatSizeCm"
                    invalid={!!fieldState.invalid}
                  />
                  <FormHelper message={fieldState.error?.message} />
                </>
              )}
            />
          </FormGroup>
        </Col>
        <Col xs={12} md={6}>
          <FormGroup>
            <FormLabel htmlFor="color" className="form-label">
              {productText.field.color}
            </FormLabel>
            <Controller
              name="color"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <FormInput
                    {...field}
                    type="text"
                    className="form-control"
                    id="color"
                    invalid={!!fieldState.invalid}
                  />
                  <FormHelper message={fieldState.error?.message} />
                </>
              )}
            />
          </FormGroup>
        </Col>
        <Col xs={12}>
          <FormGroup>
            <FormLabel htmlFor="description" className="form-label">
              {productText.field.description}
            </FormLabel>
            <Controller
              name="description"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <FormInput
                    {...field}
                    type="textarea"
                    className="form-control"
                    id="description"
                    invalid={!!fieldState.invalid}
                  />
                  <FormHelper message={fieldState.error?.message} />
                </>
              )}
            />
          </FormGroup>
        </Col>
        <h5 className="py-2 fw-semibold">{productText.field.workStep}</h5>
        <Col lg={12}>
          <Card className="card-gray">
            <CardBody>
              <DndContext
                collisionDetection={closestCenter}
                onDragEnd={handleDragEnd}
                modifiers={[restrictToVerticalAxis]}
              >
                <SortableContext
                  items={workStepsField.map((item) => item.id as ID)}
                  strategy={verticalListSortingStrategy}
                >
                  {workStepsField.map((field, index) => (
                    <StepCard
                      key={field.id}
                      index={index}
                      field={field}
                      control={control}
                      removeStep={removeStep}
                      workStepsField={workStepsField}
                    />
                  ))}
                </SortableContext>
              </DndContext>
              <Button
                color="primary"
                size="sm"
                className="mb-4"
                onClick={() =>
                  appendStep({
                    step: null,
                    estimateTime: null,
                    quantity: null,
                    order: workStepsField.length + 1,
                  })
                }
              >
                {buttonText.add}
              </Button>
            </CardBody>
          </Card>
        </Col>
        <Col xs={12} md={6}>
          <FormGroup>
            <FormLabel htmlFor="images" className="form-label">
              {productText.field.image}
            </FormLabel>
            <Controller
              name="images"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <FileUpload
                    value={field.value || []}
                    onChange={field.onChange}
                  />
                  <FormHelper message={fieldState.error?.message} />
                </>
              )}
            />
          </FormGroup>
        </Col>
        <Col xs={12} md={6}>
          <FormGroup>
            <FormLabel htmlFor="documents" className="form-label">
              {productText.field.document}
            </FormLabel>
            <Controller
              name="documents"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <FileUpload
                    value={field.value || []}
                    type="document"
                    onChange={field.onChange}
                  />
                  <FormHelper message={fieldState.error?.message} />
                </>
              )}
            />
          </FormGroup>
        </Col>
        <Col xs={12}>
          <div className="hstack gap-2 justify-content-end">
            {onCancel ? (
              <Button color="light" onClick={onCancel}>
                {buttonText.cancel}
              </Button>
            ) : null}
            <Button
              disabled={!isEnableSubmit}
              type="submit"
              color="primary"
              loading={submitting}
            >
              {buttonText.save}
            </Button>
          </div>
        </Col>
      </Row>
    </Form>
  );
};
