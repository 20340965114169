import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { httpClient } from "app/datasources";
import { AxiosError, AxiosResponse } from "axios";
import { Response } from "../types";
import { Bom } from "./types";

export const UPDATE_BOM_URL = (id: ID) => `/api/admin/boms/${id}`;

export enum UpdateBomError {}

export const updateBomErrorMessages = {};

export type UpdateBomResponse = Response<Bom>;

export type UpdateBomArgs = {
  id: ID;
  name: string;
  version: string;
  description: string | null;
};

export const updateBom = ({ id, ...args }: UpdateBomArgs) => {
  return httpClient.put<UpdateBomResponse>(UPDATE_BOM_URL(id), args);
};

export const useUpdateBomMutation = (
  opts?: Omit<
    UseMutationOptions<
      AxiosResponse<UpdateBomResponse>,
      AxiosError<any>,
      UpdateBomArgs
    >,
    "mutationFn"
  >,
) =>
  useMutation<AxiosResponse<UpdateBomResponse>, AxiosError<any>, UpdateBomArgs>(
    {
      mutationFn: updateBom,
      mutationKey: [UPDATE_BOM_URL],
      ...opts,
    },
  );
