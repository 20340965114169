import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { httpClientPanel } from "app/datasources";
import { Response } from "app/services/types";
import { AxiosError, AxiosResponse } from "axios";
import { Ticket } from "./types";

export const START_PANEL_TICKET_URL = (id: ID) =>
  `/api/panel/tickets/${id}/start`;

export enum StartPanelTicketError {}

export const startPanelTicketErrorMessages = {};

export interface StartPanelTicketArgs {
  id: ID;
  employeeId: ID;
}

export type StartPanelTicketResponse = Response<Ticket>;

export const startPanelTicket = ({ id, ...args }: StartPanelTicketArgs) => {
  return httpClientPanel.post<StartPanelTicketResponse>(
    START_PANEL_TICKET_URL(id),
    args,
  );
};

export const useStartPanelTicketMutation = (
  opts?: Omit<
    UseMutationOptions<
      AxiosResponse<StartPanelTicketResponse>,
      AxiosError<any>,
      StartPanelTicketArgs
    >,
    "mutationFn"
  >,
) =>
  useMutation<
    AxiosResponse<StartPanelTicketResponse>,
    AxiosError<any>,
    StartPanelTicketArgs
  >({
    mutationFn: startPanelTicket,
    mutationKey: [START_PANEL_TICKET_URL],
    ...opts,
  });
