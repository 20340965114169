import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { httpClientPanel } from "app/datasources";
import { APIError, APISuccess, Response } from "../types";

export const LOGIN_PANEL_URL = "/api/panel/auth/login";

export type LoginPanelResponse = Response<{
  accessToken: string;
  refreshToken: string;
}>;

export type LoginPanelArgs = {
  username: string;
  password: string;
  rememberMe?: boolean;
};

export enum LoginPanelError {
  UserInactive = "User Inactive",
  InvalidLoginPanelInfo = "User Not Found",
  UserNotValidAccessType = "User Not Valid Access Type",
  HaveNotPermission = "HAVE_NOT_PERMISSION",
  Unauthorized = "Unauthorized",
}

export const loginPanelErrorMessages = {
  [LoginPanelError.InvalidLoginPanelInfo]:
    "Thông tin đăng nhập không chính xác",
  [LoginPanelError.UserInactive]: "Thông tin đăng nhập không chính xác",
  [LoginPanelError.UserNotValidAccessType]:
    "Thông tin đăng nhập không chính xác",
  [LoginPanelError.HaveNotPermission]: "Bạn không có quyền truy cập",
  [LoginPanelError.Unauthorized]: "Thông tin đăng nhập không chính xác",
};

export const loginPanel = (loginPanelArgs: LoginPanelArgs) => {
  return httpClientPanel.post<LoginPanelResponse>(
    LOGIN_PANEL_URL,
    loginPanelArgs,
  );
};

export const useLoginPanelMutation = (
  opts?: UseMutationOptions<
    APISuccess<LoginPanelResponse>,
    APIError,
    LoginPanelArgs
  >,
) =>
  useMutation({
    mutationFn: loginPanel,
    mutationKey: [LOGIN_PANEL_URL],
    ...opts,
  });
