import { PagingData } from "./../types";
import {
  keepPreviousData,
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  useQuery,
  UseQueryOptions,
} from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import { Response } from "../types";
import { httpClient } from "app/datasources";
import { Vendor } from "./types";
import { DEFAULT_LIMIT_PAGE, DEFAULT_PAGE } from "app/helpers";

export const RETRIEVE_VENDORS_URL = "/api/admin/vendors";

export interface RetrieveVendorsArgs {
  page: number;
  limit: number;
  searchString?: string;
}

export type RetrieveVendorsResponse = Response<PagingData<Vendor[]>>;

export const retrieveVendors = (args: RetrieveVendorsArgs) => {
  return httpClient.get<RetrieveVendorsResponse>(RETRIEVE_VENDORS_URL, {
    params: args,
  });
};

export const useRetrieveVendorsQuery = (
  retrieveVendorsArgs: RetrieveVendorsArgs,
  opts?: Omit<
    UseQueryOptions<AxiosResponse<RetrieveVendorsResponse>, AxiosError<any>>,
    "queryKey" | "queryFn"
  >,
) =>
  useQuery<AxiosResponse<RetrieveVendorsResponse>, AxiosError<any>>({
    queryKey: [RETRIEVE_VENDORS_URL, retrieveVendorsArgs],
    queryFn: () => retrieveVendors(retrieveVendorsArgs),
    placeholderData: keepPreviousData,
    ...opts,
  });

export const useRetrieveVendorsInfiniteQuery = (
  retrieveVendorsArgs: RetrieveVendorsArgs,
  opts?: Omit<
    UseInfiniteQueryOptions<
      AxiosResponse<RetrieveVendorsResponse>,
      AxiosError<any>
    >,
    "initialPageParam" | "queryFn" | "queryKey" | "getNextPageParam"
  >,
): any => {
  return useInfiniteQuery<
    AxiosResponse<RetrieveVendorsResponse>,
    AxiosError<any>,
    AxiosResponse<RetrieveVendorsResponse, any>
  >({
    initialPageParam: {
      page: DEFAULT_PAGE,
      limit: DEFAULT_LIMIT_PAGE,
    },
    queryKey: [RETRIEVE_VENDORS_URL, "INFINITE_QUERY"],
    queryFn: ({ pageParam }) => {
      const { page, limit } = pageParam as RetrieveVendorsArgs;
      return retrieveVendors({
        ...retrieveVendorsArgs,
        page,
        limit,
      });
    },
    getNextPageParam: (lastPage) => {
      const total = lastPage.data.data.total;
      const { limit, page } = lastPage.config.params as RetrieveVendorsArgs;
      let nextPageParam: RetrieveVendorsArgs | undefined;

      if (page) {
        const hasNextPage = page * (limit || DEFAULT_LIMIT_PAGE) < total;

        if (hasNextPage) {
          nextPageParam = {
            ...retrieveVendorsArgs,
            page: page + 1,
            limit,
          };
        }
      }
      return nextPageParam;
    },
    ...opts,
  });
};
