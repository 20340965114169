import { DebouncedInput } from "app/components/molecules/DebounceInput";
import { FilterOffCanvas } from "app/components/molecules/FilterOffCanvas";
import { FormLabel } from "app/components/molecules/FormLabel";
import { useAppTranslation } from "app/hooks";
import { MasterDataInfiniteSelect } from "app/modules/masterData/components";
import { ProductTypeEnum } from "app/modules/product/constants";
import { useRetrieveComponents } from "app/modules/product/hooks";
import { MasterDataType } from "app/services/masterData";
import { OptionSelect } from "app/services/types";
import { FC, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { FormGroup } from "reactstrap";

export interface ComponentFilterParams {
  type: OptionSelect | null;
}

export interface ListComponentFilterProps {
  searchString: string;
  filterParams: ComponentFilterParams;
  handleSearchChange: (value: string) => void;
  setCustomParams: (params: ComponentFilterParams) => void;
}

export const ListComponentFilter: FC<ListComponentFilterProps> = ({
  handleSearchChange,
  filterParams,
  searchString,
  setCustomParams,
}) => {
  const { componentText } = useAppTranslation();
  const { control, reset, watch, handleSubmit } =
    useForm<ComponentFilterParams>({
      defaultValues: filterParams,
    });

  const watchType = watch("type");

  const { components } = useRetrieveComponents({
    limit: 20,
    page: 1,
    searchString: searchString || undefined,
    typeId: watchType ? watchType.value : undefined,
  });

  const total = components?.total || 0;

  const handleFilter = () => {
    setCustomParams({
      type: watchType,
    });
    handleToggle();
  };

  const handleClearFilter = () => {
    reset({
      type: null,
    });
  };

  useEffect(() => {
    reset(filterParams);
  }, [filterParams]);

  const formID = "component-filter-form";

  const [show, setShow] = useState(false);
  const handleToggle = () => setShow(!show);
  return (
    <div className="d-flex align-items-center mb-2">
      <div className="search-box me-2 d-inline-block">
        <DebouncedInput
          name="list-component-search"
          value={searchString || ""}
          onChange={(value) => {
            handleSearchChange(String(value));
          }}
        />
        <i className="bx bx-search-alt search-icon"></i>
      </div>
      <form noValidate id={formID} onSubmit={handleSubmit(handleFilter)}>
        <FilterOffCanvas
          formId={formID}
          handleClearFilter={handleClearFilter}
          onToggle={handleToggle}
          show={show}
          total={total}
        >
          <div className="d-flex flex-column flex-grow-1">
            <FormGroup>
              <FormLabel className="mb-2">{componentText.field.type}</FormLabel>
              <Controller
                name="type"
                control={control}
                render={({ field, fieldState }) => (
                  <MasterDataInfiniteSelect
                    isCreatable
                    key="type"
                    name="type"
                    inputId="type"
                    value={field.value}
                    onChange={field.onChange}
                    isInvalid={fieldState.invalid}
                    retrieveKeys={{ type: MasterDataType.ProductType }}
                    filterOption={(option) =>
                      option.value !== ProductTypeEnum.Product
                    }
                  />
                )}
              />
            </FormGroup>
          </div>
        </FilterOffCanvas>
      </form>
    </div>
  );
};
