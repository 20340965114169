import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { httpClient } from "app/datasources";
import { Response } from "app/services/types";
import { AxiosError, AxiosResponse } from "axios";
import { Bom } from "./types";

export const CREATE_BOM_URL = "/api/admin/boms";

export enum CreateBomError {}

export const createBomErrorMessages = {};

export interface CreateBomArgs {
  productId: ID;
  name: string;
  version: string;
  description: string | null;
}

export type CreateBomResponse = Response<Bom>;

export const createBom = (args: CreateBomArgs) => {
  return httpClient.post<CreateBomResponse>(CREATE_BOM_URL, args);
};

export const useCreateBomMutation = (
  opts?: Omit<
    UseMutationOptions<
      AxiosResponse<CreateBomResponse>,
      AxiosError<any>,
      CreateBomArgs
    >,
    "mutationFn"
  >,
) =>
  useMutation<AxiosResponse<CreateBomResponse>, AxiosError<any>, CreateBomArgs>(
    {
      mutationFn: createBom,
      mutationKey: [CREATE_BOM_URL],
      ...opts,
    },
  );
