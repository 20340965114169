import {
  RetrievePanelTicketsByEmployeeIdArgs,
  useRetrievePanelTicketsByEmployeeIdQuery,
} from "app/services/ticket";

export const useRetrievePanelTicketsByEmployeeId = (
  args: RetrievePanelTicketsByEmployeeIdArgs,
  opts?: Parameters<typeof useRetrievePanelTicketsByEmployeeIdQuery>[1],
) => {
  const result = useRetrievePanelTicketsByEmployeeIdQuery(args, opts as any);
  return {
    tickets: result.data?.data.data,
    isLoadingTicket: result.isLoading,
    ...result,
  };
};
