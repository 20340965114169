import { yupResolver } from "@hookform/resolvers/yup";
import { ColumnDef } from "@tanstack/react-table";
import { Button } from "app/components/molecules/Button";
import { useConfirm } from "app/components/molecules/ConfirmationProvider";
import { DebouncedInput } from "app/components/molecules/DebounceInput";
import { FormHelper } from "app/components/molecules/FormHelper";
import { FormInput } from "app/components/molecules/FormInput";
import { EditInlineDataTable } from "app/components/organisms/EditInlineDataTable";
import ListPage from "app/components/templates/ListPage";
import { DEFAULT_LIMIT_PAGE, DEFAULT_PAGE } from "app/helpers";
import { yupSchema } from "app/helpers/schema";
import useAppSearch from "app/hooks/useAppSearch";
import { useAppTranslation } from "app/hooks/useAppTranslation";
import { MasterDataInfiniteSelect } from "app/modules/masterData/components";
import { StepAction, StepActionDropdown } from "app/modules/step/components";
import {
  useCreateStep,
  useDeleteStep,
  useRetrieveSteps,
  useUpdateStep,
} from "app/modules/step/hooks";
import { mapCreateStep } from "app/modules/step/mapper";
import { MasterDataType } from "app/services/masterData";
import { Step } from "app/services/step";
import BreadCrumb from "Components/Common/BreadCrumb";
import "gridjs/dist/theme/mermaid.css";
import React, { FC, useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Badge, Col, Row } from "reactstrap";
import * as yup from "yup";

interface ListStepProps {}

const schema = yup.object({
  steps: yup
    .array()
    .of(
      yup.object({
        name: yupSchema.stringRequired("step.error.nameRequired"),
        description: yupSchema.stringNotRequired(),
        skills: yupSchema.arrayNotRequired(),
      }),
    )
    .notRequired(),
});

const ListStep: FC<ListStepProps> = () => {
  const { confirm } = useConfirm();
  const navigate = useNavigate();
  const { stepText, confirmText, t } = useAppTranslation();

  const { page, limit, searchString, setPage, setLimit, setSearchString } =
    useAppSearch({
      initialPage: DEFAULT_PAGE,
      initialLimit: DEFAULT_LIMIT_PAGE,
      initialSearchString: "",
    });

  const { steps, isLoadingSteps } = useRetrieveSteps({
    limit,
    page,
    searchString: searchString || undefined,
  });

  const { mutateAsync: updateStep } = useUpdateStep();

  const { mutateAsync: deleteStep } = useDeleteStep();

  const handleActionClick = (action: StepAction, data?: Step) => {
    switch (action) {
      case "edit":
        if (data) {
          navigate(`/step/${data.id}/update`);
        }
        break;
      case "detail":
        if (data) {
          navigate(`/step/${data.id}`);
        }
        break;
      case "delete":
        if (data?.id) {
          confirm({
            title: confirmText.deleteTitle(stepText.title),
            description: confirmText.deleteContent(stepText.title),
            onConfirm: () => deleteStep({ id: data.id }),
          });
        }
        break;
      default:
        break;
    }
  };

  const [data, setData] = useState<Step[]>(steps?.result || []);
  const [editingRows, setEditingRows] = useState<Record<number, boolean>>({});
  const [backupData, setBackupData] = useState<Record<number, Step>>({});

  useEffect(() => {
    setData(steps?.result || []);
  }, [steps]);

  const { control, getValues, reset, trigger } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      steps: data.map((step) => ({
        name: step.name || "",
        description: step.description || "",
        skills:
          step.stepSkills?.map((skill) => ({
            label: skill.skill.name,
            value: skill.skill.id,
          })) || [],
      })),
    },
  });

  useEffect(() => {
    reset({
      steps: data.map((step) => ({
        name: step.name || "",
        description: step.description || "",
        skills:
          step.stepSkills?.map((skill) => ({
            label: skill.skill.name,
            value: skill.skill.id,
          })) || [],
      })),
    });
  }, [data]);

  const startEditing = (rowIndex: number) => {
    setBackupData((prev) => ({ ...prev, [rowIndex]: { ...data[rowIndex] } })); // Lưu dữ liệu cũ
    setEditingRows((prev) => ({ ...prev, [rowIndex]: true }));
  };

  const saveRow = async (rowIndex: number) => {
    const isValid = await trigger(`steps.${rowIndex}`, { shouldFocus: true });
    if (!isValid) {
      return;
    }
    const updatedRow = { ...data[rowIndex], ...getValues(`steps.${rowIndex}`) };

    updateStep({
      id: updatedRow.id,
      name: updatedRow.name,
      description: updatedRow.description,
      skillIds: updatedRow.skills.map((skill) => skill.value),
    });

    setEditingRows((prev) => ({ ...prev, [rowIndex]: false }));
  };

  const { mutateAsync: createStep } = useCreateStep();

  const handleCreate = () => {
    const createData = getValues(`steps.${0}`);
    createStep(mapCreateStep(createData));
  };

  const handleRemove = () => {
    setData(data.slice(1));
    setEditingRows((prev) => {
      const newEditingRows = { ...prev };
      Object.keys(newEditingRows).forEach((key: any) => {
        newEditingRows[key] = false;
      });
      return newEditingRows;
    });
  };

  const cancelEdit = (rowIndex: number) => {
    if (backupData[rowIndex]) {
      setData((prevData) =>
        prevData.map((row, index) =>
          index === rowIndex ? backupData[rowIndex] : row,
        ),
      );
    }
    setEditingRows((prev) => ({ ...prev, [rowIndex]: false }));
  };

  const columns: ColumnDef<Step>[] = useMemo(
    () => [
      {
        header: stepText.field.name,
        accessorKey: "name",
        size: 200,
        cell: ({ row }) => {
          const rowIndex = row.index;
          const controller = (
            <Controller
              name={`steps.${rowIndex}.name` as const}
              control={control}
              render={({ field, fieldState }) => (
                <div className="d-flex flex-column">
                  <FormInput
                    {...field}
                    value={field.value || ""}
                    type="text"
                    id={`steps.${rowIndex}.name`}
                    invalid={!!fieldState.invalid}
                  />
                  <FormHelper message={fieldState.error?.message} />
                </div>
              )}
            />
          );
          const isNew = row.original.id === undefined;
          if (isNew) {
            return controller;
          }
          return editingRows[rowIndex] ? (
            <div className="d-flex align-items-center gap-2">{controller}</div>
          ) : (
            row.original.name || "-"
          );
        },
      },
      {
        header: stepText.field.description,
        accessorKey: "description",
        size: 200,
        cell: ({ row }) => {
          const rowIndex = row.index;
          const isNew = row.original.id === undefined;
          const controller = (
            <Controller
              name={`steps.${rowIndex}.description` as const}
              control={control}
              render={({ field, fieldState }) => (
                <div className="d-flex flex-column">
                  <FormInput
                    {...field}
                    value={field.value || ""}
                    type="text"
                    id={`steps.${rowIndex}.description` as const}
                    invalid={!!fieldState.invalid}
                  />
                  <FormHelper message={fieldState.error?.message} />
                </div>
              )}
            />
          );
          if (isNew) {
            return controller;
          }
          return editingRows[rowIndex] ? (
            <div className="d-flex align-items-center gap-2">{controller}</div>
          ) : (
            row.original.description || "-"
          );
        },
      },
      {
        header: stepText.field.skill,
        accessorKey: "skill",
        size: 200,
        cell: ({ row }) => {
          const rowIndex = row.index;
          const isNew = row.original.id === undefined;
          const controller = (
            <Controller
              name={`steps.${rowIndex}.skills` as const}
              control={control}
              render={({ field, fieldState }) => (
                <div className="d-flex flex-column">
                  <MasterDataInfiniteSelect
                    className="w-100"
                    isMulti
                    isCreatable
                    key="skills"
                    name="skills"
                    inputId={`steps.${rowIndex}.skills` as const}
                    value={field.value}
                    onChange={field.onChange}
                    isInvalid={fieldState.invalid}
                    retrieveKeys={{ type: MasterDataType.Skill }}
                  />
                  <FormHelper message={fieldState.error?.message} />
                </div>
              )}
            />
          );
          if (isNew) {
            return controller;
          }
          return editingRows[rowIndex] ? (
            <div className="d-flex align-items-center gap-2">{controller}</div>
          ) : (
            <div className="d-flex gap-1 flex-wrap">
              {row.original.stepSkills?.map((skill) => (
                <Badge pill key={skill.id} color="primary">
                  {skill.skill.name}
                </Badge>
              )) || "-"}
            </div>
          );
        },
      },
      {
        header: "",
        accessorKey: "action",
        size: 40,
        cell: ({ row }) => {
          const rowIndex = row.index;
          const isNew = row.original.id === undefined;
          if (isNew) {
            return (
              <div
                className="d-flex gap-2"
                onClick={(e) => e.stopPropagation()}
              >
                <Button color="primary" size="sm" onClick={handleCreate}>
                  Save
                </Button>
                <Button
                  outline
                  color="primary"
                  size="sm"
                  onClick={handleRemove}
                >
                  Remove
                </Button>
              </div>
            );
          }
          return (
            <div className="d-flex gap-2" onClick={(e) => e.stopPropagation()}>
              {editingRows[rowIndex] ? (
                <>
                  <Button
                    color="primary"
                    size="sm"
                    onClick={() => saveRow(rowIndex)}
                  >
                    Lưu
                  </Button>
                  <Button
                    outline
                    color="primary"
                    size="sm"
                    onClick={() => cancelEdit(rowIndex)}
                  >
                    Hủy
                  </Button>
                </>
              ) : (
                <Button
                  color="primary"
                  size="sm"
                  onClick={() => startEditing(rowIndex)}
                >
                  Sửa
                </Button>
              )}
              <StepActionDropdown
                onAction={handleActionClick}
                data={row.original}
              />
            </div>
          );
        },
      },
    ],
    [stepText, editingRows, t],
  );

  const isCreating = data[0]?.id === undefined;

  const handleAddNewColumn = () => {
    setData([
      {
        name: "",
        description: "",
      } as Step,
      ...data,
    ]);
    setEditingRows((prev) => {
      const newEditingRows = { ...prev };
      Object.keys(newEditingRows).forEach((key: any) => {
        newEditingRows[key] = false;
      });
      return newEditingRows;
    });
  };

  return (
    <React.Fragment>
      <ListPage>
        <ListPage.BreadCrumb>
          <BreadCrumb title={stepText.title} pageTitle="Kingston" />
        </ListPage.BreadCrumb>
        <ListPage.Filter>
          <Row>
            <Col xs={6}>
              <div className="search-box me-2 mb-2 d-inline-block col-12">
                <DebouncedInput
                  name="list-step-search"
                  value={searchString || ""}
                  onChange={(value) => {
                    setSearchString(String(value));
                  }}
                />
                <i className="bx bx-search-alt search-icon"></i>
              </div>
            </Col>
            <Col xs={6} className="text-end">
              <Button
                color="primary"
                size="sm"
                onClick={() => navigate("/step/new")}
              >
                {stepText.add}
              </Button>
            </Col>
          </Row>
        </ListPage.Filter>
        <ListPage.Main>
          <EditInlineDataTable<Step>
            columns={columns}
            data={data}
            loading={isLoadingSteps}
            page={page}
            limit={limit}
            setPage={setPage}
            setLimit={setLimit}
            total={steps?.total || 0}
            onRowClick={(row) => {
              return !editingRows[row.index] && !isCreating
                ? handleActionClick("detail", row.original)
                : undefined;
            }}
            handleAddNewColumn={handleAddNewColumn}
            isCreatingNewColumn={isCreating}
          />
        </ListPage.Main>
      </ListPage>
    </React.Fragment>
  );
};

export default ListStep;
