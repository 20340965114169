import { Button } from "app/components/molecules/Button";
import { FormHelper } from "app/components/molecules/FormHelper";
import { ExcelUpload } from "app/components/organisms/ExcelUpload";
import { useAppTranslation, useGetEnableSubmitButton } from "app/hooks";
import { FC } from "react";
import { Controller, useForm } from "react-hook-form";
import { Col, Modal, ModalBody, ModalHeader } from "reactstrap";
import { ListProductAction } from "../ListProductActionDropdown";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { yupSchema } from "app/helpers/schema";

export interface UploadProductExcelFormModalProps {
  open: boolean;
  onToggle: () => void;
  onSubmit: (data: UploadProductExcelFormData) => void;
  submitting?: boolean;
  onActionClick: (action: ListProductAction) => void;
}

export interface UploadProductExcelFormData {
  file: File[];
}

export const UploadProductExcelFormModal: FC<
  UploadProductExcelFormModalProps
> = ({ onToggle, open = false, onSubmit, submitting, onActionClick }) => {
  const { buttonText, excelText } = useAppTranslation();

  const {
    control,
    handleSubmit,
    reset,
    formState: { isDirty, isValid },
  } = useForm<UploadProductExcelFormData>({
    mode: "onChange",
    resolver: yupResolver(Yup.object({ file: yupSchema.fileRequired(1, "") })),
  });

  const submit = (data: UploadProductExcelFormData) => {
    onSubmit(data);
  };

  const handleClose = () => {
    onToggle();
    reset();
  };

  const handleActionClick = (action: ListProductAction) => () => {
    onActionClick(action);
  };

  const FORM_ID = "upload-file-form";

  const isEnableSubmit = useGetEnableSubmitButton({
    isDirty,
    isValid,
    submitting,
  });

  return (
    <Modal isOpen={open} toggle={onToggle}>
      <ModalHeader className="modal-title" toggle={onToggle}>
        {excelText.uploadTitle}
      </ModalHeader>
      <ModalBody>
        <div className="d-flex align-items-center gap-1 my-2">
          <span>{excelText.uploadContent1}</span>
          <Button
            color=""
            className="link-primary text-decoration-underline p-0"
            onClick={handleActionClick("download-template")}
          >
            {excelText.uploadContent2}
          </Button>
          <span>{excelText.uploadContent3}</span>
        </div>
        <form id={FORM_ID} onSubmit={handleSubmit(submit)}>
          <Controller
            name="file"
            control={control}
            render={({ field, fieldState }) => (
              <>
                <ExcelUpload
                  value={field.value || []}
                  onChange={field.onChange}
                />
                <FormHelper message={fieldState.error?.message} />
              </>
            )}
          />
          <Col xs={12}>
            <div className="hstack gap-2 justify-content-end mt-2">
              <Button color="light" onClick={handleClose}>
                {buttonText.cancel}
              </Button>
              <Button
                disabled={!isEnableSubmit}
                type="submit"
                color="primary"
                loading={submitting}
              >
                {buttonText.save}
              </Button>
            </div>
          </Col>
        </form>
      </ModalBody>
    </Modal>
  );
};
