import { useQueryClient } from "@tanstack/react-query";
import { ColumnDef } from "@tanstack/react-table";
import { useConfirm } from "app/components/molecules/ConfirmationProvider";
import { DataTable } from "app/components/organisms/DataTable";
import { useAppTranslation } from "app/hooks";
import {
  ProductMaterialFormData,
  ProductMaterialFormModal,
} from "app/modules/product/components";
import {
  ProductMaterialAction,
  ProductMaterialActionDropdown,
} from "app/modules/product/components/ProductMaterialActionDropdown";
import {
  useRemoveProductMaterial,
  useUpdateProductMaterial,
} from "app/modules/product/hooks";
import { mapUpdateProductMaterial } from "app/modules/product/mapper";
import {
  ProductMaterial,
  RETRIEVE_PRODUCT_BY_ID_URL,
} from "app/services/product";
import { FC, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";

export interface DetailProductGeneralMaterialProps {
  data: ProductMaterial[];
  loading?: boolean;
}

export const DetailProductGeneralMaterial: FC<
  DetailProductGeneralMaterialProps
> = ({ data, loading }) => {
  const { productText } = useAppTranslation();
  const rowSelected = useRef<ProductMaterial | null>(null);
  const { confirm } = useConfirm();
  const { id } = useParams();
  const queryClient = useQueryClient();
  const columns: ColumnDef<ProductMaterial>[] = useMemo(
    () => [
      {
        header: productText.field.material,
        accessorKey: "material",
        cell: ({ row }) => row.original.material.name || "-",
      },
      {
        header: productText.field.quantity,
        accessorKey: "quantity",
        cell: ({ row }) => row.original.quantity || "-",
      },
      {
        header: productText.field.note,
        accessorKey: "note",
        cell: ({ row }) => row.original.note || "-",
      },
      {
        header: "",
        accessorKey: "action",
        size: 40,
        cell: ({ row }) => {
          return (
            <ProductMaterialActionDropdown
              data={row.original}
              onAction={handleActionClick}
            />
          );
        },
      },
    ],
    [productText],
  );

  const handleActionClick = (
    action: ProductMaterialAction,
    data?: ProductMaterial,
  ) => {
    if (data) {
      rowSelected.current = data;
    }
    switch (action) {
      case "edit":
        if (data) {
          handleToggleEditMaterialModal(data);
        }
        break;
      case "delete":
        if (data?.id) {
          confirm({
            title: productText.deleteMaterialTitle,
            description: productText.deleteMaterialContent,
            onConfirm: async () =>
              removeMaterial({
                id: id as ID,
                productMaterialId: data.id,
              }),
          });
        }
        break;
      default:
        break;
    }
  };

  const [isEditMaterialModalOpen, toggleEditMaterialModalOpen] =
    useState(false);

  const handleToggleEditMaterialModal = (data?: ProductMaterial) => {
    if (data) {
      rowSelected.current = data;
    }
    if (isEditMaterialModalOpen) {
      rowSelected.current = null;
    }
    toggleEditMaterialModalOpen((prevState) => !prevState);
  };

  const { mutateAsync: updateMaterial, isPending: isMaterialUpdating } =
    useUpdateProductMaterial({
      onSuccess: () => {
        handleToggleEditMaterialModal();
        if (id)
          queryClient.invalidateQueries({
            queryKey: [RETRIEVE_PRODUCT_BY_ID_URL(id)],
          });
      },
    });

  const { mutateAsync: removeMaterial } = useRemoveProductMaterial({
    onSuccess: () => {
      if (id)
        queryClient.invalidateQueries({
          queryKey: [RETRIEVE_PRODUCT_BY_ID_URL(id)],
        });
    },
  });

  const handleUpdateMaterial = async (data: ProductMaterialFormData) => {
    updateMaterial(
      mapUpdateProductMaterial({ ...data, id: rowSelected.current?.id as ID }),
    );
  };

  return (
    <>
      <DataTable<ProductMaterial>
        className="table-flat overflow-visible"
        columns={columns}
        data={data || []}
        loading={loading}
        total={0}
        isHiddenPagination
      />
      <ProductMaterialFormModal
        open={isEditMaterialModalOpen}
        onToggle={handleToggleEditMaterialModal}
        onSubmit={handleUpdateMaterial}
        submitting={isMaterialUpdating}
        edit={!!rowSelected.current}
        data={rowSelected.current || undefined}
      />
    </>
  );
};
