import { useAuth } from "app/components/organisms/AuthProvider";
import { errorMessageMapper } from "app/helpers";
import useToastify from "app/hooks/useToastify";
import { loginErrorMessages, useLogoutPanelMutation } from "app/services/auth";

export const useLogoutPanel = () => {
  const { logout } = useAuth();
  const toast = useToastify();
  return useLogoutPanelMutation({
    onSuccess: () => {
      logout();
    },
    onError: (error) => {
      toast.error(
        errorMessageMapper(loginErrorMessages, error.response?.data.message),
      );
    },
  });
};
