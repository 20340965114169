import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { httpClient } from "app/datasources";
import { Response } from "app/services/types";
import { AxiosError, AxiosResponse } from "axios";
import { Employee } from "./types";

export const IMPORT_EMPLOYEE_URL = "/api/admin/employees/import";

export enum ImportEmployeeError {}

export const importEmployeeErrorMessages = {};

export interface ImportEmployeeArgs {
  filePath: string;
}

export type ImportEmployeeResponse = Response<Employee>;

export const importEmployee = (args: ImportEmployeeArgs) => {
  return httpClient.post<ImportEmployeeResponse>(IMPORT_EMPLOYEE_URL, args);
};

export const useImportEmployeeMutation = (
  opts?: Omit<
    UseMutationOptions<
      AxiosResponse<ImportEmployeeResponse>,
      AxiosError<any>,
      ImportEmployeeArgs
    >,
    "mutationFn"
  >,
) =>
  useMutation<
    AxiosResponse<ImportEmployeeResponse>,
    AxiosError<any>,
    ImportEmployeeArgs
  >({
    mutationFn: importEmployee,
    mutationKey: [IMPORT_EMPLOYEE_URL],
    ...opts,
  });
