import { ListInfoItem } from "app/components/molecules/ListInfoItem";
import { useDetailPageContext } from "app/components/templates/DetailPage/DetailPageContext";
import { useAppTranslation } from "app/hooks";
import { mapBomStatus } from "app/modules/bom/helpers";
import { Bom } from "app/services/bom";
import { FC } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";

export interface DetailBomGeneralProps {}

const DetailBomGeneral: FC<DetailBomGeneralProps> = () => {
  const { bomText } = useAppTranslation();
  const { data: bom } = useDetailPageContext<Bom>();
  return (
    <div className="p-3 pb-0">
      <Row>
        <Col xs={12}>
          <Card>
            <CardBody>
              <h5 className="card-title mb-3">{bomText.detail.infomation}</h5>
              <Row className="gap-2">
                <Col xs={12}>
                  <ListInfoItem label={bomText.field.name} value={bom?.name} />
                  <ListInfoItem
                    label={bomText.field.product}
                    value={bom?.product ? bom.product.nameVn : null}
                  />
                  <ListInfoItem
                    label={bomText.field.status}
                    value={bom?.status ? mapBomStatus(bom.status) : null}
                  />
                  <ListInfoItem
                    label={bomText.field.version}
                    value={bom?.version}
                  />
                  <ListInfoItem
                    label={bomText.field.description}
                    value={bom?.description}
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default DetailBomGeneral;
