import {
  RetrieveProductMaterialByIdArgs,
  useRetrieveProductMaterialByIdQuery,
} from "app/services/product";

export const useRetrieveProductMaterialById = (
  args: RetrieveProductMaterialByIdArgs,
  opts?: Parameters<typeof useRetrieveProductMaterialByIdQuery>[1],
) => {
  const result = useRetrieveProductMaterialByIdQuery(args, opts as any);
  return {
    productMaterial: result.data?.data.data,
    isLoadingProductMaterial: result.isLoading,
    ...result,
  };
};
