import { FC } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import {
  DeactiveEmployeeIdCodeForm,
  DeactiveEmployeeIdCodeFormData,
} from "./DeactiveEmployeeIdCodeForm";
import { useAppTranslation } from "app/hooks";

export interface DeactiveEmployeeIdCodeFormModalProps {
  open: boolean;
  onToggle: () => void;
  onSubmit: (data: DeactiveEmployeeIdCodeFormData) => void;
  submitting?: boolean;
}

export const DeactiveEmployeeIdCodeFormModal: FC<
  DeactiveEmployeeIdCodeFormModalProps
> = ({ onToggle, open = false, onSubmit, submitting }) => {
  const { employeeText } = useAppTranslation();
  const title = employeeText.deactiveEmployeeIdCode;

  return (
    <Modal isOpen={open} toggle={onToggle}>
      <ModalHeader className="modal-title" toggle={onToggle}>
        {title}
      </ModalHeader>
      <ModalBody>
        <DeactiveEmployeeIdCodeForm
          onSubmit={onSubmit}
          onToggle={onToggle}
          submitting={submitting}
          defaultValues={{ notes: "" }}
        />
      </ModalBody>
    </Modal>
  );
};
