import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { httpClient } from "app/datasources";
import { readAsArrayBuffer } from "app/helpers/utils";
import useToastify from "app/hooks/useToastify";
import { FileKey, Source } from "app/models";
import { IBaseModel, Response } from "../types";
import { AxiosResponse } from "axios";

const UPLOAD_FILES_URL = "/api/admin/attachments";

interface AttachmentResponse extends IBaseModel {
  fileName: string;
  filePath: string;
  fileType: FileKey;
  moduleType: Source;
  attachmentUrl: string;
}

export type GetPresignedURLsResponse = Response<AttachmentResponse[]>;

export type UploadFilesResponse = {
  fileIds: ID[];
  fileResponse: {
    fileName: string;
    filePath: string;
  }[];
};

export type UploadFilesArgs = {
  files: File[];
  moduleType: Source;
  fileType: FileKey;
  isFormData?: boolean;
};

export type UploadPresignedURLsResponse = AxiosResponse<{
  fileName: string;
  filePath: string;
}>;

export const uploadFiles = async ({
  fileType,
  files,
  moduleType,
  isFormData = false,
}: UploadFilesArgs): Promise<UploadFilesResponse> => {
  const fileNames = files.map((file) => file.name);
  const getPresignedURLsResponse =
    await httpClient.post<GetPresignedURLsResponse>(UPLOAD_FILES_URL, {
      fileNames,
      fileType,
      moduleType,
    });

  const presigneds = getPresignedURLsResponse.data.data;
  const fileIds = presigneds.map((attachment) => attachment.id);

  const uploadToS3Promises = presigneds.map(async (presigned, index) => {
    const file = files[index];
    if (isFormData) {
      const formData = new FormData();
      formData.append("file", files[index]);
      return httpClient.post<UploadPresignedURLsResponse>(
        presigned.attachmentUrl,
        formData,
      );
    } else {
      const arrayBuffer = await readAsArrayBuffer(file);
      return httpClient.post<UploadPresignedURLsResponse>(
        presigned.attachmentUrl,
        arrayBuffer,
        {
          headers: { "Content-Type": file.type },
        },
      );
    }
  });

  const result = await Promise.all(uploadToS3Promises);
  return { fileIds, fileResponse: result.map((r) => r.data.data) };
};

export const useUploadFiles = (
  opts?: UseMutationOptions<UploadFilesResponse, any, UploadFilesArgs>,
) => {
  const { error } = useToastify();
  return useMutation({
    mutationFn: uploadFiles,
    onError: () => {
      error();
    },
    ...opts,
  });
};
