import { ColumnDef } from "@tanstack/react-table";
import { Chip } from "app/components/atoms/Chip";
import { AvatarRow } from "app/components/molecules/AvatarRow";
import { DataTable } from "app/components/organisms/DataTable";
import ListPage from "app/components/templates/ListPage";
import { useAppTranslation } from "app/hooks";
import useAppSearch from "app/hooks/useAppSearch";
import { ProductAction } from "app/modules/product/components";
import { useRetrieveComponents } from "app/modules/product/hooks";
import { Component } from "app/services/product";
import { OptionSelect } from "app/services/types";
import BreadCrumb from "Components/Common/BreadCrumb";
import "gridjs/dist/theme/mermaid.css";
import { FC, useMemo, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "reactstrap";
import { ListComponentFilter } from "./ListComponentFilter";
import { ListComponentFilterChips } from "./ListComponentFilterChips";

interface ListComponentProps {}

const ListComponent: FC<ListComponentProps> = () => {
  const navigate = useNavigate();
  const rowSelected = useRef<Component | null>(null);
  const { componentText } = useAppTranslation();

  const {
    page,
    limit,
    searchString,
    setPage,
    setLimit,
    setSearchString,
    customParams,
    setCustomParams,
  } = useAppSearch<{ type: OptionSelect | null }>({
    initialPage: 1,
    initialLimit: 20,
    initialSearchString: "",
    initialCustomParams: {
      type: null,
    },
  });

  const handleClearFilter = () => {
    setCustomParams({
      type: null,
    });
  };

  const { components, isLoadingComponents } = useRetrieveComponents({
    limit,
    page,
    searchString: searchString || undefined,
    typeId: customParams.type ? customParams.type.value : undefined,
  });

  const handleActionClick = (action: ProductAction, data?: Component) => {
    if (data) {
      rowSelected.current = data;
    }
    switch (action) {
      case "detail":
        if (data) {
          navigate(`/component/${data.id}`);
        }
        break;
      default:
        break;
    }
  };

  const columns: ColumnDef<Component>[] = useMemo(
    () => [
      {
        header: componentText.field.name,
        accessorKey: "name",
        size: 300,
        cell: ({ row }) => (
          <AvatarRow
            name={row.original.name}
            avatarUrl={row.original.componentImage?.attachmentUrl}
          />
        ),
      },
      {
        header: componentText.field.code,
        accessorKey: "code",
        size: 100,
      },
      {
        header: componentText.field.type,
        accessorKey: "type",
        cell: ({ row }) =>
          row.original.type?.name ? (
            <Chip
              size="sm"
              color={row.original.type?.colorCode}
              label={row.original.type?.name}
            />
          ) : (
            "-"
          ),
      },
      {
        header: componentText.field.frameType,
        accessorKey: "frameType",
        cell: ({ row }) =>
          row.original.frameType?.name ? (
            <Chip
              size="sm"
              color={row.original.frameType?.colorCode}
              label={row.original.frameType?.name}
            />
          ) : (
            "-"
          ),
      },
      {
        header: componentText.field.workpieceType,
        accessorKey: "workpieceType",
        cell: ({ row }) =>
          row.original.workpieceType?.name ? (
            <Chip
              size="sm"
              color={row.original.workpieceType?.colorCode}
              label={row.original.workpieceType?.name}
            />
          ) : (
            "-"
          ),
      },
      {
        header: componentText.field.name,
        accessorKey: "name",
        size: 200,
        cell: ({ row }) => row.original.name || "-",
      },
      {
        header: componentText.field.thicknessCm,
        accessorKey: "thicknessCm",
        cell: ({ row }) => row.original.thicknessCm || "-",
      },
      {
        header: componentText.field.lengthOfBoxCm,
        accessorKey: "lengthOfBoxCm",
        cell: ({ row }) => row.original.lengthOfBoxCm || "-",
      },
      {
        header: componentText.field.widthOfBoxCm,
        accessorKey: "widthOfBoxCm",
        cell: ({ row }) => row.original.widthOfBoxCm || "-",
      },
      {
        header: componentText.field.lengthOfDetailCm,
        accessorKey: "lengthOfDetailCm",
        cell: ({ row }) => row.original.lengthOfDetailCm || "-",
      },
    ],
    [componentText],
  );

  return (
    <ListPage>
      <ListPage.BreadCrumb>
        <BreadCrumb title={componentText.title} pageTitle="Kingston" />
      </ListPage.BreadCrumb>
      <ListPage.Filter>
        <Row>
          <Col xs={6}>
            <ListComponentFilter
              searchString={searchString}
              handleSearchChange={setSearchString}
              filterParams={{
                type: customParams.type,
              }}
              setCustomParams={setCustomParams}
            />
          </Col>
          <Col xs={12}>
            <ListComponentFilterChips
              filterParams={{
                type: customParams.type,
              }}
              setCustomParams={setCustomParams}
              handleClearFilter={handleClearFilter}
            />
          </Col>
        </Row>
      </ListPage.Filter>
      <ListPage.Main>
        <DataTable<Component>
          columns={columns}
          data={components?.result || []}
          loading={isLoadingComponents}
          page={page}
          limit={limit}
          setPage={setPage}
          setLimit={setLimit}
          total={components?.total || 0}
          onRowClick={(row) => handleActionClick("detail", row)}
          pinLeftColumns={["name", "code"]}
        />
      </ListPage.Main>
    </ListPage>
  );
};

export default ListComponent;
