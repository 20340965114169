import {
  RetrieveProductByIdArgs,
  useRetrieveProductByIdQuery,
} from "app/services/product";

export const useRetrieveProductById = (
  args: RetrieveProductByIdArgs,
  opts?: Parameters<typeof useRetrieveProductByIdQuery>[1],
) => {
  const result = useRetrieveProductByIdQuery(args, opts as any);
  const images =
    result.data?.data.data.attachments?.filter(
      (attachment) => attachment.fileType === "image",
    ) || [];
  const documents =
    result.data?.data.data.attachments?.filter(
      (attachment) => attachment.fileType === "document",
    ) || [];
  return {
    product: { ...result.data?.data.data, images, documents },
    isLoadingProduct: result.isLoading,
    ...result,
  };
};
