import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "app/components/molecules/Button";
import { FormHelper } from "app/components/molecules/FormHelper";
import { FormInput } from "app/components/molecules/FormInput";
import { FormLabel } from "app/components/molecules/FormLabel";
import { yupSchema } from "app/helpers/schema";
import { useAppTranslation, useGetEnableSubmitButton } from "app/hooks";
import { FC, FormEvent } from "react";
import { Controller, useForm } from "react-hook-form";
import { Alert, Col, Form, FormGroup, Row } from "reactstrap";
import * as Yup from "yup";

export interface CheckInPanelTicketFormProps {
  defaultValues?: CheckInPanelTicketFormData;
  onSubmit: (data: CheckInPanelTicketFormData) => void;
  submitting?: boolean;
  onToggle: () => void;
}

export interface CheckInPanelTicketFormData {
  quantity: number | null;
  note: string;
  totalQuantity?: number;
  remainingQuantity?: number;
}

export const CheckInPanelTicketForm: FC<CheckInPanelTicketFormProps> = ({
  onSubmit,
  defaultValues,
  submitting,
  onToggle,
}) => {
  const { ticketText, buttonText } = useAppTranslation();
  const {
    control,
    handleSubmit,
    getValues,
    formState: { isDirty, isValid },
  } = useForm<CheckInPanelTicketFormData>({
    mode: "onChange",
    resolver: yupResolver(
      Yup.object({
        quantity: yupSchema
          .numberMoreThanRequired(
            0,
            "ticket.error.quantityMoreThanZero",
            "ticket.error.quantityRequired",
          )
          .max(
            Yup.ref("remainingQuantity"),
            "ticket.error.quantityMoreThanRemaining",
          ),
        note: yupSchema.stringNotRequired(),
      }),
    ),
    defaultValues,
  });

  const isEnableSubmit = useGetEnableSubmitButton({
    isDirty,
    isValid,
    submitting,
  });

  const submit = async (e: FormEvent) => {
    e.preventDefault();
    e.stopPropagation();
    handleSubmit(onSubmit)();
  };

  return (
    <Form onSubmit={submit} id="master-data-form">
      <Row>
        <Col xs={12}>
          <Alert color="info" fade={false}>
            <div>Tổng số lượng: {getValues("totalQuantity")}</div>
            <div>
              Số lượng có thể check in: {getValues("remainingQuantity")}
            </div>
          </Alert>
        </Col>
        <Col xs={12}>
          <FormGroup>
            <FormLabel required htmlFor="quantity" className="form-label">
              {ticketText.field.quantity}
            </FormLabel>
            <Controller
              name="quantity"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <FormInput
                    {...field}
                    value={field.value ?? ""}
                    onChange={(value) => {
                      field.onChange(
                        value.target.value === ""
                          ? null
                          : Number(value.target.value),
                      );
                    }}
                    type="number"
                    className="form-control"
                    id="quantity"
                    invalid={!!fieldState.error}
                  />
                  <FormHelper message={fieldState.error?.message} />
                </>
              )}
            />
          </FormGroup>
        </Col>
        <Col xs={12}>
          <FormGroup>
            <FormLabel htmlFor="note" className="form-label">
              {ticketText.field.note}
            </FormLabel>
            <Controller
              name="note"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <FormInput
                    {...field}
                    type="text"
                    className="form-control"
                    id="description"
                    placeholder="Nhập"
                    invalid={!!fieldState.error}
                  />
                  <FormHelper message={fieldState.error?.message} />
                </>
              )}
            />
          </FormGroup>
        </Col>
        <Col xs={12}>
          <div className="hstack gap-2 justify-content-end">
            <Button color="light" onClick={onToggle}>
              {buttonText.cancel}
            </Button>
            <Button
              disabled={!isEnableSubmit}
              type="submit"
              color="primary"
              loading={submitting}
            >
              {buttonText.save}
            </Button>
          </div>
        </Col>
      </Row>
    </Form>
  );
};
